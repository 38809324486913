import styled from 'styled-components';

import inProgressBanner from '../../../assets/images/in-progress-banner.png';
import { theme } from '../../../styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 480px) {
    width: 100%;
    min-height: 77%;
  }
`;

export const Header = styled.section`
  background: url(${inProgressBanner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  object-fit: contain;
  @media (max-width: 480px) {
    margin-bottom: 30px;
  }

  h1 {
    padding-left: 63px;
    font-size: 7rem;
    color: ${theme.colors.textLight};
    position: relative;
    z-index: 1;

    span {
      color: ${theme.colors.text};
      position: absolute;
      font-size: 8rem;
      opacity: 0.2;
      bottom: 10px;
      left: 24px;
      z-index: -1;
    }
  }

  @media (max-width: 1100px) {
    h1 {
      font-size: 2rem;
      z-index: 0;

      span {
        font-size: 5rem;
      }
    }
  }

  @media (max-width: 700px) {
    h1 {
      font-size: 2rem;

      span {
        font-size: 3rem;
      }
    }
  }
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  padding: 192px 24px 24px 24px;
  background: linear-gradient(
    90deg,
    rgba(89, 74, 173, 0.83) -5.53%,
    rgba(159, 151, 201, 0.3818) 60.04%,
    rgba(47, 24, 156, 0.83) 131.32%
  );

  @media (max-width: 650px) {
    padding: 80px 24px 24px 24px;
  }

  @media (max-width: 480px) {
    h1 {
      font-weight: bold;
      font-size: 40px;
      padding: 0;
    }

    span {
      font-size: 36px;
    }
  }
`;

export const BreadcrumbMobile = styled.span`
  display: flex;
  margin-bottom: 20px;

  a {
    display: flex;
    text-decoration: unset;

    img {
      padding-top: 2px;
      margin-right: 24px;
    }

    p {
      color: #fff;
      font-weight: 600;
      font-size: 16px;
    }
  }
`;

export const Content = styled.main`
  align-self: center;
  padding: 64px 187px;
  min-width: 1300px;

  section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;

    h2 {
      font-size: 1.4rem;
      font-weight: 400;
      color: ${theme.colors.textSecondary};
    }
  }

  @media (max-width: 1100px) {
    section {
      h1 {
        font-size: 1.5rem;
      }
    }
  }

  @media (max-width: 900px) {
    section {
      h1 {
        font-size: 1.4rem;
        text-align: center;
      }
    }
  }

  @media (max-width: 700px) {
    padding: 32px 90px;
  }

  @media (max-width: 650px) {
    section {
      flex-direction: column;

      h1 {
        margin-bottom: 16px;
        text-align: center;
        margin-right: 0;
      }
    }
  }

  @media (max-width: 500px) {
    padding: 16px 25px;
  }
`;

export const TopicLabel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 280px;
  margin-bottom: 64px;

  span {
    cursor: pointer;
    font-size: 1.1rem;
    color: ${theme.colors.text};

    font-weight: 500;
    border-bottom: 4px solid ${theme.colors.secondary};

    &::after {
      padding-bottom: 5px;
      content: '';
      display: block;
      width: 0;
      border-bottom: 4px solid ${theme.colors.secondary};
      transition: width 0.3s;
    }
  }

  @media (max-width: 1100px) {
    width: 300px;

    span {
      font-size: 1rem;
    }
  }
`;

export const Form = styled.form`
  fieldset {
    flex: 1;
    width: 900px;
    margin: 0 auto;
  }

  .editor {
    background: #fff;
    border: 1px solid #e1e3e6;
    border-radius: 8px;
    width: 900px;
    margin: 0 auto;
  }

  .rdw-editor-main {
    padding: 0 8px 8px;
  }
`;

export const FormHeader = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  > button {
    text-align: center;
    letter-spacing: 0.4px;
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    background: #2f189c;
    border-radius: 30px;
    padding: 12px 32px;

    position: absolute;
    right: -133px;
  }
`;

export const UserAvatar = styled.div`
  position: absolute;
  left: -60px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;

export const ErrorMessage = styled.span`
  display: block;
  font-size: 0.8rem;
  color: ${theme.colors.error};
  text-align: start;
  width: 100%;
  margin-top: 8px;
  margin-left: 10px;
`;

export const Preview = styled.section`
  display: flex;
  flex-direction: column;

  margin-top: 32px;
  padding: 16px;

  h3 {
    font-size: 1.2rem;
    color: ${theme.colors.secondary};
    margin-bottom: 24px;
  }
`;

export const PreviewContainer = styled.article`
  padding: 16px;

  background: #fff;
  border: 2px solid #e1e3e6;
  border-radius: 8px;

  width: 100%;
  max-width: 900px;

  position: relative;

  section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 2.1rem;
      font-weight: 600;
      color: ${theme.colors.text};
      margin-right: 25px;

      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
`;

export const PreviewRichText = styled.article`
  max-width: 900px;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 24px;

  img {
    margin-right: 9px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  @media (max-width: 650px) {
    margin-top: 21px;
  }

  @media (max-width: 500px) {
    width: fit-content;
    flex-wrap: wrap;
  }
`;

export const GamificationBadge = styled.span`
  margin: 0 16px;
  padding: 4px 12px;

  font-size: 0.8rem;
  font-weight: 600;
  color: ${theme.colors.light};

  background: ${theme.colors.primary};
  border-radius: 30px;
`;

export const UserName = styled.span`
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Published = styled.span`
  font-size: 0.8rem;

  @media (max-width: 500px) {
    margin-top: 8px;
    width: 100%;
    margin-left: 16px;
    color: ${theme.colors.text};
  }
`;
