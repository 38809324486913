import React from 'react';
import { components } from 'react-select';

import { ReactComponent as GlobeIcon } from '../../../../assets/icons/globe.svg';

const ValueContainerGlobeIcon = ({ children, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && <GlobeIcon style={{ marginRight: 12 }} />}
        {children}
      </components.ValueContainer>
    )
  );
};

export default ValueContainerGlobeIcon;
