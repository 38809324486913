import styled from 'styled-components';

const HomeMastersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
  margin: 0 -9999px;
  padding: 0 9999px;
  position: relative;

  padding-top: 63px;
  padding-bottom: 126px;

  h2 {
    font-size: 2.2em;
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 40px;
  }

  .carousel {
    width: 100%;

    .carousel__back-button,
    .carousel__next-button {
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 8px;
      background: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 50%;
    }

    .carousel__back-button,
    .carousel__next-button {
      transform: translate(-0%, 500%);
    }

    .carousel__back-button {
      left: 40px;

      @media (min-width: 430px) {
        transform: translate(-0%, 350%);
      }

      @media (min-width: 475px) {
        transform: translate(-0%, 500%);
      }

      @media (min-width: 1600px) {
        transform: translate(-0%, 50%);
      }
    }

    .carousel__next-button {
      right: 40px;

      @media (min-width: 430px) {
        transform: translate(0%, 350%);
      }

      @media (min-width: 475px) {
        transform: translate(0%, 500%);
      }

      @media (min-width: 1600px) {
        transform: translate(0%, 50%);
      }
    }

    .carousel__dot-group {
      position: absolute;
      bottom: -40px;
      left: 0;
      right: 0;
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      max-width: 300px;
      justify-content: center;
      gap: 8px;
      width: 100%;

      .carousel__dot {
        width: 32px;
        height: 6px;
        background: #909599;

        &.carousel__dot--selected {
          background: var(--default-blue);
        }
      }
    }

    @media (min-width: 768px) {
      .carousel__dot-group {
        display: none;
      }
    }
  }
`;

export { HomeMastersContainer };
