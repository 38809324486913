import React from 'react';
import { components } from 'react-select';

import { ReactComponent as FlagIcon } from '../../../../assets/icons/flag.svg';

const ValueContainerFlagIcon = ({ children, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && <FlagIcon style={{ marginRight: 12 }} />}
        {children}
      </components.ValueContainer>
    )
  );
};

export default ValueContainerFlagIcon;
