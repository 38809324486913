import React from 'react';

import UnloggedFooter from './components/UnloggedFooter';
import UnloggedHeader from './components/UnloggedHeader';
import { UnloggedContent, UnloggedContainer } from './style';

const Unlogged = ({ children }) => {
  return (
    <UnloggedContainer>
      <UnloggedHeader />
      <UnloggedContent>{children}</UnloggedContent>
      <UnloggedFooter />
    </UnloggedContainer>
  );
};

export default Unlogged;
