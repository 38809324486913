import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 48px;
  margin-top: auto;
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.colors.primary} 0%,
    ${({ theme }) => theme.colors.secondary} 100%
  );

  img {
    max-width: 280px;
  }

  hr {
    border: 1px solid rgba(255, 255, 255, 0.1);
    margin: 25px 0;
    width: 100%;
  }

  a {
    svg {
      margin-right: 8px;
    }

    span {
      width: 100%;
    }

    display: flex;
    align-items: center;
    flex-wrap: wrap;

    text-decoration: none;
    text-align: center;
    color: #fff;

    margin: 20px 0;
  }

  @media (min-width: 1100px) {
    a {
      text-align: start;
      justify-content: center;
    }
  }
`;

export const FooterHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: 1100px) {
    flex-direction: row;
    justify-content: space-between;

    img {
      max-width: none;
    }

    hr {
      display: none;
    }
  }

  a {
    color: white;
    text-decoration: unset;
    display: flex;
  }

  span {
    white-space: nowrap;
  }
`;

export const LeftInfoNetworks = styled.div`
  padding: 0 10px;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 480px) {
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;
  }

  div {
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    @media (max-width: 480px) {
      margin: 0 auto;
      padding: 15px 10px 0 0;
      position: relative;
      left: 8px;
    }

    p {
      color: white;
    }

    a {
      color: white;
      text-decoration: unset;
    }

    span {
      white-space: nowrap;
    }
  }
`;

export const ContentFooterTopMobile = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 1024px) {
    display: none;
  }
  @media (max-width: 480px) {
    display: flex;
  }
`;

export const ContentFooterTop = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 1024px) {
    display: flex;
  }
  @media (max-width: 480px) {
    display: none;
  }
`;

export const HeaderLinks = styled.div`
  text-align: center;

  @media (min-width: 1100px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;
    gap: 12px;

    a {
      display: inline-block;
      text-align: right;
      margin: 0;
    }
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 1100px) {
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-template-areas:
      'link link'
      'link link'
      'link link'
      'contact contact';

    .contact {
      grid-area: contact;
      text-align: center;
    }
  }
`;

export const Copyright = styled.div`
  text-align: center;
  color: #fff;
`;
