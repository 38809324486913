import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 0.9rem;
    margin-bottom: 4px;
  }
`;
