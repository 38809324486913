import styled from 'styled-components';

import { theme } from '../../styles/theme';

export const ErrorMessage = styled.span`
  display: block;
  font-size: 0.8rem;
  color: ${theme.colors.error};
  text-align: start;
  width: 100%;
  margin: 8px 0 0 10px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;

  border-radius: 6px;
  border: 1px solid
    ${({ isInvalid }) =>
      isInvalid ? theme.colors.error : theme.colors.shapeSecondary};
  background-color: #f5f8fa;

  padding: 16px 12px;
  min-width: 287px;
  min-height: 122px;

  textarea {
    width: 100%;
    margin-left: 12px;
    min-width: 287px;
    min-height: 122px;
    background-color: #f5f8fa;
    font-size: 16px;
    font-weight: 400;
    resize: none;

    &::placeholder {
      color: #adb5bd;
    }
  }

  @media (max-width: 470px) {
    min-width: 300px;
  }
`;
