import ProgressBar from '@ramonak/react-progress-bar';
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import iconHidden from '../../assets/icons/icon-hidden-sidebar.svg';
import iconNavToggle from '../../assets/icons/icon-nav-toggle.svg';
import { useExecutionCourse } from '../../hooks/executionCourse';
import useWindowSize from '../../hooks/useWindowSize';
import { updateContentProgress } from '../../services/ContentUser';
import { theme } from '../../styles/theme';
import { goToTop } from '../../utils/helpers';

import Exams from './Exams';
import Modules from './Modules';
import {
  SidebarContainer,
  Container,
  Header,
  HeaderTop,
  HeaderBottom,
  Content,
} from './styles';

function Sidebar({ data: course, parentUuid, modules }) {
  const [sidebarOpen, setOpenSidebar] = useState(true);

  const dropSidebarMobile = useRef(null);
  const { setWindowSize, windowSize } = useWindowSize();
  const { data } = useExecutionCourse();

  const [examUserData, setExamUserData] = useState();

  const currentModule = document.getElementById(course?.current_module_id);

  function showSidebar() {
    setOpenSidebar(state => !state);
    // Atualizar Window Size na intenção de recalcular a altura ideal do player
    const minimalValue = 0.001;
    setWindowSize(windowSize + minimalValue);

    if (!sidebarOpen && windowSize < 1101) {
      goToTop();
      document.body.style.overflow = 'hidden';
    }
    if (sidebarOpen && windowSize < 1101) {
      document.body.style.overflow = 'auto';
    }
  }

  useEffect(() => {
    openCurrentModule(currentModule);
  }, [currentModule]);

  useEffect(() => {
    if (windowSize < 1101) {
      setOpenSidebar(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function openCurrentModule(elem) {
    if (elem === null) {
      return null;
    }
    const items = elem.parentElement.getElementsByTagName('ul');
    items[0].style.display = 'block';
    elem.getElementsByTagName('span')[0].classList.add('active');
    return true;
  }

  const percentage = useMemo(() => {
    if (!modules.length) return 0;

    // considere exam como lesson tb
    const totalLessons = [];

    modules.map(module =>
      module?.children?.map(lesson => totalLessons.push(lesson)),
    );

    if (
      course?.data?.exams &&
      course?.data?.exams[0]?.block_certificate &&
      examUserData
    ) {
      totalLessons.push(examUserData);
    }
    const totalLessonsFinished = totalLessons?.filter(
      lesson => lesson?.content_user?.finish_at || lesson?.finish_at,
    );

    const totalLessonsNumber = totalLessons?.length;

    const totalLessonsFinishedNumber = totalLessonsFinished?.length;

    const progress = (totalLessonsFinishedNumber / totalLessonsNumber) * 100;
    return progress;
  }, [course.data.exams, examUserData, modules]);

  useEffect(() => {
    const updateProgress = async () => {
      await updateContentProgress(course.data.content_id, percentage);
    };
    if (course.data.content_id && percentage) updateProgress();
  }, [course.data.content_id, percentage]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropSidebarMobile.current &&
        !dropSidebarMobile.current.contains(event.target)
      ) {
        setOpenSidebar(false);
        document.body.style.overflow = 'auto';
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropSidebarMobile]);

  if (course)
    return (
      <SidebarContainer>
        {sidebarOpen && (
          <>
            <Container ref={dropSidebarMobile}>
              <Header>
                <HeaderTop>
                  <h3>Conteúdo do curso</h3>
                  <div>
                    <img
                      onClick={showSidebar}
                      onKeyPress={showSidebar}
                      src={iconNavToggle}
                      aria-hidden="true"
                      alt="Mostrar/Esconder"
                      className="hidden-mobile"
                    />
                    <button
                      onClick={showSidebar}
                      onKeyPress={showSidebar}
                      className="hidden-desktop"
                    >
                      <AiOutlineClose
                        style={{
                          color: '#594aad',
                          width: '20px',
                          height: '20px',
                        }}
                      />
                    </button>
                  </div>
                </HeaderTop>
                <HeaderBottom>
                  <p>Progresso</p>
                  <span />
                  <ProgressBar
                    completed={percentage || 0}
                    ariaValuetext={percentage || 0}
                    borderRadius="10px"
                    height="8px"
                    isLabelVisible={false}
                    bgColor={theme.colors.secondary}
                    baseBgColor={theme.colors.shapeSecondary}
                    width="100%"
                  />
                </HeaderBottom>
              </Header>
              {course && (
                <Content>
                  {course?.data?.children?.length > 0 && (
                    <Modules
                      parentUuid={parentUuid}
                      setOpenSidebar={setOpenSidebar}
                      modules={modules}
                    />
                  )}
                  {course?.data?.exams?.length > 0 && (
                    <Exams
                      course={course}
                      parentUuid={parentUuid}
                      setOpenSidebar={setOpenSidebar}
                      setExamUserData={setExamUserData}
                    />
                  )}
                </Content>
              )}
            </Container>
          </>
        )}
        {!sidebarOpen && (
          <img
            onClick={showSidebar}
            onKeyPress={showSidebar}
            src={iconHidden}
            aria-hidden="true"
            alt=""
          />
        )}
      </SidebarContainer>
    );
  return null;
}

export default Sidebar;
