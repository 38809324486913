import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ContentFinishedImg } from '../../../assets/icons/content-finished.svg';
import { ReactComponent as ChakraIcon } from '../../../assets/icons/fire.svg';
import StarsImg from '../../../assets/images/stars.png';
import { Button } from '../../../components/Button';
import { useAuth } from '../../../hooks/auth';
import { useExecutionCourse } from '../../../hooks/executionCourse';
import { getLevels } from '../../../services/gamification';
import { UserService } from '../../../services/userService';
import { theme } from '../../../styles/theme';

import ModalRating from './ModalRating';
import ProgressBar from './ProgressBar';
import {
  Container,
  GamificationProgress,
  RateContainer,
  NextLevelInfo,
} from './styles';

const RateCourse = ({ contentType, contentName, contentId, points = 0 }) => {
  const [showModalRating, setShowModalRating] = useState(false);
  const [nextLevelToAscend, setNextLevelToAscend] = useState(false);
  const history = useHistory();
  const { resetExecutionData } = useExecutionCourse();

  const { user } = useAuth();

  useEffect(() => {
    const fetchUserData = async () => {
      const userService = new UserService();

      const response = await userService.getUserLevel();

      const levels = await getLevels();

      const closestLevel = levels.data.reduce((prev, curr) => {
        return Math.abs(curr?.level_range?.start - response.data?.range?.end) <
          Math.abs(prev?.level_range?.start - response.data?.range?.end)
          ? curr?.level_range?.start
          : prev?.level_range?.start;
      });

      const nextLevel = levels.data.find(
        item => item?.level_range?.start === closestLevel,
      );

      setNextLevelToAscend(nextLevel);
    };

    fetchUserData();

    return () => {
      resetExecutionData();
    };
  }, [resetExecutionData]);

  const percentageCompleted = (
    (user?.points + points) /
    user?.level?.range?.end
  ).toFixed(0);

  return (
    <Container>
      <ContentFinishedImg alt="Concluído" />

      <p>
        <b>Parabéns, {user?.level?.name}</b> você concluiu
        <b>{contentName}!</b>
      </p>

      <GamificationProgress>
        <span>
          +{points} pontos obtidos
          <ChakraIcon />
        </span>

        <ProgressBar
          completed={Number(percentageCompleted) * 100}
          ariaValuetext={percentageCompleted || 0}
          maxCompleted={100}
          bgColor={theme.colors.primary}
          baseBgColor={theme.colors.shapeSecondary}
        />

        <NextLevelInfo>
          <span>
            <b>{user?.points + points}</b>/{user?.level?.range?.end} pontos
          </span>
          <p>{nextLevelToAscend.level_name}</p>
        </NextLevelInfo>
      </GamificationProgress>

      {showModalRating ? (
        <ModalRating
          {...{ contentType, contentName, contentId, setShowModalRating }}
        />
      ) : null}

      <Button
        title="ENCONTRAR MAIS CURSOS"
        onClick={() => history.push('/home')}
        containerStyle={{ maxWidth: 387, marginTop: 50, marginBottom: 40 }}
      />

      <RateContainer onClick={() => setShowModalRating(true)}>
        <p>Avalie seu {contentType}:</p>
        <img src={StarsImg} alt="Estrelas para avaliação" />
      </RateContainer>
    </Container>
  );
};

export default RateCourse;
