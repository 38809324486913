import React, { useState } from 'react';
import { GoEye, GoEyeClosed } from 'react-icons/go';

import { Input } from '../Input';

import { Button, Wrapper } from './styles';

export function InputPassword({ ...props }) {
  const [seePassword, setSeePassword] = useState(false);

  const handleSeePassword = () => {
    setSeePassword(!seePassword);
  };

  return (
    <Wrapper>
      <Input
        {...props}
        type={seePassword ? 'text' : 'password'}
        containerStyle={{
          paddingRight: '2.5rem',
        }}
      />

      <Button type="button" onClick={handleSeePassword}>
        {seePassword ? <GoEye /> : <GoEyeClosed />}
      </Button>
    </Wrapper>
  );
}
