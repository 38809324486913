/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import iconBack from '../../assets/icons/icon-back.svg';
import Breadcrumbs from '../../components/Breadcrumbs';
import CourseContent from '../../components/CourseContent';
import { Exam } from '../../components/Exam';
import ModalAscendLevel from '../../components/ModalAscendLevel';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import { toast } from '../../components/Toast/Toast';
import { useAuth } from '../../hooks/auth';
import { useExecutionCourse } from '../../hooks/executionCourse';
import * as ContentService from '../../services/Content';
import { goToTop } from '../../utils/helpers';
import RateCourse from '../Course/RateCourse';

import {
  Container,
  Content,
  IconBackMobile,
  Background,
  TextMobile,
  LoadingContainer,
  SpinnerContainer,
} from './styles';

const MyCourseLive = () => {
  // uuid: id do conteudo
  // parentUuid: id do curso
  const { uuid, parentUuid } = useParams();
  const [skipCount, setSkipCount] = useState(true);
  const [exams, setExams] = useState([]);
  const [isRetreat, setIsRetreat] = useState(null);
  const [sideBarModules, setSideBarModules] = useState([{}]);
  const { location } = useHistory();
  const history = useHistory();
  const {
    storeCourseData,
    changeCurrentLesson,
    resetExecutionData,
    getModulesWithLock,
    getSidebarModulesWithLock,
    data,
    setData,
    updateModules,
  } = useExecutionCourse();

  const handleGoBack = () => {
    history.goBack();
  };

  const REDIRECT_TO_LAST_WATCHED_LESSON =
    history?.location?.state?.redirectToLastWatchedLesson;

  const getCourse = useCallback(async () => {
    if (skipCount) {
      setSkipCount(false);
      await ContentService.getById(parentUuid)
        .then(response => {
          storeCourseData(
            response?.data,
            history?.location?.state?.redirectToLastWatchedLesson,
          );
          if (response.data?.data?.exams) {
            setExams(response.data?.data?.exams);
          }
          setIsRetreat(response.data.data?.type === 'retiro');
          //
          const modulesUpdated = getModulesWithLock(response.data.data);

          setSideBarModules(modulesUpdated);
          //
        })
        .catch(() => {
          toast.error(
            'Ocorreu um erro ao buscar os dados do curso, recarregue a página...',
          );
        });
      return null;
    }
    if (!skipCount) {
      changeCurrentLesson({});
    }
    if (!location.pathname.includes('exam')) {
      ContentService.getLessonById(parentUuid, uuid)
        .then(response => {
          changeCurrentLesson(response.data);
        })
        .catch(err => {
          if (err.status === 402) {
            toast.warn('Você não possui acesso a este conteúdo.');
            setTimeout(() => {
              history.goBack();
            }, 2000);
            return;
          }
          toast.error(
            'Ocorreu um erro ao buscar os dados da aula, recarregue a página.',
          );
        });
    }
    ContentService.getById(parentUuid)
      .then(response => {
        const modulesUpdated = getSidebarModulesWithLock(response.data.data);
        setSideBarModules(modulesUpdated);
      })
      .catch(() => {
        toast.error(
          'Ocorreu um erro ao buscar os dados do curso, recarregue a página.',
        );
      });
    return null;
  }, [
    changeCurrentLesson,
    getModulesWithLock,
    getSidebarModulesWithLock,
    history,
    location.pathname,
    parentUuid,
    skipCount,
    storeCourseData,
    uuid,
  ]);

  useEffect(() => {
    setData(state => ({
      ...state,
      isLoading: true,
    }));
    getCourse();
    setData(state => ({
      ...state,
      isLoading: false,
    }));
  }, [getCourse, setData, updateModules, data?.course?.data?.content_id]);

  useEffect(() => {
    goToTop();

    return () => {
      resetExecutionData();
    };
  }, [resetExecutionData]);

  const totalCoursePoints = useMemo(() => {
    const sumPoints = course => {
      let total = course.info?.gamification_points || 0;
      course.children?.forEach(element => {
        total += sumPoints(element);
      });
      return total;
    };

    return sumPoints(data?.course?.data || {});
  }, [data?.course?.data]);

  if (data.modules.length)
    return (
      <Container>
        <Background>
          <img src={data?.course?.data?.images?.banner_url || ''} alt="" />
          <Breadcrumbs
            className="hidden-mobile"
            links={[
              {
                name: isRetreat ? 'Vivências' : 'Cursos',
                url: isRetreat ? '/vivencias' : '/cursos',
              },
              {
                name: data?.course?.data?.name,
                url: isRetreat
                  ? `/curso/detalhe/live/${data?.course?.data?.content_id}`
                  : `/curso-detalhe/${data?.course?.data?.content_id}`,
              },
              {
                name:
                  data?.courseStep === 'exam'
                    ? data?.course?.data?.exams[0]?.title
                    : data?.currentLesson?.name,
                url: null,
              },
            ]}
          />
        </Background>
        <IconBackMobile className="hidden-desktop">
          <img src={iconBack} alt="Ícone Voltar" />
          <span
            onKeyPress={handleGoBack}
            role="button"
            tabIndex="0"
            onClick={handleGoBack}
          >
            Voltar
          </span>
        </IconBackMobile>
        <TextMobile>
          <h2 className="hidden-desktop">{data?.course?.data?.name}</h2>
        </TextMobile>
        <Content>
          <h3 className="hidden-desktop">Conteúdo do curso</h3>
          {data?.isLoading && (
            <LoadingContainer>
              <SpinnerContainer>
                <Spinner />
                <span>Carregando...</span>
              </SpinnerContainer>
            </LoadingContainer>
          )}
          {data?.course && (
            <Sidebar
              parentUuid={parentUuid}
              data={data?.course}
              modules={sideBarModules}
            />
          )}
          {!data?.isLoading && data?.courseStep === 'inProgress' && (
            <CourseContent
              data={
                REDIRECT_TO_LAST_WATCHED_LESSON
                  ? data?.nextLesson
                  : data?.currentLesson
              }
              course={data?.course}
            />
          )}
          {!data?.isLoading && data?.courseStep === 'exam' && exams[0] && (
            <>
              <Exam exam={exams[0]} />
            </>
          )}
          {!data?.isLoading && data?.courseStep === 'finished' && (
            <RateCourse
              contentType="curso"
              contentName={data?.course?.data?.name || ''}
              contentId={data?.course?.data?.content_id || ''}
              points={totalCoursePoints}
            />
          )}
          {!data?.isLoading && data?.hasReachedLevelPoints && (
            <ModalAscendLevel />
          )}
        </Content>
      </Container>
    );
  return null;
};

export default MyCourseLive;
