import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AiFillCreditCard, AiFillIdcard, AiOutlineUser } from 'react-icons/ai';
import { BsFillCalendarFill, BsPaypal } from 'react-icons/bs';
import { FaRegAddressCard } from 'react-icons/fa';
import { RiBillLine } from 'react-icons/ri';
import Select from 'react-select';
import { toast } from 'react-toastify';

import CloseIcon from '../../../../assets/icons/close-icon.png';
import PixIcon from '../../../../assets/icons/pix.png';
import AddressContainerIcon from '../../../../components/AddressIcon';
import { Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';
import Spinner from '../../../../components/Spinner';
import brStates from '../../../../data/brStatesISO.json';
import { changePaymentMethod } from '../../../../services/plans';
import {
  changePaymentYupValidation,
  paymentYupValidationBankSlip,
} from '../../../../utils/paymentYupValidation';

import {
  Overlay,
  Container,
  PaymentForm,
  RowInputs,
  ListPaymentMethods,
  SelectContainer,
  SelectCustomStyle,
  ErrorMessage,
  ContainerDebitCreditCard,
  LabelDebitCreditCard,
} from './styles';

const ModalPayment = ({ paymentType, onFinishChange }) => {
  const [choosePaymentType, setChoosePaymentType] = useState(paymentType);

  const handleChangePaymentMethod = async form => {
    try {
      const data = {
        payment_method_code: choosePaymentType,
      };

      if (choosePaymentType === 'credit_card') {
        const credit_card_data = {
          holder_name: form.name,
          registry_code: form.document,
          card_expiration: form.expirationDate,
          card_number: form.cardNumber.replaceAll(' ', ''),
          card_cvv: form.cvv,
        };

        Object.assign(data, { credit_card_data });
      }

      if (choosePaymentType === 'bank_slip' || choosePaymentType === 'pix') {
        const bank_slip_data = {
          cpf: form.cpf,
          street: form.street,
          number: form.number,
          additional_details: form.additional_details,
          zipcode: form.zipcode,
          neighborhood: form.neighborhood,
          city: form.city,
          state: form.state,
          country: 'BR',
        };

        Object.assign(data, { bank_slip_data });
      }

      await changePaymentMethod(data);
      toast.success('Forma de pagamento atualizada com sucesso!');
      onFinishChange();
    } catch {
      toast.warning(
        'Ocorreu um erro ao tentar atualizar a forma de pagamento, tente mais tarde.',
      );
    }
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(paymentYupValidationBankSlip),
  });

  const {
    register: registerCreditCardInput,
    handleSubmit: handleSubmitCreditCardInput,
    formState: {
      errors: errorsCreditCardInput,
      isSubmitting: isSubmittingCreditCardInput,
    },
  } = useForm({
    resolver: yupResolver(changePaymentYupValidation),
  });

  return (
    <Overlay>
      <Container>
        <img
          src={CloseIcon}
          alt="Fechar"
          role="none"
          onClick={onFinishChange}
        />

        <h3>Formas de pagamento disponíveis</h3>
        <ListPaymentMethods>
          <li>
            <input
              type="radio"
              id="credit_card"
              name="paymentType"
              value="credit_card"
              checked={choosePaymentType === 'credit_card'}
              onClick={() => setChoosePaymentType('credit_card')}
            />
            <label htmlFor="credit_card">Cartão de Crédito</label>
          </li>

          <li>
            <input
              type="radio"
              id="bank_slip"
              name="paymentType"
              value="bank_slip"
              checked={choosePaymentType === 'bank_slip'}
              onClick={() => setChoosePaymentType('bank_slip')}
            />
            <label htmlFor="bank_slip">Boleto</label>
          </li>

          <li>
            <input
              type="radio"
              id="offsite"
              name="paymentType"
              value="offsite"
              checked={choosePaymentType === 'offsite'}
              onClick={() => setChoosePaymentType('offsite')}
            />
            <label htmlFor="offsite">Paypal</label>
          </li>

          <li>
            <input
              type="radio"
              id="offsite"
              name="paymentType"
              value="offsite"
              checked={choosePaymentType === 'pix'}
              onClick={() => setChoosePaymentType('pix')}
            />
            <label htmlFor="pix">Pix</label>
          </li>
        </ListPaymentMethods>

        {choosePaymentType === 'credit_card' && (
          <ContainerDebitCreditCard>
            <LabelDebitCreditCard>
              O pagamento via cartão de crédito é feito via débito automático.
            </LabelDebitCreditCard>
          </ContainerDebitCreditCard>
        )}

        {choosePaymentType === 'credit_card' && (
          <PaymentForm
            onSubmit={handleSubmitCreditCardInput(handleChangePaymentMethod)}
          >
            <h4>Dados do cartão</h4>
            <Input
              containerStyle={{
                minWidth: 90,
              }}
              placeholder="Nome do titular do cartão"
              icon={<AiOutlineUser color="#909599" />}
              isInvalid={errorsCreditCardInput.name?.message}
              errorMessage={errorsCreditCardInput.name?.message}
              {...registerCreditCardInput('name')}
            />
            <Input
              containerStyle={{
                minWidth: 90,
              }}
              placeholder="CPF ou CNPJ"
              icon={<AiFillIdcard color="#909599" />}
              isInvalid={errorsCreditCardInput.document?.message}
              errorMessage={errorsCreditCardInput.document?.message}
              mask="999.999.999-99"
              {...registerCreditCardInput('document')}
            />
            <Input
              containerStyle={{
                minWidth: 90,
              }}
              placeholder="Número do cartão"
              icon={<AiFillCreditCard color="#909599" />}
              isInvalid={errorsCreditCardInput.cardNumber?.message}
              errorMessage={errorsCreditCardInput.cardNumber?.message}
              mask="9999 9999 9999 9999"
              {...registerCreditCardInput('cardNumber')}
            />
            <RowInputs>
              <Input
                containerStyle={{
                  minWidth: 90,
                  marginRight: 8,
                }}
                placeholder="Validade"
                icon={<BsFillCalendarFill color="#909599" />}
                isInvalid={errorsCreditCardInput.expirationDate?.message}
                errorMessage={errorsCreditCardInput.expirationDate?.message}
                mask="99/9999"
                {...registerCreditCardInput('expirationDate')}
              />

              <Input
                containerStyle={{
                  minWidth: 90,
                  marginLeft: 8,
                }}
                type="number"
                placeholder="CVV"
                icon={<AiFillCreditCard color="#909599" />}
                isInvalid={errorsCreditCardInput.cvv?.message}
                errorMessage={errorsCreditCardInput.cvv?.message}
                {...registerCreditCardInput('cvv')}
              />
            </RowInputs>

            {isSubmittingCreditCardInput ? (
              <div className="spinner">
                <Spinner />
              </div>
            ) : (
              <Button title="CONFIRMAR" type="submit" />
            )}
          </PaymentForm>
        )}

        {choosePaymentType === 'offsite' && <BsPaypal size={60} />}

        {(choosePaymentType === 'pix' || choosePaymentType === 'bank_slip') && (
          <PaymentForm onSubmit={handleSubmit(handleChangePaymentMethod)}>
            {choosePaymentType === 'pix' && (
              <img src={PixIcon} alt="Pagamento com Pix" />
            )}
            {choosePaymentType === 'bank_slip' && <RiBillLine size={60} />}

            <h4>Preencha os dados</h4>

            <Input
              containerStyle={{
                minWidth: 90,
              }}
              placeholder="CPF ou CNPJ"
              icon={<AiFillIdcard color="#909599" />}
              isInvalid={errors.cpf?.message}
              errorMessage={errors.cpf?.message}
              mask="999.999.999-99"
              {...register('cpf')}
            />

            <RowInputs>
              <Input
                containerStyle={{
                  minWidth: 90,
                }}
                control={control}
                placeholder="Código Postal"
                icon={<FaRegAddressCard />}
                mask="99999 999"
                isInvalid={errors.zipcode?.message}
                errorMessage={errors.zipcode?.message}
                name="zipcode"
              />

              <Input
                containerStyle={{
                  minWidth: 90,
                  marginLeft: 8,
                }}
                placeholder="Rua"
                icon={<FaRegAddressCard />}
                isInvalid={errors.street?.message}
                errorMessage={errors.street?.message}
                {...register('street')}
              />
            </RowInputs>

            <RowInputs>
              <Input
                containerStyle={{
                  minWidth: 90,
                  marginRight: 8,
                }}
                placeholder="Número"
                icon={<FaRegAddressCard />}
                isInvalid={errors.number?.message}
                errorMessage={errors.number?.message}
                {...register('number')}
              />

              <Controller
                control={control}
                name="additional_details"
                render={({ field: { onChange, ref } }) => (
                  <SelectContainer error={errors.additional_details?.message}>
                    <Select
                      ref={ref}
                      options={[
                        { label: 'Casa', value: 'Casa' },
                        { label: 'Apartamento', value: 'Apartamento' },
                      ]}
                      components={{
                        ValueContainer: AddressContainerIcon,
                      }}
                      placeholder={<span>Complemento</span>}
                      styles={SelectCustomStyle}
                      noOptionsMessage={() => 'Sem opções disponíveis'}
                      onChange={option => {
                        onChange(option.value);
                      }}
                    />
                    {errors.additional_details?.message ? (
                      <ErrorMessage>
                        {errors.additional_details?.message}
                      </ErrorMessage>
                    ) : null}
                  </SelectContainer>
                )}
              />
            </RowInputs>

            <RowInputs>
              <Input
                containerStyle={{
                  minWidth: 90,
                }}
                placeholder="Bairro"
                icon={<FaRegAddressCard />}
                isInvalid={errors.neighborhood?.message}
                errorMessage={errors.neighborhood?.message}
                {...register('neighborhood')}
              />

              <Input
                containerStyle={{
                  minWidth: 90,
                  marginLeft: 8,
                }}
                placeholder="Cidade"
                icon={<FaRegAddressCard />}
                isInvalid={errors.city?.message}
                errorMessage={errors.city?.message}
                {...register('city')}
              />
            </RowInputs>

            <Controller
              control={control}
              name="state"
              render={({ field: { onChange, ref } }) => (
                <SelectContainer
                  style={{ marginBottom: 32 }}
                  error={errors.state?.message}
                >
                  <Select
                    ref={ref}
                    options={brStates}
                    components={{
                      ValueContainer: AddressContainerIcon,
                    }}
                    placeholder={<span>Estado</span>}
                    styles={SelectCustomStyle}
                    noOptionsMessage={() => 'Sem opções disponíveis'}
                    onChange={option => {
                      onChange(option.value);
                    }}
                  />
                  {errors.state?.message ? (
                    <ErrorMessage>{errors.state?.message}</ErrorMessage>
                  ) : null}
                </SelectContainer>
              )}
            />

            {isSubmitting ? (
              <div className="spinner">
                <Spinner />
              </div>
            ) : (
              <Button title="CONFIRMAR" type="submit" />
            )}
          </PaymentForm>
        )}

        {choosePaymentType === 'offsite' && (
          <Button title="CONFIRMAR" onClick={handleChangePaymentMethod} />
        )}
      </Container>
    </Overlay>
  );
};

export default ModalPayment;
