import styled, { css } from 'styled-components';

import { theme } from '../../styles/theme';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props.bgColor === 'light' ? '#2f189c' : '#240F85'};
  padding: 12px;
  min-width: 270px;
  width: 100%;
  border-radius: 30px;
  text-align: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  max-width: 100%;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #676767;
    `}

  svg {
    width: 24px;
    height: 24px;
    color: ${theme.colors.light};
  }

  background-color: ${props => (props.disabled ? '#CFD2D4' : '#240F85')};

  button {
    background-color: transparent;
    color: ${theme.colors.textLight};
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    margin-left: 8px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

    @media (max-width: 480px) {
      font-size: 14px;
      max-width: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 480px) {
    max-width: 100px;
    box-shadow: 0 6px 8px -6px rgba(24, 39, 75, 0.12),
      0px 8px 16px -6px rgba(24, 39, 75, 0.08);
    border-radius: 62px;
  }

  @media (min-width: 2300px) {
    padding: 16px;

    button {
      font-size: 1.2rem;
      letter-spacing: 0.06em;
    }
  }
`;
