import { isPast } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { ReactComponent as LogoMobileHeader } from '../../../../assets/icons/logo-mobile-header.svg';
import { parseJwt } from '../../../../utils/parseJwt';

import {
  UnloggedHeaderContainer,
  UnloggedSideButtons,
  UnloggedLogoContainer,
  UnloggedHeaderContent,
} from './style';

const UnloggedHeader = () => {
  const [logged, setLogged] = useState(false);

  const headerRef = useRef(null);

  const history = useHistory();

  const handleGoToRegister = () => {
    history.push('/registro');
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      headerRef.current?.classList.add('scrolled');
    } else {
      headerRef.current?.classList.remove('scrolled');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('@HaluGamashi:token');
    if (!token) {
      setLogged(false);
      return;
    }
    const jwtData = parseJwt(token);
    const expirationDate = new Date(jwtData.exp * 1000);
    setLogged(!isPast(expirationDate));
  }, []);

  return (
    <UnloggedHeaderContainer ref={headerRef}>
      <UnloggedHeaderContent className="max-content">
        <UnloggedLogoContainer to="/">
          <LogoMobileHeader />
        </UnloggedLogoContainer>

        <UnloggedSideButtons>
          <button type="button" onClick={handleGoToRegister}>
            CRIAR CONTA
          </button>

          <Link to={logged ? '/home' : '/logar'} className="transparent">
            ACESSAR INSTITUTO
          </Link>
        </UnloggedSideButtons>
      </UnloggedHeaderContent>
    </UnloggedHeaderContainer>
  );
};

export default UnloggedHeader;
