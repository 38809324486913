import styled from 'styled-components'

import availableSoonImg from '../../assets/images/available-soon.png'
import { theme } from '../../styles/theme'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  @media (min-width: 1101px) {
    padding-right: 100px;
  }
  @media (max-width: 480px) {
    padding: 0;
  }
`

export const ModulesCourseMobile = styled.div`
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  margin: 0 0 24px 0;

  p > {
    color: #594aad;
    font-weight: bold;
    font-size: 15px;
    padding-bottom: 8px;
  }

  h1 {
    color: #25282b;
    font-weight: bold;
    font-size: 24px;
    padding: 0 0 16px 16px;
  }

  span {
    padding-top: 32px;
    display: flex;
    justify-content: space-between;
  }

  h4 {
    color: #2f189c;
    padding: 24px 0 8px 16px;
  }
`

export const InfoLeftLiveMobile = styled.div`
  display: flex;
  padding: 16px;

  p {
    font-weight: 600;
    font-size: 14px;
    color: #2f189c;
  }

  img {
    padding-right: 14px;
  }
`

export const InfoRightLiveMobile = styled.div`
  display: flex;
  padding: 16px;

  p {
    font-weight: 600;
    font-size: 14px;
    color: #2f189c;
  }

  img {
    padding-left: 14px;
  }
`

export const Content = styled.article`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  @media (max-width: 1100px) {
    padding: 0 20px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > p {
    font-size: 16px;
    line-height: 24px;
    color: #25282b;
    margin-bottom: 100px;
  }

  > h1 {
    font-weight: bold;
    font-size: 40px;
    color: #25282b;
    padding: 50px 0 32px 0;
  }
`

export const ContentBottom = styled.ul`
  text-decoration: none;
  display: flex;
  list-style: none;
  padding-bottom: 24px;
  flex-direction: column;
  li {
    cursor: pointer;
    font-weight: 400;
    font-size: 18px;
    margin-right: 34px;
  }

  li.active {
    border-bottom: 4px solid #2f189c;
  }
  @media (max-width: 1100px) {
    justify-content: center;
    li {
      font-size: 16px;
      padding-bottom: 10px;
      &:last-of-type {
        margin: 0;
      }
    }
  }
  .box-certificates {
    display: flex;
  }
`

export const ExtraMaterial = styled.li`
  background-color: ${theme.colors.light};
  height: 65px;
  max-width: 500px;
  padding: 15px 20px;
  margin: 8px 0;
  display: flex;
  align-items: center;
  font-weight: bold;

  span {
    font-weight: 400;
    font-size: 0.9rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  svg:first-of-type {
    margin-right: 20px;
    width: 24px;
    height: 24px;
  }

  svg:last-of-type {
    margin-left: auto;
    cursor: pointer;
  }
`

export const VideoComponent = styled.div`
  min-width: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }

  div {
    background-color: black;
    width: auto;
    margin-bottom: 40px;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.8) 0 0 10px;
    -moz-box-shadow: rgba(0, 0, 0, 0.8) 0 0 10px;

    @media (max-width: 900px) {
      box-shadow: none;
      border-radius: 0;
      margin: 0;
      margin-top: 22px;
    }
  }
`

export const AvailableSoon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${availableSoonImg});
  background-position: center;
  background-size: cover;
  max-height: 900px;
  max-width: 450px;
  min-height: 400px;
  min-width: 700px;
  margin: 0 auto 40px;

  h1 {
    color: ${theme.colors.textLight};
    text-transform: uppercase;
  }

  @media (max-width: 1100px) {
    min-height: 300px;
    min-width: 320px;

    h1 {
    }
  }
`

export const TextInfo = styled.div`
  padding: 0;
  flex-direction: column;
  @media (max-width: 480px) {
    padding: 0 16px;
  }
`

export const OverviewAndSupportMaterial = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;
`
