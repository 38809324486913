import axiosApi from './api';

class CategoriesService {
  constructor() {
    this.api = axiosApi;
    this.baseUrl = '/categories';
  }

  async getCategories() {
    return this.api.get(this.baseUrl).then(res => res.data);
  }
}

export { CategoriesService };
