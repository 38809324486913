/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineClose, AiOutlineCloseCircle } from 'react-icons/ai';
import { BiMessageDetail } from 'react-icons/bi';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import { FiSettings } from 'react-icons/fi';
import { VscPinned } from 'react-icons/vsc';
import InputEmoji from 'react-input-emoji';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { io } from 'socket.io-client';
import * as yup from 'yup';

import { ReactComponent as HourGlass } from '../../assets/hour-glass.svg';
import BlockIcon from '../../assets/icons/block-icon.svg';
import { ReactComponent as ChatSendIcon } from '../../assets/icons/chat-send.svg';
import { ReactComponent as DotsIcon } from '../../assets/icons/dots.svg';
import HourGlassIcon from '../../assets/icons/hour-glass-icon.svg';
import { ReactComponent as HourGlassMedium } from '../../assets/icons/hour-glass-medium-icon.svg';
import { ReactComponent as LogoLeft } from '../../assets/icons/logo-live-left.svg';
import { ReactComponent as LogoRight } from '../../assets/icons/logo-live-right.svg';
import PinIcon from '../../assets/icons/pin-icon.svg';
import { ReactComponent as ChatPolygon } from '../../assets/icons/polygon.svg';
import TrashIcon from '../../assets/icons/trash-icon.svg';
import UnpinIcon from '../../assets/icons/unpin-icon.svg';
import { ReactComponent as UserBlockedIcon } from '../../assets/icons/user-blocked-icon.svg';
import AvatarFallback from '../../assets/images/avatar-fallback.png';
import UserIcon from '../../assets/user.svg';
import { Button } from '../../components/Button';
import { Input as DefaultInput } from '../../components/Input';
import SVplayer from '../../components/SVplayer';
import { useAuth } from '../../hooks/auth';
import { getById } from '../../services/Content';
import { LivesService } from '../../services/lives';
import { theme } from '../../styles/theme';
import checkUserIsPaid from '../../utils/checkUserIsFree';

import {
  Container,
  CloseIcon,
  ShowChatButton,
  VideoFooter,
  Chat,
  TitleChat,
  Messages,
  MessageHighlight,
  Message,
  MessageInfo,
  HeaderMessageInfo,
  Text,
  InputContainer,
  AdminMenuOptions,
  Option,
  UnpinnedContainer,
  ChatOptions,
  Overlay,
  TimeDelayModal,
  TimeDelayHeader,
  TimeDelayForm,
  TimeDelayButtons,
  Icons,
  UsersBlockedModal,
  UsersBlockedHeader,
  UsersBlockedTitle,
  UsersBlockedList,
  UserBlocked,
  UsersBlockedButtons,
  ChatSection,
  ChatOptionsContainer,
  CloseChatButton,
  MessagesAnimationWrapper,
} from './styles';

const schema = yup.object().shape({
  timeDelay: yup.string().matches(/(^\d{1,})$/, 'Digite apenas números'),
});

const Live = () => {
  const [socket, setSocket] = useState(null);
  const [delayModalOpen, setDelayModalOpen] = useState(false);
  const [usersBlockedModalOpen, setUsersBlockedModalOpen] = useState(false);
  const [isChatOpen, setChatIsOpen] = useState(true);
  const [chatOptionOpen, setChatOptionOpen] = useState(false);
  const [messageOptionOpen, setOptionOpen] = useState(null);
  const [pinnedOptionsOpen, setPinnedOptionsOpen] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatMessagesHighlight, setChatMessagesHighlight] = useState([]);
  const [usersBlocked, setUsersBlocked] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [live, setLive] = useState(null);
  const [chatMobileView, setChatMobileView] = useState('live-messages');

  const { liveId } = useParams();
  const { token } = useAuth();
  const auth = useAuth();
  const user = useMemo(() => {
    if (auth.user?.user_id) {
      return auth.user;
    }
    return auth.user?.data;
  }, [auth]);

  const history = useHistory();

  const chatRef = useRef(null);
  const messagesEndRef = useRef(null);
  const messagesListRef = useRef(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleCloseLive = () => {
    history.push('/home');
  };

  const handleToggleChat = state => {
    setChatIsOpen(state);

    if (!state) {
      setTimeout(() => {
        chatRef.current.style.visibility = 'hidden';
      }, 2000);
      return;
    }

    chatRef.current.style.visibility = 'visible';
  };

  const handleSendMessage = () => {
    if (!inputValue) return;

    socket.emit('message', {
      live_id: liveId,
      user_id: user?.user_id,
      message: inputValue,
    });
    setInputValue('');
  };

  const handleOpenMessageOptions = (clickEvent, index) => {
    clickEvent.stopPropagation();
    setOptionOpen(index);
  };

  const handlePinMessage = (clickEvent, messageId) => {
    clickEvent.stopPropagation();
    socket.emit('message_highlight', {
      live_id: liveId,
      message_id: messageId,
    });
    setOptionOpen(null);
  };

  const handleOpenPinnedOptions = (clickEvent, index) => {
    clickEvent.stopPropagation();
    setPinnedOptionsOpen(index);
  };

  const handleUnpinMessage = (clickEvent, messageId) => {
    clickEvent.stopPropagation();
    socket.emit('remove_message_highlight', {
      live_id: liveId,
      message_id: messageId,
    });
    setPinnedOptionsOpen(null);
  };

  const handleOpenChatOptions = clickEvent => {
    clickEvent.stopPropagation();
    setChatOptionOpen(true);
  };

  const handleSaveChatDelayTime = data => {
    socket.emit('message_delay', {
      live_id: liveId,
      message_delay: data.timeDelay,
    });
    setDelayModalOpen(false);
    toast.success('Tempo de delay do chat alterado.');
  };

  const blockUser = userId => {
    socket.emit('block_user', {
      live_id: liveId,
      user_id: userId,
    });
    toast.info('Usuário bloqueado.');
  };

  const unblockUser = userId => {
    socket.emit('remove_user_block', {
      live_id: liveId,
      user_id: userId,
    });
    toast.info('Usuário desbloqueado.');
  };

  const handleExcludeMessage = (clickEvent, messageId) => {
    socket.emit('delete_message', {
      live_id: liveId,
      message_id: messageId,
    });
    socket.emit('remove_message_highlight', {
      live_id: liveId,
      message_id: messageId,
    });
    toast.info('Mensagem deletada.');
  };

  const checkUserHasAccess = useCallback(
    async (associatedPans, courseId) => {
      if (!courseId) return null;

      const content = await getById(courseId)
        .then(res => ({
          ...res.data.data,
          prerequisites: res.data.prerequisites,
        }))
        .catch(() => {
          toast.error(
            'Não foi possível recuperar o curso ao qual a live faz parte, por favor atualize a página.',
            { toastId: 'error-access-live' },
          );
        });

      if (content?.prerequisites?.availableToConsume !== true) return false;

      const userIsPaid = checkUserIsPaid(user?.plans_paid);
      const userPlansMap =
        user?.plans_paid?.reduce(
          (map, payment) => map.set(payment.plan_id, payment.plan),
          new Map(),
        ) ?? new Map();

      if (associatedPans?.length) {
        return associatedPans.some(id => userPlansMap.get(id));
      }

      if (
        content?.info?.free_for_subscribers ||
        content?.info?.only_subscriber
      ) {
        return userIsPaid;
      }

      if (content?.info?.price > 0) {
        return user?.contents_paid?.some(
          payment => payment.content_id === courseId,
        );
      }
      if (content?.info?.is_free) {
        return true;
      }

      return false;
    },
    [user],
  );

  useEffect(() => {
    window.addEventListener('click', () => {
      setOptionOpen(null);
      setChatOptionOpen(false);
      setPinnedOptionsOpen(null);
    });
  }, [token]);

  useEffect(() => {
    const scrollHeight = messagesListRef?.current?.scrollHeight;
    const scrollOfTop = messagesListRef?.current?.scrollTop;
    const messageElementHeight = 74;

    const shouldScroll =
      (scrollHeight - scrollOfTop) % messagesListRef.current.clientHeight <=
      messageElementHeight * 5;

    if (shouldScroll) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatMessages]);

  useEffect(() => {
    if (user) {
      (async () => {
        if (!liveId) return;
        const liveService = new LivesService();

        await liveService
          .getLive(liveId)
          .then(async res => {
            const hasAccess = await checkUserHasAccess(
              res?.info?.associated_plans,
              res?.info?.course_id,
            );

            if (!hasAccess && res?.info?.course_id) {
              toast.warn('Você não possui permissão para assistir essa live!', {
                toastId: 'error-access-live',
              });
              history.replace(`/curso-detalhe/${res.info?.course_id}`);

              return;
            }

            setLive(res);
          })
          .catch(() => {
            toast.error(
              'Ocorreu um erro ao tentar acessar essa live, tente mais tarde',
            );
            handleCloseLive();
          });
      })();
    }
  }, [liveId, auth]);

  useEffect(() => {
    const connection = () => {
      const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

      const socketIo = io(SOCKET_URL, {
        query: {
          token,
        },
        transports: ['websocket'],
      });

      setSocket(socketIo);

      if (!live) return;

      socketIo.emit(
        'select_room',
        {
          live_id: liveId,
          user_id: user?.user_id,
          username: user?.name,
          chat_avatar: user?.images?.chat_avatar,
        },
        data => {
          setChatMessages(data.messages);
          setChatMessagesHighlight(data.messages_highlight);
          setUsersBlocked(data.blocked_users);
        },
      );
    };
    if (token) connection();
  }, [liveId, user, token, live]);

  useEffect(() => {
    if (socket) {
      socket.on('message', newMessage => {
        setChatMessages(messages => {
          const index = messages.findIndex(
            msg => msg.message_id === newMessage.message_id,
          );
          if (index === -1) {
            return [...messages, newMessage];
          }
          return messages;
        });
      });

      socket.on('delete_message', data => {
        setChatMessages(messages =>
          messages.filter(msg => msg.message_id !== data.message_id),
        );
      });
    }
  }, [socket]);

  useEffect(() => {
    if (socket) {
      socket.on('message_highlight', data => {
        setChatMessagesHighlight(data.messages_highlight);
      });

      socket.on('remove_message_highlight', data => {
        setChatMessagesHighlight(data.messages_highlight);
      });
    }
  }, [chatMessagesHighlight]);

  useEffect(() => {
    if (socket) {
      socket.on('block_user', data => {
        setUsersBlocked(data.blocked_users);
      });

      socket.on('remove_user_block', data => {
        setUsersBlocked(data.blocked_users);
      });
    }
  }, [usersBlocked]);

  useEffect(() => {
    return () => socket && socket.disconnect();
  }, []);

  const liveUrl = (() => {
    const playerHash = process.env.REACT_APP_PLAYER_HASH;
    const playerBaseUrl = process.env.REACT_APP_PLAYER_BASE_URL;

    return `${playerBaseUrl}/${playerHash}/live/${
      live?.channel_sv_id
    }?enableShare=false&trafficEconomy=true${
      live?.images?.thumbnail_url
        ? `&thumbnailLiveURL=${live?.images?.thumbnail_url}`
        : ''
    }`;
  })();

  return (
    <>
      <CloseIcon
        onClick={() => {
          socket?.emit('disconnectado');
          return handleCloseLive();
        }}
      >
        <AiOutlineClose color="#fff" size={20} alt="Fechar" />
      </CloseIcon>
      <Container chatState={isChatOpen}>
        <section id="live-chat">
          <SVplayer liveUrl={liveUrl} />

          <VideoFooter>
            <LogoLeft />
            <div>
              <h1>{live?.name}</h1>
              <div
                id="live-description"
                dangerouslySetInnerHTML={{ __html: live?.description }}
              />
            </div>

            <LogoRight />
          </VideoFooter>
        </section>

        {!isChatOpen && (
          <ShowChatButton
            chatState={isChatOpen}
            onClick={() => handleToggleChat(!isChatOpen)}
          >
            <span>Chat</span>

            <BsChevronLeft />
          </ShowChatButton>
        )}

        {isChatOpen && (
          <CloseChatButton
            chatState={isChatOpen}
            onClick={() => handleToggleChat(!isChatOpen)}
          >
            <BsChevronRight />
          </CloseChatButton>
        )}

        <Chat ref={chatRef} chatState={isChatOpen}>
          {user?.is_admin && (
            <ChatOptionsContainer
              onClick={handleOpenChatOptions}
              onKeyPress={handleOpenChatOptions}
            >
              <FiSettings />
              <ChatOptions isOpen={chatOptionOpen}>
                <Option onClick={() => setUsersBlockedModalOpen(true)}>
                  <img src={UserIcon} alt="Usuário ícone" />
                  <span>Usuários bloqueados</span>
                </Option>
                <Option onClick={setDelayModalOpen}>
                  <img src={HourGlassIcon} alt="Ampulheta ícone" />
                  <span>Delay do chat</span>
                </Option>
              </ChatOptions>
            </ChatOptionsContainer>
          )}

          <TitleChat>
            <ChatSection
              onClick={() => setChatMobileView('live-messages')}
              active={chatMobileView === 'live-messages'}
              className="to-right"
            >
              <BiMessageDetail />
              <h1>Chat ao vivo</h1>
            </ChatSection>
            <ChatSection
              onClick={() => setChatMobileView('pinned-messages')}
              active={chatMobileView === 'pinned-messages'}
              className="to-left"
            >
              <VscPinned />
              <h1>Mensagens fixadas</h1>
            </ChatSection>
          </TitleChat>

          <Messages ref={messagesListRef}>
            <MessagesAnimationWrapper
              active={chatMobileView === 'live-messages'}
            >
              {chatMobileView === 'live-messages' &&
                chatMessages.map((message, index) => (
                  <Message key={message.message_id}>
                    <Icons>
                      {user?.is_admin ? (
                        <DotsIcon
                          onClick={e => handleOpenMessageOptions(e, index)}
                        />
                      ) : null}

                      <img
                        src={message.chat_avatar || AvatarFallback}
                        alt={`Foto de perfil ${message.username}`}
                      />
                    </Icons>

                    <MessageInfo>
                      <AdminMenuOptions
                        id={message.message_id}
                        isOpen={messageOptionOpen === index}
                      >
                        <Option
                          onClick={e =>
                            handlePinMessage(e, message?.message_id)
                          }
                        >
                          <img src={PinIcon} alt="Pin ícone" />
                          <span>Fixar</span>
                        </Option>
                        {message.user_id !== user?.user_id && (
                          <Option onClick={() => blockUser(message?.user_id)}>
                            <img src={BlockIcon} alt="Bloqueio ícone" />
                            <span>Bloquear usuário</span>
                          </Option>
                        )}

                        <Option
                          onClick={e =>
                            handleExcludeMessage(e, message?.message_id)
                          }
                        >
                          <img src={TrashIcon} alt="Lixeira ícone" />
                          <span style={{ color: theme.colors.error }}>
                            Excluir mensagem
                          </span>
                        </Option>
                      </AdminMenuOptions>

                      <HeaderMessageInfo>
                        <span>
                          {message.user_id === user?.user_id
                            ? 'Eu'
                            : message.username}
                        </span>
                        <span>
                          {format(new Date(message.created_at), 'HH:mm')}
                        </span>
                      </HeaderMessageInfo>

                      <Text>
                        <ChatPolygon />
                        {message.text}
                      </Text>
                    </MessageInfo>
                  </Message>
                ))}
            </MessagesAnimationWrapper>

            <MessagesAnimationWrapper
              active={chatMobileView === 'pinned-messages'}
            >
              {chatMobileView === 'pinned-messages' &&
                chatMessagesHighlight.map((message, index) => (
                  <MessageHighlight
                    key={message.message_id}
                    openOptions={pinnedOptionsOpen === index}
                  >
                    <UnpinnedContainer>
                      <Option
                        onClick={e => handleUnpinMessage(e, message.message_id)}
                      >
                        <img src={UnpinIcon} alt="Desafixar ícone" />
                        <span>Dispensar fixado</span>
                      </Option>
                    </UnpinnedContainer>

                    {user?.is_admin ? (
                      <DotsIcon
                        onClick={e => handleOpenPinnedOptions(e, index)}
                      />
                    ) : null}

                    <img
                      src={message.chat_avatar || AvatarFallback}
                      alt={`Foto de perfil ${message.username}`}
                    />
                    <MessageInfo>
                      <HeaderMessageInfo>
                        <span>{message.username}</span>
                      </HeaderMessageInfo>

                      <Text>
                        <ChatPolygon />
                        {message.text}
                      </Text>
                    </MessageInfo>
                  </MessageHighlight>
                ))}
            </MessagesAnimationWrapper>
            <div ref={messagesEndRef} />
          </Messages>

          <InputContainer>
            <InputEmoji
              value={inputValue}
              onChange={e => setInputValue(e)}
              onEnter={handleSendMessage}
              cleanOnEnter
              placeholder="Escreva uma mensagem"
              id="input-chat-emoji"
            />

            <DefaultInput
              wrapperStyle={{
                marginLeft: 4,
              }}
              onChange={e => setInputValue(e.target.value)}
              onKeyDown={event => event.key === 'Enter' && handleSendMessage()}
              placeholder="Envie uma mensagem"
              containerId="input-chat"
              value={inputValue}
            />
            <button type="button" onClick={handleSendMessage}>
              <ChatSendIcon alt="Enviar mensagem" />
            </button>
          </InputContainer>

          <Overlay open={usersBlockedModalOpen}>
            <UsersBlockedModal open={usersBlockedModalOpen}>
              <UsersBlockedHeader>
                <UserBlockedIcon />
                <UsersBlockedTitle>
                  <h1>Usuários bloqueados</h1>
                  <h2>Veja a lista e retire o bloqueio.</h2>
                </UsersBlockedTitle>
              </UsersBlockedHeader>

              <hr />
              <UsersBlockedList>
                {usersBlocked.map(userBlocked => (
                  <UserBlocked>
                    <p>
                      <img
                        src={userBlocked?.chat_avatar || AvatarFallback}
                        alt={`Foto de perfil ${userBlocked.username}`}
                      />
                      <span>{userBlocked.username}</span>
                    </p>

                    <AiOutlineCloseCircle
                      onClick={() => unblockUser(userBlocked.user_id)}
                    />
                  </UserBlocked>
                ))}
              </UsersBlockedList>

              <UsersBlockedButtons>
                <Button
                  title="FECHAR"
                  type="submit"
                  onClick={() => setUsersBlockedModalOpen(false)}
                  buttonStyle={{
                    maxWidth: 100,
                  }}
                  containerStyle={{
                    justifyContent: 'center',
                    height: 30,
                    maxWidth: 100,
                    minWidth: 150,
                    padding: 20,
                  }}
                />
              </UsersBlockedButtons>
            </UsersBlockedModal>
          </Overlay>

          <Overlay open={delayModalOpen}>
            <TimeDelayModal open={delayModalOpen}>
              <TimeDelayHeader>
                <HourGlass />
                <h1>Informe o tempo de delay para o chat</h1>
              </TimeDelayHeader>

              <TimeDelayForm>
                <DefaultInput
                  containerStyle={{
                    width: 90,
                  }}
                  placeholder="Tempo de delay em segundos"
                  icon={<HourGlassMedium />}
                  isInvalid={errors.timeDelay?.message}
                  errorMessage={errors.timeDelay?.message}
                  {...register('timeDelay')}
                />
              </TimeDelayForm>

              <TimeDelayButtons>
                <Button
                  title="CANCELAR"
                  onClick={() => setDelayModalOpen(false)}
                  type="button"
                  containerStyle={{
                    justifyContent: 'center',
                    height: 30,
                    padding: 20,
                    background: 'transparent',
                    maxWidth: 100,
                    minWidth: 150,
                  }}
                  buttonStyle={{
                    maxWidth: 100,
                    color: '#240F85',
                  }}
                />

                <Button
                  title="SALVAR"
                  type="submit"
                  onClick={handleSubmit(handleSaveChatDelayTime)}
                  buttonStyle={{
                    maxWidth: 100,
                  }}
                  containerStyle={{
                    justifyContent: 'center',
                    height: 30,
                    maxWidth: 100,
                    minWidth: 150,
                    padding: 20,
                  }}
                />
              </TimeDelayButtons>
            </TimeDelayModal>
          </Overlay>
        </Chat>
      </Container>
    </>
  );
};

export default Live;
