import { enc } from 'crypto-js';
import AES from 'crypto-js/aes';
import React, { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BiChevronDown } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import CardPlan from '../../components/CardPlan';
import HeaderLoginAndRegister from '../../components/HeaderLoginAndRegister';
import Spinner from '../../components/Spinner';
import { useAuth } from '../../hooks/auth';
import { RegisterLayoutRoutes } from '../../routes/RegisterLayoutRoutes';
import { periods, listPlans, purchasePlan } from '../../services/plans';
import { goToTop } from '../../utils/helpers';
import { planConverter } from '../../utils/planConverter';

import {
  Container,
  PeriodSelector,
  PeriodSelectorMobile,
  PeriodSelectedMobile,
  PeriodOptionsContainer,
  PeriodOptions,
  NoPlansFound,
  Cards,
} from './styles';

const SelectPlan = () => {
  const [loading, setLoading] = useState(false);
  const [monthlyPlans, setMonthlyPlans] = useState([]);
  const [quarterlyPlans, setQuarterlyPlans] = useState([]);
  const [semesterPlans, setSemesterPlans] = useState([]);
  const [yearlyPlans, setYearlyPlans] = useState([]);
  const [openPeriodSelect, setOpenPeriodSelect] = useState(false);
  const [periodSelected, setPeriodSelected] = useState({
    label: 'Anual',
    value: 'yearlyPlans',
  });

  const history = useHistory();
  const { user, storeUser, token } = useAuth();

  const mapPeriodToPlanState = {
    monthlyPlans,
    quarterlyPlans,
    semesterPlans,
    yearlyPlans,
  };

  const handleTogglePeriodSelect = () => {
    setOpenPeriodSelect(state => {
      if (state) {
        document.querySelector('body').style.overflow = 'auto';
      } else {
        document.querySelector('body').style.overflow = 'hidden';
      }
      return !state;
    });
  };

  const handleSelectPeriod = period => {
    setPeriodSelected(period);
    handleTogglePeriodSelect();
  };

  const handleSubscribe = planId => {
    const freePlansIds = [
      ...monthlyPlans,
      ...quarterlyPlans,
      ...semesterPlans,
      ...yearlyPlans,
    ]
      .filter(plan => plan.info.type === 0)
      .map(plan => plan.plan_id);

    if (freePlansIds.includes(planId)) {
      const encryptedData = AES.encrypt(
        JSON.stringify('fake-data'),
        `Bearer ${token}`,
      ).toString();

      const encodedData = enc.Base64.stringify(enc.Utf8.parse(encryptedData));

      purchasePlan(planId, encodedData);

      const userWithPlanId = { ...user, plan_id: planId };
      storeUser(userWithPlanId);

      history.push('/registro/obrigado-por-registrar');
      return;
    }

    history.push(`/registro/pagamento/${planId}`);
  };

  goToTop();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        setLoading(true);
        const responseWithoutFilter = await listPlans();
        const response = responseWithoutFilter.filter(
          plan => plan.is_active === true,
        );

        setMonthlyPlans(
          response
            .filter(plan => plan.period_in_days === 30)
            .sort((a, b) => (a.price > b.price ? 1 : -1)),
        );
        setQuarterlyPlans(
          response
            .filter(plan => plan.period_in_days === 90)
            .sort((a, b) => (a.price > b.price ? 1 : -1)),
        );
        setSemesterPlans(
          response
            .filter(plan => plan.period_in_days === 180)
            .sort((a, b) => (a.price > b.price ? 1 : -1)),
        );
        setYearlyPlans(
          response
            .filter(plan => plan.period_in_days === 365)
            .sort((a, b) => (a.price > b.price ? 1 : -1)),
        );
        setPeriodSelected({
          label: 'Anual',
          value: 'yearlyPlans',
        });
      } catch {
        toast.warning(
          'Ocorreu um erro ao buscar os planos, contrate um pelo seu perfil',
          {
            autoClose: false,
          },
        );
        history.push('/logar');
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, [history]);

  return (
    <RegisterLayoutRoutes>
      <Container>
        <HeaderLoginAndRegister
          className="hidden-mobile"
          buttonText="JÁ SOU ASSINANTE"
        />
        <HeaderLoginAndRegister
          className="hidden-desktop"
          buttonText="JÁ SOU "
          buttonSubText="ASSINANTE"
        />

        <h2>Passo 2 de 3</h2>
        <h1>Escolha uma das opções para sua conta:</h1>

        <PeriodSelector>
          {periods.map(period => (
            <li
              className={periodSelected.value === period.value && 'selected'}
              tabIndex="0"
              onKeyPress={() => setPeriodSelected(period)}
              role="menuitem"
              onClick={() => setPeriodSelected(period)}
              key={periodSelected.planId}
            >
              {period.label}
            </li>
          ))}
        </PeriodSelector>

        <PeriodSelectorMobile onClick={handleTogglePeriodSelect}>
          <PeriodSelectedMobile>
            <p>{periodSelected.label}</p>
            <BiChevronDown />
          </PeriodSelectedMobile>
        </PeriodSelectorMobile>

        {loading ? (
          <div className="spinner">
            <Spinner color="white" />
          </div>
        ) : (
          <Cards>
            {mapPeriodToPlanState[periodSelected.value].length === 0 ? (
              <NoPlansFound>Não temos planos para essa opção</NoPlansFound>
            ) : null}
            {mapPeriodToPlanState[periodSelected.value].map(item => (
              <CardPlan
                key={item.plan_id}
                data={planConverter(item)}
                onSelect={handleSubscribe}
              />
            ))}
          </Cards>
        )}
        <PeriodOptionsContainer open={openPeriodSelect}>
          <PeriodOptions>
            <AiOutlineClose onClick={handleTogglePeriodSelect} />
            {periods.map(period => (
              <li
                key={period.value}
                onKeyPress={() => handleSelectPeriod(period)}
                role="menuitem"
                tabIndex="0"
                onClick={() => handleSelectPeriod(period)}
              >
                {period.label}
              </li>
            ))}
          </PeriodOptions>
        </PeriodOptionsContainer>
      </Container>
    </RegisterLayoutRoutes>
  );
};

export default SelectPlan;
