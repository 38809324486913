import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { useRouteMatch } from 'react-router-dom';

import useWindowSize from '../../hooks/useWindowSize';

import * as S from './styles';

const WhatsApp = () => {
  const { windowSize } = useWindowSize();
  const isMobile = windowSize < 768;

  const apiWhatsApp = `
    https://${isMobile ? 'api' : 'web'}.whatsapp.com/send?phone=5511950578895
  `;

  const routesNotAllowed = [
    '/curso/:uuid/:parentUuid',
    '/live-chat/:liveId',
    '/curso-detalhe/:uuid',
    '/curso/detalhe/live/:uuid',
  ];

  const routeMatch = useRouteMatch({
    path: routesNotAllowed,
    exact: true,
  });

  if (routeMatch) return null;
  return (
    <>
      <S.WhatsAppIcon>
        <S.WhatsAppIconInner href={apiWhatsApp} target="_blank">
          <FaWhatsapp color="#fff" size={20} />
        </S.WhatsAppIconInner>

        <S.Text>
          <span>
            Olá! Tem alguma dúvida? Envie uma mensagem para nosso Suporte
            Técnico <strong>Equipe HG</strong>
          </span>
        </S.Text>
      </S.WhatsAppIcon>
    </>
  );
};

export default WhatsApp;
