import React from 'react';
import { AiFillCreditCard } from 'react-icons/ai';
import { components } from 'react-select';

const ValueContainerCardIcon = ({ children, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && <AiFillCreditCard color="#909599" />}
        {children}
      </components.ValueContainer>
    )
  );
};

export default ValueContainerCardIcon;
