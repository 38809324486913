import React, { useRef, useState } from 'react';
import { BsMailbox } from 'react-icons/bs';
import { FaWhatsapp } from 'react-icons/fa';
import { MdOutlineWeb } from 'react-icons/md';
import { toast } from 'react-toastify';

import { sendMail } from '../../../../services/faq';

import {
  Container,
  Content,
  TextArea,
  Title,
  ContentForm,
  InputGroup,
  Input,
  Button,
} from './style';

const ContactUsForm = () => {
  const nameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const subjectInputRef = useRef(null);
  const messageInputRef = useRef(null);

  const [sendingMail, setSendingMail] = useState(false);

  const formRef = useRef(null);
  const handleSubmit = async e => {
    e.preventDefault();

    try {
      setSendingMail(true);
      await sendMail({
        name: nameInputRef.current?.value,
        email: emailInputRef.current?.value,
        subject: subjectInputRef.current?.value,
        message: messageInputRef.current?.value,
      }).then(() => {
        toast.success('FAQ enviado com sucesso.');
        nameInputRef.current.value = '';
        emailInputRef.current.value = '';
        subjectInputRef.current.value = '';
        messageInputRef.current.value = '';
      });
    } catch (err) {
      toast.error('Não foi possível enviar o FAQ.');
    } finally {
      setSendingMail(false);
    }
  };

  return (
    <Container id="faq-form">
      <Content className="content">
        <div>
          <section id="contacts">
            <Title>Ou entre em contato através do formulário.</Title>
            <ul id="contact-list">
              <li>
                <a
                  href="https://web.whatsapp.com/send?phone=5511950578895"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaWhatsapp size={20} color="inherit" />
                  <span>+55 11 95057-8895</span>
                </a>
              </li>
              <li>
                <a
                  href="mailto:suporte@institutomisteriosascensionais.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <BsMailbox size={20} color="inherit" />
                  <span>suporte@institutomisteriosascensionais.com</span>
                </a>
              </li>
              <li>
                <a
                  href="https://halugamashi.com.br"
                  target="_blank"
                  rel="noreferrer"
                >
                  <MdOutlineWeb size={20} color="inherit" />
                  <span>https://halugamashi.com.br</span>
                </a>
              </li>
            </ul>
          </section>
          <ContentForm onSubmit={handleSubmit} ref={formRef}>
            <InputGroup>
              <Input
                ref={nameInputRef}
                placeholder="nome completo*"
                type="text"
                name="name"
                label="Nome"
                required
              />
            </InputGroup>

            <InputGroup>
              <Input
                ref={emailInputRef}
                type="email"
                name="email"
                label="Email"
                placeholder="email*"
                required
              />
            </InputGroup>

            <InputGroup>
              <Input
                ref={subjectInputRef}
                type="text"
                name="subject"
                label="subject"
                placeholder="assunto"
              />
            </InputGroup>

            <InputGroup>
              <TextArea
                ref={messageInputRef}
                type="text"
                name="text"
                label="Escreva a sua dúvida"
                placeholder="escreva sua dúvida aqui*"
                required
                rows={5}
              />
            </InputGroup>
            <Button disabled={sendingMail}>Enviar</Button>
          </ContentForm>
        </div>
      </Content>
    </Container>
  );
};

export default ContactUsForm;
