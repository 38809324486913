import styled, { keyframes, css } from 'styled-components';

import { theme } from '../../styles/theme';

const upSizeBackground = keyframes`
  0% {
    background-size: cover;
    background-position-y: center;
  }
  25% {
    background-position-y: 5%;
    background-size: 105%;
  }
  50% {
    background-position-y: 10%;
    background-size: 110%;
  }
  75% {
    background-position-y: 15%;
    background-size: 115%;
  }
  100% {
    background-position-y: 20%;
    background-size: 120%;
  }
`;

const downSizeBackground = keyframes`
  0% {
    background-position-y: 20%;
    background-size: 120%;
  }
  25% {
    background-position-y: 15%;
    background-size: 115%;
  }
  50% {
    background-position-y: 10%;
    background-size: 110%;
  }
  75% {
    background-position-y: 5%;
    background-size: 105%;
  }
  100% {
    background-position-y: center;
    background-size: cover;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 340px;
  border-radius: 20px;
  position: relative;
  margin: 0 24px 0 0;
  &:hover {
    background-color: ${theme.colors.light};
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  transition: all 0.5s ease-in;

  & > button {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1;
  }
  @media (max-width: 480px) {
    margin: 24px 0;
  }
`;

export const ImageCourseBackground = styled.div`
  display: block;
  background: url(${({ courseImg }) => courseImg});
  background-position: center;
  background-size: cover;
  width: 100%;
  min-height: 172px;
  border-radius: 20px;
  color: ${theme.colors.textLight};
  cursor: pointer;
  &:hover,
  &:focus {
    animation: ${upSizeBackground} 0.5s linear forwards;
  }

  ${({ mouseLeave }) =>
    mouseLeave &&
    css`
      animation: ${downSizeBackground} 0.5s linear forwards;
    `}
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(37, 40, 43, 0.3);
  border-radius: 20px;
  min-height: 172px;
  padding: 16px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-family: 'OpenSans';
`;

export const Rating = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 18px;
    width: 18px;
  }

  span {
    font-weight: bold;
    color: ${theme.colors.warning};
    margin-left: 4px;

    & > span {
      font-weight: 400;
      color: ${theme.colors.textLight};
    }
  }

  @media (max-width: 470px) {
    display: none;
  }
`;

export const Like = styled.div`
  img.hidden {
    opacity: 0;
  }

  img {
    position: absolute;
    top: 0px;
    right: 0px;
    opacity: 1;
    transition: opacity 0.5s linear;
  }
`;

export const Tag = styled.div`
  display: flex;
  justify-content: center;
  max-width: 108px;
  padding: 8px 16px;
  background-color: ${theme.colors.primary};
  border-radius: 20px;
  text-align: center;

  span {
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

export const EmptyProgressBar = styled.div`
  height: 39px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0 16px;
  min-height: 210px;

  > p {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 10px;
    max-width: 100%;
    height: 90px;
    white-space: pre-wrap;
  }

  > p:nth-child(2) {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 10px;
    @media (max-width: 480px) {
      text-align: left;
      font-weight: bold;
      font-size: 20px;
    }
  }

  > span {
    margin-bottom: 10px;
    display: flex;
    color: #657787;
    font-size: 1.3rem;
    max-width: 250px;

    cite {
      font-style: normal;
      font-size: 0.9rem;
      font-weight: 600;
      color: ${theme.colors.primary};
    }

    h4 {
      padding-left: 5px;
      color: #594aad;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @media (max-width: 480px) {
      text-align: left;
      font-weight: 600;
      font-size: 14px;
    }
  }
`;

export const CourseProgress = styled.div`
  padding-bottom: 16px;

  p {
    font-size: 0.8rem;
    font-weight: 400;
    margin-bottom: 6px;
  }
`;

export const DateInfo = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 25px;

  span {
    margin: 0 0 0 8px;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
  }

  img {
    height: 20px;
  }

  div {
    display: flex;
    align-items: center;
  }

  div ~ div {
    margin-left: 40px;
  }

  @media (max-width: 480px) {
    width: 100%;

    span {
      min-width: fit-content;
    }

    img {
      height: 15px;
    }
  }
`;

export const ThanksForRating = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  p {
    font-weight: 500;
    font-size: 0.9rem;
    margin: 0;
  }

  div {
    display: flex;
    align-items: center;

    span {
      margin: 0;
      margin-left: 8px;
    }

    button {
      margin-left: 8px;
      text-decoration: underline;
      font-weight: 500;
      font-size: 0.8rem;
      color: ${theme.colors.secondary};
    }
  }
`;
