import React, { useState, useEffect } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';

import LogoImaLeft from '../../assets/icons/logo-left.svg';
import LogoImaRight from '../../assets/icons/logo-right.svg';
import NotCheckIcon from '../../assets/images/check-no.png';
import CheckIcon from '../../assets/images/check-ok.jpg';
import { UserService } from '../../services/userService';
import { getMostRecentPlan } from '../../utils/getMostRecentPlan';
import { Button } from '../Button';

import {
  Container,
  RecommendedLabel,
  Content,
  PlanTitle,
  IsFreeContainer,
  ValueInfo,
  Benefits,
  BenefitsButton,
} from './styles';

const CardPlan = ({ data, onSelect }) => {
  const [benefitsOpen, setBenefitsOpen] = useState(false);
  const [user, setUser] = useState();

  const handleToggleBenefits = () => {
    setBenefitsOpen(state => !state);
  };

  const allowShowButton = () => {
    const currentActivePlan = getMostRecentPlan(user?.plans_paid);

    return (
      currentActivePlan?.plan?.info?.type === 0 ||
      currentActivePlan?.plan_id !== data.planId ||
      currentActivePlan?.status === 'canceled'
    );
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const userService = new UserService();

      try {
        const response = await userService.getUserData();

        setUser(response.data);
      } catch (error) {
        setUser(null);
      }
    };
    fetchUserData();
  }, []);

  return (
    <Container isRecommended={data.isRecommended}>
      {data.isRecommended && <RecommendedLabel>Recomendado</RecommendedLabel>}

      <Content>
        <PlanTitle isFree={data.isFree} type={data.type}>
          <h6>{data.isFree ? 'Cadastro Grátis' : 'Plano'}</h6>
          <h3>{data.planTitle}</h3>
        </PlanTitle>

        {data.isFree ? (
          <IsFreeContainer>
            <img src={LogoImaRight} alt="Logo do Ima" />
            <h5>
              <br /> Gratuito
            </h5>
          </IsFreeContainer>
        ) : (
          <ValueInfo>
            <img src={LogoImaLeft} alt="Logo do Ima" />
            <p>Plano {data.planPeriod}</p>
            {data.valueWithoutDiscount && (
              <span>
                De <s>R${data.valueWithoutDiscount}</s> por
              </span>
            )}
            <h5>
              <h6>R$</h6>
              {data.value}
            </h5>
          </ValueInfo>
        )}

        <Benefits open={benefitsOpen}>
          {data.benefits.map(benefit => (
            <li key={benefit.label}>
              <img
                src={benefit.active ? CheckIcon : NotCheckIcon}
                alt={benefit.active ? 'Incluso' : 'Não incluso'}
              />
              {benefit.active ? <p>{benefit.label}</p> : <s>{benefit.label}</s>}
            </li>
          ))}
        </Benefits>

        <BenefitsButton onClick={handleToggleBenefits}>
          {benefitsOpen ? (
            <>
              <span>Ver menos</span>
              <BiChevronUp />
            </>
          ) : (
            <>
              <span>Ver benefícios</span>
              <BiChevronDown />
            </>
          )}
        </BenefitsButton>

        {allowShowButton() ? (
          <Button
            onClick={() => onSelect(data.planId)}
            title="quero assinar agora"
            containerStyle={{
              maxWidth: '260px',
              minWidth: '260px',
              margin: '20px',
            }}
            buttonStyle={{
              textTransform: 'uppercase',
              fontSize: '14px',
            }}
            className="subscribe"
          />
        ) : null}
      </Content>
    </Container>
  );
};

export default CardPlan;
