import { yupResolver } from '@hookform/resolvers/yup';
import { enc } from 'crypto-js';
import AES from 'crypto-js/aes';
import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { AiFillCreditCard, AiFillIdcard, AiOutlineUser } from 'react-icons/ai';
import { BsFillCalendarFill, BsPaypal } from 'react-icons/bs';
import { FaRegAddressCard } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';

import iconGenerateBillet from '../../assets/icons/icon-generate-billet.svg';
import iconTicket from '../../assets/icons/icon-ticket.png';
import paypalImage from '../../assets/icons/paypal-2700934-2239070.png';
import PixIcon from '../../assets/icons/pix.png';
import AddressContainerIcon from '../../components/AddressIcon';
import { Button } from '../../components/Button';
import { ViewBilletCamp } from '../../components/CardCourseInfo/components/PaymentModal/styles';
import ValueContainerCardIcon from '../../components/CardIcon';
import HeaderLoginAndRegister from '../../components/HeaderLoginAndRegister';
import { Input } from '../../components/Input';
import Spinner from '../../components/Spinner';
import brStates from '../../data/brStatesISO.json';
import { useAuth } from '../../hooks/auth';
import { RegisterLayoutRoutes } from '../../routes/RegisterLayoutRoutes';
import {
  checkPaymentStatus,
  createPaymentPlanWitPix,
  createPaymentWithBankSlipPlan,
  createPaymentWithPaypalPlan,
} from '../../services/paymentsService';
import { getPlan, purchasePlan } from '../../services/plans';
import { UserService } from '../../services/userService';
import { goToTop } from '../../utils/helpers';
import { mappedErrors } from '../../utils/paymentsMappedErrors';
import {
  paymentYupValidationBankSlip,
  paymentYupValidationGeneric,
} from '../../utils/paymentYupValidation';
import { mapPeriodInDaysToString } from '../../utils/planConverter';

import {
  Container,
  PaymentForm,
  PaymentFormHeader,
  PaymentFormPrice,
  PaymentFormContainer,
  PaymentFormInfo,
  RowInputs,
  SelectContainer,
  SelectCustomStyle,
  ErrorMessage,
  SelectFormOfPayment,
  ViewBillet,
  TicketNumberCamp,
  ViewPayPalCamp,
  SelectFormOption,
  PixCopyAndPasteContainer,
  PixContainer,
  PixCopyAndPaste,
  FormInputs,
  ContainerDebitCreditCard,
  LabelDebitCreditCard,
} from './styles';
import { paymentCreditCardYupValidation } from './validation';

goToTop();

const PaymentPlan = () => {
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState({});
  const [payment, setPayment] = useState('credit_card');
  const [paymentBankSlip, setPaymentBankSlip] = useState(false);
  const [paymentBankSlipSuccess, setPaymentBankSlipSuccess] = useState(false);
  const [paymentPaypal, setPaymentPaypal] = useState(false);
  const [paymentPaypalSuccess, setPaymentPaypalSuccess] = useState(false);
  const [pixInfo, setPixInfo] = useState(null);
  const [intervalId, setIntervalId] = useState(0);
  const [showUserAddressForm, setShowUserAddressForm] = useState(false);

  const history = useHistory();
  const { planId } = useParams();
  const { token } = useAuth();
  const pixInstructionsQrCodeRef = useRef();

  const getScheme = () => {
    switch (payment) {
      case 'credit_card':
        return paymentCreditCardYupValidation;

      case 'bank_slip':
        return paymentYupValidationBankSlip;

      default:
        return paymentYupValidationGeneric;
    }
  };

  const installmentOptions = (() => {
    const options = [];

    if (!plan?.info?.max_installments) return [];

    for (let index = 0; index < plan.info.max_installments; index += 1) {
      const numInstallment = index + 1;
      const installmentValue = (parseFloat(plan.price, 2) / numInstallment)
        .toFixed(2)
        .replace('.', ',');

      const getInstallment = () => {
        if (numInstallment === 1) {
          return `${numInstallment}x parcela de R$ ${installmentValue}`;
        }
        if (numInstallment !== 1) {
          return `${numInstallment}x parcelas de R$ ${installmentValue}`;
        }
        return null;
      };
      options.push({
        label: `${getInstallment()}`,
        value: numInstallment,
      });
    }

    return options;
  })();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(getScheme()),
  });

  const handlePayment = async data => {
    if (isSubmitting) return;
    setLoading(true);

    try {
      const formattedData = {
        holder_name: data.name,
        registry_code: data.document,
        card_expiration: data.expirationDate,
        card_number: data.cardNumber.replaceAll(' ', ''),
        card_cvv: data.cvv,
      };

      const userService = new UserService();

      const userAddress = await userService
        .getUserCustomerAddress()
        .then(res => res.data)
        .catch(() => {
          toast.error(
            'Erro ao tentar recuperar dados do usuário, tente novamente.',
          );
          return null;
        });

      delete userAddress.additional_details;

      const allFieldsOk = Object.values(userAddress).every(
        value => value !== null,
      );

      if (!allFieldsOk) {
        setShowUserAddressForm(true);
        setLoading(false);
        toast.info('Preencha os dados do endereço para continuar.');
        return;
      }

      const encryptedData = AES.encrypt(
        JSON.stringify(formattedData),
        `Bearer ${token}`,
      ).toString();

      const encodedData = enc.Base64.stringify(enc.Utf8.parse(encryptedData));

      await purchasePlan(planId, encodedData);

      history.push('/registro/obrigado-por-registrar');
    } catch (err) {
      const parsedErrorMessage = mappedErrors.find(
        option => option.error === err?.response?.data?.message,
      );

      if (parsedErrorMessage) {
        toast.warning(parsedErrorMessage.message);
        history.push('/logar');
        return;
      }

      toast.warning(
        'Ocorreu um erro ao comprar o plano, contrate um pelo seu perfil',
        {
          autoClose: false,
        },
      );
      history.push('/logar');
    }
  };

  const handleSubmitPaymentBankSlip = async data => {
    if (isSubmitting) return;

    const objectFormatted = data;

    delete objectFormatted.expirationDate;
    delete objectFormatted.installments;
    delete objectFormatted.cardNumber;
    delete objectFormatted.cvv;
    delete objectFormatted.name;
    delete objectFormatted.document;

    objectFormatted.cpf = objectFormatted.cpf
      .replaceAll('.', '')
      .replace('-', '');

    objectFormatted.zipcode = objectFormatted.zipcode.replace('-', '');
    objectFormatted.zipcode = objectFormatted.zipcode.replaceAll(' ', '');

    await createPaymentWithBankSlipPlan(planId, objectFormatted)
      .then(res => {
        setPaymentBankSlip(res.data);
        setPaymentBankSlipSuccess(true);
      })
      .catch(err => {
        const parsedErrorMessage = mappedErrors.find(
          option => option.error === err?.response?.data?.message,
        );

        if (parsedErrorMessage) {
          toast.warning(parsedErrorMessage.message);
          return;
        }

        toast.error(
          'Erro ao registrar boleto, verifique os dados e tente novamente.',
        );
      });
  };

  const handleSubmitPaymentPaypal = async () => {
    if (isSubmitting) return;

    await createPaymentWithPaypalPlan(plan.plan_id)
      .then(res => {
        setPaymentPaypal(res.data);
        setPaymentPaypalSuccess(true);
      })
      .catch(err => {
        const parsedErrorMessage = mappedErrors.find(
          option => option.error === err?.response?.data?.message,
        );

        if (parsedErrorMessage) {
          toast.warning(parsedErrorMessage.message);
          return;
        }

        toast.error('Erro ao enviar compra com o PayPal, tente novamente.');
      });
  };

  const handleCopyBarCode = barCode => {
    navigator.clipboard.writeText(barCode);
    toast.info('Texto copiado!');
  };

  const paymentName = paymentType => {
    switch (paymentType) {
      case 'credit_card':
        return 'Dados do Cartão';

      case 'bank_slip':
        return 'Dados do Boleto';

      case 'paypal':
        return 'Paypal';

      default:
        return '';
    }
  };

  const pollingCheckPixPaymentStatus = async userPaymentId => {
    try {
      const userPayment = await checkPaymentStatus(userPaymentId);

      if (userPayment?.data.status === 'paid') {
        setPixInfo(state => ({ ...state, status: 'paid' }));
        toast.success('Assinatura ativada com sucesso!');
        history.push('/registro/obrigado-por-registrar');
        return;
      }
    } catch {
      toast.error(
        'Ocorreu um error ao verificar o status do pagamento, vamos tentar novamente aguarde...',
        { toastId: 'pix-polling-error' },
      );
    }
  };

  const handleSubmitPaymentPix = async formData => {
    if (isSubmitting) return;

    const objectFormatted = formData;

    delete objectFormatted.expirationDate;
    delete objectFormatted.installments;
    delete objectFormatted.cardNumber;
    delete objectFormatted.cvv;
    delete objectFormatted.name;
    delete objectFormatted.document;

    objectFormatted.cpf = objectFormatted.cpf.replace(/[^\d]/g, '');
    objectFormatted.zipcode = objectFormatted.zipcode.replace(/[^\d]/g, '');

    const userPaymentPix = await createPaymentPlanWitPix(
      plan.plan_id,
      objectFormatted,
    )
      .then(response => {
        const { data } = response;

        const userPayment = {
          qrCode: data.qrcode,
          paymentUrl: data.payment_url,
          pixKey: data.pix_key,
          status: data.status,
          userPaymentId: data.user_payment_id,
        };

        setPixInfo(userPayment);
        return userPayment;
      })
      .catch(err => {
        const parsedErrorMessage = mappedErrors.find(
          option => option.error === err?.response?.data?.message,
        );

        if (parsedErrorMessage) {
          toast.warning(parsedErrorMessage.message, { autoClose: false });
          return null;
        }

        toast.error('Ocorreu um erro ao gerar o Pix, tente novamente.');
        return null;
      })
      .finally(() => {
        if (pixInstructionsQrCodeRef?.current) {
          pixInstructionsQrCodeRef?.current?.scrollIntoView();
        }
      });

    if (!userPaymentPix) return;

    const id = setInterval(async () => {
      await pollingCheckPixPaymentStatus(userPaymentPix.userPaymentId);
    }, 2000);

    setIntervalId(id);
  };

  const handleCopyPixKey = pixKey => {
    navigator.clipboard.writeText(pixKey);
    toast.info('Chave copiada!');
  };

  const updateCustomerProfile = async (data, isCreditCard) => {
    const objectFormatted = {
      ...data,
      country: 'BR',
    };

    delete objectFormatted.expirationDate;
    delete objectFormatted.installments;
    delete objectFormatted.cardNumber;
    delete objectFormatted.cvv;
    delete objectFormatted.name;
    delete objectFormatted.document;
    delete objectFormatted.coupon;

    objectFormatted.cpf = objectFormatted.cpf
      .replaceAll('.', '')
      .replace('-', '');

    objectFormatted.zipcode = objectFormatted.zipcode.replaceAll(' ', '');

    const userService = new UserService();

    try {
      await userService.updateUserCustomerAddress(objectFormatted);
      if (isCreditCard) {
        toast.info(
          'Dados do endereço atualizado com sucesso! Realize a confirmação do pagamento novamente.',
        );
        setShowUserAddressForm(false);
        return;
      }
      toast.info(
        'Endereço atualizado com sucesso! Realize a confirmação do Pix novamente.',
      );
      setShowUserAddressForm(false);
    } catch {
      toast.error('Erro ao atualizar endereço, tente novamente.');
    }
  };

  const paymentMethods = {
    credit_card: handlePayment,
    bank_slip: handleSubmitPaymentBankSlip,
    paypal: handleSubmitPaymentPaypal,
    pix: handleSubmitPaymentPix,
  };

  useEffect(() => {
    try {
      const fetchPlan = async () => {
        setLoading(true);
        const response = await getPlan(planId);
        setPlan(response);
      };

      fetchPlan();
    } catch {
      toast.warning(
        'Ocorreu um erro ao buscar os planos, contrate um pelo seu perfil',
        {
          autoClose: false,
        },
      );
    } finally {
      setLoading(false);
    }
  }, [planId]);

  useEffect(() => {
    setValue('installments', installmentOptions[0]?.value);
  }, [installmentOptions, setValue]);

  useEffect(() => {
    return () => clearInterval(intervalId);
  }, [intervalId]);

  return (
    <RegisterLayoutRoutes>
      <Container>
        <HeaderLoginAndRegister
          className="hidden-mobile"
          buttonText="JÁ SOU ASSINANTE"
        />
        <HeaderLoginAndRegister
          className="hidden-desktop"
          buttonText="JÁ SOU "
          buttonSubText="ASSINANTE"
        />
        <h2>Passo 3 de 3</h2>
        <h1>Faça seu pagamento</h1>

        <PaymentFormContainer>
          <SelectFormOfPayment>
            <SelectFormOption>
              <input
                type="radio"
                checked={payment === 'credit_card'}
                onChange={() => setPayment('credit_card')}
              />
              <label>Cartão de crédito</label>
            </SelectFormOption>

            <SelectFormOption>
              <input
                type="radio"
                checked={payment === 'bank_slip'}
                onChange={() => setPayment('bank_slip')}
              />
              <label>Boleto</label>
            </SelectFormOption>

            <SelectFormOption>
              <input
                type="radio"
                checked={payment === 'paypal'}
                onChange={() => setPayment('paypal')}
              />
              <label>PayPal</label>
            </SelectFormOption>

            <SelectFormOption>
              <input
                type="radio"
                checked={payment === 'pix'}
                onChange={() => setPayment('pix')}
              />
              <label>Pix</label>
            </SelectFormOption>
          </SelectFormOfPayment>
          {payment === 'credit_card' && (
            <ContainerDebitCreditCard>
              <LabelDebitCreditCard>
                O pagamento via cartão de crédito é feito via débito automático.
              </LabelDebitCreditCard>
            </ContainerDebitCreditCard>
          )}
          <PaymentFormHeader>
            {loading ? (
              <div className="spinner">
                <Spinner />
              </div>
            ) : (
              <>
                <PaymentFormInfo>
                  <p>
                    PLANO{' '}
                    {mapPeriodInDaysToString[
                      plan?.period_in_days
                    ]?.toUpperCase()}
                  </p>
                  <h3>{plan?.title}</h3>
                </PaymentFormInfo>

                <PaymentFormPrice>
                  <p>Por apenas</p>
                  <h3>R$ {plan?.price?.toFixed(2).replace('.', ',')}</h3>
                </PaymentFormPrice>
              </>
            )}
          </PaymentFormHeader>

          {payment === 'pix' && (
            <PixContainer>
              <h5>Pix</h5>

              {pixInfo?.qrCode && pixInfo?.status !== 'paid' ? (
                <>
                  <p ref={pixInstructionsQrCodeRef}>
                    Leia o QrCode abaixo para realizar o pagamento
                  </p>

                  <iframe src={pixInfo?.qrCode} title="QrCode Pix" />
                </>
              ) : (
                <img src={PixIcon} alt="Pagamento com Pix" />
              )}

              {pixInfo && pixInfo?.status !== 'paid' && (
                <PixCopyAndPasteContainer>
                  <p>Aguardando seu pagamento...</p>
                  <p>
                    Esta operação poderá levar até
                    <br />
                    30 minutos para ser reconhecida após o pagamento
                  </p>
                  <div className="spinner">
                    <Spinner />
                  </div>

                  <PixCopyAndPaste>
                    <p>Pix Copia e Cola</p>

                    <textarea readOnly>{pixInfo?.pixKey}</textarea>

                    <button
                      type="button"
                      onClick={() => handleCopyPixKey(pixInfo?.pixKey)}
                    >
                      COPIAR
                    </button>
                  </PixCopyAndPaste>
                  <a
                    href={pixInfo?.paymentUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ou clique aqui...
                  </a>
                </PixCopyAndPasteContainer>
              )}

              {pixInfo && pixInfo?.status === 'paid' && (
                <PixCopyAndPasteContainer>
                  <h1>Pagamento realizado com sucesso!</h1>
                </PixCopyAndPasteContainer>
              )}
            </PixContainer>
          )}

          {payment === 'bank_slip' && paymentBankSlipSuccess && (
            <ViewBilletCamp>
              <>
                <h5>Boleto gerado!</h5>
                <p>
                  Você poderá pagar o boleto abaixo impresso ou por aplicativo.
                </p>
                <ViewBillet>
                  <img src={iconTicket} alt="Visualizar boleto" />
                  <a
                    href={paymentBankSlip?.bank_slip_url}
                    download="boleto.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    VISUALIZAR BOLETO
                  </a>
                </ViewBillet>

                <h4>Código do boleto</h4>

                <TicketNumberCamp>
                  <span>{paymentBankSlip?.barcode}</span>
                  <button
                    onClick={() => handleCopyBarCode(paymentBankSlip?.barcode)}
                  >
                    <img src={iconGenerateBillet} alt="Gerar boleto" />
                  </button>
                </TicketNumberCamp>

                <Button
                  title="CONTINUAR"
                  containerStyle={{ maxWidth: '90%' }}
                  type="button"
                  onClick={() =>
                    history.push('/registro/obrigado-por-registrar')
                  }
                />
              </>
            </ViewBilletCamp>
          )}

          {!paymentBankSlipSuccess && !pixInfo && (
            <PaymentForm onSubmit={handleSubmit(paymentMethods[payment])}>
              <h4>{paymentName(payment)}</h4>

              {payment === 'paypal' && <BsPaypal />}
              {payment === 'bank_slip' ||
                (payment === 'pix' && (
                  <>
                    <Input
                      containerStyle={{
                        minWidth: 90,
                      }}
                      placeholder="CPF ou CNPJ"
                      icon={<AiFillIdcard color="#909599" />}
                      isInvalid={errors.cpf?.message}
                      errorMessage={errors.cpf?.message}
                      mask="999.999.999-99"
                      {...register('cpf')}
                    />

                    <Input
                      placeholder="Rua"
                      icon={<FaRegAddressCard />}
                      isInvalid={errors.street?.message}
                      errorMessage={errors.street?.message}
                      {...register('street')}
                    />

                    <Input
                      placeholder="Número"
                      icon={<FaRegAddressCard />}
                      isInvalid={errors.number?.message}
                      errorMessage={errors.number?.message}
                      {...register('number')}
                    />

                    <Input
                      placeholder="Bairro"
                      icon={<FaRegAddressCard />}
                      isInvalid={errors.neighborhood?.message}
                      errorMessage={errors.neighborhood?.message}
                      {...register('neighborhood')}
                    />

                    <Controller
                      control={control}
                      name="additional_details"
                      render={({ field: { onChange, ref } }) => (
                        <SelectContainer
                          error={errors.additional_details?.message}
                        >
                          <Select
                            ref={ref}
                            options={[
                              { label: 'Casa', value: 'Casa' },
                              { label: 'Apartamento', value: 'Apartamento' },
                            ]}
                            components={{
                              ValueContainer: AddressContainerIcon,
                            }}
                            placeholder={<span>Complemento</span>}
                            styles={SelectCustomStyle}
                            noOptionsMessage={() => 'Sem opções disponíveis'}
                            onChange={option => {
                              onChange(option.value);
                            }}
                          />
                          {errors.additional_details?.message ? (
                            <ErrorMessage>
                              {errors.additional_details?.message}
                            </ErrorMessage>
                          ) : null}
                        </SelectContainer>
                      )}
                    />

                    <Input
                      control={control}
                      placeholder="Código Postal"
                      icon={<FaRegAddressCard />}
                      mask="99999 999"
                      isInvalid={errors.zipcode?.message}
                      errorMessage={errors.zipcode?.message}
                      name="zipcode"
                    />

                    <Input
                      placeholder="Cidade"
                      icon={<FaRegAddressCard />}
                      isInvalid={errors.city?.message}
                      errorMessage={errors.city?.message}
                      {...register('city')}
                    />

                    <Controller
                      control={control}
                      name="state"
                      render={({ field: { onChange, ref } }) => (
                        <SelectContainer
                          style={{ marginBottom: 32 }}
                          error={errors.state?.message}
                        >
                          <Select
                            ref={ref}
                            options={brStates}
                            components={{
                              ValueContainer: AddressContainerIcon,
                            }}
                            placeholder={<span>Estado</span>}
                            styles={SelectCustomStyle}
                            noOptionsMessage={() => 'Sem opções disponíveis'}
                            onChange={option => {
                              onChange(option.value);
                            }}
                          />
                          {errors.state?.message ? (
                            <ErrorMessage>{errors.state?.message}</ErrorMessage>
                          ) : null}
                        </SelectContainer>
                      )}
                    />
                  </>
                ))}

              {payment === 'credit_card' && plan?.price && (
                <>
                  <Input
                    containerStyle={{
                      minWidth: 90,
                    }}
                    placeholder={
                      payment === 'credit_card'
                        ? 'Nome do titular do cartão'
                        : 'Nome completo'
                    }
                    icon={<AiOutlineUser color="#909599" />}
                    isInvalid={errors.name?.message}
                    errorMessage={errors.name?.message}
                    {...register('name')}
                  />

                  <Input
                    containerStyle={{
                      minWidth: 90,
                    }}
                    placeholder="CPF ou CNPJ"
                    icon={<AiFillIdcard color="#909599" />}
                    isInvalid={errors.document?.message}
                    errorMessage={errors.document?.message}
                    mask="999.999.999-99"
                    {...register('document')}
                  />

                  <Input
                    containerStyle={{
                      minWidth: 90,
                    }}
                    placeholder="Número do cartão"
                    icon={<AiFillCreditCard color="#909599" />}
                    isInvalid={errors.cardNumber?.message}
                    errorMessage={errors.cardNumber?.message}
                    mask="9999 9999 9999 9999"
                    {...register('cardNumber')}
                  />

                  <RowInputs>
                    <Input
                      containerStyle={{
                        minWidth: 90,
                      }}
                      placeholder="Validade"
                      icon={<BsFillCalendarFill color="#909599" />}
                      isInvalid={errors.expirationDate?.message}
                      errorMessage={errors.expirationDate?.message}
                      mask="99/9999"
                      {...register('expirationDate')}
                    />

                    <Input
                      containerStyle={{
                        minWidth: 90,
                      }}
                      type="number"
                      placeholder="CVV"
                      icon={<AiFillCreditCard color="#909599" />}
                      isInvalid={errors.cvv?.message}
                      errorMessage={errors.cvv?.message}
                      {...register('cvv')}
                    />
                  </RowInputs>

                  <h6>Parcelamento</h6>

                  <Controller
                    control={control}
                    name="installments"
                    render={({ field: { onChange, ref } }) => (
                      <SelectContainer error={errors.installments?.message}>
                        <Select
                          ref={ref}
                          options={installmentOptions}
                          components={{
                            ValueContainer: ValueContainerCardIcon,
                          }}
                          placeholder={
                            <span>Escolha um plano de parcelamento</span>
                          }
                          styles={SelectCustomStyle}
                          noOptionsMessage={() => 'Sem opções disponíveis'}
                          defaultValue={installmentOptions[0]}
                          onChange={option => {
                            onChange(option.value);
                          }}
                        />
                        {errors.installments?.message ? (
                          <ErrorMessage>
                            {errors.installments?.message}
                          </ErrorMessage>
                        ) : null}
                      </SelectContainer>
                    )}
                  />
                </>
              )}

              <FormInputs>
                {payment === 'credit_card' && showUserAddressForm && (
                  <>
                    <h6>Endereço</h6>
                    <Input
                      control={control}
                      containerStyle={{
                        minWidth: 90,
                      }}
                      placeholder="CPF ou CNPJ"
                      icon={<AiFillIdcard color="#909599" />}
                      isInvalid={errors.cpf?.message}
                      errorMessage={errors.cpf?.message}
                      mask="999.999.999-99"
                      name="cpf"
                    />

                    <Input
                      control={control}
                      placeholder="Rua"
                      icon={<FaRegAddressCard />}
                      isInvalid={errors.street?.message}
                      errorMessage={errors.street?.message}
                      name="street"
                    />

                    <Input
                      control={control}
                      placeholder="Número"
                      icon={<FaRegAddressCard />}
                      isInvalid={errors.number?.message}
                      errorMessage={errors.number?.message}
                      name="number"
                    />

                    <Input
                      control={control}
                      placeholder="Bairro"
                      icon={<FaRegAddressCard />}
                      isInvalid={errors.neighborhood?.message}
                      errorMessage={errors.neighborhood?.message}
                      name="neighborhood"
                    />

                    <Controller
                      control={control}
                      name="additional_details"
                      render={({ field: { onChange, ref } }) => (
                        <SelectContainer
                          error={errors.additional_details?.message}
                        >
                          <Select
                            ref={ref}
                            options={[
                              { label: 'Casa', value: 'Casa' },
                              {
                                label: 'Apartamento',
                                value: 'Apartamento',
                              },
                            ]}
                            components={{
                              ValueContainer: AddressContainerIcon,
                            }}
                            placeholder={<span>Complemento</span>}
                            styles={SelectCustomStyle}
                            noOptionsMessage={() => 'Sem opções disponíveis'}
                            onChange={option => {
                              onChange(option.value);
                            }}
                          />
                          {errors.additional_details?.message ? (
                            <ErrorMessage>
                              {errors.additional_details?.message}
                            </ErrorMessage>
                          ) : null}
                        </SelectContainer>
                      )}
                    />

                    <Input
                      control={control}
                      placeholder="Código Postal"
                      icon={<FaRegAddressCard />}
                      mask="99999 999"
                      isInvalid={errors.zipcode?.message}
                      errorMessage={errors.zipcode?.message}
                      name="zipcode"
                    />

                    <Input
                      control={control}
                      placeholder="Cidade"
                      icon={<FaRegAddressCard />}
                      isInvalid={errors.city?.message}
                      errorMessage={errors.city?.message}
                      name="city"
                    />

                    <Controller
                      control={control}
                      name="state"
                      render={({ field: { onChange, ref } }) => (
                        <SelectContainer
                          style={{ marginBottom: 32 }}
                          error={errors.state?.message}
                        >
                          <Select
                            ref={ref}
                            options={brStates}
                            components={{
                              ValueContainer: AddressContainerIcon,
                            }}
                            placeholder={<span>Estado</span>}
                            styles={SelectCustomStyle}
                            noOptionsMessage={() => 'Sem opções disponíveis'}
                            onChange={option => {
                              onChange(option.value);
                            }}
                          />
                          {errors.state?.message ? (
                            <ErrorMessage>{errors.state?.message}</ErrorMessage>
                          ) : null}
                        </SelectContainer>
                      )}
                    />
                  </>
                )}
              </FormInputs>

              {(isSubmitting || loading) && payment !== 'paypal' && (
                <div className="spinner">
                  <Spinner />
                </div>
              )}

              {!isSubmitting && payment === 'bank_slip' && (
                <Button
                  title="GERAR BOLETO"
                  type="submit"
                  containerStyle={{
                    height: 30,
                    minWidth: 300,
                    padding: 20,
                  }}
                />
              )}

              {!isSubmitting && payment === 'credit_card' && (
                <Button
                  title="CONFIRMAR COMPRA"
                  type="submit"
                  containerStyle={{
                    marginTop: 16,
                  }}
                  onClick={
                    // eslint-disable-next-line consistent-return
                    () => {
                      if (showUserAddressForm) {
                        return updateCustomerProfile(getValues(), true);
                      }

                      if (!showUserAddressForm) {
                        return handlePayment(getValues());
                      }
                    }
                  }
                />
              )}

              {!isSubmitting &&
                payment === 'paypal' &&
                !paymentBankSlipSuccess &&
                !paymentPaypalSuccess && (
                  <Button title="PAGAR COM PAYPAL" type="submit" />
                )}

              {!isSubmitting && payment === 'pix' && (
                <Button
                  title="GERAR PIX"
                  type="submit"
                  containerStyle={{
                    marginTop: 16,
                  }}
                />
              )}
            </PaymentForm>
          )}

          {payment === 'paypal' && paymentPaypalSuccess && (
            <>
              {true && (
                <ViewPayPalCamp>
                  <>
                    <h5>Pagamento com o Paypal gerado com sucesso!</h5>

                    <ViewPayPalCamp>
                      <a
                        href={paymentPaypal?.payment_url}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() =>
                          history.push('/registro/obrigado-por-registrar')
                        }
                      >
                        <img src={paypalImage} alt="Pagamento com PayPal" />
                        ACESSAR PAYPAL
                      </a>
                    </ViewPayPalCamp>
                  </>
                </ViewPayPalCamp>
              )}
            </>
          )}
        </PaymentFormContainer>
      </Container>
    </RegisterLayoutRoutes>
  );
};

export default PaymentPlan;
