import styled from 'styled-components';

const HomeScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  color: var(--default-font-black);

  margin: 0 -9999px;
  padding: 0 9999px;
  padding-top: 126px;
  padding-bottom: 83px;

  position: relative;

  > h2 {
    font-size: 2.4em;
  }

  > h5 {
    font-size: 1.7em;
  }

  > p {
    font-size: 1.6em;
    font-weight: 500;
    padding-bottom: 24px;
  }

  .carousel {
    position: relative;
    width: 100%;
    height: 424px;

    .carousel__slider {
      height: 100%;
    }

    .max-content {
      margin: 0px 8px;

      @media (min-width: 430px) {
        margin: 0 auto;
      }
    }

    .carousel__back-button,
    .carousel__next-button {
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 8px;
      background: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 50%;
    }

    .carousel__back-button {
      z-index: 2;
      transform: translate(-0%, 650%);
      left: 25px;

      @media (min-width: 430px) {
        transform: translate(-0%, 450%);
      }

      @media (min-width: 475px) {
        transform: translate(-0%, 450%);
      }

      @media (min-width: 1600px) {
        transform: translate(-0%, 50%);
      }
    }

    .carousel__next-button {
      z-index: 2;
      transform: translate(0%, 650%);
      right: 25px;

      @media (min-width: 430px) {
        transform: translate(0%, 450%);
      }

      @media (min-width: 475px) {
        transform: translate(0%, 450%);
      }

      @media (min-width: 1600px) {
        transform: translate(0%, 50%);
      }
    }
    .carousel__dot-group {
      position: absolute;
      bottom: -80px;
      left: 0;
      display: flex;
      justify-content: center;
      gap: 8px;
      width: 100%;

      .carousel__dot {
        width: 32px;
        height: 6px;
        background: #909599;

        &.carousel__dot--selected {
          background: var(--default-blue);
        }
      }
    }

    @media (min-width: 768px) {
      .carousel__dot-group {
        display: none;
      }
    }
  }
`;

export { HomeScheduleContainer };
