import styled from 'styled-components';

export const SidebarContainer = styled.div`
  padding: 26px;
  @media (min-width: 1100px) {
    min-height: 190px;
  }
`;

export const Container = styled.div`
  @media (max-width: 1099px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 80vh;
  }
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  @media (min-width: 1101px) {
    min-width: 302px;
    max-width: 450px;
  }

  @media (max-width: 1100px) {
    position: absolute;
    left: 0;
    width: 100%;
    border-radius: 20px 20px 0 0;
    height: 75vh;
    box-shadow: 0px -2px 20px rgb(0 0 0 / 10%);
    z-index: 1;
  }
`;

export const Header = styled.div`
  padding-bottom: 50px;
  padding: 0 20px;
  @media (max-width: 1100px) {
    margin-bottom: 1rem;
  }
`;

export const HeaderTop = styled.div`
  display: flex;
  padding: 32px 0;

  h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #594aad;
    flex: 1;
  }

  div {
    img {
      cursor: pointer;
    }
  }
`;

export const HeaderBottom = styled.div`
  p {
    font-weight: bold;
    margin-bottom: 10px;
  }

  span {
  }
`;

export const Content = styled.div`
  overflow-y: auto;
`;
