import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  max-width: 445px;
  box-shadow: 0 0 32px rgba(136, 152, 170, 0.15);
  background: ${({ theme }) => theme.colors.light};
  border-radius: 20px;

  text-align: center;

  padding: 16px 8px;

  h3 {
    font-weight: 600;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.primary};

    margin-bottom: 8px;
  }

  p {
    margin-top: 8px;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.textSecondary};

    &.counter-message {
      margin-bottom: 24px !important;
    }
  }

  p ~ p {
    margin-bottom: 62px;
  }

  .resend-button {
    font-weight: 600;
    font-size: 1rem;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.05em;
    color: ${({ theme }) => theme.colors.secondary};
    text-decoration: underline;
    margin: 0 0 16px 0;
  }

  @media (min-width: 2300px) {
    max-width: 920px;
    padding: 32px 24px;

    text-align: center;

    h3 {
      font-size: 2rem;
    }

    p {
      font-size: 1.6rem;
      line-height: 36px;
    }

    .resend-button {
      font-size: 1.2rem;
      letter-spacing: 0.06em;
      margin: 0 0 32px 0;
    }
  }
`;

export const SecurityCodeContainer = styled.label`
  display: flex;
  margin-bottom: 32px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    text-align: center;
    color: #25282b;
    font-weight: 500;
    font-size: 24px;
    width: 25px;
  }
`;

export const ButtonBack = styled.button`
  display: flex;
  color: #ffffff;
  font-size: 16px;
  align-items: flex-start;
  margin-bottom: 40px;

  img {
    padding-right: 24px;
    margin-top: 2px;
  }
`;

export const Label = styled.label`
  border-radius: 50%;
  box-sizing: border-box;
  height: 50px;
  max-width: 50px;
  border: 1px solid #25282b;
  align-self: center;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 12px;
  margin: 5px;

  @media (max-width: 480px) {
    height: 40px;
    max-width: 40px;
  }
`;
