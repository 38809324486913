export const mappedErrors = [
  {
    error: 'Erro interno: Attempt limit exceeded, please try after some time..',
    message: 'Limite máximo de tentativas alcançado, tente mais tarde...',
  },
  {
    error: 'Validation failed',
    message: 'Email ou senha inválidos',
  },
  {
    error: 'Código de validação não corresponde ao enviado.',
    message: 'Código de validação não corresponde ao enviado. Tente novamente.',
  },
];
