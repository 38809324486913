import api from './api';

export async function getLadingPageContent() {
  return api.get(`/home-info?type=landingPageContent`, {
    headers: {
      authorization: `Bearer ${process.env.REACT_APP_UNLOGGED_TOKEN}`,
    },
  });
}

export async function getTermsContent() {
  return api.get(`/home-info?type=terms`, {
    headers: {
      authorization: `Bearer ${process.env.REACT_APP_UNLOGGED_TOKEN}`,
    },
  });
}

export async function getAboutHaluContent() {
  return api.get(`/home-info?type=aboutHaluContent`, {
    headers: {
      authorization: `Bearer ${process.env.REACT_APP_UNLOGGED_TOKEN}`,
    },
  });
}

export async function getAboutImaContent() {
  return api.get(`/home-info?type=aboutImaContent`, {
    headers: {
      authorization: `Bearer ${process.env.REACT_APP_UNLOGGED_TOKEN}`,
    },
  });
}
