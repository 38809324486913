import styled from 'styled-components';

export const AccordionStyle = styled.div`
  position: static;
  border: 1px solid #e1e3e6;
  border-radius: 25px;
  max-height: 100%;
  background-color: white;
  margin-bottom: 2%;
  cursor: pointer;

  @media (max-width: 480px) {
    width: 100%;
    margin: 0 0 10px 0;
    min-height: 72px;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }

  article.title {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
  }

  div:last-of-type {
    border-bottom: none;
  }
`;

export const ContainerAccordion = styled.div`
  display: flex;
  flex-direction: column;
  height: 72px;
  align-self: center;
  border-bottom: 1px solid #e1e3e6;
  padding-left: 50px;
  padding-right: 8px;
  @media (max-width: 1024px) {
    padding-right: 20px;
  }
  @media (max-width: 480px) {
    min-height: 72px;
    height: unset;
    padding: 0;
  }

  > div {
    display: flex;
    height: 100%;
    align-items: center;
    text-decoration: none;
    color: #25282b;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    padding-right: 26px;
    @media (max-width: 480px) {
      width: 100%;
      padding: 16px 24px;
    }

    img,
    p {
      padding-right: 18px;
      @media (max-width: 480px) {
        padding-right: 0;
      }
    }
    svg {
      margin-right: 18px;
      @media (max-width: 480px) {
        margin-right: 0;
      }
    }

    span {
      font-weight: 500;
      font-size: 12px;
      color: #657787;
      @media (max-width: 480px) {
        display: flex;
        width: 100%;
      }
    }

    p {
      @media (max-width: 480px) {
        padding: 8px 8px 8px 14px;
      }
    }
  }
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: ${props => (props.show ? '#594aad' : '#FFFFFF')};
  border-radius: ${props => (props.show ? '25px 25px 0 0' : '25px')};
  padding: 24px;

  button {
    color: ${props => (props.show ? '#FFFFFF' : '#594aad')};
    font-weight: bold;
    font-size: 18px;
  }
`;
export const InfoPoints = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  max-width: 120px;

  @media (max-width: 1024px) {
    max-width: 100px;
  }
  @media (max-width: 480px) {
    max-width: 50px;
    flex-direction: column;
  }

  span {
    @media (max-width: 480px) {
      padding: 0 0 8px 0;
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }
`;

export const FireImagePoints = styled.span`
  display: flex;
  align-items: center;

  div,
  p {
    color: #25282b;
    font-weight: 500;
    font-size: 16px;
    padding-left: 4px;
  }

  img {
    height: auto;
    width: 40px;
    @media (max-width: 480px) {
      height: 25px;
      width: unset;
    }
  }
`;
