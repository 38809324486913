import React, { useState, useEffect } from 'react';
import { BiChevronRight, BiChevronLeft } from 'react-icons/bi';
import ReactPaginate from 'react-paginate';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import Breadcrumbs from '../../components/Breadcrumbs';
import CardCourse from '../../components/CardCourse';
import CardCourseSkeleton from '../../components/CardCourseSkeleton';
import useCardCourse from '../../hooks/cardCourse';
import {
  ContentsService,
  ContentsServiceConstants,
} from '../../services/contentsService';
import getPageCount from '../../utils/getPageCount';
import { goToTop } from '../../utils/helpers';

import {
  Container,
  ImageFilter,
  Header,
  Overlay,
  Content,
  CoursesGrid,
  Pagination,
  ResultArea,
} from './styles';

goToTop();

const Search = () => {
  const location = useLocation();
  const [results, setResults] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [offset, setOffset] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchName, setSearchName] = useState([]);

  const { getCardCourseData } = useCardCourse();

  const { search } = location;

  useEffect(() => {
    (async () => {
      try {
        const name = search.split('=');
        if (!name[1]) return;
        const contentsService = new ContentsService();
        const response = await contentsService.searchContentsWithFilter(
          {
            flag: ['course', 'retreat'],
            type: ['curso', 'on-demand', 'retiro'],
            name: name[1],
            relations: [
              'reviews',
              'content_user',
              'authors',
              'tags',
              'categories',
            ],
          },
          offset,
        );
        setPageCount(
          getPageCount(response.count, ContentsServiceConstants.LIMIT),
        );
        setResults(response.data);
        setLoading(false);
        setSearchName(name[1]);
      } catch {
        toast.error(
          'Desculpe não foi possível recuperar os resultados da pesquisa, tente novamente.',
        );
      }
    })();
  }, [offset, search]);

  return (
    <Container>
      <Header>
        <Overlay>
          <ImageFilter />
          <Breadcrumbs
            className="hidden-mobile"
            style={{ paddingLeft: '30px', paddingBottom: '10px' }}
            links={[
              {
                name: 'Pesquisa',
                url: '/pesquisa',
              },
            ]}
          />
          <Breadcrumbs
            className="hidden-desktop"
            style={{
              paddingLeft: '10px',
              paddingTop: '30px',
              paddingBottom: '30px',
              fontWeight: '600',
              fontSize: '16px',
            }}
            links={[
              {
                name: 'Pesquisa',
                url: null,
              },
            ]}
          />

          <h1>
            Pesquisa
            <span className="hidden-mobile">Pesquisa</span>
          </h1>
        </Overlay>
      </Header>

      <ResultArea>
        <p>
          {results.length} Resultado(s) para{' '}
          <span>&quot;{searchName}&quot;</span>
        </p>
      </ResultArea>

      <Content>
        {loading ? (
          <CoursesGrid>
            {[...Array(12).keys()].map(item => (
              <CardCourseSkeleton key={item} />
            ))}
          </CoursesGrid>
        ) : (
          <CoursesGrid>
            {results.map(item => (
              <CardCourse
                key={item.content_id}
                data={getCardCourseData(item)}
              />
            ))}

            {!results.length && <h3>Não foi encontrado nenhum conteúdo!</h3>}

            <Pagination>
              <ReactPaginate
                initialPage={0}
                previousLabel={<BiChevronLeft />}
                nextLabel={<BiChevronRight />}
                nextClassName="nextButton"
                previousClassName="previousButton"
                previousLinkClassName="previousContainerButton"
                nextLinkClassName="nextContainerButton"
                containerClassName="pagination"
                disabledClassName="disabledButtons"
                activeLinkClassName="activeLink"
                pageClassName="page"
                breakClassName="page"
                breakLabel="..."
                pageCount={pageCount}
                marginPagesDisplayed={1}
                onPageChange={page =>
                  setOffset(
                    (page.selected + 1) * ContentsServiceConstants.LIMIT -
                      ContentsServiceConstants.LIMIT +
                      1,
                  )
                }
              />
            </Pagination>
          </CoursesGrid>
        )}
      </Content>
    </Container>
  );
};

export default Search;
