import React from 'react';
import { Link } from 'react-router-dom';

import newLogoFooterLogged from '../../assets/images/new-logo-footer-logged.png';

import { Container } from './styles';

const FooterLogged = () => {
  return (
    <Container>
      <section>
        <img
          src={newLogoFooterLogged}
          alt="Instituto dos Mistérios Ascensionais"
        />
        <span className="hidden-desktop">
          <hr />
        </span>
        <div>
          <Link to="/leia-os-termos-de-uso">Termos de Uso</Link>
          <Link to="/politica-de-privacidade">Política de Privacidade</Link>
        </div>
      </section>

      <hr />

      <p>
        &copy; Copyright {new Date().getUTCFullYear()} - Todos direitos
        reservados.
      </p>
    </Container>
  );
};

export default FooterLogged;
