import styled from 'styled-components';

export const ContainerAccordion = styled.div`
  display: flex;
  min-height: 100%;
  min-width: 95%;
  width: 95%;
  height: 118px;
  background-color: #ffffff;
  margin-bottom: 48px;
  cursor: pointer;
`;

export const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  .row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  @media screen and (max-width: 997px) {
    .row {
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const StatusLive = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 480px) {
    text-align: right;
  }
  h6 {
    color: #594aad;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    @media screen and (min-width: 998px) {
      padding: 48px;
    }

    @media (max-width: 480px) {
      font-size: 10px;
    }
  }

  .CREATING,
  .ERROR,
  .STARTING,
  .STOPPING,
  .STOPPED,
  .SCHEDULED,
  .FINISHED {
    color: #657787;
  }

  .ACTIVE {
    color: #594aad;
  }
  @media screen and (max-width: 997px) {
    img {
      margin-right: 30px;
    }
  }
`;

export const Square = styled.div`
  width: 16px;
  height: 16px;
  background-color: #594aad;
  position: relative;
  left: 60px;
  bottom: 20px;
  transform: rotate(45deg);
`;

export const BoxLeft = styled.div`
  background-color: #594aad;
  width: 120px;
  height: 118px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 50px;
  padding: 16px;

  @media (max-width: 480px) {
    margin-right: 10px;
  }

  h2 {
    font-weight: bold;
    font-size: 22px;
    color: #ffffff;
  }

  h4 {
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
  }

  @media (max-width: 1100px) {
    h2,
    h4 {
      text-align: center;
    }
  }
`;

export const BoxRight = styled.div`
  max-height: 100%;
  flex: 1 !important;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;

  > p {
    padding-left: 35px;
  }
`;

export const BoxTitle = styled.div`
  display: flex;
  align-items: center;

  h1 {
    font-size: 16px;
    @media (max-width: 480px) {
      font-size: 14px;
      padding: 0 8px;
    }
  }
  img {
    padding-right: 10px;
    @media (max-width: 480px) {
      padding: 0;
    }
  }
`;
