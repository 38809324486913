import styled, { keyframes, css } from 'styled-components';

import { theme } from '../../styles/theme';

const upSizeBackground = keyframes`
  0% {
    background-size: cover;
    background-position-y: center;
  }
  25% {
    background-position-y: 5%;
    background-size: 105%;
  }
  50% {
    background-position-y: 10%;
    background-size: 110%;
  }
  75% {
    background-position-y: 15%;
    background-size: 115%;
  }
  100% {
    background-position-y: 20%;
    background-size: 120%;
  }
`;

const downSizeBackground = keyframes`
  0% {
    background-position-y: 20%;
    background-size: 120%;
  }
  25% {
    background-position-y: 15%;
    background-size: 115%;
  }
  50% {
    background-position-y: 10%;
    background-size: 110%;
  }
  75% {
    background-position-y: 5%;
    background-size: 105%;
  }
  100% {
    background-position-y: center;
    background-size: cover;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 360px;
  height: max-content;
  border-radius: 20px;
  margin: 24px 16px 60px 16px;
  @media (max-width: 480px) {
    margin: 0;
    flex-direction: column;
    height: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  &:hover {
    background-color: ${theme.colors.light};
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  transition: all 0.5s ease-in;

  & > button {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1;
  }
`;

export const Box = styled.div`
  position: relative;
  @media (max-width: 480px) {
    padding: 0 0 16px 0;
  }

  button {
    z-index: 10;
    position: absolute;
    top: 10px;
    right: 30px;
  }
`;

export const BoxRate = styled.div`
  display: flex;
`;

export const ImageCourseBackground = styled.div`
  display: block;
  background: url(${({ courseImg }) => courseImg});
  background-position: center;
  height: 100%;
  min-height: 224px;
  min-width: 340px;
  border-radius: 20px;
  color: ${theme.colors.textLight};
  cursor: pointer;
  z-index: 1;
  @media (max-width: 480px) {
    width: 100%;
    min-width: 100%;
    padding: 0;
  }

  &:hover,
  &:focus {
    animation: ${upSizeBackground} 0.5s linear forwards;
  }

  ${({ mouseLeave }) =>
    mouseLeave &&
    css`
      animation: ${downSizeBackground} 0.5s linear forwards;
    `}
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(37, 40, 43, 0.3);
  border-radius: 20px;
  height: 100%;
  min-height: 224px;
  padding: 16px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-family: 'OpenSans';
`;

export const Rating = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;

  img {
    height: 18px;
    width: 18px;
  }

  span {
    font-weight: bold;
    color: ${theme.colors.warning};
    margin-left: 4px;

    & > span {
      font-weight: 400;
      color: ${theme.colors.textLight};
    }
  }
`;

export const Like = styled.div`
  position: relative;
  padding: 5px 20px 0 0;
  z-index: 1;

  img.hidden {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 16px !important;
  }

  img {
    opacity: 1;
    transition: opacity 0.5s linear;
    position: absolute;
  }
`;

export const Tag = styled.div`
  display: flex;
  justify-content: center;
  max-width: 108px;
  padding: 8px 16px;
  background-color: ${theme.colors.primary};
  border-radius: 20px;
  text-align: center;

  span {
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 32px 12px 32px;
  width: 100%;
  @media (max-width: 480px) {
    flex-direction: column;
    padding: 0 26px;
  }

  > span {
    margin-bottom: 10px;
    color: #2f189c;
    @media (max-width: 480px) {
      text-align: left;
      font-weight: 600;
      font-size: 14px;
    }

    cite {
      font-style: normal;
      font-size: 0.9rem;
      font-weight: 600;
      color: ${theme.colors.primary};
    }
  }
`;

export const CourseProgress = styled.div`
  padding-bottom: 16px;

  p {
    font-size: 0.8rem;
    font-weight: 400;
  }
`;

export const DateInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: #25282b;
  font-weight: 500;
  font-size: 14px;
  @media (max-width: 480px) {
    width: 100%;
  }

  div {
    padding: 0;
  }

  span {
    margin-left: 8px;
    font-weight: 500;
    font-size: 14px;
    @media (max-width: 480px) {
      min-width: 100%;
    }
  }

  img {
    height: 20px;
    @media (max-width: 480px) {
      height: 15px;
    }
  }
`;

export const CalendarInfo = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 0 20px 0;
  padding: 0;
  display: flex;
`;

export const ClockInfo = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
`;

export const RateDivider = styled.div`
  background-color: ${theme.colors.shapeSecondary};
  margin: 12px 0;
  height: 1px;
  width: 95%;
`;

export const ThanksForRating = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  p {
    font-weight: 500;
    font-size: 0.9rem;
    margin: 0;
  }

  div {
    display: flex;
    align-items: center;

    span {
      margin: 0 0 0 8px;
    }

    button {
      margin-left: 8px;
      text-decoration: underline;
      font-weight: 500;
      font-size: 0.8rem;
      color: ${theme.colors.secondary};
    }
  }
`;

export const EmptyProgressBar = styled.div`
  height: 39px;
`;

export const BoxLeftFinalized = styled.div`
  flex-direction: column;
  width: 100%;

  p {
    font-weight: bold;
    font-size: 26px;
    line-height: 36px;
    color: #25282b;
    width: 300px;
    @media (max-width: 480px) {
      width: 100%;
      font-size: 20px;
    }
  }

  > span {
    color: #657787;
    display: flex;
    font-weight: 600;
    font-size: 18px;
    margin: 10px 0 25px 0;
    @media (max-width: 480px) {
      text-align: left;
      font-weight: 600;
      font-size: 14px;
    }

    h4 {
      color: #2f189c;
      padding-left: 5px;
    }
  }
`;

export const BoxRightFinalized = styled.div`
  flex-direction: column;
  width: 100%;
  padding-top: 16px;
`;

export const RateYouCourse = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 30px;
`;

export const Certificates = styled.div`
  flex-direction: column;
  padding-right: 5px;
  @media (max-width: 480px) {
    min-width: 100%;
  }
`;
