import { isFuture } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { BsLock } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import calendar from '../../assets/icons/calendar.svg';
import fire from '../../assets/icons/fire.svg';
import starImg from '../../assets/icons/star.svg';
import ImageDefault from '../../assets/images/img-default.png';
import watch from '../../assets/watch.svg';
import { useAuth } from '../../hooks/auth';
import useCardCourse from '../../hooks/cardCourse';
import { usePaymentModal } from '../../hooks/paymentModal';
import { checkPaymentStatus } from '../../services/paymentService';
import { UserService } from '../../services/userService';
import { convertDuration } from '../../utils/helpers';
import { Button } from '../Button';
import Spinner from '../Spinner';

import {
  Container,
  DateContainer,
  ImageCourseContainer,
  InfoCourseContainer,
  AuthorAndRateContainer,
  RateContainer,
  InfoContainer,
  Badge,
  CourseRating,
  CourseTotalRatings,
  BadgesBox,
  BadgeDiscount,
  SpinnerWrapper,
  Info,
  BadgePaymentStatus,
} from './styles';

const CardCourseInfo = ({ data, reloadData, coursePrerequisites }) => {
  const [contentPaymentStatus, setContentPaymentStatus] = useState(null);
  const [courseInfo, setCourseInfo] = useState({});
  const [paymentInfo, setPaymentInfo] = useState({});
  const [loading, setLoading] = useState(true);

  const { uuid } = useParams();
  const history = useHistory();
  const { getCardCourseData } = useCardCourse();
  const { openModal } = usePaymentModal();
  const firstModule = getModuleInProgress();
  const { user } = useAuth();

  const discount = (() => {
    const userPlanPaid = user?.plans_paid?.find(plan =>
      isFuture(new Date(plan?.finish_at)),
    );

    return Number(userPlanPaid?.plan?.discount);
  })();

  function handleGoToCourse() {
    if (courseInfo.buttonText === 'Aguarde o lançamento') {
      toast.info('Aguarde o lançamento do curso!');
      return;
    }

    if (courseInfo.redirectToSubscribePage) {
      history.push('/perfil/selecionar-plano');
      toast.warning('Para acessar esse conteúdo você precisa ser assinante');
      return;
    }

    if (courseInfo?.needBuy) {
      openModal({
        name: courseInfo?.name,
        type: courseInfo.type,
        author: courseInfo?.author,
        rating: courseInfo?.rating,
        discount: courseInfo.discount,
        price: courseInfo?.price,
        id: courseInfo?.id,
        info: data.info,
      });
      return;
    }

    const textAllowAccess = ['Ver mais', 'Conteúdo Disponível'];

    if (
      data?.content_user ||
      courseInfo?.isFree ||
      textAllowAccess.includes(courseInfo?.buttonText)
    ) {
      history.push(`/curso/${firstModule}/${uuid}`, {
        redirectToLastWatchedLesson: true,
        paymentInfo,
      });
    }
  }

  function getModuleInProgress() {
    const courseAux = data?.children?.sort((a, b) =>
      a.position > b.position ? 1 : -1,
    );

    if (courseAux) {
      const lessons = courseAux
        .map(module => module?.children?.map(lesson => lesson))
        .flat();

      const currentLesson = lessons?.find(
        lesson =>
          lesson?.content_user &&
          lesson?.content_user?.start_at &&
          !lesson?.content_user.finish_at,
      );
      return (
        currentLesson?.content_id || data.children?.[0].children?.[0].content_id
      );
    }

    return data.children?.[0].children?.[0].content_id;
  }

  const totalCoursePoints = useMemo(() => {
    let total = data?.info?.gamification_points || 0;

    data?.children?.forEach(element => {
      total += element?.info?.gamification_points || 0;

      element?.children?.forEach(el => {
        total += el?.info?.gamification_points || 0;
      });
    });

    return total;
  }, [data]);

  useEffect(() => {
    (async () => {
      setContentPaymentStatus(null);
      setLoading(true);
      const userService = new UserService();

      const userCoursesIds = await userService
        .getContentsPurchaseIds()
        .then(res => res.data);

      const dataFormatted = getCardCourseData(data, userCoursesIds);

      if (dataFormatted.buttonText === 'Lançamento em breve') {
        dataFormatted.buttonText = dataFormatted.needBuy
          ? 'Garanta já o seu acesso!'
          : 'Aguarde o lançamento';
      }

      setCourseInfo(dataFormatted);

      const updatePaymentStatus = {
        credit_card: status =>
          status === 'processing' &&
          setContentPaymentStatus({
            message: 'A sua compra esta sendo processada',
            disableButton: true,
          }),
        offsite: status =>
          status === 'processing' &&
          setContentPaymentStatus({
            message: 'Foi gerado um boleto pelo Paypal',
            disableButton: false,
          }),
        pix: status =>
          status === 'processing' &&
          setContentPaymentStatus({
            message: 'Foi gerado um Pix, confira seu email',
            disableButton: false,
          }),
        bank_slip: () => {},
        allow_by_admin: () => {},
      };

      checkPaymentStatus(uuid)
        .then(response => {
          setPaymentInfo(response.data);
          if (response.data.type) {
            updatePaymentStatus[response.data.type](response.data.status);
          }
        })
        .catch(() => {
          toast.error(
            'Houve um erro ao recuperar os dados de pagamento deste conteúdo ',
          );
        })
        .finally(() => {
          setLoading(false);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, reloadData]);

  const releaseDate = convertDuration(courseInfo?.duration);

  function formatCourseDate(date) {
    const dataObj = new Date(date);
    const dia = String(dataObj.getDate()).padStart(2, '0');
    const mes = String(dataObj.getMonth() + 1).padStart(2, '0');
    const ano = dataObj.getFullYear();
    return `${dia}/${mes}/${ano}`;
  }

  return (
    <Container>
      <ImageCourseContainer>
        <img
          src={courseInfo?.courseImg || ImageDefault}
          alt="Imagem de capa do curso"
        />
      </ImageCourseContainer>

      <InfoCourseContainer>
        {loading && (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        )}

        {!loading && (
          <>
            <BadgesBox>
              {courseInfo?.tag ? <Badge>{courseInfo?.tag}</Badge> : ''}
              {discount && !courseInfo?.isFree ? (
                <BadgeDiscount>
                  {discount.toFixed(0)}% de desconto
                </BadgeDiscount>
              ) : null}
              {contentPaymentStatus && (
                <BadgePaymentStatus>
                  {contentPaymentStatus.message}
                </BadgePaymentStatus>
              )}
            </BadgesBox>

            <h2>{courseInfo?.name}</h2>

            <AuthorAndRateContainer>
              <p>
                por <h3>{courseInfo?.author}</h3>
              </p>
              <RateContainer>
                <img src={starImg} alt="Avaliação" />
                <CourseRating>{courseInfo?.rating?.value}</CourseRating>

                <CourseTotalRatings>
                  ({courseInfo?.rating?.totalRatings})
                </CourseTotalRatings>
              </RateContainer>
            </AuthorAndRateContainer>

            <InfoContainer>
              <Info>
                <img src={calendar} alt="Calendário" />
                <span>{courseInfo?.date}</span>
              </Info>
              {releaseDate && (
                <Info>
                  <img src={watch} alt="Horário" /> <span>{releaseDate}</span>
                </Info>
              )}
            </InfoContainer>

            {paymentInfo.start_at && (
              <DateContainer>
                <span>Data da compra: </span>{' '}
                {formatCourseDate(paymentInfo.start_at)}
              </DateContainer>
            )}

            {paymentInfo.finish_at && (
              <DateContainer>
                <span>Seu acesso ao curso vai até: </span>
                {formatCourseDate(paymentInfo.finish_at)}
              </DateContainer>
            )}
            {totalCoursePoints ? (
              <InfoContainer>
                <Info>
                  <img className="fire" src={fire} alt="Pontos" />{' '}
                  <span>
                    {totalCoursePoints || ''} <p>pontos</p>
                  </span>
                </Info>
              </InfoContainer>
            ) : null}

            {coursePrerequisites?.availableToConsume ? (
              <Button
                id="buttonAccessContent"
                title={courseInfo?.buttonText}
                type="button"
                icon={courseInfo?.buttonIcon}
                onClick={handleGoToCourse}
                disabled={!!contentPaymentStatus?.disableButton}
                containerStyle={{
                  marginTop: 32,
                  alignSelf: 'center',
                }}
              />
            ) : (
              <Button
                id="buttonAccessContent"
                title="Conteúdo bloqueado"
                type="button"
                icon={BsLock}
                disabled
                containerStyle={{
                  marginTop: 32,
                  alignSelf: 'center',
                }}
              />
            )}
          </>
        )}
      </InfoCourseContainer>
    </Container>
  );
};

export default CardCourseInfo;
