import styled from 'styled-components';

import BackgroundImg from '../../assets/images/login-bg.png';

export const Container = styled.div`
  min-width: 100%;
  min-height: 100vh;
  background: url(${BackgroundImg});
  background-size: cover;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  margin: 0 auto;
  @media (max-width: 480px) {
    width: 100%;
    margin: 0;
    padding: 40px 10px 20px 10px;
  }
`;

export const ButtonBack = styled.button`
  display: flex;
  color: #ffffff;
  font-size: 16px;
  align-items: flex-start;
  margin-bottom: 40px;

  img {
    padding-right: 24px;
    margin-top: 2px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  box-shadow: 0 0 32px rgba(136, 152, 170, 0.15);
  background: #ffffff;
  border-radius: 20px;

  max-width: 445px;
  height: 100%;
  padding: 16px;

  text-align: center;

  h3 {
    font-weight: 600;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.primary};
  }

  p {
    padding-top: 16px;
    padding-bottom: 24px;

    font-size: 0.9rem;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.textSecondary};
  }

  img {
    padding: 10px;
  }

  @media (min-width: 600px) {
    padding: 24px 42px;

    h3 {
      font-size: 1.4rem;
    }

    p {
      font-size: 1rem;
    }
  }

  @media (min-width: 2000px) {
    padding: 32px;

    h3 {
      font-size: 1.6rem;
    }

    p {
      font-size: 1.2rem;
    }
  }
`;

export const CodeSecurityAndPasswordContainer = styled.div`
  ${Box}:last-child {
    margin-top: 42px;
  }
`;

export const Password = styled.label`
  display: flex;
`;

export const ContainerInput = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    text-align: center;
    color: #25282b;
    font-weight: 500;
    font-size: 24px;
    width: 25px;
  }
`;

export const Label = styled.label`
  border-radius: 50%;
  box-sizing: border-box;
  height: 50px;
  max-width: 50px;
  border: 1px solid #25282b;
  align-self: center;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 12px;
  margin: 5px;
  @media (max-width: 480px) {
    height: 40px;
    max-width: 40px;
  }
`;
