/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable func-names */

import axios from 'axios';
import { toast } from 'react-toastify';

const token = localStorage.getItem('@HaluGamashi:token');
const currentToken = token ?? process.env.REACT_APP_UNLOGGED_TOKEN;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY,
  headers: {
    authorization: `Bearer ${currentToken}`,
  },
});

api.interceptors.response.use(
  function (successfulReq) {
    return successfulReq;
  },
  function (error) {
    if (
      [401].includes(error?.response?.status) &&
      location.pathname !== '/logar' &&
      location.pathname !== '/registro' &&
      location.pathname !== '/'
    ) {
      toast.warning('Sua sessão expirou, faça login novamente.');
      localStorage.clear();
      window.location.href = '/logar';
      api.defaults.headers.authorization = `Bearer ${process.env.REACT_APP_UNLOGGED_TOKEN}`;
    }
    return Promise.reject(error);
  },
);

export default api;
