import styled, { keyframes } from 'styled-components';

import { theme } from '../../styles/theme';

const fadeInDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-1.25em);
  }
  
  100% {
    opacity: 1;
    transform: translateY(0);
}`;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .spinner {
    margin-top: 32px;
  }

  h1,
  h2 {
    color: ${theme.colors.textLight};
    text-align: center;
  }

  h1 {
    margin-bottom: 40px;
    margin-top: 8px;
    padding: 0 24px;
  }

  h2 {
    margin-bottom: 8px;
    margin-top: 40px;
    font-weight: normal;
  }

  @media (max-width: 900px) {
    height: auto;
  }

  @media (max-width: 480px) {
    h1 {
      font-weight: 600;
      font-size: 32px;
    }

    h2 {
      font-size: 16px;
    }
  }
`;

export const PeriodSelector = styled.ul`
  list-style: none;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${theme.colors.light};

  li:first-of-type {
    border-left: none;
    border-bottom-left-radius: 14px;
    border-top-left-radius: 14px;
  }

  li:last-of-type {
    border-bottom-right-radius: 14px;
    border-top-right-radius: 14px;
  }

  li {
    position: relative;
    background: ${theme.colors.light};
    padding: 7px 0;
    min-width: 220px;
    text-align: center;
    border-left: 1px solid ${theme.colors.shapeSecondary};
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      background: transparent;
      transform: rotate(45deg);
      border: 6px solid ${theme.colors.light};
      width: 0;
      height: 0;
      bottom: -7px;
      left: 48%;
      border-right: 5px solid transparent;
      border-bottom: 5px solid transparent;
    }
  }

  li:first-of-type.selected {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
  }

  li:last-of-type.selected {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
  }

  li.selected {
    background: ${theme.colors.secondary};
    color: ${theme.colors.textLight};
  }

  @media (max-width: 1100px) {
    display: none;
  }
`;

export const PeriodSelectorMobile = styled.div`
  width: 90%;
  background: transparent;
  border-radius: 20px;
  border: 1px solid ${theme.colors.light};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 24px;

  ul {
    display: none;
  }

  @media (min-width: 1100px) {
    display: none;
  }
`;

export const PeriodSelectedMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  cursor: pointer;

  color: ${theme.colors.textLight};
  font-weight: 700;

  span {
    margin-left: auto;
  }

  svg {
    position: absolute;
    font-size: 2rem;
    right: 0;
  }
`;

export const PeriodOptionsContainer = styled.div`
  animation: ${fadeInDown} 0.5s;
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(37, 40, 43, 0.6);
`;

export const PeriodOptions = styled.ul`
  background: ${theme.colors.light};
  list-style: none;
  padding: 32px 30px;
  position: absolute;
  bottom: -15px;
  height: 45%;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.1);

  svg {
    float: right;
    font-size: 1.2rem;
    font-weight: bold;
    color: ${theme.colors.secondary};
    cursor: pointer;
  }

  li:first-of-type {
    margin-top: 20px;
  }

  li {
    cursor: pointer;
    padding: 16px;
    font-weight: 700;
    color: ${theme.colors.secondary};
  }

  li:hover {
    border-radius: 20px;
    background: ${theme.colors.secondary};
    color: ${theme.colors.textLight};
  }
`;

export const Cards = styled.article`
  margin-top: 32px;
  padding: 0 16px;
  gap: 16px;
  flex: 1;

  @media (min-width: 1100px) {
    display: flex;
  }
`;

export const NoPlansFound = styled.h3`
  font-size: 2.5rem;
  color: ${theme.colors.textLight};
  padding: 20px 0;
  text-align: center;
`;
