import styled from 'styled-components';

import Header from '../../components/Header';

export const HeaderAbout = styled(Header)`
  @media (max-width: 1024px) {
    min-width: 100%;
    @media (max-width: 480px) {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
    }
  }
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-top: 45px;
  @media (max-width: 1024px) {
    height: 100%;
    min-width: 100%;
  }
  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  div {
    display: flex;
    align-items: center;
    @media (max-width: 480px) {
      margin-top: 40px;
    }
  }

  h1 {
    max-width: 1064px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: start;

    font-size: 100px;
    line-height: 120px;
    color: #ffffff;
    padding-bottom: 18px;
    padding-top: 48px;
    text-shadow: 0 2px 4px rgba(24, 39, 75, 1), 0 4px 4px rgba(24, 39, 75, 0.08);
    @media (max-width: 480px) {
      margin: 32px 0 0 0;
      padding: 0;
      font-size: 40px;
      font-weight: bold;
      letter-spacing: 0.05em;
    }
  }

  span {
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    padding-left: 24px;
  }

  @media (max-width: 1024px) {
    h1 {
      font-weight: 700;
      font-size: 31px;
      line-height: 44px;
      padding-bottom: 14px;
    }

    p {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
    }
  }
`;
export const AboutTextAboutIma = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 100px;
  padding: 0;
  @media (max-width: 480px) {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 16px 16px 16px;
    background: #f3f9fb;
    margin-bottom: 10px;
  }
  article {
    h1 {
      padding: 80px 24px 40px;

      @media (max-width: 480px) {
        padding: 0;
        max-width: 100%;
      }
    }
  }

  h1 {
    font-family: Lato, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: #25282b;
    padding-bottom: 40px;
    @media (max-width: 480px) {
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      padding: 0 0 24px 0;
      margin: 0;
      text-align: center;
    }
  }

  article {
    div {
      div {
        iframe {
          border-radius: 20px;
        }
      }
    }

    div {
      ul {
        margin-top: 10px;
        li {
          color: ${props => props.theme.colors.text};
          font-family: Lato, Arial, sans-serif;
          font-size: 18px;
          font-weight: 300;
          line-height: 28px;
          margin-left: 20px;
        }
      }
      p {
        color: ${props => props.theme.colors.text};
        font-family: Lato, Arial, sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        margin-top: 10px;
      }
    }
  }
`;

export const AboutTitleMobile = styled.div`
  padding-bottom: 40px;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;

  @media screen and (max-width: 600px) {
    width: fit-content;
    margin: 0 auto;
    text-align: center;
  }
`;

export const Video = styled.div`
  max-width: 1064px;
  width: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;

  margin: 0 auto;
  @media (max-width: 560px) {
    padding-bottom: 30px;
    width: 100%;
  }
  iframe {
    border-radius: 20px;
  }
`;

export const TextInfo = styled.div`
  max-width: 1064px;
  width: 100%;

  padding: 0 24px;

  ul {
    padding-left: 20px;
  }
`;
