import React from 'react';
import { Link } from 'react-router-dom';

import backArrow from '../../assets/icons/icon-back-breadcrumb-mobile.png';
import myCoursesMobile from '../../assets/images/my-course-mobile.png';
import myCourses from '../../assets/images/my-courses.jpg';
import Breadcrumbs from '../../components/Breadcrumbs';
import CardMasters from '../../components/CardMyCoursesPage';
import Spacer from '../../components/Spacer';
import { goToTop } from '../../utils/helpers';

import {
  Container,
  Background,
  Header,
  CardsTop,
  CardsBottom,
  Overlay,
  ImageFilter,
  BreadcrumbMobile,
} from './styles';

const MyCourses = () => {
  goToTop();

  return (
    <Container>
      <Header>
        <Overlay>
          <ImageFilter />
          <Breadcrumbs
            className="hidden-mobile"
            style={{ paddingLeft: '30px', paddingBottom: '10px' }}
            links={[
              {
                name: 'Meus Cursos',
                url: '/meus-cursos',
              },
            ]}
          />

          <BreadcrumbMobile className="hidden-desktop">
            <Link to="/cursos">
              <img src={backArrow} alt="" />
              <p>Meus Cursos</p>
            </Link>
          </BreadcrumbMobile>

          <h1>
            Meus Cursos
            <span>Meus Cursos</span>
          </h1>
        </Overlay>
      </Header>

      <>
        <CardsTop>
          <CardMasters
            description="Veja aqui todos os cursos, lives e palestras que você favoritar."
            name="Favoritos"
            url="/favoritos"
          />

          <CardMasters
            description="Aqui você encontra todos os cursos que deu início para seu aprendizado."
            name="Em andamento"
            url="/em-andamento"
          />
        </CardsTop>
        <CardsBottom>
          <CardMasters
            description=" Além de visualizar todos os conteúdos que você finalizou, você também pode visualizar e baixar seus certificados."
            name="Finalizados"
            url="/finalizados"
          />
          <Spacer />
        </CardsBottom>
      </>
    </Container>
  );
};
export default MyCourses;
