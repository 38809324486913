import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import ResponsivePlayer from '../../../../components/ResponsivePlayer';
import { VideoWrapper } from '../../../../components/VideoWrapper';
import { controlsToEnable } from '../../../../configs/SvPlayer';
import useWindowSize from '../../../../hooks/useWindowSize';

import {
  HomeAboutContainer,
  HomeAboutLeftContent,
  HomeAboutRightContent,
} from './style';

const HomeAbout = ({ mediaUrl }) => {
  const history = useHistory();
  const { windowSize } = useWindowSize();

  const handleGoToLogin = () => {
    history.push('/logar');
  };

  return (
    <HomeAboutContainer className="max-content">
      {windowSize > 1024 && (
        <HomeAboutLeftContent>
          <VideoWrapper>
            <ResponsivePlayer
              contentUrl={mediaUrl}
              controlsToEnable={controlsToEnable}
              id="playerDesktopLadingPageIMA"
              styles={{ width: '100%' }}
            />
          </VideoWrapper>
        </HomeAboutLeftContent>
      )}

      <HomeAboutRightContent>
        <div className="title">
          <h2>Sobre o IMA</h2>
          <Link to="/sobre-ima">SAIBA MAIS</Link>
        </div>

        {windowSize <= 1024 && (
          <VideoWrapper>
            <ResponsivePlayer
              contentUrl={mediaUrl}
              controlsToEnable={controlsToEnable}
              id="playerDesktopLadingPageIMA"
              styles={{ width: '100%' }}
            />
          </VideoWrapper>
        )}

        <p>
          O Instituto dos Mistérios Ascencionais, através de seus cursos,
          vivências e retiros, tem o objetivo de compartilhar, com todos os
          interessados na Revelação dos Mistérios, as Ferramentas, Conhecimentos
          e Saberes que proporcionam a construção dos Órgãos Sutis: Os Chakras.
        </p>

        <p>
          Começando pelos Mistérios Menores, preparatórios para o despertar. E
          os Mistérios Maiores, preparatórios para a Ascensão.
        </p>

        <button type="button" onClick={handleGoToLogin}>
          COMEÇAR MINHA JORNADA
        </button>
      </HomeAboutRightContent>
    </HomeAboutContainer>
  );
};

export default HomeAbout;
