import ReactCodeInput from 'react-verification-code-input';
import styled from 'styled-components';

export const InputWrapper = styled(ReactCodeInput)`
  .styles_react-code-input__CRulA {
    display: flex;
    gap: 5px !important;
    align-items: center;
    justify-content: center;

    input {
      display: flex;
      align-self: center;
      align-items: center;

      border-radius: 50%;
      box-sizing: border-box;

      width: 42px !important;
      height: 42px !important;
      border: 1px solid #25282b;

      text-align: center;
      padding: 12px;

      @media (min-width: 420px) {
        width: 50px !important;
        height: 50px !important;
      }

      @media (min-width: 600px) {
        width: 60px !important;
        height: 60px !important;
      }
    }
  }
`;
