import React, { useEffect, useState } from 'react';
import { BiChevronRight, BiChevronLeft } from 'react-icons/bi';
import { FiSearch } from 'react-icons/fi';
import ReactPaginate from 'react-paginate';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import backArrow from '../../../assets/icons/icon-back-breadcrumb-mobile.png';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { Button } from '../../../components/Button';
import Spinner from '../../../components/Spinner';
import { useAuth } from '../../../hooks/auth';
import * as ContentService from '../../../services/Content';
import {
  getAllTopicsBySection,
  getAllTopicsByTitle,
  getSectionById,
  TOPICS_FORUM_LIMIT,
} from '../../../services/forum';
import { UserService } from '../../../services/userService';
import debounce from '../../../utils/debounce';
import getPageCount from '../../../utils/getPageCount';

import TopicCard from './components/TopicCard';
import {
  Container,
  Header,
  BreadcrumbMobile,
  Overlay,
  Content,
  List,
  ListHeader,
  ListHeaderOptions,
  SearchTopicsInput,
  ListItems,
  Pagination,
} from './styles';

const Section = () => {
  const { sectionId } = useParams();

  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(true);

  const [pageCount, setPageCount] = useState(1);
  const [offset, setOffset] = useState(0);

  const history = useHistory();
  const { user } = useAuth();

  const handleGoToCreateTopic = () => {
    history.push('/criar-topico', {
      sectionId,
    });
  };

  const handleGoToTopic = topicId => {
    history.push(`topico/${topicId}`);
  };

  const fetchTopicsByTitle = async title => {
    try {
      const response = await getAllTopicsByTitle(title, offset);

      setTopics(response.topics);
      setPageCount(getPageCount(response.total, TOPICS_FORUM_LIMIT));
    } catch {
      toast.error('Ocorreu um erro ao buscar os tópicos com este título.');
    } finally {
      setLoading(false);
    }
  };

  const handleSearchByTitle = debounce(event => {
    fetchTopicsByTitle(event.target.value);
  }, 500);

  const onDeleteTopic = topicId => {
    setTopics(state => state.filter(topic => topic.topic_id !== topicId));
  };

  const isModerator = user?.roles.some(role => role === 'Moderator');

  useEffect(() => {
    async function checkUserAccess() {
      if (user?.is_admin || isModerator) return true;
      try {
        const userService = new UserService();

        const [
          section,
          responsePlansPurchaseIds,
          responseContentssPurchaseIds,
        ] = await Promise.all([
          getSectionById(sectionId),
          userService.getPlansPurchaseIds(),
          userService.getContentsPurchaseIds(),
        ]);

        const { data: content } = await ContentService.getByIdDetails(
          section.content_id,
        );

        const purchases = responsePlansPurchaseIds.data.concat(
          responseContentssPurchaseIds.data,
        );

        const courseIsFree = Boolean(!content?.data?.info?.price);

        if (courseIsFree && section.associated_plans.length === 0) {
          return true;
        }

        if (courseIsFree && section.associated_plans.length !== 0) {
          const intersectionPlans = purchases?.filter(plan =>
            section.associated_plans.includes(plan),
          );

          return Boolean(intersectionPlans?.length);
        }

        const userContentsPaid = purchases.map(contentPaid => contentPaid);

        const userHasPurchaseContent = userContentsPaid.includes(
          section.content_id,
        );

        return userHasPurchaseContent;
      } catch (error) {
        throw new Error(error);
      }
    }

    toast.dismiss();

    checkUserAccess()
      .then(userPermittedToAccess => {
        if (!userPermittedToAccess) {
          toast.warning('Usuário não tem permissão para acessar essa seção.', {
            toastId: 'user-not-permitted-section',
          });
          history.goBack();
        }
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao acessar essa seção.');
        history.goBack();
      })
      .finally(() => {
        setLoading(false);
      });
  }, [sectionId, history, user?.is_admin, isModerator]);

  useEffect(() => {
    async function fetchTopics() {
      try {
        const response = await getAllTopicsBySection(sectionId, offset);

        setTopics(response.topics);
        setPageCount(getPageCount(response.total, TOPICS_FORUM_LIMIT));
      } catch {
        toast.error('Ocorreu um erro ao buscar os tópicos, tente mais tarde.');
      }
    }

    if (!sectionId) {
      toast.error('Não foi possível determinar qual seção você escolheu.');
      history.goBack();
    }

    fetchTopics();
  }, [sectionId, offset, history]);

  return (
    <Container>
      <Header>
        <Overlay>
          <Breadcrumbs
            className="hidden-mobile"
            style={{ paddingLeft: '30px', paddingBottom: '10px' }}
            links={[{ name: 'Fórum', url: '/forum' }, { name: 'Tópicos' }]}
          />
          <BreadcrumbMobile className="hidden-desktop">
            <Link to="/home">
              <img src={backArrow} alt="Voltar" />
              <p>Início</p>
            </Link>
          </BreadcrumbMobile>
          <h1>
            Lista de Tópicos
            <span>Lista de Tópicos</span>
          </h1>
        </Overlay>
      </Header>

      <Content>
        <section>
          <h2>
            Quer bater um papo sobre o curso ou tirar dúvidas com os colegas?
          </h2>
          {(user?.is_admin || isModerator) && (
            <Button
              className="btn-create-topic"
              title="Criar Tópico"
              type="button"
              containerStyle={{
                maxWidth: 200,
                minWidth: 200,
              }}
              onClick={handleGoToCreateTopic}
            />
          )}
        </section>

        <List>
          <ListHeader>
            <ListHeaderOptions>
              <span>Tópicos</span>
            </ListHeaderOptions>

            <SearchTopicsInput>
              <FiSearch />
              <input
                type="text"
                placeholder="Digite aqui o que você procura"
                onChange={handleSearchByTitle}
              />
            </SearchTopicsInput>
          </ListHeader>

          {loading && (
            <div className="spinner">
              <Spinner />
            </div>
          )}
          {!loading && (
            <ListItems>
              {topics.map(topic => (
                <TopicCard
                  onDeleteTopic={onDeleteTopic}
                  onClick={() => handleGoToTopic(topic.topic_id)}
                  data={topic}
                />
              ))}
            </ListItems>
          )}
        </List>
        {!loading && topics.length === 0 && (
          <h3>
            No momento não existem tópicos para essa seção, fale com um
            administrador
          </h3>
        )}
      </Content>
      <Pagination>
        <ReactPaginate
          initialPage={0}
          previousLabel={<BiChevronLeft />}
          nextLabel={<BiChevronRight />}
          nextClassName="nextButton"
          previousClassName="previousButton"
          previousLinkClassName="previousContainerButton"
          nextLinkClassName="nextContainerButton"
          containerClassName="pagination"
          disabledClassName="disabledButtons"
          activeLinkClassName="activeLink"
          pageClassName="page"
          breakClassName="page"
          breakLabel="..."
          pageCount={pageCount}
          marginPagesDisplayed={1}
          onPageChange={page =>
            setOffset(
              (page.selected + 1) * TOPICS_FORUM_LIMIT - TOPICS_FORUM_LIMIT,
            )
          }
        />
      </Pagination>
    </Container>
  );
};

export default Section;
