import React from 'react';
import { useHistory } from 'react-router-dom';

import CheckImg from '../../../assets/icons/check-circle.svg';
import { Button } from '../../../components/Button';
import FooterLoginAndRegister from '../../../components/FooterLoginAndRegister';
import HeaderLoginAndRegister from '../../../components/HeaderLoginAndRegister';
import { goToTop } from '../../../utils/helpers';

import { Container, ThanksContainer } from './styles';

const ThanksRegister = () => {
  const history = useHistory();

  function handleGoToHome() {
    history.push('/logar');
  }

  goToTop();

  return (
    <Container>
      <HeaderLoginAndRegister buttonText="JÁ SOU ASSINANTE" />

      <ThanksContainer>
        <img src={CheckImg} alt="Ícone de confirmação" />
        <h1>Parabéns, seu cadastro foi concluído com sucesso!</h1>
        <h2>
          Agora começa sua jornada conosco, aproveite todo nosso conteúdo!
        </h2>

        <Button
          className="hidden-mobile"
          title="ACESSAR CONTA"
          onClick={handleGoToHome}
        />
        <Button
          className="hidden-desktop"
          title="ACESSAR CONTA"
          onClick={handleGoToHome}
          containerStyle={{
            minWidth: '100%',
            maxHeight: '44px',
          }}
        />
      </ThanksContainer>

      <FooterLoginAndRegister />
    </Container>
  );
};

export default ThanksRegister;
