import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';
import React from 'react';

import FontAbuget from '../../assets/Abuget.ttf';
import institutoText from '../../assets/certificates/CERTIFICADO_HALU_0000_Instituto-dos-Mistérios-Ascensionais.png';
import linhaCampo from '../../assets/certificates/CERTIFICADO_HALU_0000s_0001_Preenchimento-de-Cor-1.png';
import certificamosText from '../../assets/certificates/CERTIFICADO_HALU_0001_Certificamos-a-conclusão-do-curso-por_.png';
import linhaAssinatura from '../../assets/certificates/CERTIFICADO_HALU_V@_0000s_0000_Preenchimento-de-Cor-1-copiar.png';
import assinatura from '../../assets/certificates/CERTIFICADO_HALU_V@_0000s_0003_assinatura-halu.png';
import imgBackground from '../../assets/certificates/imagem-fundo-certificado.png';
import FontComfortaa from '../../assets/Comfortaa.ttf';

Font.register({
  family: 'Comfortaa',
  src: FontComfortaa,
  format: 'truetype',
});
Font.register({
  family: 'Abuget',
  src: FontAbuget,
  format: 'truetype',
});

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  section: {
    display: 'flex',
    width: '100%',
  },
  textCamp: {
    fontFamily: 'Abuget',
    letterSpacing: 2.4,
    position: 'absolute',
    minWidth: '100%',
    width: '100%',
    maxWidth: '100%',
    textAlign: 'center',
    padding: '308pt 0 0 0',
    fontSize: '60pt',
    flexDirection: 'column',
  },
  textCampTop: {
    fontFamily: 'Comfortaa',
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    padding: '175pt 0 0 0',
    fontSize: '32pt',
    opacity: 0.8,
    letterSpacing: 0.5,
  },
  textCampLeft: {
    fontFamily: 'Comfortaa',
    letterSpacing: 0.5,
    position: 'absolute',
    width: 'auto',
    textAlign: 'center',
    alignItems: 'center',
    padding: '478pt 0pt 0pt 108pt',
    fontSize: '12pt',
    lineHeight: 1.2,
  },
  textCampRight: {
    fontFamily: 'Comfortaa',
    letterSpacing: 0.5,
    position: 'absolute',
    width: 'auto',
    textAlign: 'center',
    alignItems: 'center',
    paddingTop: '485pt',
    paddingLeft: '589pt',
    fontSize: '12pt',
    lineHeight: 1.2,
  },
  imgBackgroundArea: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  institutoArea: {
    position: 'absolute',
    display: 'flex',
    alignSelf: 'center',
    width: '100%',
    padding: '106pt 90pt 0pt 90pt',
  },
  certificamosTextArea: {
    fontFamily: 'Comfortaa',
    position: 'absolute',
    display: 'flex',
    alignSelf: 'center',
    width: '100%',
    padding: '245pt 255pt 70pt 255pt',
  },
  linhaCampoArea: {
    position: 'absolute',
    alignSelf: 'center',
    width: '45.6%',
    padding: '374pt 0 70pt 0',
  },
  assinaturaArea: {
    position: 'absolute',
    width: '202pt',
    paddingTop: '435pt',
    right: '0',
  },
  linhaAssinaturaArea: {
    position: 'absolute',
    width: '181pt',
    paddingTop: '481pt',
    right: '11pt',
    opacity: 0.75,
  },
  textoAssinatura: {
    right: '30pt',
    alignItems: 'center',
    paddingTop: '8pt',
  },
});

function convertFromStringToDate(responseDate) {
  if (!responseDate) {
    return '';
  }
  const date = new Date(responseDate);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear().toString();

  return `${day}/${month}/${year}`;
}

function convertDuration(seconds) {
  const minutes = Math.floor(seconds / 60);
  const hours = seconds / 3600;
  const hourHasDecimal = hours % 1 !== 0;

  return minutes < 60
    ? `${minutes} minutos`
    : `${hours.toFixed((hourHasDecimal && 2) || 0)} horas`;
}

const Certificate = ({ data, onRender }) => {
  return (
    <Document onRender={onRender}>
      <Page
        object-fit="contain"
        style={styles.page}
        size="A4"
        orientation="landscape"
      >
        <View style={styles.section}>
          <Image src={imgBackground} style={styles.imgBackgroundArea} />
          <Image src={institutoText} style={styles.institutoArea} />
          <Image src={certificamosText} style={styles.certificamosTextArea} />
          <Text style={styles.textCampTop}>{data.content_name}</Text>
          <View style={styles.textCamp}>
            <Text>{data.user?.name}</Text>
            <Image src={linhaCampo} style={styles.linhaCampoArea} />
          </View>
          <View style={styles.textCampLeft}>
            <Text>Ministrado por Halu Gamashi</Text>
            <Text>
              no período de {convertFromStringToDate(data.content_start_at)} a
            </Text>
            <Text>
              {convertFromStringToDate(data.content_finish_at)}, com uma carga{' '}
            </Text>
            <Text>
              horária total de {convertDuration(data.content_duration)}.
            </Text>
          </View>
          <View style={styles.textCampRight}>
            <Image src={assinatura} style={styles.assinaturaArea} />
            <Image src={linhaAssinatura} style={styles.linhaAssinaturaArea} />
            <View style={styles.textoAssinatura}>
              <Text>Halu Gamashi</Text>
              <Text>Fundadora do I.M.A e</Text>
              <Text>do Método GAMASHI</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Certificate;
