import React, { useState } from 'react';
import { FaPowerOff, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { useAuth } from '../../../../hooks/auth';
import { deleteSection, toggleSectionStatus } from '../../../../services/forum';
import { theme } from '../../../../styles/theme';

import { ActiveStatusIcon, Container, Title, TrashIcon } from './styles';

const SectionCard = ({ onClick, onDeleteTopic, data }) => {
  const [isActive, setIsActive] = useState(data.is_active);
  const { user } = useAuth();

  const fetchDeleteSection = async () => {
    try {
      await deleteSection(data.section_id);

      toast.success('Seção excluída com sucesso!');
      onDeleteTopic(data.section_id);
    } catch {
      toast.error('Ocorreu um erro ao tentar excluir esta seção.');
    }
  };

  const handleDeleteSection = async event => {
    event.stopPropagation();

    const result = await Swal.fire({
      icon: 'warning',
      iconColor: theme.colors.warning,
      title: 'Deseja confirmar a exclusão desta seção?',
      text: 'Essa ação irá tornar inacessível seus tópicos e discussões.',
      showCancelButton: true,
      confirmButtonColor: theme.colors.warning,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    });

    if (result.isConfirmed) await fetchDeleteSection();
  };

  const fetchToggleSectionStatus = async () => {
    try {
      await toggleSectionStatus(data.section_id, {
        is_active: !isActive,
      });

      toast.success(`Seção ${isActive ? 'inativada' : 'ativada'} com sucesso!`);
      setIsActive(state => !state);
    } catch {
      toast.error('Ocorreu um erro ao tentar trocar o status desta seção.');
    }
  };

  const handleToggleSectionStatus = async event => {
    event.stopPropagation();

    const result = await Swal.fire({
      icon: 'question',
      title: `Deseja ${isActive ? 'Inativar' : 'Ativar'} essa seção?`,
      text: `Os usuários irão ${
        isActive ? 'perder' : 'retomar'
      } acesso aos tópicos.`,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    });

    if (result.isConfirmed) await fetchToggleSectionStatus();
  };

  const isModerator = user.roles.some(role => role === 'Moderator');

  return (
    <Container
      isActive={isActive}
      role="button"
      tabIndex="0"
      onKeyPress={onClick}
      onClick={onClick}
    >
      <Title>{data.name}</Title>

      {(user.is_admin || isModerator) && (
        <TrashIcon onClick={handleDeleteSection}>
          <FaTrash />
          Excluir
        </TrashIcon>
      )}

      {(user.is_admin || isModerator) && (
        <ActiveStatusIcon
          isActive={isActive}
          onClick={handleToggleSectionStatus}
        >
          <FaPowerOff />
          {isActive ? 'Inativar' : 'Ativar'}
        </ActiveStatusIcon>
      )}
    </Container>
  );
};

export { SectionCard };
