import React, { useCallback } from 'react';
import { TiShoppingCart } from 'react-icons/ti';

import { theme } from '../styles/theme';
import adapterContentToCardCourse from '../utils/adapterContentToCardCourse';
import checkUserIsPaid from '../utils/checkUserIsFree';

import { useAuth } from './auth';

function useCardCourse() {
  const { user } = useAuth();
  const userIsPaid = checkUserIsPaid(user?.plans_paid);

  const getCardCourseData = useCallback(
    (data, userCoursesIds = []) => {
      const cardCourse = adapterContentToCardCourse(data, user);

      const userHasPayedThisContent = userCoursesIds.includes(data.content_id);

      cardCourse.userHasPermissionToAccess =
        !cardCourse.price || cardCourse.isFree;

      cardCourse.redirectToSubscribePage =
        !userIsPaid && cardCourse.onlySubscribers && !userHasPayedThisContent;

      cardCourse.userHasPermissionToAccess = userHasPayedThisContent;

      cardCourse.showRater = !!data.progress;

      if (cardCourse.date !== 'Já disponível') {
        cardCourse.needBuy = !userHasPayedThisContent && cardCourse.price;
        cardCourse.buttonIcon = undefined;
        cardCourse.buttonText = 'Lançamento em breve';
        return cardCourse;
      }

      if (
        data?.info?.free_for_subscribers &&
        userIsPaid &&
        !data?.info?.associated_to_plans
      ) {
        cardCourse.buttonText = 'Conteúdo Disponível';
        return cardCourse;
      }

      // TODO verificar para quando switch de "Exclusivo para assinantes" nao estiver marcado.
      if (
        data?.info?.associated_to_plans &&
        data?.info?.userHasExclusiveAccess &&
        data?.info?.free_for_subscribers
      ) {
        cardCourse.buttonText = 'Conteúdo Disponível';
        return cardCourse;
      }
      if (
        data?.info?.associated_to_plans &&
        !data?.info?.userHasExclusiveAccess &&
        !data?.info?.free_for_subscribers
      ) {
        cardCourse.redirectToSubscribePage = true;

        cardCourse.buttonText = 'Exclusivo para assinantes';
        return cardCourse;
      }

      if (
        data?.info?.free_for_subscribers &&
        !userIsPaid &&
        !userHasPayedThisContent
      ) {
        cardCourse.buttonIcon = TiShoppingCart;
        cardCourse.needBuy = true;
        cardCourse.buttonText = (
          <span>
            Acesse por {cardCourse.price}{' '}
            {cardCourse?.originalPrice && (
              <s style={{ color: theme.colors.textDisabled, fontSize: 12 }}>
                ({cardCourse?.originalPrice})
              </s>
            )}
          </span>
        );
        return cardCourse;
      }

      if (
        cardCourse.onlySubscribers &&
        !userIsPaid &&
        !userHasPayedThisContent
      ) {
        cardCourse.redirectToSubscribePage = true;
        cardCourse.buttonIcon = undefined;
        cardCourse.buttonText = 'Exclusivo para assinantes';
        return cardCourse;
      }

      if (!userHasPayedThisContent && cardCourse.price && cardCourse.isSell) {
        cardCourse.needBuy = true;
        cardCourse.buttonIcon = TiShoppingCart;
        cardCourse.buttonText = (
          <span>
            Acesse por {cardCourse.price}{' '}
            {cardCourse?.originalPrice && (
              <s style={{ color: theme.colors.textDisabled, fontSize: 12 }}>
                ({cardCourse?.originalPrice})
              </s>
            )}
          </span>
        );

        return cardCourse;
      }

      if (!cardCourse.courseProgress && userHasPayedThisContent) {
        cardCourse.buttonIcon = undefined;
        cardCourse.buttonText = 'Ver mais';
        return cardCourse;
      }

      if (cardCourse.courseProgress) {
        cardCourse.buttonIcon = undefined;
        cardCourse.buttonText = 'Continuar de onde parei';
        return cardCourse;
      }

      if (cardCourse.courseFinished) {
        cardCourse.buttonText = `Assistir novamente`;
        cardCourse.buttonIcon = undefined;
        return cardCourse;
      }

      if (!cardCourse.price || cardCourse.isFree) {
        cardCourse.buttonText = 'Conteúdo Disponível';
        cardCourse.buttonIcon = undefined;
        return cardCourse;
      }

      if (!cardCourse.isSell && !userHasPayedThisContent) {
        cardCourse.buttonText = 'Conteúdo Não Disponível';
        cardCourse.buttonIcon = undefined;

        return cardCourse;
      }

      return cardCourse;
    },
    [user, userIsPaid],
  );

  return { getCardCourseData };
}

export default useCardCourse;
