import api from './api';

export const listFaqs = async (params = {}) => {
  const res = await api.get('/faqs', {
    params: { sort_by: 'position', order_by: 'ASC', ...params },
  });

  return res.data;
};

export const sendMail = async (data = {}) => {
  const res = await api.post('/faqs/mail', data);

  return res.data;
};
