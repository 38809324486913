import styled from 'styled-components';

export const Container = styled.div`
  display: contents;
  width: 100%;
  height: 100%;
  @media (max-width: 480px) {
    overflow-x: hidden;
  }
`;

export const Background = styled.div`
  width: 100%;
  height: auto;
  padding: 120px 40px 40px 40px;
  min-width: 100%;
  @media (max-width: 1100px) {
    height: 280px;
    z-index: 9;
  }

  > img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    @media (max-width: 1100px) {
      min-height: 280px;
      max-height: 280px;
      z-index: 10;
    }
  }

  @media (max-width: 870px) {
    padding: 10px;
    padding-top: 120px;
  }

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;

    > img {
      height: 250px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  min-width: 100%;
  background-color: #f3f9fb;

  > h2 {
    @media (max-width: 480px) {
      position: relative;
      color: #fff;
      font-weight: 600;
      font-size: 18px;
      left: 70px;
      top: 55px;
      align-items: center;
      letter-spacing: 0.04em;
      text-transform: uppercase;
    }
  }

  > h3 {
    position: relative;
    left: 70px;
    top: 55px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    color: #ffffff;
    width: max-content;
  }

  @media (max-width: 1100px) {
    flex-direction: column;
    bottom: 320px;
    z-index: 10;
    background-color: inherit;
  }
`;

export const TextMobile = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 280px;
  left: 30px;

  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.05em;
    color: #ffffff;
    z-index: 30;
  }

  @media screen and (max-width: 1100px) {
    display: none;
  }
`;

export const IconBackMobile = styled.div`
  display: flex;
  position: absolute;
  top: 95px;
  left: 24px;
  z-index: 20;

  img {
    padding-right: 24px;
    padding-top: 1px;
  }

  span {
    font-size: 16px;
    color: #ffffff;
  }
`;

export const VideoComponent = styled.div`
  min-width: 100%;
  padding: 0;
  iframe {
    border-radius: 20px;
  }

  div {
    background-color: black;
    width: auto;
    height: 400px;
    margin-top: 36px;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.8) 0 0 10px;
    -moz-box-shadow: rgba(0, 0, 0, 0.8) 0 0 10px;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &:before {
    content: '';
    display: block;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }
`;

export const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  z-index: 1001;
  span {
    margin-top: 16px;
    font-size: 1.4rem;
    font-weight: 600;
  }
`;
