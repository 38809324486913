import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import logoMobileHeader from '../../assets/icons/logo-mobile-header.svg';
import { Button } from '../Button';

import {
  ContainerHeaderTop,
  Background,
  ContentHeader,
  TopHeaderLeft,
  TopHeaderRight,
  Content,
} from './styles';

function Header({ children, backgroundImage, backgroundImageStyle }) {
  const history = useHistory();

  function handleGoToLogin() {
    history.push('/registro');
  }

  return (
    <ContainerHeaderTop>
      <Background>
        <span style={backgroundImageStyle} />
        {backgroundImage ? (
          <img src={backgroundImage} alt="" style={backgroundImageStyle} />
        ) : (
          ''
        )}
      </Background>

      <ContentHeader>
        <TopHeaderLeft>
          <Link to="/">
            <img
              className="hidden-mobile"
              src={logoMobileHeader}
              alt="Logo Ventos-Antigos"
            />
          </Link>

          <Link to="/">
            <img
              className="hidden-desktop"
              src={logoMobileHeader}
              alt="Logo Ventos-Antigos-left"
            />
          </Link>
        </TopHeaderLeft>

        <TopHeaderRight>
          <Link to="logar" className="hidden-desktop">
            JÁ SOU <br /> ASSINANTE
          </Link>
          <Link to="logar" className="hidden-mobile">
            JÁ SOU ASSINANTE
          </Link>
          <Button
            className="hidden-mobile"
            containerStyle={{ minWidth: '194px', height: '56px' }}
            type="button"
            title="ASSINE AGORA"
            onClick={handleGoToLogin}
          />
        </TopHeaderRight>
      </ContentHeader>

      <Content>{children}</Content>
    </ContainerHeaderTop>
  );
}

export default Header;
