import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import useWindowSize from '../../hooks/useWindowSize';
import PlayerSambaVideos from '../Player';

import { ResponsivePlayerContainer } from './style';

const ResponsivePlayer = props => {
  const { id, className } = props;
  const [playerUuid, setPlayerUuid] = useState('');
  const { windowSize } = useWindowSize();

  const playerId = useMemo(() => {
    return `responsive-player_${playerUuid}`;
  }, [playerUuid]);

  const configurePlayerHeightAndWidth = useCallback(() => {
    const player = document.getElementById(playerId);
    if (player) {
      const { clientWidth: width, clientHeight: height } = player;

      const idealWidthProportion = 16;
      const idealHeightProportion = 9;

      if (width > 0) {
        const foundIdealHeight =
          (width * idealHeightProportion) / idealWidthProportion;

        player.style.height = `${(foundIdealHeight || 0).toFixed(0)}px`;
      } else if (height > 0) {
        const foundIdealWidth =
          (height * idealWidthProportion) / idealHeightProportion;

        player.style.width = `${(foundIdealWidth || 0).toFixed(0)}px`;
      }
    }
  }, [playerId]);

  useEffect(() => {
    setPlayerUuid(uuidv4);
  }, []);

  useEffect(() => {
    configurePlayerHeightAndWidth();
  }, [configurePlayerHeightAndWidth, windowSize]);

  return (
    <ResponsivePlayerContainer id={id} className={className}>
      <PlayerSambaVideos {...props} id={playerId} />
    </ResponsivePlayerContainer>
  );
};

export default ResponsivePlayer;
