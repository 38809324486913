import React from 'react';
import { BiSearch } from 'react-icons/bi';
import styled from 'styled-components';
import { boolean } from 'yup';

import bannerBackgroundMobile from '../../assets/images/image-home.png';
import { theme } from '../../styles/theme';
import { Input } from '../Input';

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  top: 0;
  z-index: 200;
  width: 100%;
  max-width: 100%;
  max-height: 104px;
  padding: 32px 0;
  background: rgba(37, 40, 43, 0.1);
  @media (max-width: 1100px) {
    padding: 5px;
  }
  @media (max-width: 480px) {
    max-width: 100%;
    justify-content: space-between;
  }

  ul {
    display: flex;
    list-style-type: none;
    justify-content: space-around;
    @media (max-width: 480px) {
      padding: 0;
      margin: 0;
      max-width: 100%;
    }

    li {
      font-size: 16px;
      font-weight: 500;
      display: flex;
      margin: 0 32px 0 0;
      padding: 15px 0;
      width: max-content;

      &:last-child {
        margin: 0;
      }

      @media (max-width: 480px) {
        padding: 0;
        margin: 0;
      }

      a {
        color: ${theme.colors.textLight};
        font-size: 1.1rem;
        text-decoration: none;
        @media (max-width: 480px) {
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  .buttons-wrapper {
    margin-right: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 8px;
  }

  .menu-button {
    width: 32px;
    height: 32px;

    color: white;

    cursor: pointer;
  }
`;

export const Box = styled.div`
  position: relative;
  padding: 0 20px;

  img {
    cursor: pointer;
    height: 30px;

    @media (max-width: 480px) {
      height: 30px;
      margin-left: 16px;
      padding-right: 0;
    }
  }
`;

export const Dropdown = styled.li`
  display: flex;
  align-items: center;
  margin: 0 32px 0;
  width: max-content;
  cursor: pointer;
  color: ${theme.colors.textLight};
  font-size: 1.1rem !important;
  z-index: 110;

  @media (max-width: 480px) {
    padding: 0;
    margin: 0;
  }

  img {
    height: 46px;
    width: 46px;
    max-width: 46px;
    max-height: 46px;
    border-radius: 100%;
    object-fit: cover;
    margin-right: 8px;
  }

  > svg[alt='Sem foto de perfil'] {
    height: 36px;
    width: 36px;
    margin-right: 8px;
  }

  > span {
    position: relative;
    margin-right: 8px;
  }

  div {
    display: none;
    position: absolute;
    top: 35px;
    right: -40px;
    background: ${theme.colors.light};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    min-width: 253px;
    max-width: 253px;

    &:hover button:first-child {
      border-radius: 20px 20px 0 0;
    }

    &:hover button:last-child {
      border-radius: 0 0 20px 20px;
    }

    @media (max-width: 480px) {
      padding: 0;
      position: absolute;
      left: 0;
      top: 60px;
      width: 60%;
      background-size: cover !important;
      background: linear-gradient(rgba(89, 74, 173, 0.8), rgba(47, 24, 156, 1)),
        url(${bannerBackgroundMobile}) no-repeat;
      background-position: center;
      min-height: 100%;
    }

    a,
    button {
      display: flex;
      align-items: center;
      width: 100%;
      text-align: start;
      text-decoration: none;
      padding: 12px 32px;
      color: ${theme.colors.text} !important;
      font-size: 1.1rem;
      font-weight: 600;
      @media (max-width: 480px) {
        padding: 30px 0 10px 20px;
      }

      svg {
        margin-right: 8px;
        color: ${theme.colors.light};
        @media (max-width: 480px) {
          padding: 0;
          margin: 0;
        }
      }

      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }

    &:hover {
      display: block;
      @media (max-width: 480px) {
        padding: 0;
        margin: 0;
      }
    }
  }

  &:hover div {
    display: block;
    @media (max-width: 480px) {
      padding: 0;
      margin: 0;
    }
  }

  .last {
    padding-bottom: 100px;

    p {
      color: white;
    }
  }
`;

export const ProfileArea = styled.section`
  display: flex;
  align-items: center;
  grid-gap: 24px;
`;

export const LinksMobileHome = styled.div`
  .LinkHome {
    p {
      color: white;
    }
  }

  button {
    p {
      color: white;
      padding-left: 5px;
    }
  }
`;

export const ProfileIcon = styled.div`
  .lastOpition {
    margin-right: 20px;
    margin-top: 10px;
  }
`;

export const SearchIconWrapper = styled.div`
  width: 32px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchIcon = styled(BiSearch)`
  width: 32px;
  height: 32px;

  color: white;
`;

export const SearchInput = styled(Input).attrs({
  placeholder: 'Pesquisa',
  icon: <SearchIcon color={theme.colors.primary} />,
  containerStyle: { maxHeight: 46 },
})`
  width: 100%;
`;

export const Hidden = styled.div`
  @media (max-width: 1100px) {
    display: none;
  }
`;

export const ContainerMobile = styled.header`
  width: 100%;
  height: fit-content;

  padding: 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 24px;

  background: rgba(37, 40, 43, 0.04);

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 300;

  transition: all 0.3s ease-in-out;

  .logo {
    width: 128px;
  }

  .buttons-wrapper {
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 24px;

    > div {
      max-width: 280px !important;

      > div {
        min-width: fit-content !important;
        margin: 0 !important;
      }
    }
  }

  .menu-button {
    width: 32px;
    height: 32px;

    color: white;

    cursor: pointer;
  }
`;

export const SidebarContainer = styled.div`
  max-width: 480px;
  width: 100%;
  height: 100%;

  overflow-y: auto;

  padding: 24px;

  position: fixed;
  top: 0;
  left: ${props => (props.isOpen ? '0' : '-100%')};
  bottom: 0;
  z-index: 9999;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;

  background-size: cover !important;
  background: linear-gradient(rgba(89, 74, 173, 0.8), rgba(47, 24, 156, 1)),
    url(${bannerBackgroundMobile}) no-repeat;
  background-position: top;

  transition: all 0.3s ease-in-out;

  .logo-close-button-wrapper {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .close-button {
      width: 24px;
      height: 24px;
    }
  }

  .signout {
    display: flex;
    align-items: center;
    justify-content: start;
    grid-gap: 8px;

    img {
      width: 18px;
      height: 18px;
    }

    font-size: 16px;
    font-weight: 600;
    color: white;
    letter-spacing: 0.5px;
    line-height: 20px;

    text-decoration: none;
  }
`;

export const LinksList = styled.div`
  width: 100%;
  height: 100%;

  margin: 80px 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  grid-gap: 40px;

  .link {
    font-size: 16px;
    font-weight: 600;
    color: white;
    letter-spacing: 0.5px;
    line-height: 20px;

    text-decoration: none;
  }
`;
