import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';
import { ReactComponent as UnlockedIcon } from '../../assets/icons/unlocked.svg';
import { Button } from '../../components/Button';
import FooterLoginAndRegister from '../../components/FooterLoginAndRegister';
import HeaderLoginAndRegister from '../../components/HeaderLoginAndRegister';
import { Input } from '../../components/Input';
import { InputPassword } from '../../components/InputPassword';
import Spinner from '../../components/Spinner';
import { useAuth } from '../../hooks/auth';
import { goToTop } from '../../utils/helpers';

import {
  Checkbox,
  Container,
  Content,
  FormContainer,
  LoginArea,
  Presentation,
} from './styles';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Digite um email válido')
    .required('Email é obrigatório'),
  password: yup.string().required('Senha é obrigatória'),
  keepLoggedIn: yup.boolean(),
});

const Login = () => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const auth = useAuth();
  const history = useHistory();

  async function handleLogin(data) {
    const { email, password, keepLoggedIn } = data;

    setLoading(true);

    try {
      await auth.login({ email, password, keepLoggedIn });
      history.push('/home');
    } catch (err) {
      setLoading(false);

      if (err?.response?.status === 300) {
        toast.info('🔍️ Seu pré cadastro foi encontrado! ');
        toast.info('Por favor preencha com seus dados antes de continuar', {
          autoClose: false,
        });

        history.push('/registro/concluir-cadastro');
        return;
      }

      const mappedExceptions = {
        NotAuthorizedException: () => toast.error(err.response.data.message),
        UserNotConfirmedException: () => {
          localStorage.setItem('@HaluGamashi:email', email);
          history.push('/registro/confirmar-conta');
        },
      };

      if (Object.keys(mappedExceptions).includes(err?.response?.data?.code)) {
        mappedExceptions[err.response.data.code]();
        return;
      }

      toast.error('Erro ao logar, tente novamente.');
    }
  }

  function handleGoToRegister() {
    history.push('/registro');
  }

  function handleGoToForgotPassword() {
    history.push('/recuperar-senha');
  }

  goToTop();

  return (
    <Container>
      <HeaderLoginAndRegister
        className="hidden-mobile"
        buttonText="QUERO SER ASSINANTE"
      />
      <HeaderLoginAndRegister
        className="hidden-desktop"
        buttonText="QUERO"
        buttonSubText="SER ASSINANTE"
      />

      <Content>
        <Presentation>
          <h1>
            É tempo de
            <br />
            Ascender!
          </h1>
          <h2>
            Seja bem vindo de volta ao Instituto
            <br />
            dos Mistérios Ascensionais!
          </h2>
        </Presentation>
        <LoginArea>
          <FormContainer>
            <span>Entrar</span>

            <form onSubmit={handleSubmit(handleLogin)}>
              <Input
                placeholder="Email"
                type="email"
                icon={<EmailIcon />}
                isInvalid={errors.email?.message}
                errorMessage={errors.email?.message}
                {...register('email')}
              />

              <InputPassword
                placeholder="Senha"
                type="password"
                icon={<UnlockedIcon />}
                isInvalid={errors.password?.message}
                errorMessage={errors.password?.message}
                {...register('password')}
              />

              <Checkbox>
                <input
                  type="checkbox"
                  id="keepLoggedIn"
                  {...register('keepLoggedIn')}
                />
                <label htmlFor="keepLoggedIn">Manter conectado</label>
              </Checkbox>

              {loading ? (
                <Spinner />
              ) : (
                <Button
                  type="submit"
                  title="ENTRAR"
                  containerStyle={{ marginTop: 24 }}
                />
              )}
            </form>
          </FormContainer>
          <section>
            <button onClick={handleGoToForgotPassword}>
              <h4>Esqueceu sua senha?</h4>
            </button>

            <button className="hidden-mobile" onClick={handleGoToRegister}>
              <h4>Criar nova conta</h4>
            </button>
          </section>
        </LoginArea>
      </Content>

      <FooterLoginAndRegister />
    </Container>
  );
};

export default Login;
