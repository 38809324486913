import styled from 'styled-components';

import { theme } from '../../../../styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 500px;

  padding: 8px 16px;
  background: ${theme.colors.light};
  border-radius: 8px;
  max-width: 500px;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;

  > img {
    position: absolute;
    right: 16px;
    top: 16px;
    color: ${theme.colors.secondary};
    cursor: pointer;
    width: 14px;
    height: 14px;
  }

  h3 {
    text-align: center;
    color: ${theme.colors.secondary};
    font-size: 1.5rem;
    margin-top: 16px;
  }

  h6 {
    margin: 40px auto;
    text-align: center;
    font-size: 1.3rem;
    color: ${theme.colors.secondary};
  }

  > svg {
    align-self: center;
    margin: auto;
    color: ${theme.colors.secondary};
  }

  .spinner {
    margin: 0 auto;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #594aad;
    border: 4px solid transparent;
    border-radius: 8px;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar {
    width: 16px;
  }

  @media (min-width: 370px) {
    padding: 32px 24px;
    max-height: 550px;
  }

  @media (min-height: 800px) {
    max-height: 80vh;
    min-height: 50vh;
  }
`;

export const PaymentFormHeader = styled.div`
  position: relative;
  display: flex;
  border-bottom: 1px solid ${theme.colors.shapeSecondary};
  padding: 0 24px;
  padding-bottom: 32px;
`;

export const PaymentFormInfo = styled.div`
  p {
    font-weight: 500;
  }

  h3 {
    margin: 0;
    padding: 0;
    color: ${theme.colors.secondary};
    font-size: 2rem;
  }
`;

export const PaymentFormPrice = styled.div`
  margin-left: 32px;

  p {
    color: ${theme.colors.textSecondary};
  }

  h3 {
    font-size: 2rem;
    font-weight: 500;
  }
`;

export const ListPaymentMethods = styled.ul`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;

  margin: 24px 0;
  padding-bottom: 12px;

  list-style: none;
  border-bottom: 1px solid ${theme.colors.shapeSecondary};

  li {
    display: flex;
    align-items: center;

    label {
      font-weight: 600;
      margin-left: 8px;
    }
  }

  @media (min-width: 460px) {
    flex-direction: row;
  }
`;

export const PaymentForm = styled.form`
  display: flex;
  flex-direction: column;

  > svg {
    align-self: center;
    margin-bottom: 24px;
    color: ${theme.colors.secondary};
  }

  img {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    margin-bottom: 16px;
  }

  h4 {
    color: ${theme.colors.secondary};
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
  }
`;

export const SelectContainer = styled.div`
  width: 100%;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Border of ReactSelect */
  span + span + div {
    border: 1px solid
      ${({ error }) =>
        error ? theme.colors.error : theme.colors.shapeSecondary};
  }
`;

export const SelectCustomStyle = {
  container: provided => ({
    ...provided,
    width: '100%',
  }),
  control: provided => ({
    ...provided,
    width: '100%',
    borderRadius: 30,
    padding: '8px 12px',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
    display: 'flex',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 12,
    color: '#adb5bd',
    width: 10,
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 12,
  }),
  option: provided => ({
    ...provided,
    maxWidth: 397,
    borderRadius: 20,
  }),
};

export const ErrorMessage = styled.span`
  display: block;
  font-size: 0.8rem;
  color: ${theme.colors.error};
  text-align: start;
  width: 100%;
  margin-top: 8px;
  margin-left: 10px;
`;

export const RowInputs = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const Overlay = styled.div`
  height: 100vh;

  &:before {
    content: '';
    display: block;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
  }
`;

export const ContainerDebitCreditCard = styled.div`
  background-color: #ffcc00;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 10px 10px;
  margin-bottom: 20px;
`;

export const LabelDebitCreditCard = styled.p`
  font-size: 16px;
`;
