import api from './api';

export function getAll() {
  return api.get('/contents?is_active=true');
}

export async function getLessonById(courseId, lessonId) {
  return api.get(`/contents/${courseId}/${lessonId}`);
}

export async function getById(contentId) {
  return api.get(`/contents/${contentId}`, {
    params: {
      relations: [
        'authors',
        'tags',
        'children',
        'extra_materials',
        'content_user',
        // 'progress',
        'exams',
        'exam_user',
        'reviews',
      ],
      client_relations: ['live'],
    },
  });
}

export function getByIdDetails(contentId) {
  return api.get(`/contents/${contentId}`, {
    params: {
      relations: [
        'authors',
        'tags',
        'children',
        'content_user',
        'extra_materials',
        'exams',
        // 'progress',
        'reviews',
      ],
      detail: true,
      client_relations: ['live'],
    },
  });
}

export function getCoursesRecommended() {
  return api.get('/contents', {
    params: {
      cache_name: 'courses_recommended',
      is_active: true,
      is_recommended: true,
      flag: ['course'],
      type: ['curso'],
      relations: ['authors', 'tags', 'children'],
    },
  });
}

export function getCoursesInProgress() {
  return api.get('/contents', {
    params: {
      cache_name: 'courses_in_progress',
      in_progress: true,
      realtions: ['content_user'],
    },
  });
}

export function getCoursesFinished() {
  return api.get('/contents', {
    params: {
      cache_name: 'courses_finished',
      is_finished: true,
      relations: ['content_user'],
    },
  });
}

export async function finishContent(content_id) {
  if (!content_id) {
    return {};
  }

  const updatedContent = await api.post(`/content-users/${content_id}/finish`);

  return updatedContent;
}

export async function updateContentProgress(contentId, time) {
  return api.patch(`/content-users/${contentId}/content-view`, {
    content_view: time,
  });
}

export async function startContent(contentId) {
  return api.post(`/content-users/${contentId}/start`);
}

export async function getContentSchedule() {
  return api.get('/contents', {
    params: {
      cache_name: 'content_schedule',
      info_key: 'scheduled',
      info_value: true,
      relations: ['authors', 'tags'],
    },
    headers: {
      authorization: `Bearer ${process.env.REACT_APP_UNLOGGED_TOKEN}`,
    },
  });
}

export async function getContentNextCourses() {
  return api.get('/contents', {
    params: {
      cache_name: 'content_next_courses',
      info_key: 'next_release',
      info_value: true,
      relations: ['authors', 'tags'],
    },
    headers: {
      authorization: `Bearer ${process.env.REACT_APP_UNLOGGED_TOKEN}`,
    },
  });
}

export async function getModuleByLessonId(contentId) {
  return api.get(`/contents/${contentId}/parent`);
}
