import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const Button = styled.button`
  position: absolute;
  top: 28px;
  right: 7px;
  padding: 0.5rem;
  color: #909599;
  cursor: pointer;
`;
