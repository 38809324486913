const controlsToEnable = [
  'play',
  'pause',
  'quality',
  'fullscreen',
  'time',
  'volume',
  'backward',
  'forward',
  'seekbar',
  'speed',
];

export { controlsToEnable };
