import styled from 'styled-components';

import inProgressBanner from '../../assets/images/in-progress-banner.png';
import { theme } from '../../styles/theme';

export const Container = styled.div`
  min-height: 100%;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const Header = styled.section`
  background: url(${inProgressBanner});
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 480px) {
    margin-bottom: 30px;
  }

  h1 {
    padding-left: 63px;
    font-size: 7rem;
    color: ${theme.colors.textLight};
    position: relative;
    z-index: 1;

    span {
      color: ${theme.colors.text};
      position: absolute;
      font-size: 8rem;
      opacity: 0.2;
      bottom: 10px;
      left: 24px;
      z-index: -1;
    }
  }

  @media (max-width: 1100px) {
    h1 {
      font-size: 2rem;
      z-index: 0;

      span {
        font-size: 5rem;
      }
    }
  }

  @media (max-width: 700px) {
    h1 {
      font-size: 2rem;

      span {
        font-size: 3rem;
      }
    }
  }
`;

export const ImageFilter = styled.small`
  background: linear-gradient(
    90deg,
    rgba(89, 74, 173, 0.83) -5.53%,
    rgba(159, 151, 201, 0.3818) 60.04%,
    rgba(47, 24, 156, 0.83) 131.32%
  );
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
  width: 100%;
  height: 100%;
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  padding: 152px 24px 24px 24px;
  position: relative;
  @media (max-width: 480px) {
    padding: 80px 24px 24px 24px;
  }

  h1 {
    @media (max-width: 480px) {
      font-weight: bold;
      font-size: 40px;
      padding: 0;
    }
  }
`;

export const Content = styled.section`
  padding: 72px 40px 90px;
  display: flex;

  section {
    flex: 1;
    margin: 0 24px;
  }

  @media (max-width: 1400px) {
    padding-right: 20px;
  }

  @media (max-width: 1100px) {
    padding: 0;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 400px) {
    padding: 0;
    section {
      margin: 0;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  h3 {
    font-size: 1.4rem;
    font-weight: 700;
    color: ${theme.colors.text};
  }

  svg {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 1400px) {
    margin-bottom: 10px;

    h3 {
      font-size: 1rem;
    }
  }
`;

export const CoursesGrid = styled.section`
  flex: 1;
  margin: 0 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  align-items: start;
  justify-items: center;
  column-gap: 24px;
  row-gap: 24px;
  position: relative;

  @media (max-width: 1400px) {
    margin: 0 12px;
    column-gap: 12px;
    row-gap: 12px;
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }

  @media (max-width: 1100px) {
    margin-top: 20px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    column-gap: 12px;
  }
`;

export const Pagination = styled.section`
  position: absolute;
  bottom: -48px;
  width: 328px;

  .pagination {
    display: flex;
    list-style-type: none;
  }

  .page {
    display: flex;
    align-items: center;
    margin: 0 20px;
    cursor: pointer;
  }

  .activeLink {
    color: ${theme.colors.secondary};
    font-weight: 700;
  }

  .nextButton {
    margin-left: 72px;
  }

  .previousButton {
    margin-right: 72px;
  }

  .previousContainerButton,
  .nextContainerButton {
    display: flex;
  }

  .nextButton,
  .previousButton {
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      font-size: 2rem;
      fill: ${theme.colors.secondary};
    }
  }

  .disabledButtons {
    cursor: default;

    svg {
      fill: #cfd2d4;
    }
  }

  @media (max-width: 1100px) {
    position: static;
    width: auto;
    margin: 16px auto 64px !important;
  }
`;

export const ResultArea = styled.div`
  display: flex;
  font-size: 24px;
  color: #25282b;
  padding: 0 24px;

  p {
    padding-right: 5px;
  }

  span {
    color: #2f189c;
    font-weight: 600;
    white-space: nowrap;
    width: 100vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (min-width: 480px) {
    padding: 16px 24px;
  }
`;
