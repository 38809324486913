import { format } from 'date-fns';
import React from 'react';
import { BsChat } from 'react-icons/bs';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import AvatarFallback from '../../../../../assets/images/avatar-fallback.png';
import { useAuth } from '../../../../../hooks/auth';
import { deleteTopic } from '../../../../../services/forum';
// import { deleteSection } from '../../../../../services/forum';
import { theme } from '../../../../../styles/theme';

import {
  Container,
  Title,
  UserInfo,
  Username,
  Published,
  ConversationIcon,
  ActionIcons,
  TrashIcon,
  EditIcon,
} from './styles';

const TopicCard = ({ onClick, onDeleteTopic, data }) => {
  const { user } = useAuth();
  const history = useHistory();

  const fetchDeleteTopic = () => {
    try {
      deleteTopic(data.topic_id);

      toast.success('Tópico excluído com sucesso');
      onDeleteTopic(data.topic_id);
    } catch {
      toast.error('Ocorreu um erro ao tentar deletar este tópico');
    }
  };

  const handleAdminDeleteTopic = async event => {
    event.stopPropagation();

    const result = await Swal.fire({
      icon: 'warning',
      iconColor: theme.colors.warning,
      title: 'Deseja confirmar a exclusão deste tópico?',
      text: 'Essa ação irá tornar inacessível as discussões do tópico.',
      showCancelButton: true,
      confirmButtonColor: theme.colors.warning,
      confirmButtonText: 'Confirmar',
    });

    if (result.isConfirmed) await fetchDeleteTopic();
  };

  const handleGoToEditTopic = event => {
    event.stopPropagation();
    history.push(`/editar-topico/${data.topic_id}`);
  };

  const isModerator = user.roles.some(role => role === 'Moderator');

  return (
    <Container
      onKeyPress={onClick}
      role="button"
      tabIndex="0"
      onClick={onClick}
    >
      <Title>{data.title}</Title>

      <UserInfo>
        <img src={data.user?.images.avatar || AvatarFallback} alt="Avatar" />
        <Username>{data.user.name}</Username>
        {/* TODO badge de gameficação aqui */}
        <Published>
          Iniciado em {format(new Date(data.created_at), 'dd/MM/yyyy')}
        </Published>
      </UserInfo>

      <ConversationIcon>
        <BsChat />
        <span>{data.answers.length}</span>
      </ConversationIcon>

      {(user?.is_admin || isModerator) && (
        <ActionIcons>
          <EditIcon onClick={handleGoToEditTopic}>
            <FaEdit />
            Editar
          </EditIcon>

          <TrashIcon onClick={handleAdminDeleteTopic}>
            <FaTrash />
            Excluir
          </TrashIcon>
        </ActionIcons>
      )}
    </Container>
  );
};

export default TopicCard;
