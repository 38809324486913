import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';
import backArrow from '../../assets/icons/icon-back.svg';
import { ReactComponent as UnlockedIcon } from '../../assets/icons/unlocked.svg';
import okNewPassword from '../../assets/images/ok-new-password.svg';
import { Button } from '../../components/Button';
import { CodeVerificationInput } from '../../components/CodeVerificationInput';
import FooterLoginAndRegister from '../../components/FooterLoginAndRegister';
import HeaderLoginAndRegister from '../../components/HeaderLoginAndRegister';
import { Input } from '../../components/Input';
import Spinner from '../../components/Spinner';
import { toast } from '../../components/Toast/Toast';
import * as AuthService from '../../services/Auth';
import {
  regexPasswordPhrase,
  regexPasswordValidation,
} from '../../utils/regexPasswordValidation';

import {
  Container,
  Box,
  CodeSecurityAndPasswordContainer,
  Password,
  ContainerInput,
  Content,
  ButtonBack,
} from './styles';
import { mappedErrors } from './utils/errorMessages';

const PasswordRecovery = () => {
  const [loading, setLoading] = useState(false);

  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);

  const [confirmCode, setConfirmCode] = useState('');

  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  const history = useHistory();

  function showStep2(event) {
    event.preventDefault();
    setLoading(true);

    AuthService.forgotPassword({
      email,
    })
      .then(() => {
        toast.info('Código de verificação enviado para o e-mail informado!');
        setStep2(true);
        setStep1(false);
      })
      .catch(() => {
        toast.error(
          'Ocorreu um erro ao buscar o email informado,por favor recarregue a página.',
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function showStep4() {
    setLoading(true);

    if (!confirmCode) {
      toast.error('Por favor, informe o código de verificação.');
      setLoading(false);
      return;
    }

    AuthService.confirmNewPassword({
      email,
      new_password: newPassword,
      new_password_confirm: newPasswordConfirm,
      code: confirmCode,
    })
      .then(() => {
        setStep1(false);
        setStep2(false);
        setStep3(true);
      })
      .catch(err => {
        const parsedErrorMessage = mappedErrors.find(
          option => option.error === err.response.data.message,
        );

        toast.error(
          parsedErrorMessage.message ||
            'Erro ao validar informações, tente novamente.',
          {
            autoClose: 7000,
          },
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function goToLogin() {
    history.push(`/logar`);
  }

  function goBackStep() {
    if (step1) {
      goToLogin();
    }
    if (step2) {
      setStep2(false);
      setStep1(true);
    }
  }

  return (
    <Container>
      <HeaderLoginAndRegister buttonText="QUERO SER ASSINANTE" />

      <Content>
        {!step3 && (
          <ButtonBack onClick={goBackStep} type="button">
            <img src={backArrow} alt="Voltar" />
            Voltar
          </ButtonBack>
        )}

        <form onSubmit={showStep2}>
          {step1 && (
            <Box>
              <h3>Recuperação de senha</h3>
              <p>Digite seu email cadastrado para encontrarmos sua conta.</p>

              <Input
                placeholder="Email"
                type="email"
                icon={<EmailIcon />}
                containerStyle={{ display: 'flex', flexDirection: 'row' }}
                onChange={event => setEmail(event.target.value)}
              />

              {loading ? (
                <Spinner />
              ) : (
                <Button
                  disabled={email.trim() === ''}
                  type="button"
                  title="CONTINUAR"
                  containerStyle={{
                    width: '397px',
                    backgroundColor: email.trim() === '' ? 'gray' : '#2F189C',
                    marginTop: '40px',
                  }}
                  onClick={showStep2}
                />
              )}
            </Box>
          )}

          {step2 && (
            <CodeSecurityAndPasswordContainer>
              <Box>
                <h3>Código de segurança</h3>
                <p>
                  Digite o código de segurança enviado para seu email.
                  <br />
                  Seu código possui 6 dígitos.
                </p>
                <Password>
                  <ContainerInput>
                    <CodeVerificationInput
                      onChange={e => setConfirmCode(e)}
                      values={confirmCode}
                    />
                  </ContainerInput>
                </Password>
              </Box>

              <Box>
                <h3>Crie sua nova senha</h3>
                <p>
                  Digite a sua nova senha.
                  <br />
                  {regexPasswordPhrase}
                </p>
                <Input
                  placeholder="Senha"
                  type="password"
                  autoComplete={false}
                  onFocus={() => setInvalidPassword(false)}
                  onBlur={() =>
                    !regexPasswordValidation.test(newPassword) &&
                    setInvalidPassword(true)
                  }
                  isInvalid={invalidPassword}
                  errorMessage={regexPasswordPhrase}
                  icon={<UnlockedIcon />}
                  containerStyle={{ display: 'flex', flexDirection: 'row' }}
                  onChange={event => setNewPassword(event.target.value)}
                />

                <Input
                  placeholder="Confirmar Senha"
                  isInvalid={newPassword !== newPasswordConfirm}
                  errorMessage="Senhas não conferem"
                  type="password"
                  autoComplete={false}
                  icon={<UnlockedIcon />}
                  containerStyle={{ display: 'flex', flexDirection: 'row' }}
                  onChange={event => setNewPasswordConfirm(event.target.value)}
                />

                {loading ? (
                  <Spinner />
                ) : (
                  <Button
                    type="button"
                    title="CONTINUAR"
                    containerStyle={{
                      width: '397px',
                      marginTop: '40px',
                      backgroundColor:
                        !newPassword || newPassword !== newPasswordConfirm
                          ? 'gray'
                          : '#2F189C',
                    }}
                    disabled={
                      !newPassword || newPassword !== newPasswordConfirm
                    }
                    onClick={showStep4}
                  />
                )}
              </Box>
            </CodeSecurityAndPasswordContainer>
          )}

          {step3 && (
            <Box>
              <h3>Sucesso</h3>
              <img src={okNewPassword} alt="Sucesso" />
              <p>Sua senha foi alterada com sucesso!</p>

              <Button
                type="button"
                title="VOLTAR PARA LOGIN"
                containerStyle={{
                  width: '397px',
                  color: 'red',
                }}
                onClick={goToLogin}
              />
            </Box>
          )}
        </form>
      </Content>
      <FooterLoginAndRegister />
    </Container>
  );
};

export default PasswordRecovery;
