import React from 'react';
import AvatarEditor from 'react-avatar-editor';

import Loading from '../../../../../assets/loading.svg';
import { Button } from '../../../../../components/Button';

const ImageCrop = ({
  imageSrc,
  onCrop,
  setEditorRef,
  scaleValue,
  onScaleChange,
  onCancel,
  loading,
}) => {
  return (
    <div>
      <AvatarEditor
        image={imageSrc}
        scale={scaleValue}
        ref={setEditorRef}
        width={140}
        height={140}
        border={0}
        color={[255, 255, 255, 0.8]}
      />

      {loading && <img src={Loading} style={{ marginTop: 12 }} alt="Loading" />}

      {!loading && (
        <>
          <input
            type="range"
            value={scaleValue}
            min="1"
            max="10"
            onChange={onScaleChange}
          />

          <Button title="Salvar" onClick={onCrop}>
            Salvar
          </Button>
          <button
            onClick={() => {
              onCancel(false);
            }}
          >
            Cancelar
          </button>
        </>
      )}
    </div>
  );
};

export default ImageCrop;
