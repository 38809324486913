import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from '../../components/Button';
import { CodeVerificationInput } from '../../components/CodeVerificationInput';
import FooterLoginAndRegister from '../../components/FooterLoginAndRegister';
import HeaderLoginAndRegister from '../../components/HeaderLoginAndRegister';
import Spinner from '../../components/Spinner';
import { toast } from '../../components/Toast/Toast';
import { useAuth } from '../../hooks/auth';
import * as AuthService from '../../services/Auth';

import { Container, Box, Password, ContainerInput, Content } from './styles';

const ConfirmAccount = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const [time, setTime] = useState(60);
  const [confirmCode, setConfirmCode] = useState('');

  const { state } = useLocation();
  const history = useHistory();
  const { storeUser } = useAuth();

  function goToSelectPlan() {
    history.push('/registro/selecionar-plano');
  }

  function checkCode() {
    setLoading(true);
    AuthService.confirmAccount({
      email: email || state?.redirectEmail,
      code: confirmCode,
    })
      .then(res => {
        setLoading(false);
        storeUser(null, res.data.result);
        localStorage.removeItem('@HaluGamashi:email');
        goToSelectPlan();
      })
      .catch(error => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  }

  function resendCode() {
    AuthService.resendCode({
      email: email || state?.redirectEmail,
    })
      .then(response => {
        setTime(60);
        toast.success(response.data.message);
      })
      .catch(error => {
        toast.error(error.response.data.message);
      });
  }

  function timer() {
    return setTimeout(() => {
      const currentTime = time - 1;
      setTime(currentTime);
    }, 1000);
  }

  useEffect(() => {
    const timeout = timer();

    if (time === 0) {
      clearTimeout(timeout);
    }
  });

  useEffect(() => {
    const userEmail = localStorage.getItem('@HaluGamashi:email');
    if (!userEmail && !state?.redirectEmail) {
      history.push('/logar');
    }

    setEmail(userEmail);
  }, [history, state?.redirectEmail]);

  return (
    <Container>
      <HeaderLoginAndRegister buttonText="JÁ SOU ASSINANTE" />

      <Content>
        <form>
          <Box>
            <h3>Confirmação de email</h3>

            <p>
              Digite o código de segurança enviado para <b>{email}</b> . Seu
              código possui 6 dígitos.
            </p>

            <Password>
              <ContainerInput>
                <CodeVerificationInput
                  onChange={e => setConfirmCode(e)}
                  values={confirmCode}
                />
              </ContainerInput>
            </Password>

            {time !== 0 && (
              <p>
                Ainda não recebeu o email? Aguarde
                <span
                  style={{
                    color: '#2F189C',
                    fontWeight: '600',
                    paddingLeft: '5px',
                  }}
                >
                  {time}
                </span>
              </p>
            )}
            {time === 0 && (
              <button
                type="button"
                aria-hidden="true"
                onClick={resendCode}
                className="resend"
              >
                Reenviar código
              </button>
            )}

            {loading ? (
              <div className="spinner">
                <Spinner />
              </div>
            ) : (
              <Button
                title="CONTINUAR"
                disabled={!confirmCode}
                containerStyle={{
                  width: '397px',
                  marginTop: '40px',
                  backgroundColor: !confirmCode ? 'gray' : '#2F189C',
                }}
                type="button"
                onClick={checkCode}
              />
            )}
          </Box>
        </form>
      </Content>
      <FooterLoginAndRegister />
    </Container>
  );
};

export default ConfirmAccount;
