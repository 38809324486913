import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const WYSIWYGEditor = ({ setTextPreview, onChange, value }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (!updated) {
      const defaultValue = value || '';
      const blocksFromHtml = htmlToDraft(defaultValue);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap,
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  }, [value, updated]);

  const onEditorStateChange = edState => {
    setUpdated(true);
    setEditorState(edState);
    setTextPreview(draftToHtml(convertToRaw(edState.getCurrentContent())));

    return onChange(draftToHtml(convertToRaw(edState.getCurrentContent())));
  };

  return (
    <>
      <div className="editor">
        <Editor
          spellCheck
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          placeholder="Descrição"
          textAlignment="start"
          locale="pt"
          toolbar={{
            options: [
              'inline',
              'blockType',
              'list',
              'textAlign',
              'link',
              'emoji',
              'remove',
              'history',
            ],
            inline: {
              options: ['bold', 'italic', 'underline', 'strikethrough'],
            },
          }}
        />
      </div>
    </>
  );
};

export default WYSIWYGEditor;
