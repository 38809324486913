import React from 'react';
import { useHistory } from 'react-router-dom';

import LogoImg from '../../assets/icons/logo-small.svg';

import { Container, Box } from './styles';

const HeaderLoginAndRegister = ({ buttonText, buttonSubText, className }) => {
  const history = useHistory();

  function handleNavigate() {
    if (buttonText === 'JÁ SOU ASSINANTE') {
      history.push('/logar');
    }
    if (buttonText === 'JÁ SOU' || buttonSubText === 'ASSINANTE') {
      history.push('/logar');
    }

    if (buttonText === 'QUERO SER ASSINANTE') {
      history.push('/registro');
    }
    if (buttonText === 'QUERO') {
      history.push('/registro');
    }
  }

  function handleGoToLading() {
    history.push('/');
  }

  return (
    <Container className={className}>
      <Box>
        <img
          src={LogoImg}
          alt="IMA Logo"
          onClick={handleGoToLading}
          onKeyPress={handleGoToLading}
          aria-hidden="true"
        />
      </Box>
      <button onClick={handleNavigate}>
        <h3>{buttonText}</h3>
        <h3>{buttonSubText}</h3>
      </button>
    </Container>
  );
};

export default HeaderLoginAndRegister;
