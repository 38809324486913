import { isPast } from 'date-fns';
import * as yup from 'yup';

export const paymentYupValidation = yup.object().shape({
  name: yup.string().required('Este campo é obrigatório'),
  document: yup.string().required('Este campo é obrigatório'),
  cardNumber: yup
    .string()
    .required('Este campo é obrigatório')
    .matches(/[(\d{4}\s)]{4}/, 'Formato de cartão inválido'),
  expirationDate: yup
    .string()
    .required('Este campo é obrigatório')
    .matches(/(\d{2})\/(\d{4})/, 'Digite no seguinte formato: 12/2048')
    .test(
      'validate-date',
      () => 'Data inválida',
      value => {
        if (!value) return false;
        const month = value.split('/')[0];
        const year = value.split('/')[1];

        return !isPast(new Date(`${month}-30-${year}`));
      },
    ),
  cvv: yup
    .string()
    .required('Este campo é obrigatório')
    .matches(/\d{3}/, 'Digite apenas 3 números'),
  installments: yup.string().required('Parcela é obrigatória'),
  coupon: yup.string().matches(/^(\w){8,}$/, 'Cupom Invalido'),
});

export const changePaymentYupValidation = yup.object().shape({
  name: yup.string().required('Este campo é obrigatório'),
  document: yup.string().required('Este campo é obrigatório'),
  cardNumber: yup
    .string()
    .required('Este campo é obrigatório')
    .matches(/[(\d{4}\s)]{4}/, 'Formato de cartão inválido'),
  expirationDate: yup
    .string()
    .required('Este campo é obrigatório')
    .matches(/(\d{2})\/(\d{4})/, 'Digite no seguinte formato: 12/2048')
    .test('validate-date', 'Data inválida', value => {
      if (!value) return false;
      const month = value.split('/')[0];
      const year = value.split('/')[1];

      return !isPast(new Date(`${month}-29-${year}`));
    }),
  cvv: yup
    .string()
    .required('Este campo é obrigatório')
    .matches(/\d{3}/, 'Digite apenas 3 números'),
  coupon: yup.string().matches(/^(\w){8,}$/, 'Cupom Invalido'),
});

export const paymentYupValidationBankSlip = yup.object().shape({
  cpf: yup.string().required('Este campo é obrigatório'),
  street: yup.string().required('Este campo é obrigatório'),
  number: yup.string().required('Este campo é obrigatório'),
  additional_details: yup.string().required('Este campo é obrigatório'),
  zipcode: yup
    .string()
    .test(
      'is-zipcode-valid',
      () => 'Não é um CEP válido',
      value => {
        if (!value) return false;

        const parsedValue = value
          .replace('_', '')
          .replace('-', '')
          .replace(' ', '');

        return parsedValue.length >= 8;
      },
    )
    .required('Este campo é obrigatório'),
  neighborhood: yup.string().required('Este campo é obrigatório'),
  city: yup.string().required('Este campo é obrigatório'),
  state: yup.string().required('Este campo é obrigatório'),
  coupon: yup.string().matches(/^(\w){8,}$/, 'Cupom Invalido'),
});

export const paymentYupValidationGeneric = yup.object().shape({});
