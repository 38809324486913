import React from 'react';

import { Container } from './styles';

const Spacer = () => (
  <Container>
    <div />
  </Container>
);

export default Spacer;
