import React from 'react';

import bannerTimeOfAscension from '../../../../assets/images/banner-time-of-ascension.png';

import { AscendHomeContainer, AscendHomeContent } from './style';

const AscendHome = () => {
  return (
    <AscendHomeContainer background={bannerTimeOfAscension}>
      <AscendHomeContent className="max-content">
        <h2>É TEMPO DE ASCENSÃO.</h2>

        <p>
          É tempo de aprender a conectar com nosso guia-interior, o supra
          mental, com as energias sutis de crescimento, de aprender a trazer
          memórias do Mundo Espiritual.
        </p>

        <h3>É TEMPO DE FLUIR, EXPANDIR, ASCENDER.</h3>
      </AscendHomeContent>
    </AscendHomeContainer>
  );
};

export default AscendHome;
