import api from './api';

export function getCertificates() {
  return api.get(`/certificates?relations[]=content`);
}

export function getCertificate(certificateId) {
  return api.get(
    `/certificates/${certificateId}?relations[]=content&client_relations[]=user`,
  );
}

export function postCertificate(contentId) {
  return api.post(`/certificates/`, { content_id: contentId });
}

export function findCertificate(contentId) {
  return api.get(`/certificates/${contentId}/content`);
}
