import styled from 'styled-components';

const Container = styled.div`
  margin: 0 -9999px;
  padding: 0 9999px;

  padding-top: 72px;
  padding-bottom: 72px;
  margin-bottom: 63px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;
  padding-top: 48px;
  color: var(--default-font-black);
  background: #fff;

  .title {
    display: flex;
    gap: 16px;
    align-items: flex-end;
    padding: 0 8px;

    a,
    * {
      color: var(--default-blue);
    }

    h2 {
      line-height: 90%;
      font-size: 1.6em;
    }

    a {
      font-size: 0.9em;
      font-weight: 500;
      text-align: center;
    }
  }

  @media (min-width: 470px) {
    div.title {
      > h2 {
        font-size: 2em;
      }
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 48px;

  .title {
    display: flex;
    gap: 16px;
    align-items: flex-end;
    padding: 0 8px;
    margin-bottom: 2.5rem;

    a,
    * {
      color: var(--default-blue);
    }

    h2 {
      line-height: 90%;
      font-size: 1.6em;
    }

    a {
      font-size: 0.9em;
      font-weight: 500;
      text-align: center;
    }
  }

  > button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 8px 32px;
    background: var(--default-blue);
    border-radius: 24px;
    height: 48px;
    max-width: 400px;
    margin: 0 auto;

    font-size: 0.9em;
    font-weight: 600;
    color: white;
  }

  @media (min-width: 470px) {
    div.title {
      > h2 {
        font-size: 2em;
      }
    }

    > button {
      align-self: center;
      width: 100%;
      font-size: 1.2rem;
    }
  }

  @media (min-width: 500px) {
    justify-content: center;
  }

  @media (min-width: 800px) {
    flex-direction: row;
  }
`;

const HomeAboutHaluLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  flex: 1;

  p {
    padding: 0 16px;
    font-size: 1.2em;
    font-weight: 500;
    line-height: 1.8em;
    text-align: center;
  }

  > button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 8px 32px;
    background: var(--default-blue);
    border-radius: 24px;
    height: 48px;
    max-width: 400px;
    margin: 1rem auto 0;

    font-size: 0.9em;
    font-weight: 600;
    color: white;
  }

  @media (min-width: 800px) {
    order: 1;
    padding-right: 48px;

    > button {
      margin: 2rem 16px 0;
    }

    p {
      text-align: start;
    }
  }
`;

const HomeAboutHaluRightContent = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 100%;
  position: relative;
  margin: 0 auto;
  margin-top: 37px;
  flex-basis: 300px;

  div:nth-child(1) {
    position: absolute;
    top: 86px;
    right: 20px;
    z-index: 1;
    width: 381px;
    height: 450px;
    border: 4px solid rgba(47, 24, 156, 0.5);
    box-sizing: border-box;
    border-radius: 20px;

    width: 181px;
    height: 190px;
    top: 17px;
    right: 100px;
  }

  div:nth-child(2) {
    background-color: ${({ theme }) => theme.colors.secondary};
    width: 252px;
    height: 350px;
    border-radius: 20px;
    position: absolute;
    top: 20px;
    right: 125px;
    z-index: 2;

    height: 190px;
    width: 210px;
    top: -15px;
    right: 60px;
  }

  img {
    margin-right: 0;
    position: relative;
    width: 255px;
    height: 217px;
    z-index: 3;
  }

  @media (min-width: 800px) {
    div:nth-child(1) {
      height: 460px;
      top: 50px;
      right: 200px;
    }

    div:nth-child(2) {
      height: 250px;
      width: 260px;
      top: -20px;
      right: 100px;
    }

    img {
      width: auto;
      height: auto;
    }

    order: 2;
  }
`;

export {
  Container,
  ContentContainer,
  HomeAboutHaluLeftContent,
  HomeAboutHaluRightContent,
};
