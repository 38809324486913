import styled from 'styled-components';

import iconNavUp from '../../../assets/icons/icon-nav-up.svg';
import iconNavDown from '../../../assets/icons/iconNavDown.svg';
import iconLock from '../../../assets/icons/lock.svg';

export const Menu = styled.div`
  margin-bottom: 15px;
`;

export const MenuTitle = styled.div`
  display: flex;
  min-width: 100%;
  width: 100%;
  background-color: #ffffff;
  cursor: pointer;
  padding: 20px 20px 0 20px;
  > h2 {
    flex: 1;
    font-weight: bold;
    color: #25282b;
    font-size: 20px;
  }

  img {
    color: whitesmoke;
    margin-left: 80px;
    @media (max-width: 480px) {
      margin-left: 10px;
    }
  }

  span {
    cursor: pointer;
    width: 20px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 6px;
    position: relative;
    left: 6px;
  }

  span.inactive {
    background-image: url(${iconNavDown});
  }

  span.active {
    background-image: url(${iconNavUp});
  }

  span.locked {
    cursor: not-allowed;
    background-image: url(${iconLock});
  }
`;

export const MenuItems = styled.ul`
  /* max-height: 320px; */
  overflow-y: auto;
  display: ${props => (props.active ? 'flex' : 'none')};
  flex-direction: column;

  ::-webkit-scrollbar {
    width: 6px;
    height: 5px;
    max-height: 5px !important;
    overflow: hidden;
  }

  ::-webkit-scrollbar-track {
    background: inherit;
    max-height: 5px !important;
    overflow: hidden;
    height: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #2f189c;
    border-radius: 20px;
    max-height: 5px !important;
    overflow: hidden;
    height: 5px;
  }
`;

export const MenuItem = styled.div`
  cursor: pointer;
  padding: 16px 0 0 36px;
  background: ${props => (props.active ? '#f5f8fa' : 'none')};
  :hover {
    background: #f5f8fa;
  }
  position: relative;
  ::before {
    ${props =>
      props.active &&
      `content: '';
    background: #2F189C;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 6px;`};
  }
`;

export const MenuItemTitle = styled.div`
  display: flex;
  @media (max-width: 480px) {
    height: 100%;
    display: flex;
    align-items: center;
  }

  a {
    width: 100%;
    display: flex;
    text-decoration: none;

    img {
      padding-right: 8px;
      @media (max-width: 480px) {
        padding: 0;
      }
    }
  }
  .lesson-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #25282b;
    margin: 6px 0;
  }
  div {
    display: flex;
    @media (max-width: 480px) {
      height: 100%;
      min-height: 100%;
      max-height: 100%;
      flex-direction: column;
    }

    img {
      padding: 0 8px 0 0;
      @media (max-width: 1100px) {
        width: 24px;
        padding: 0;
        margin: 0;
      }
    }
  }
`;

export const MenuItemTime = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #657787;
  padding-left: 35px;
  padding-bottom: 16px;
  padding-top: 6px;

  p {
    @media (max-width: 480px) {
      padding: 6px 0 10px 0;
    }
  }

  span {
    @media (max-width: 480px) {
      padding: 6px 0 10px 0;
    }
  }
`;
