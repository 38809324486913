import React from 'react';
import { useHistory } from 'react-router-dom';

import CheckImg from '../../../../assets/icons/check-circle.svg';
import { Button } from '../../../../components/Button';

import { Container, ThanksContainer } from './styles';

const ThanksRegister = () => {
  const history = useHistory();

  const handleGoToLogin = () => {
    history.push('/logar');
  };

  return (
    <Container>
      <ThanksContainer>
        <img src={CheckImg} alt="Ícone de confirmação" />
        <h1>Parabéns, seu cadastro foi concluído com sucesso!</h1>
        <h2>
          Agora começa sua jornada conosco, aproveite todo nosso conteúdo!
        </h2>

        <Button title="ACESSAR CONTA" onClick={handleGoToLogin} />
      </ThanksContainer>
    </Container>
  );
};

export default ThanksRegister;
