import React from 'react';
import { useHistory, Link } from 'react-router-dom';

import backArrow from '../../assets/icons/icon-back-breadcrumb-mobile.png';
import Breadcrumbs from '../../components/Breadcrumbs';

import {
  Container,
  Content,
  Header,
  Overlay,
  BreadcrumbMobile,
} from './styles';

const NotificationDetails = () => {
  const history = useHistory();
  const notification = history?.location?.state?.notificationData;

  return (
    <Container>
      <Header>
        <Overlay>
          <Breadcrumbs
            className="hidden-mobile"
            style={{ paddingLeft: '30px', paddingBottom: '10px' }}
            links={[{ name: 'Notificação' }]}
          />
          <BreadcrumbMobile className="hidden-desktop">
            <Link to="/home">
              <img src={backArrow} alt="Voltar" />
              <p>Início</p>
            </Link>
          </BreadcrumbMobile>
          <h1>
            Notificação
            <span>Notificação</span>
          </h1>
        </Overlay>
      </Header>

      <Content>
        <h2>{notification.title}</h2>
        <p>{notification.description}</p>
      </Content>
    </Container>
  );
};

export default NotificationDetails;
