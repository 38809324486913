import styled from 'styled-components';

export const Container = styled.div``;

export const BackgroundLogged = styled.div`
  min-height: 400px;
  max-height: 400px;
  @media (max-width: 560px) {
    max-width: 100%;
    object-fit: contain;
    min-height: unset;
  }

  img {
    width: 100%;
    min-height: 400px;
    max-height: 400px;

    object-fit: cover;

    @media (max-width: 560px) {
      height: 250px;
      min-height: 250px;
      max-height: 250px;
    }
  }

  span {
    min-height: 400px;
    max-height: 400px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: linear-gradient(
      90deg,
      rgba(89, 74, 173, 0.83) -5.53%,
      rgba(159, 151, 201, 0.3818) 60.04%,
      rgba(47, 24, 156, 0.83) 131.32%
    );
    @media (min-height: 900px) {
      height: 438.7px;
    }
    @media (max-width: 560px) {
      height: 250px;
      min-height: 250px;
      max-height: 250px;
    }
  }
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding-top: 45px;
  position: absolute;
  top: 80px;
  left: 50px;

  div {
    display: flex;
    align-items: center;

    span {
      cursor: pointer;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      margin-left: 16px;
    }
  }

  h1 {
    font-size: 100px;
    line-height: 120px;
    color: #ffffff;
    padding-bottom: 18px;
    padding-top: 48px;
    padding-left: 47px;
    text-shadow: 0 2px 4px rgba(24, 39, 75, 1), 0 4px 4px rgba(24, 39, 75, 0.08);
  }

  h2 {
    font-weight: bold;
    font-size: 100px;
    line-height: 140px;
    color: #2f189c;
    mix-blend-mode: multiply;
    opacity: 0.1;
    text-shadow: 0 2px 4px rgba(24, 39, 75, 0.12),
      0 4px 4px rgba(24, 39, 75, 0.08);
    position: absolute;
    left: 70px;
    bottom: 70px;
    width: 100%;
  }

  @media (max-width: 1024px) {
    h1 {
      font-weight: 700;
      font-size: 31px;
      line-height: 44px;
    }

    p {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
    }
  }

  @media (max-width: 560px) {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;

    div {
      display: flex;
      align-items: center;

      position: absolute;
      left: -24px;
      top: 40px;

      span {
        margin-top: -6px;
      }
    }

    h1 {
      padding: 0;
      font-size: 40px;
      font-weight: bold;
      letter-spacing: 0.05em;

      margin: 112px 0 0 -24px;
    }
  }
`;

export const AboutTextAboutIma = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 100px;
  padding: 0;
  @media (max-width: 560px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 16px 16px 16px;
    background: #f3f9fb;
    margin-bottom: 10px;
  }
  article {
    h1 {
      padding: 80px 24px 40px;
      @media (max-width: 480px) {
        padding: 0;
        max-width: 100%;
      }
    }
  }

  h1 {
    font-family: Lato, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: #25282b;
    padding-bottom: 40px;
    @media (max-width: 560px) {
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      padding: 0 0 24px 0;
      margin: 0;
      text-align: center;
    }
  }

  article {
    div {
      div {
        iframe {
          border-radius: 20px;
        }
      }
    }

    div {
      ul {
        margin-top: 10px;
        li {
          color: ${props => props.theme.colors.text};
          font-family: Lato, Arial, sans-serif;
          font-size: 18px;
          font-weight: 300;
          line-height: 28px;
          margin-left: 20px;
        }
      }
      p {
        color: ${props => props.theme.colors.text};
        font-family: Lato, Arial, sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        margin-top: 10px;
      }
    }
  }
`;

export const Video = styled.div`
  max-width: 1064px;
  width: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;

  margin: 0 auto;
  @media (max-width: 560px) {
    padding-bottom: 30px;
    width: 100%;
  }
  iframe {
    border-radius: 20px;
  }
`;

export const TextInfo = styled.div`
  max-width: 1064px;
  width: 100%;

  padding: 0 24px;

  ul {
    padding-left: 20px;
  }
`;

export const AboutTitleMobile = styled.div`
  padding-bottom: 40px;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
`;
