import React, { lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';

import HeaderLogged from '../components/HeaderLogged';
import NewFooterWithIconsLogged from '../components/NewFooterWithIconsLogged';
import Spinner from '../components/Spinner';
import UnloggedLayout from '../layouts/unlogged';
import ConfirmRegister from '../new-pages/ConfirmRegister';
import NewHome from '../new-pages/Home';
import AboutHaluGamashi from '../pages/AboutHaluGamashi';
import AboutHaluGamashiLogged from '../pages/AboutHaluGamashiLogged';
import AboutIma from '../pages/AboutIma';
import AboutImaLogged from '../pages/AboutImaLogged';
import CompleteRegistration from '../pages/CompleteRegistration';
import ConfirmAccount from '../pages/ConfirmAccount';
import Courses from '../pages/Courses';
import CoursesDetail from '../pages/CoursesDetail';
import { FAQ } from '../pages/FAQ';
import Favorites from '../pages/Favorites';
import Finalized from '../pages/Finalized';
import Forum from '../pages/Forum';
import CreateTopic from '../pages/Forum/CreateTopic';
import EditTopic from '../pages/Forum/EditTopic';
import Section from '../pages/Forum/Section';
import Topic from '../pages/Forum/Topic';
import HistoryNotifications from '../pages/HistoryNotifications';
import HomeLogged from '../pages/HomeLogged';
import InProgress from '../pages/InProgress';
import Live from '../pages/Live';
import Login from '../pages/Login';
import MyCourseDetail from '../pages/MyCourseDetail';
import MyCourses from '../pages/MyCourses';
import NotificationDetails from '../pages/NotificationDetails';
import PasswordRecovery from '../pages/PasswordRecovery';
import PaymentPlan from '../pages/PaymentPlan';
import Plans from '../pages/Plans';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import PrivacyPolicyLogged from '../pages/PrivacyPolicyLogged';
import Profile from '../pages/Profile';
import Register from '../pages/Register';
import ThanksRegister from '../pages/Register/ThanksRegister';
import Retreats from '../pages/Retreats';
import Search from '../pages/Search';
import SelectPlan from '../pages/SelectPlan';
import TermsOfUse from '../pages/TermsOfUse';
import TermsOfUseLogged from '../pages/TermsOfUseLogged';

import Route from './Route';

const Certificates = lazy(() => import('../components/Certificates'));

const PrivateRoutes = () => {
  return (
    <>
      <HeaderLogged />
      <Switch>
        <Route isPrivate path="/home" component={HomeLogged} />
        <Route isPrivate path="/cursos" component={Courses} />
        <Route isPrivate path="/favoritos" component={Favorites} />

        <Route isPrivate path="/finalizados" component={Finalized} />

        <Route isPrivate path="/em-andamento" component={InProgress} />
        <Route isPrivate path="/pesquisa" component={Search} />
        <Route
          isPrivate
          path="/sobre-halu"
          component={AboutHaluGamashiLogged}
        />
        <Route isPrivate path="/sobre-o-ima" component={AboutImaLogged} />
        <Route
          isPrivate
          path="/leia-os-termos-de-uso"
          component={TermsOfUseLogged}
        />
        <Route
          isPrivate
          path="/politica-de-privacidade"
          component={PrivacyPolicyLogged}
        />
        <Route
          isPrivate
          path="/curso-detalhe/:uuid"
          exact
          component={CoursesDetail}
        />
        <Route
          isPrivate
          path="/curso/detalhe/live/:uuid"
          exact
          component={CoursesDetail}
        />
        <Route isPrivate path="/meus-cursos" component={MyCourses} />
        <Route
          isPrivate
          path="/curso/:uuid/:parentUuid"
          exact
          component={MyCourseDetail}
        />
        <Route
          isPrivate
          path="/curso/:uuid/:parentUuid/exam"
          exact
          component={MyCourseDetail}
        />
        <Route isPrivate path="/vivencias" exact component={Retreats} />
        <Route path="/perfil" exact component={Profile} />

        <Route path="/forum" exact component={Forum} />
        <Route path="/secao/:sectionId" exact component={Section} />
        <Route path="/secao/topico/:topicId" exact component={Topic} />
        <Route path="/criar-topico" exact component={CreateTopic} />
        <Route path="/editar-topico/:topic_id" exact component={EditTopic} />
        <Route
          path="/notificacao/:notificationId"
          exact
          component={NotificationDetails}
        />
        <Route
          path="/history-notifications"
          exact
          component={HistoryNotifications}
        />
      </Switch>
      <NewFooterWithIconsLogged />
    </>
  );
};

const Routes = () => (
  <Suspense fallback={<Spinner />}>
    <Switch>
      <Route path="/" exact>
        <UnloggedLayout>
          <NewHome />
        </UnloggedLayout>
      </Route>
      <Route path="/logar" exact component={Login} />
      <Route path="/registro" exact component={Register} />
      <Route
        path="/registro/concluir-cadastro"
        exact
        component={CompleteRegistration}
      />
      <Route path="/registro/selecionar-plano" exact component={SelectPlan} />
      <Route path="/registro/pagamento/:planId" exact component={PaymentPlan} />
      <Route
        path="/registro/obrigado-por-registrar"
        exact
        component={ThanksRegister}
      />
      <Route
        path="/registro/confirmar-conta"
        exact
        component={ConfirmAccount}
      />
      <Route path="/perfil/selecionar-plano" exact component={Plans} />
      <Route path="/sobre-halu-gamashi" exact component={AboutHaluGamashi} />
      <Route path="/sobre-ima" exact component={AboutIma} />
      <Route path="/recuperar-senha" exact component={PasswordRecovery} />
      <Route path="/politica-privacidade" exact component={PrivacyPolicy} />
      <Route path="/termos-de-uso" exact component={TermsOfUse} />
      <Route path="/faq" exact component={FAQ} />
      <Route
        isPrivate
        path="/certificado/:uuid"
        exact
        component={Certificates}
      />
      <Route path="/confirmar-cadastro" exact component={ConfirmRegister} />

      {/* TODO Está aqui pois não deve possuir o cabeçalho logado */}
      <Route isPrivate path="/live-chat/:liveId" exact component={Live} />

      {/* <Route path="/" exact component={UnderMaintenance} />
    <Route component={UnderMaintenance} /> */}
      <PrivateRoutes />
    </Switch>
  </Suspense>
);

export default Routes;
