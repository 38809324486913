import React from 'react';
import { Link } from 'react-router-dom';

import logoFooter from '../../assets/images/logo-footer.png';

import {
  ContainerFooter,
  ContentFooterTop,
  LeftFooter,
  RightFooter,
  ContentFooterBottom,
} from './styles';

const FooterUnlogged = () => {
  return (
    <ContainerFooter>
      <ContentFooterTop>
        <LeftFooter>
          <img src={logoFooter} alt="Logo Halu Gamashi Footer" />
        </LeftFooter>

        <RightFooter>
          <Link to="/termos-de-uso" className="hidden-mobile">
            Termos de Uso
          </Link>
          <Link to="/politica-privacidade" className="hidden-mobile">
            Política de Privacidade
          </Link>
          <Link to="/faq" className="hidden-mobile">
            FAQ
          </Link>
        </RightFooter>
      </ContentFooterTop>
      <hr className="hidden-mobile" />
      <ContentFooterBottom>
        <p>
          &copy; Copyright {new Date().getFullYear()} - Todos direitos
          reservados.
        </p>
      </ContentFooterBottom>
    </ContainerFooter>
  );
};

export default FooterUnlogged;
