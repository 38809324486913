import styled from 'styled-components';

import { theme } from '../../styles/theme';

export const Container = styled.div`
  width: 100%;
  background: #f3f9fb;
  p {
    font-weight: 700;
    color: ${theme.colors.text};
    margin: 26px 0;
  }

  ul {
    list-style: none;
  }

  li {
    padding: 4px;
    margin: 4px 0;
  }

  .correct {
    background: rgba(1, 183, 2, 0.1);
    font-weight: 700;
  }
  .incorrect {
    background: rgba(239, 50, 39, 0.1);
  }

  input {
    margin-right: 10px;
    position: relative;
    cursor: pointer;
  }

  .unSelected {
    opacity: 0.8;
    color: gray;
  }

  input[type='radio']:checked + label {
    font-weight: 600;
    color: ${theme.colors.secondary};
  }

  input:checked:before {
    border: 2px solid ${theme.colors.secondary};
  }

  input:checked:after {
    position: absolute;
    content: '';
    width: 7px;
    height: 7px;
    top: 1px;
    left: 1px;
    background-color: ${theme.colors.secondary};
    background-size: contain;
    border: 2px solid ${theme.colors.secondary};
    border-radius: 6px;
  }

  input:not(:checked):disabled {
    opacity: 0.5;
  }

  textarea {
    width: 100%;
    min-width: 280px;
    max-width: 900px;
    margin-bottom: 50px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    height: 180px;
    padding: 10px;
    resize: none;
  }

  @media (min-width: 1101px) {
    padding: 40px 60px;
  }
  @media (max-width: 1100px) {
    padding: 40px 20px;
  }
`;

export const EndMessageFeedback = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  svg {
    margin-right: 8px;
  }

  h2 {
    font-size: 1rem;
    font-weight: 500;
    color: ${theme.colors.secondary};
  }
`;

export const Questions = styled.div`
  margin-bottom: 40px;
  margin-top: ${({ endExam }) => (endExam ? '16' : '37')}px;
`;

export const DisapprovedBox = styled.div`
  display: flex;
  margin: 1rem 0;
  align-items: center;
  p {
    color: #ef3227;
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
    line-height: 22px;
    svg {
      position: relative;
      top: 4px;
    }
  }
`;

export const ApprovedBox = styled.div`
  display: flex;
  margin: 1rem 0;
  align-items: center;
  p {
    color: #594aad;
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 22px;
    svg {
      position: relative;
      top: 4px;
    }
  }
  span {
    font-weight: 700;
  }
`;

export const ButtonStartContainer = styled.div`
  margin-top: 1rem;
`;
