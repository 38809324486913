import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 36px 24px;
  background-color: #fff;
  svg {
    cursor: pointer;
  }
  @media (max-width: 768px) {
    margin-top: 8px;
    padding: 24px 8px;
  }

  border-radius: 4px;
`;

export const Content = styled.div`
  width: 100%;
  margin-left: 1rem;
`;

export const Question = styled.h3`
  font-weight: 600;
  font-size: 18px;
  color: rgb(44, 52, 59);

  @media (max-width: 768px) {
    font-size: var(--font-regular);
  }

  ::first-letter {
    text-transform: uppercase;
  }
`;

export const Answer = styled.p`
  font-size: 16px;
  max-width: 80%;
  color: #555;
  margin-top: 1rem;

  @media (max-width: 768px) {
    max-width: 95%;
    font-size: var(--font-regular);
  }

  ::first-letter {
    text-transform: uppercase;
  }
`;
