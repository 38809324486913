import styled from 'styled-components';

import { theme } from '../../../../styles/theme';

export const Overlay = styled.div`
  &:before {
    content: '';
    display: block;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 400;
  }
`;

export const Container = styled.div`
  display: block;
  flex-direction: column;
  background: ${theme.colors.light};
  padding: 50px 0 40px 0;
  z-index: 500;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  overflow: auto;
  max-height: 95vh;
  position: fixed;
  top: 50%;
  left: 50%;
  border-bottom: 5px solid ${theme.colors.light};
  border-top: 5px solid ${theme.colors.light};

  > svg {
    align-self: center;
    width: 100%;
  }

  h1 {
    align-self: center;
    text-align: center;
    font-size: 1.8rem;
    color: ${theme.colors.secondary};
    margin: 30px 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.secondary};
    border: 4px solid transparent;
    border-radius: 8px;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    background: ${theme.colors.shapeSecondary};
    border-radius: 10px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  h6 {
    margin: 40px auto;
    text-align: center;
    font-size: 1.3rem;
    color: ${theme.colors.secondary};
  }

  @media (min-width: 1101px) {
    max-width: 90%;
    min-width: 680px;
    transform: translate(-50%, -50%);
    border-radius: 20px;
  }

  @media (max-width: 1100px) {
    width: 100%;
    padding: 0 20px 80px;
    transform: translate(-50%, -46%);
    border-radius: 20px 20px 0 0;
  }
`;

export const PurchaseDetails = styled.div`
  background: ${theme.colors.shape};
  padding: 25px 35px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  svg {
    margin: 0 10px;
  }

  @media (max-width: 680px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
`;

export const ProductInfo = styled.div`
  p {
    font-weight: 500;
    font-size: 0.9rem;
  }

  h5 {
    margin: 0;
    padding: 0;
    color: ${theme.colors.secondary};
    font-size: 2rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 250px;
  }

  h4 {
    margin: 0;
    padding: 0;
    color: ${theme.colors.text};
    font-size: 2rem;
    font-weight: 500;
  }

  h3 {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: 680px) {
    margin: 16px 0;
  }
`;

export const ProductInfoFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    color: ${theme.colors.textSecondary};
    font-weight: 500;

    > span {
      margin-left: 8px;
      color: ${theme.colors.secondary};
    }

    svg ~ span {
      margin-left: 0;
      color: ${theme.colors.warning};
    }
  }

  p ~ p {
    svg {
      width: 16px;
      height: 16px;
    }

    font-size: 0.9rem;
  }

  @media (max-width: 690px) {
    p {
      font-size: 0.9rem;
    }

    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ProductPrice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 24px;

  p {
    font-size: 0.9rem;
    color: ${theme.colors.textSecondary};
  }

  h4 {
    font-size: 2rem;
  }

  @media (max-width: 690px) {
    h4 {
      font-size: 1rem;
    }
  }
`;

export const PaymentForm = styled.div`
  h5 {
    margin-top: 50px;
    margin-left: 40px;
    margin-bottom: 32px;
    text-align: ${storedCard => (storedCard ? 'left' : 'center')};
    font-size: 1rem;
    color: ${theme.colors.secondary};
    display: flex;
    align-items: center;
    justify-content: ${storedCard => (storedCard ? 'flex-start' : 'center')};

    svg {
      display: ${({ storedCard }) => (storedCard ? 'block' : 'none')};
      margin-right: 20px;
      cursor: pointer;
    }
  }
`;

export const FormInputs = styled.div`
  label {
    cursor: pointer;
  }

  input[type='checkbox'] {
    cursor: pointer;
    margin-right: 8px;
  }
  @media (min-width: 1101px) {
    padding: 0 40px 36px;
  }
`;

export const RowInputs = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 26px;
`;

export const FormButtons = styled.div`
  display: flex;
  justify-content: space-around;
  border-top: 1px solid ${theme.colors.shapeSecondary};
  padding-top: 30px;

  @media (max-width: 690px) {
    flex-direction: column;
    align-items: center;

    div:first-of-type {
      display: none;
    }
  }
`;

export const SavedCardsList = styled.ul`
  list-style: none;
`;

export const SavedCard = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 18px 40px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
`;

export const CardInfo = styled.div`
  margin-left: 20px;

  span {
    font-weight: bold;
    font-size: 0.8rem;
  }

  p {
    color: ${theme.colors.textSecondary};

    span {
      color: ${theme.colors.text};
      font-size: 0.9rem;
    }
  }

  span ~ p {
    margin-top: 8px;
  }
`;

export const CloseButtonMobile = styled.div`
  position: absolute;
  top: 25px;
  right: 20px;
`;

export const SelectContainer = styled.div`
  width: 100%;
  z-index: 200;

  /* Border of ReactSelect */
  span + span + div {
    border: 1px solid
      ${({ error }) =>
        error ? theme.colors.error : theme.colors.shapeSecondary};
  }
`;

export const SelectContainerInstallments = styled.div`
  margin-bottom: 32px;
  z-index: 200;

  /* Border of ReactSelect */
  span + span + div {
    border: 1px solid
      ${({ error }) =>
        error ? theme.colors.error : theme.colors.shapeSecondary};
  }
  @media (min-width: 1101px) {
    padding: 0 40px;
  }
`;

export const SelectCustomStyle = {
  container: provided => ({
    ...provided,
    width: '100%',
    margin: '0 auto',
  }),
  control: provided => ({
    ...provided,
    width: '100%',
    borderRadius: 20,
    padding: '8px 12px',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
    display: 'flex',
  }),
  placeholder: provided => ({
    ...provided,
    paddingLeft: 12,
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  option: provided => ({
    ...provided,
    borderRadius: 20,
  }),
};

export const ErrorMessage = styled.span`
  display: block;
  font-size: 0.8rem;
  color: ${theme.colors.error};
  text-align: start;
  width: 100%;
  margin-top: 8px;
  margin-left: 10px;
`;

export const SelectFormOfPayment = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 30px 0 30px 50px;

  input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: 25px;
    height: 25px;
    padding: 3px;
    background-clip: content-box;
    border: 2px solid #25282b;
    background-color: #e7e6e7;
    border-radius: 50%;
    @media (min-width: 300px) {
      width: 20px;
      height: 20px;
    }
  }

  input[type='radio']:checked {
    background-color: #594aad;
    border: 2px solid #594aad;
  }

  label {
    color: #25282b;
    font-size: 1rem;
    font-weight: 600;
    align-self: center;
    padding: 0 50px 0 8px;
  }

  @media (max-width: 482px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    label {
      font-size: 0.8rem;
      padding: 0 10px 0 8px;
    }
  }
`;

export const SelectFormOfPaymentOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;

  @media (max-width: 482px) {
    margin: 4px 0;
  }
`;

export const PixContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;

  p {
    text-align: center;
    font-size: 0.9rem;
    margin-bottom: 24px;
  }

  iframe {
    width: 100%;
    height: 200px;
    margin-bottom: 24px;
  }

  h5 {
    font-weight: 600;
    font-size: 24px;
    color: #2f189c;
    padding-bottom: 24px;
  }

  img {
    width: 100%;
    width: 60px;
    height: 60px;
    margin-bottom: 32px;
  }
`;

export const PixCopyAndPasteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  margin-bottom: 32px;

  p {
    font-size: 0.9rem;
    font-weight: 600;
  }

  p + p {
    text-transform: uppercase;
  }

  a {
    color: var(--default-blue);
    font-size: 0.9rem;
    font-weight: 500;
    text-decoration: none;
    font-style: italic;
  }

  .spinner {
    margin-top: 16px;
  }
`;

export const PixCopyAndPaste = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  min-width: 200px;
  max-width: 400px;
  margin-bottom: 24px;

  > p {
    font-size: 0.9rem;
    color ${theme.colors.primary};
    font-weight: 600;
    margin-top: 32px;
    margin-bottom: 16px;
    text-align: center;
  }

  > textarea {
    background-color ${theme.colors.textDisabled};
    color ${theme.colors.text};
    padding: 8px;
    border-radius: 4px;

    text-align: center;

    height: 140px;
    width: 100%;
    max-width: 260px;
    resize: none;
  }

  > button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 8px 16px;
    background: var(--default-blue);
    border-radius: 24px;
    width: 100%;
    max-width: 140px;
    margin-top: 16px;

    font-size: 0.8em;
    font-weight: 600;
    color: white;
  }
`;

export const ContainerDebitCreditCard = styled.div`
  background-color: #ffcc00;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 10px 10px;
`;

export const LabelDebitCreditCard = styled.p`
  font-size: 16px;
`;
