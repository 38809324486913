import styled, { css } from 'styled-components';

const AscendHomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 48px;
  padding-bottom: 48px;
  min-height: 390px;

  ${props => css`
    background: linear-gradient(
        rgba(47, 24, 156, 0.2) 0%,
        rgba(47, 24, 156, 0.5) 100%
      ),
      url(${props.background});
    background-size: cover;
    background-position: center;
  `}

  @media (min-width: 1700px) {
    min-height: 450px;
  }

  @media (min-width: 2000px) {
    min-height: 550px;
  }

  @media (min-width: 2200px) {
    min-height: 650px;
  }
`;

const AscendHomeContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;

  color: white;
  text-align: center;

  h2 {
    font-size: 2.4em;
  }

  p {
    font-size: 1.6em;
    padding: 0 8px;
  }

  h3 {
    font-size: 2.2em;
  }

  @media (min-width: 2200px) {
    min-height: 650px;

    h2 {
      font-size: 2.6rem;
    }

    p {
      font-size: 2rem;
    }

    h3 {
      font-size: 2.4rem;
    }
  }
`;

export { AscendHomeContainer, AscendHomeContent };
