import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { useAuth } from '../../hooks/auth';
import { getNotifications } from '../../services/notification';

const useNotification = () => {
  const [notifications, setNotifications] = useState({
    list: [],
    hasNotification: false,
  });

  const { user } = useAuth();

  const changeNotifications = callback => {
    setNotifications(prev => {
      const newList = callback?.(prev);
      return newList;
    });
  };

  const getNotificationList = async () => {
    if (!user?.user_id) return;

    try {
      const response = await getNotifications();

      changeNotifications(_prev => {
        const data = response?.data.map(n => ({ ...n, id: uuid() }));
        return {
          list: data,
          hasNotification: data?.some(n => !n.seen_at),
        };
      });
    } catch (error) {
      changeNotifications(data => data);
    }
  };

  useEffect(() => {
    getNotificationList();

    const oneMinute = 60_000;

    const intervalId = setInterval(() => {
      getNotificationList();
    }, oneMinute);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.user_id]);

  return {
    notifications,
    changeNotifications,
  };
};

export default useNotification;
