import styled from 'styled-components';

import { theme } from '../../styles/theme';

export const Container = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 26px 40px;
  @media (max-width: 480px) {
    padding: 16px 16px 0 16px;
    margin: 0;
    box-sizing: border-box;
    max-width: 100%;
  }

  img {
    width: 182px;
    height: 40px;
    @media (max-width: 480px) {
      width: 100%;
      height: 30px;
      position: relative;
      top: 5px;
    }
  }

  button {
    width: 187px;
       @media (max-width: 480px) {
      width: unset;
    }
    
    h3 {
      font-weight: bold;
      color: ${theme.colors.light};,
      text-transform: uppercase;
      @media (max-width: 480px) {
        font-weight: bold;
        font-size: 12px;
        padding-right: 6px;
      }
    }
  }
`;

export const Box = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  @media (max-width: 480px) {
    width: unset;
  }

  img {
    cursor: pointer;
    @media (max-width: 480px) {
      height: 30px;
      padding-left: 16px;
    }
  }
`;
