import styled from 'styled-components';

export const Container = styled.div`
  @media screen and (max-width: 1100px) {
    margin-bottom: 64px;
  }

  & > section {
    padding-bottom: 24px;
  }

  .button_next {
    position: absolute;
    right: 0;
    padding: 0 15px;
  }
`;

export const SpinnerWrapper = styled.div`
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(150, 142, 203, 1) 0%,
    rgba(204, 204, 204, 1) 50%,
    rgba(150, 142, 203, 1) 100%
  );
`;

export const NameCard = styled.div`
  font-weight: 600;
  font-size: 24px;
`;
