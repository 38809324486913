export const mapPeriodInDaysToString = {
  30: 'mensal',
  90: 'trimestral',
  180: 'semestral',
  365: 'anual',
};

export function planConverter(plan) {
  return {
    planId: plan.plan_id,
    planTitle: plan.title,
    valueWithoutDiscount:
      plan.full_price === 0
        ? null
        : plan.full_price.toFixed(2).replace('.', ','),
    value: plan.price.toFixed(2).replace('.', ','),
    planPeriod: mapPeriodInDaysToString[plan.period_in_days],
    benefits: plan.benefits,
    isRecommended: plan.info.isRecommended,
    isFree: plan.info.type === 0,
    type: plan.info.type,
  };
}
