import {
  CarouselProvider,
  Slide,
  Slider,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';

import CardCourse from '../../../../components/CardCourse';
import useCardCourse from '../../../../hooks/cardCourse';
import useWindowSize from '../../../../hooks/useWindowSize';
import { getContentSchedule as getContentScheduleService } from '../../../../services/Content';

import { HomeScheduleContainer } from './style';

const HomeSchedule = () => {
  const [contents, setContents] = useState([]);
  const { windowSize } = useWindowSize();
  const { getCardCourseData } = useCardCourse();

  const getContents = useCallback(async () => {
    const {
      data: { data: scheduleContents },
    } = await getContentScheduleService();
    setContents((scheduleContents || []).map(c => getCardCourseData(c)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getContents();
  }, [getContents]);

  const visibleSlides = useMemo(() => {
    let slides = 0;

    if (windowSize > 1400) {
      slides = 4;
    } else if (windowSize > 1120) {
      slides = 3.4;
    } else if (windowSize > 1000) {
      slides = 2.8;
    } else if (windowSize > 810) {
      slides = 2.4;
    } else if (windowSize > 620) {
      slides = 1.8;
    } else {
      slides = 1;
    }

    return slides;
  }, [windowSize]);

  return (
    <HomeScheduleContainer>
      <h2>Agenda de conteúdo</h2>
      <p>Veja alguns cursos, retiros e palestras</p>

      {contents?.length ? (
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={100}
          totalSlides={contents.length}
          visibleSlides={visibleSlides}
        >
          <Slider className="max-content">
            {contents.map((c, index) => (
              <Slide key={c.id} index={index}>
                <CardCourse showButtons={false} key={c.id} data={c} />
              </Slide>
            ))}
          </Slider>

          <ButtonBack>
            <AiOutlineLeft size={32} />
          </ButtonBack>
          <ButtonNext>
            <AiOutlineRight size={32} />
          </ButtonNext>

          <DotGroup />
        </CarouselProvider>
      ) : (
        <h5>Sem conteúdos programados no momento!</h5>
      )}
    </HomeScheduleContainer>
  );
};

export default HomeSchedule;
