import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import iconBack from '../../assets/icons/icon-back.svg';
import aboutIma from '../../assets/images/about-ima.png';
import ResponsivePlayer from '../../components/ResponsivePlayer';
import { controlsToEnable } from '../../configs/SvPlayer';
import * as HomeInfoService from '../../services/HomeInfo';
import { goToTop } from '../../utils/helpers';

import {
  ContentHeader,
  AboutTextAboutIma,
  Container,
  BackgroundLogged,
  Video,
  TextInfo,
  AboutTitleMobile,
} from './styles';

const AboutImaLogged = () => {
  const [content, setContent] = useState([]);
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    HomeInfoService.getAboutImaContent()
      .then(response => {
        setContent(response.data[0]);
      })
      .catch(() => {
        toast.error(
          'Ocorreu um erro ao buscar os conteúdos, recarregue a página.',
        );
      });
  }, []);

  goToTop();

  return (
    <Container>
      <BackgroundLogged>
        <img src={aboutIma} alt="bannerAboutHaluGamashi" />
        <span />
      </BackgroundLogged>
      <ContentHeader>
        <div>
          <img src={iconBack} alt="Ícone Voltar" />
          <span
            onKeyPress={handleGoBack}
            role="button"
            tabIndex="0"
            onClick={handleGoBack}
          >
            Voltar
          </span>
        </div>
        <h2 className="hidden-mobile">Sobre o IMA</h2>
        <h1>Sobre o IMA</h1>
      </ContentHeader>

      <AboutTextAboutIma>
        <article>
          <h1 className="hidden-mobile">
            Sobre o Instituto dos Mistérios Ascencionais
          </h1>

          <AboutTitleMobile className="hidden-desktop">
            <h1>
              Sobre o Instituto dos
              <br />
              Mistérios Ascencionais
            </h1>
          </AboutTitleMobile>

          {content.info?.mediaUrl && (
            <Video>
              <ResponsivePlayer
                className="hidden-mobile"
                contentUrl={content.info.mediaUrl}
                controlsToEnable={controlsToEnable}
                id="playerdesktopSobreImaLogged"
                styles={{ width: '65%' }}
              />
              <ResponsivePlayer
                className="hidden-desktop"
                contentUrl={content.info.mediaUrl}
                controlsToEnable={controlsToEnable}
                id="playerMobileSobreImalogged"
                styles={{ height: '270px', maxWidth: '100%' }}
              />
            </Video>
          )}

          <TextInfo dangerouslySetInnerHTML={{ __html: content.text }} />
        </article>
      </AboutTextAboutIma>
    </Container>
  );
};
export default AboutImaLogged;
