import React from 'react';

import { AuthProvider } from './auth';
import { ExecutionCourseProvider } from './executionCourse';
import { PaymentModalProvider } from './paymentModal';

const AppProvider = ({ children }) => {
  return (
    <AuthProvider>
      <PaymentModalProvider>
        <ExecutionCourseProvider>{children}</ExecutionCourseProvider>
      </PaymentModalProvider>
    </AuthProvider>
  );
};

export default AppProvider;
