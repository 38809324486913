import styled from 'styled-components';

export const Line = styled.hr`
  height: 300px;
  border-left: 1px solid #cfd2d4;
  margin-top: 80px;

  @media (max-width: 800px) {
    height: 0;
    border-left: none;
  }
`;
