import styled from 'styled-components';

export const ErrorMessage = styled.span``;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 16px;

  h2 {
    color ${({ theme }) => theme.colors.light};
    font-size: 1.1rem;
    font-weight: 500;

    margin: 8px;
  }

  h1 {
    color ${({ theme }) => theme.colors.light};
    font-weight: 600;
    font-size: 1.6rem;

    margin: 8px;
  }

  @media (min-width: 2300px) {
    padding: 24px;

    h2 {
      font-size: 2rem;
    }

    h1 {
      font-size: 2.6rem;
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.light};
  border-radius: 20px;
  text-align: center;

  padding: 16px 8px;

  > span {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1.25rem;
    font-weight: 500;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 445px;

    label {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 40px;

      input {
        margin-right: 6px;
        width: 15px;
        height: 15px;
        border: 1px solid #657787;
        border-radius: 2px;
      }

      span {
        font-size: 0.8rem;

        color: ${({ theme }) => theme.colors.text};
        text-align: center;

        a {
          color: #74b6d1;
        }
      }
    }
  }

  ${ErrorMessage} {
    display: block;
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.error};
    text-align: start;
    width: 100%;
    margin: 8px 0 0 10px;
  }

  @media (min-width: 400px) {
    padding: 24px;
    margin-top: 20px;

    ${ErrorMessage} {
      font-size: 1.1rem;
    }
  }

  @media (min-width: 800px) {
    form {
      label {
        input {
          width: 20px;
          height: 20px;
        }

        span {
          font-size: 0.9rem;
        }
      }
    }
  }

  @media (min-width: 2300px) {
    padding: 24px 32px;

    > span {
      font-size: 1.6rem;
    }

    form {
      max-width: 700px;

      label {
        span {
          font-size: 1.2rem;
        }
      }
    }

    ${ErrorMessage} {
      font-size: 1rem;
    }
  }
`;
