import ProgressBar from '@ramonak/react-progress-bar';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import CalendarImg from '../../assets/icons/calendar.svg';
import ClockImg from '../../assets/icons/clock.svg';
import HeartLikedImg from '../../assets/icons/heart-liked.svg';
import HeartUnLikedImg from '../../assets/icons/heart-unliked.svg';
import StarImg from '../../assets/icons/star.svg';
import { ContentsService } from '../../services/contentsService';
import { theme } from '../../styles/theme';
import { convertDuration } from '../../utils/helpers';
import { Button } from '../Button';
import Rater from '../Rater';
import Spacer from '../Spacer';

import {
  Container,
  ImageCourseBackground,
  Overlay,
  Header,
  Rating,
  Like,
  Tag,
  Content,
  CourseProgress,
  DateInfo,
  ThanksForRating,
  EmptyProgressBar,
} from './styles';

const CardCourse = ({ data, showButtons = true }) => {
  const [liked, setLiked] = useState(data?.courseLiked);
  const [mouseLeave, setMouseLeave] = useState(false);
  const [initialRating, setInitialRating] = useState(
    data?.rating?.initialRating.toFixed(1).replace('.', ','),
  );

  const contentsService = new ContentsService();

  const history = useHistory();

  async function handleToggleLikeCourse() {
    setLiked(state => !state);

    try {
      await contentsService.likeContent(data.id, !liked);
    } catch {
      setLiked(state => !state);
      toast.error(
        'Ocorreu um erro ao favoritar este conteúdo, tente novamente',
      );
    }
  }

  function handleEditRating() {
    setInitialRating('0,0');
  }

  async function handleRate(value) {
    try {
      await contentsService.reviewContent(data.id, value);
      setInitialRating(value.toFixed(1).replace('.', ','));
    } catch {
      toast.error('Ocorreu um erro ao avaliar este conteúdo, tente novamente');
    }
  }

  function handleClickCard() {
    if (data.type.toLowerCase() === 'retiro') {
      history.push(`/curso/detalhe/live/${data.id}`);
    } else {
      history.push(`/curso-detalhe/${data.id}`);
    }
  }

  function limitText(text) {
    if (text.length > 40) {
      return `${text.substring(0, 40)}...`;
    }
    return text;
  }

  const releaseDate = convertDuration(data?.duration);

  return (
    <Container onMouseLeave={() => setMouseLeave(true)}>
      <button type="button" onClick={handleToggleLikeCourse}>
        <Like>
          <img
            className={liked ? '' : 'hidden'}
            src={HeartLikedImg}
            alt="Marcado como favorito"
          />
          <img
            className={liked ? 'hidden' : ''}
            src={HeartUnLikedImg}
            alt="Não favorito"
          />
        </Like>
      </button>

      <ImageCourseBackground
        mouseLeave={mouseLeave}
        courseImg={data?.courseImg}
        onClick={handleClickCard}
      >
        <Overlay>
          <Header>
            <Rating>
              <img src={StarImg} alt="Avaliações" />
              <span>
                {data?.rating?.value || 0}{' '}
                <span>
                  {data?.rating?.totalRatings
                    ? `(${data?.rating?.totalRatings})`
                    : null}
                </span>
              </span>
            </Rating>
          </Header>

          <Tag>
            <span>{data?.tag}</span>
          </Tag>
        </Overlay>
      </ImageCourseBackground>

      <Content>
        {data?.courseProgress ? (
          <CourseProgress>
            <p>Em andamento: {data?.progress || 0}%</p>
            <ProgressBar
              completed={Number(data?.progress)}
              ariaValuetext={data?.progress}
              borderRadius="10px"
              height="8px"
              isLabelVisible={false}
              bgColor={theme.colors.secondary}
              baseBgColor={theme.colors.shapeSecondary}
            />
          </CourseProgress>
        ) : (
          <EmptyProgressBar />
        )}

        <p>{limitText(data?.name)}</p>

        <span>
          por
          <h4>{data?.author}</h4>
        </span>

        <DateInfo>
          <div>
            <img src={CalendarImg} alt="Calendário" />
            <span>{data?.date}</span>
          </div>

          {releaseDate && (
            <div>
              <img src={ClockImg} alt="Relógio" />
              <span>{releaseDate}</span>
            </div>
          )}
        </DateInfo>

        <Spacer />

        {showButtons && (
          <>
            <Button
              className="hidden-mobile"
              title={data?.buttonText}
              icon={data?.buttonIcon}
              containerStyle={{
                width: '100%',
                marginTop: '15px',
                marginBottom: '15px',
                background: '#2F189C',
              }}
              onClick={handleClickCard}
            />

            <Button
              className="hidden-desktop"
              title={data?.buttonText}
              icon={data?.buttonIcon}
              containerStyle={{
                marginTop: '15px',
                marginBottom: '15px',
                background: '#2F189C',
                alignSelf: 'center',
              }}
              onClick={handleClickCard}
            />
          </>
        )}

        {data?.progress && initialRating === '0,0' && data?.showRater ? (
          <Rater
            title="Avalie seu curso"
            initialRating={initialRating}
            changeRating={value => handleRate(value)}
          />
        ) : null}

        {initialRating !== '0,0' && data?.showRater ? (
          <ThanksForRating>
            <p>Muito obrigado!</p>

            <div>
              <img src={StarImg} alt="Estrela de avaliação" />
              <span>{initialRating}</span>
              <button onClick={handleEditRating}>Editar</button>
            </div>
          </ThanksForRating>
        ) : null}
      </Content>
    </Container>
  );
};

export default CardCourse;
