import { closestIndexTo, isFuture } from 'date-fns';

export function getMostRecentPlan(plans_paid = []) {
  const plansStartDates = plans_paid
    .filter(
      plan => isFuture(new Date(plan.finish_at)) || plan.finish_at === null,
    )
    .map(plan => new Date(plan.start_at));

  return plans_paid[closestIndexTo(new Date(), plansStartDates)];
}
