import styled from 'styled-components';

import { theme } from '../../styles/theme';

export const Container = styled.section`
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding: 48px 24px;
`;

export const HeaderBackdrop = styled.div`
  height: 112px;
  width: 100%;
  background-color: ${props => props?.theme?.colors.primary};
`;

export const IntroHeader = styled.header`
  margin-bottom: 48px;

  #page-title {
    font-size: 32px;
    color: #2f189c;
  }

  #page-subtitle {
    font-weight: normal;
    color: rgb(85, 85, 85);
    font-size: 20px;
    margin-top: 12px;
  }
`;

export const FAQList = styled.ol`
  list-style: none;

  li:not(:last-of-type) {
    border-bottom: 2px solid #f3f9fb;
  }
`;

export const Pagination = styled.section`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 48px;

  .pagination {
    display: flex;
    list-style-type: none;
  }

  .page {
    display: flex;
    align-items: center;
    margin: 0 20px;
    cursor: pointer;
  }

  .activeLink {
    color: ${theme.colors.secondary};
    font-weight: 700;
  }

  .nextButton {
    margin-left: 72px;
  }

  .previousButton {
    margin-right: 72px;
  }

  .previousContainerButton,
  .nextContainerButton {
    display: flex;
  }

  .nextButton,
  .previousButton {
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      font-size: 2rem;
      fill: ${theme.colors.secondary};
    }
  }

  .disabledButtons {
    cursor: default;

    svg {
      fill: #cfd2d4;
    }
  }

  @media (max-width: 1100px) {
    position: static;
    width: auto;

    margin: 16px auto 64px !important;
  }
`;
