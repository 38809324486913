import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Container } from './styles';

const Box = ({ children }) => {
  return <Container>{children}</Container>;
};

const CardCourseSkeleton = () => {
  return (
    <div style={{ margin: 10 }}>
      <Skeleton wrapper={Box} count={4} width={300} height={15} />
      <Skeleton wrapper={Box} count={3} width={250} height={15} />
      <Skeleton wrapper={Box} count={2} width={150} height={15} />
      <Skeleton wrapper={Box} count={1} width={300} height={15} />
    </div>
  );
};

export default CardCourseSkeleton;
