import api from './api';

export function getAll() {
  return api.get('/ascended-masters/', {
    headers: {
      authorization: `Bearer ${process.env.REACT_APP_UNLOGGED_TOKEN}`,
    },
  });
}

export function getAscendedMaster(ascended_master_id) {
  return api.get(`/ascended-masters/${ascended_master_id}`);
}
