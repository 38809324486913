import styled, { keyframes, css } from 'styled-components';

import { theme } from '../../styles/theme';

const chatAppear = keyframes`
  from {
    right:  -100%;
  }
  to {
    right: 0;
  }
`;

const chatHide = keyframes`
  from {
    right: 0;
  }
  to {
    right: -100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${theme.colors.light};
  overflow-x: hidden;
  height: 100vh;

  #live-chat {
    background-color: ${theme.colors.primary};
  }

  #playerId {
    min-height: 300px;
    height: 100%;
    width: 100%;
  }

  @media (min-width: 1100px) {
    flex-direction: row;
    height: 100vh;

    #playerId {
      min-height: 300px;
      height: calc(100vh - 158px);
    }

    #live-chat {
      max-height: none;
      flex-grow: 1;
      max-width: ${({ chatState }) =>
        chatState ? 'calc(100vw - 500px)' : '100vw'};

      transition: max-width 2s;
    }
  }
`;

export const CloseIcon = styled.div`
  position: absolute;
  padding: 16px;
  top: 16px;
  left: 16px;
  z-index: 1;
  cursor: pointer;
  @media (max-width: 1100px) {
    display: none;
  }
`;

export const ShowChatButton = styled.button`
  position: fixed;
  top: 30px;
  right: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px 24px;
  background: rgba(254, 253, 254, 0.7);
  border-radius: 20px;
  width: 155px;
  color: ${theme.colors.secondary};
  font-size: 1.2rem;
  font-weight: 900;

  > span {
    text-transform: uppercase;
  }

  ${({ chatState }) => {
    return (
      chatState &&
      css`
        animation: ${chatHide} 2.5s;
      `
    );
  }};

  ${({ chatState }) => {
    return (
      !chatState &&
      css`
        animation: ${chatAppear} 2.5s;
      `
    );
  }};

  @media (max-width: 1100px) {
    display: none;
  }
`;

export const CloseChatButton = styled.button`
  position: fixed;
  top: 30px;
  right: 520px;

  display: none;

  background: rgba(254, 253, 254, 0.7);
  width: 40px;
  border-radius: 20px;
  padding: 10px;
  color: ${theme.colors.secondary};
  font-size: 1.2rem;
  font-weight: 900;

  > span {
    text-transform: uppercase;
  }

  ${({ chatState }) => {
    return (
      chatState &&
      css`
        animation: ${chatHide} 2.5s;
      `
    );
  }};

  ${({ chatState }) => {
    return (
      !chatState &&
      css`
        animation: ${chatAppear} 2.5s;
      `
    );
  }};

  @media (min-width: 1100px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const VideoFooter = styled.div`
  display: none;

  div#live-description {
    p {
      span {
        color: #fff !important;
        background: transparent !important;
      }
    }
  }

  @media (min-width: 1100px) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 34px 72px;
    height: 158px;
    background-color: ${theme.colors.primary};

    > div {
      text-align: center;
      color: ${theme.colors.textLight};
    }

    h1 {
      font-size: 2rem;
      font-weight: 500;
    }

    p {
      font-size: 1.3rem;
      font-weight: 400;
      max-width: 100%;
      margin-top: 16px;
    }
  }
`;

export const InputContainer = styled.div`
  border-top: 1px solid #e1e3e6;
  display: flex;
  background: ${theme.colors.light};

  .react-emoji {
    display: none;
  }

  .react-emoji > .react-input-emoji--container > .react-input-emoji--wrapper {
    word-break: break-word;
  }

  > button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > button svg {
    width: 55px;
    height: 80px;
  }

  @media (min-width: 400px) {
    > button svg {
      width: auto;
      height: auto;
    }
  }

  @media (min-width: 1100px) {
    #input-chat {
      display: none;
    }

    .react-emoji {
      display: flex;
    }
  }
`;

export const Chat = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: ${theme.colors.shape};
  position: relative;

  @media (min-width: 1100px) {
    flex-grow: 0;
    width: 500px;
    position: absolute;
    height: 100vh;
    right: 0px;

    ${({ chatState }) => {
      return (
        !chatState &&
        css`
          animation: ${chatHide} 2s;
        `
      );
    }};

    ${({ chatState }) => {
      return (
        chatState &&
        css`
          animation: ${chatAppear} 2s;
        `
      );
    }};

    ${InputContainer} {
      display: ${({ chatState }) => (chatState ? 'flex' : ' none')};
    }
  }
`;

export const ChatOptionsContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 84px;
  z-index: 6;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;
  border-radius: 50%;

  background: ${theme.colors.light};
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

  cursor: pointer;

  svg {
    width: 26px;
    height: 26px;
  }
`;

export const ChatOptions = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  top: 45px;
  right: 10px;
  background: ${theme.colors.light};
  padding: 13px;
  border-radius: 20px;
  width: fit-content;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  z-index: 7;
`;

export const TitleChat = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  position: relative;

  background: ${theme.colors.light};
  svg {
    color: ${theme.colors.secondary};
    width: 20px;
    height: 20px;
  }

  h1 {
    font-size: 0.8rem;
    color: ${theme.colors.secondary};
  }

  svg:nth-of-type(2n) {
    margin-left: 8px;
    cursor: pointer;
  }

  > img {
    margin-left: auto;
    cursor: pointer;
    width: 16px;
    height: 16px;
  }

  @media (min-width: 480px) {
    h1 {
      font-size: 1rem;
    }
  }

  @media (min-width: 700px) {
    h1 {
      font-size: 1.2rem;
    }
  }
`;

const AnimateActive = keyframes`
  from {
    transform: scaleX(0);
    transform-origin: 0% 50%;
  }

  to {
    transform: scaleX(1);
    transition: transform 2s ;
    transform-origin: 0% 50%;
  }
`;

const AnimateActiveToLeft = keyframes`
  from {
    transform: scaleX(0);
    transform-origin: 100% 50%;
  }

  to {
    transform: scaleX(1);
    transition: transform 2s ;
    transform-origin: 100% 50%;
  }
`;

const AnimateNotActive = keyframes`
  from {
    border-bottom: 4px solid rgba(47, 24, 156, 0.5);
  }

  to {
    border-bottom:  4px solid rgba(112, 124, 151, 0.15); 
  }
`;

export const ChatSection = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 24px 0;
  @media (max-width: 1100px) {
    padding: 12px 0;
  }

  > svg {
    margin-right: 8px;
  }

  &.to-right:after {
    content: '';
    display: block;

    border-bottom: 4px solid rgba(47, 24, 156, 0.5);

    position: absolute;
    bottom: 0;

    animation: ${AnimateActive} 2s ease-in-out;
    width: 100%;
    max-width: 50%;
  }

  /* TODO can not run on first render */
  ${({ active }) =>
    active &&
    css`
      &.to-right:after {
        content: '';
        display: block;

        border-bottom: 4px solid rgba(47, 24, 156, 0.5);

        position: absolute;
        bottom: 0;

        animation: ${AnimateActiveToLeft} 2s ease-in-out;
        width: 100%;
        max-width: 50%;
      }
    `}

  ${({ active }) =>
    !active &&
    css`
      h1,
      svg {
        opacity: 0.6;
        transition: opacity 2s;
      }
    `}

  ${({ active }) =>
    !active &&
    css`
      &.to-right:after {
        content: '';
        display: block;

        border-bottom: 4px solid rgba(112, 124, 151, 0.15);

        position: absolute;
        bottom: 0;

        animation: ${AnimateNotActive} 2s ease-in-out;
        width: 100%;
        max-width: 50%;
      }
    `}

  ${({ active }) =>
    active &&
    css`
      &.to-left:after {
        content: '';
        display: block;

        border-bottom: 4px solid rgba(47, 24, 156, 0.5);

        position: absolute;
        bottom: 0;

        animation: ${AnimateActive} 2s ease-in-out;
        width: 100%;
        max-width: 50%;
      }
    `}

    ${({ active }) =>
    !active &&
    css`
      &.to-left:after {
        content: '';
        display: block;

        border-bottom: 4px solid rgba(112, 124, 151, 0.15);

        position: absolute;
        bottom: 0;

        animation: ${AnimateNotActive} 2s ease-in-out;
        width: 100%;
        max-width: 50%;
      }
    `}
`;

export const Messages = styled.div`
  position: relative;
  padding: 8px 16px;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 304px - 96px - 73px);
  flex-grow: 1;

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #e1e3e6;
  }
  ::-webkit-scrollbar-thumb {
    background: #2f189c;
    border-radius: 10px;
  }

  @media (min-width: 1100px) {
    max-height: none;
  }
`;

export const HeaderMessageInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  span:first-of-type {
    color: ${theme.colors.secondary};
    font-weight: 600;
    font-size: 0.7rem;
  }

  span:nth-of-type(2n) {
    font-size: 0.7rem;
    color: ${theme.colors.textSecondary};
  }
`;

export const UnpinnedContainer = styled.div`
  visibility: hidden;
  position: absolute;
  top: 10px;
  left: 10px;
  margin: 10px;
  background: ${theme.colors.light};
  padding: 8px;
  border-radius: 20px;
  width: fit-content;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  z-index: 2;
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;

  > svg {
    margin-right: 8px;
    cursor: pointer;
  }

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
`;

export const Message = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 5px 0;
  width: 100%;
  position: relative;
`;

export const MessagesAnimationWrapper = styled.div`
  position: relative;
  flex-grow: 1;

  ${({ active }) => {
    return (
      active &&
      css`
        animation: ${chatAppear} 2s;
      `
    );
  }};
`;

export const MessageHighlight = styled.div`
  display: flex;
  align-self: center;
  align-items: flex-start;
  padding: 8px;
  margin: 8px 0px;
  width: 90%;
  z-index: 5;
  background: #b4aae7;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
  border-radius: 10px;
  position: relative;

  img {
    margin-left: 20px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  > svg {
    position: absolute;
    top: 15px;
    left: 15px;
    cursor: pointer;
    z-index: 5;
    fill: #fff;
  }

  ${({ openOptions }) =>
    openOptions &&
    css`
      ${UnpinnedContainer} {
        visibility: visible;
      }
    `}

  &::before {
    content: '';
  }

  ${HeaderMessageInfo} {
    span:first-of-type {
      color: ${theme.colors.textLight};
      font-weight: 600;
      font-size: 0.9rem;
    }

    span:nth-of-type(2n) {
      display: none;
    }
  }
`;

export const AdminMenuOptions = styled.section`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: absolute;
  top: 20px;
  left: -60px;
  margin: 10px;
  background: ${theme.colors.light};
  padding: 13px;
  border-radius: 20px;
  width: fit-content;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  z-index: 3;

  @media (min-width: 1100px) {
    flex-direction: column;
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.7rem;
  padding: 8px;
  cursor: pointer;
  img {
    margin-right: 8px;
  }

  @media (min-width: 1100px) {
    font-size: 1rem;
    padding: 13px;
  }
`;

export const MessageInfo = styled.div`
  width: 100%;
  margin-left: 10px;
  position: relative;
`;

export const Text = styled.p`
  position: relative;
  background: ${theme.colors.light};
  padding: 8px 10px;
  width: fit-content;
  margin-top: 4px;
  box-shadow: 0 0 12px rgba(136, 152, 170, 0.15);
  line-height: 1.3rem;
  max-width: 250px;
  line-height: 1.6;
  word-break: break-word;
  border-radius: 5px;
  font-size: 0.7rem;

  svg {
    position: absolute;
    left: -10px;
  }

  @media (min-width: 520px) {
    max-width: 350px;
  }

  @media (min-width: 720px) {
    max-width: 450px;
  }

  @media (min-width: 820px) {
    max-width: 550px;
  }

  @media (min-width: 920px) {
    max-width: 650px;
  }

  @media (min-width: 1100px) {
    max-width: 350px;
  }
`;

export const Overlay = styled.div`
  &:before {
    content: '';
    display: ${({ open }) => (open ? 'block' : 'none')};
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
  }
`;

export const TimeDelayModal = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  background: ${theme.colors.light};
  border-radius: 20px;
  width: fit-content;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;

  label {
    margin-left: 8px;
  }
`;

export const TimeDelayHeader = styled.div`
  display: flex;
  margin-bottom: 48px;
  padding: 40px 40px 0px 40px;
  justify-content: center;

  h1 {
    font-size: 1.2rem;
    color: ${theme.colors.secondary};
    text-align: center;
    max-width: 210px;
  }

  > svg {
    margin-right: 24px;
    width: 60px;
    height: 60px;
  }
`;

export const TimeDelayForm = styled.div`
  padding: 0 40px;
`;

export const TimeDelayButtons = styled.div`
  padding: 16px 40px 20px 40px;
  display: flex;
  justify-content: space-around;
  margin-top: 49px;
  border-top: 1px solid ${theme.colors.shapeSecondary};
`;

export const UsersBlockedModal = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  background: ${theme.colors.light};
  border-radius: 20px;
  width: fit-content;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  max-width: 350px;

  hr {
    border: 1px solid rgba(0, 0, 0, 0.15);
  }

  @media (min-width: 1100px) {
    max-width: none;
  }
`;

export const UsersBlockedHeader = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;

  @media (min-width: 1100px) {
    padding: 40px;
  }
`;

export const UsersBlockedTitle = styled.div`
  margin-left: 20px;

  h1 {
    font-size: 1.2rem;
    color: ${theme.colors.secondary};
  }

  h2 {
    font-size: 0.9rem;
    color: ${theme.colors.textSecondary};
    font-weight: 500;
  }
`;

export const UsersBlockedList = styled.ul`
  list-style: none;
  overflow-y: auto;
  max-height: 250px;

  img {
    margin-right: 8px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #e1e3e6;
  }
  ::-webkit-scrollbar-thumb {
    background: #2f189c;
    border-radius: 10px;
  }
`;

export const UserBlocked = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;

  &:hover {
    background: ${theme.colors.shapeSecondary};
  }

  svg {
    color: ${theme.colors.secondary};
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  p {
    display: flex;
    align-items: center;
    font-weight: 600;
    min-width: 0;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      margin-right: 8px;
    }
  }

  @media (min-width: 1100px) {
    padding: 12px 32px;
  }
`;

export const UsersBlockedButtons = styled.div`
  padding: 16px 40px 20px 40px;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid ${theme.colors.shapeSecondary};
`;
