import styled from 'styled-components';

import { theme } from '../../../../styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  h1,
  h2 {
    color: ${theme.colors.textLight};
    text-align: center;
  }
`;

export const ThanksContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${theme.colors.light};
  border-radius: 20px;

  max-width: 300px;
  padding: 24px 12px;

  text-align: center;

  h1 {
    font-size: 1.4rem;
    color: ${theme.colors.text};
    font-weight: bold;
    margin: 16px 0px;
  }

  h2 {
    font-size: 1rem;
    color: ${theme.colors.textSecondary};
    margin-bottom: 40px;
  }

  @media (min-width: 400px) {
    max-width: 445px;
  }

  @media (min-width: 600px) {
    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.6rem;
    }
  }

  @media (min-width: 2300px) {
    max-width: 800px;
    padding: 42px;

    h1 {
      font-size: 3rem;
      margin-bottom: 32px;
    }

    h2 {
      font-size: 2rem;
    }
  }
`;
