import api from './api';

export const SECTIONS_FORUM_LIMIT = 10;
export const TOPICS_FORUM_LIMIT = 8;

export async function getAllSections(offset) {
  const params = {
    limit: SECTIONS_FORUM_LIMIT,
  };

  if (offset) params.offset = offset;

  return api
    .get('/forum/sections', {
      params: { ...params },
    })
    .then(res => res.data);
}

export async function getSectionById(section_id) {
  return api.get(`/forum/sections/${section_id}`).then(res => res.data);
}

export async function getAllTopicsBySection(sectionId, offset) {
  const params = {
    limit: SECTIONS_FORUM_LIMIT,
  };

  if (offset) params.offset = offset;

  return api
    .get(`/forum/topics/${sectionId}/section`, {
      params: { ...params },
    })
    .then(res => res.data);
}

export async function getAllTopicsByTitle(title, offset) {
  const params = {
    limit: TOPICS_FORUM_LIMIT,
  };

  if (offset) params.offset = offset;
  if (title) params.title = title;

  return api
    .get('/forum/topics', {
      params: { ...params },
    })
    .then(res => res.data);
}

export async function getSectionsByName(name, offset) {
  const params = {
    limit: SECTIONS_FORUM_LIMIT,
  };

  if (offset) params.offset = offset;
  if (name) params.name = name;

  return api
    .get('/forum/sections', {
      params: { ...params, limit: SECTIONS_FORUM_LIMIT },
    })
    .then(res => res.data);
}

export async function sendTopic(data) {
  return api.post('/forum/topics', data).then(res => res.data);
}

export async function editTopic(topicId, data) {
  return api.put(`/forum/topics/${topicId}`, data);
}

export async function getTopic(topicId, order) {
  return api
    .get(`/forum/topics/${topicId}`, {
      params: {
        order,
      },
    })
    .then(res => res.data);
}

export async function sendTopicAnswer(
  { topic_answer_answered_id, topic_id },
  data,
) {
  return api
    .post('/forum/topic_answers', {
      topic_answer_answered_id,
      topic_id,
      ...data,
    })
    .then(res => res.data);
}

export async function sendLike(answerId) {
  return api
    .post('/forum/likes', { topic_answer_id: answerId })
    .then(res => res.data);
}

export async function deleteLike(answerLikeId) {
  return api.delete(`/forum/likes/${answerLikeId}`).then(res => res.data);
}

export async function deleteSection(sectionId) {
  return api.delete(`/forum/sections/${sectionId}`).then(res => res.data);
}

export async function toggleSectionStatus(sectionId, data) {
  return api.patch(`/forum/sections/${sectionId}`, data).then(res => res.data);
}

export async function deleteTopic(topicId) {
  return api.delete(`/forum/topics/${topicId}`).then(res => res.data);
}

export async function deleteAnswer(answerId) {
  return api.delete(`/forum/topic_answers/${answerId}`).then(res => res.data);
}

export async function getAnswer(topic_answer_id) {
  return api
    .get(`/forum/topic_answers/${topic_answer_id}`)
    .then(res => res.data);
}

export async function blockUser(user_id) {
  return api.post('/forum/block_user/', { user_id }).then(res => res.data);
}

export async function unblockUser(user_id) {
  return api.delete(`/forum/block_user/${user_id}`);
}
