import styled from 'styled-components';

import { theme } from '../../styles/theme';

export const Container = styled.section`
  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
    padding-top: 40px;
  }
  overflow: hidden;
`;

export const CourseListInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px;
  margin-bottom: 24px;
  @media (max-width: 480px) {
    flex-direction: column;
    min-width: 100%;
    width: 100%;
    margin: 0;
  }

  > span {
    @media (max-width: 480px) {
      flex-direction: row;
      display: flex;
      width: 100%;
      align-items: center;
      padding-bottom: 25px;
    }
  }

  h2 {
    font-size: 2rem;
    font-weight: 600;
    color: ${theme.colors.text};
    margin-right: 24px;
    @media (max-width: 480px) {
      font-weight: 600;
      font-size: 23px;
      flex: 1;
    }

    a {
      @media (max-width: 480px) {
        font-weight: 600;
        font-size: 12px;
        align-self: baseline;
      }
    }
  }

  a {
    color: ${theme.colors.primary};
    font-size: 0.9rem;
    font-weight: 600;
    margin-right: 24px;
  }

  p {
    display: flex;
    align-items: center;

    img {
      margin-right: 16px;
    }

    span {
      font-size: 1rem;
      font-weight: 600;
      color: ${theme.colors.primary};
    }
  }

  @media (max-width: 650px) {
    h2 {
      font-size: 1.2rem;
    }

    p {
      img {
        display: none;
      }

      span {
        font-size: 0.8rem;
      }
    }
  }
`;

export const ScrollButtons = styled.div`
  margin-left: auto;

  svg {
    height: 30px;
    width: 24px;
    color: ${theme.colors.primary};
  }

  button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  button:first-child {
    margin-right: 31px;
  }

  button:last-child {
    margin-left: 31px;
  }

  @media (max-width: 650px) {
    button:first-child {
      margin-right: 8px;
    }

    button:last-child {
      margin-left: 8px;
    }
  }
`;

export const ScrollContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  min-height: 400px;
  scroll-behavior: smooth;
  justify-content: ${({ total }) => (total <= 3 ? 'center' : 'space-between')};

  h5 {
    margin: auto;
    font-size: 24px;
    font-style: italic;
    color: ${theme.colors.textSecondary}};
    text-align: center;
  }

  @media (max-width: 1100px) {
    overflow-x: auto;
    width: 100%;
    align-self: stretch;
    display: flex;
    padding: 0 20px;
    -webkit-overflow-scrolling: touch;
    > div {
      margin: 0 24px 24px 0;
    }
  }
`;
