import styled from 'styled-components';

export const VideoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  padding: 12px 0;
  border-radius: 8px;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
  }
`;
