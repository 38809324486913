import api from './api';

export const periods = [
  {
    label: 'Mensal',
    value: 'monthlyPlans',
  },
  {
    label: 'Trimestral',
    value: 'quarterlyPlans',
  },
  {
    label: 'Semestral',
    value: 'semesterPlans',
  },

  {
    label: 'Anual',
    value: 'yearlyPlans',
  },
];

export async function listPlans() {
  return api
    .get('/plans', {
      headers: {
        authorization: `Bearer ${process.env.REACT_APP_UNLOGGED_TOKEN}`,
      },
    })
    .then(res => res.data);
}

export async function getPlan(planId) {
  return api.get(`/plans/${planId}`).then(res => res.data);
}

export async function purchasePlan(
  planId,
  data,
  remember_card = false,
  installments = 1,
) {
  const body = {
    payment_type: 'credit_card',
    remember_card,
    installments,
  };

  if (data) body.data = data;

  return api.post(`/payments/plan/${planId}`, body).then(res => res.data);
}

export async function updatePlan(
  planId,
  data,
  remember_card = false,
  installments = 1,
) {
  const body = {
    payment_type: 'credit_card',
    remember_card,
    installments,
  };

  if (data) body.data = data;

  return api.put(`/payments/plan/${planId}`, body).then(res => res.data);
}

export async function cancelPlan(planId) {
  return api.post(`/payments/plan/${planId}/cancel`).then(res => res.data);
}

export async function changePaymentMethod(data) {
  return api.put('/payments/change-payment-method', data).then(res => res.data);
}

export async function getUserPayments() {
  return api.get('/payment/list-plans');
}

export async function getUserSubscription() {
  return api.get('/payments/subscription');
}
