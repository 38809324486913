import React, { useEffect, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { CgProfile } from 'react-icons/cg';
import { MdNotificationsNone as NotificationIcon } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  getAllUserNotifications,
  updateNotificationSeen,
} from '../../services/notification';

import {
  BadgetIcon,
  ButtonIcon,
  Container,
  Menu,
  MenuBody,
  MenuEmpty,
  MenuFooter,
  MenuHead,
  MenuItem,
  Overlay,
  WrapperNotificationIcon,
} from './styles';
import useNotification from './useNotification';

const Notification = () => {
  const { notifications, changeNotifications } = useNotification();

  const [openMenu, setOpenMenu] = useState(false);
  const [positionMenu, setPositionMenu] = useState();
  const [notificationList, setNotificationList] = useState([]);
  const [combinedNotifications, setCombinedNotifications] = useState([]);

  const refMenu = useRef();

  const history = useHistory();

  const userStorage = localStorage.getItem('@HaluGamashi:user');
  const parsedUserObject = JSON.parse(userStorage);

  const contentsPaidId = parsedUserObject.contents_paid.map(
    content => content.content_id,
  );

  const plansPaidId = parsedUserObject.plans_paid.map(plan => plan.plan_id);

  const getNotificationList = async () => {
    try {
      const notificationResponse = await getAllUserNotifications(
        contentsPaidId,
        plansPaidId,
        parsedUserObject.user_id,
      );
      setNotificationList(notificationResponse.data);
      setCombinedNotifications([
        ...notifications.list,
        ...notificationResponse.data,
      ]);
    } catch (error) {
      toast.error(
        'Ocorreu um erro ao buscar as notificações, tente novamente.',
      );
    }
  };

  const updateNotificationList = async notificationData => {
    try {
      await updateNotificationSeen(
        notificationData.notification_id,
        parsedUserObject.user_id,
      );

      await getNotificationList();
      history.push(`/notificacao/${notificationData.notification_id}`, {
        notificationData,
      });
    } catch (error) {
      toast.error(
        'Ocorreu um erro ao atualizar as notificações, tente novamente.',
      );
    }
  };

  const handleClickOverlay = e => {
    if (e.target === e.currentTarget) {
      setOpenMenu(false);
    }
  };

  const handleClickItem = notification => {
    const { id, topic } = notification;
    unstable_batchedUpdates(() => {
      setOpenMenu(false);

      changeNotifications(prev => {
        const list = prev.list.map(n => {
          if (n.id === id) {
            return {
              ...n,
              seen_at: Date.now(),
            };
          }

          return n;
        });

        return {
          list,
          hasNotification: list?.some(n => !n.seen_at),
        };
      });
    });

    history.push(`/secao/topico/${topic.topic_id}`, {
      notification,
    });
  };

  const handleReadNotifications = async () => {
    changeNotifications(prev => ({
      list: prev.list.map(n => ({ ...n, wasRead: true })),
      hasNotification: false,
    }));

    if (notificationList?.length === 0) return;

    notificationList?.map(async notificationData => {
      await updateNotificationSeen(
        notificationData?.notification_id,
        parsedUserObject?.user_id,
      );
    });

    await getNotificationList();
  };

  const getPositionMenu = () => {
    if (refMenu.current) {
      const domRectMenu = refMenu.current.getBoundingClientRect();

      const widthMenu = 125;
      const widthButton = domRectMenu.width / 3;
      const middlePosition = domRectMenu.right - widthMenu - widthButton;

      setPositionMenu(middlePosition);
    }
  };

  useEffect(() => {
    getPositionMenu();

    window.addEventListener('resize', getPositionMenu);

    return () => {
      window.removeEventListener('resize', getPositionMenu);
    };
  }, []);

  useEffect(() => {
    getNotificationList();

    const oneMinute = 60_000;

    const intervalId = setInterval(() => {
      getNotificationList();
    }, oneMinute);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  combinedNotifications.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  function limitString(inputString, maxLength) {
    if (inputString.length <= maxLength) {
      return inputString;
    }
    return `${inputString.slice(0, maxLength - 3)}...`;
  }

  const handleNavigateToHistoryNotification = () => {
    history.push('/history-notifications');
  };

  return (
    <Container>
      <ButtonIcon ref={refMenu} onClick={() => setOpenMenu(!openMenu)}>
        <WrapperNotificationIcon
          showAnimation={combinedNotifications?.length > 0}
        >
          <NotificationIcon
            color={combinedNotifications?.length > 0 ? 'red' : 'white'}
          />
        </WrapperNotificationIcon>
        {combinedNotifications.length > 0 && (
          <BadgetIcon>{combinedNotifications.length}</BadgetIcon>
        )}
      </ButtonIcon>

      {openMenu && (
        <Overlay onClick={handleClickOverlay}>
          <Menu position={positionMenu}>
            <MenuHead>
              <p>Notificações</p>
            </MenuHead>

            <MenuBody>
              {notifications.list.length > 0 || notificationList.length > 0 ? (
                <>
                  {combinedNotifications.map(notification => (
                    <React.Fragment
                      key={notification.id || notification.notification_id}
                    >
                      {notification.type === 'like' ? (
                        <MenuItem
                          onClick={
                            notification?.topic?.title
                              ? () => handleClickItem(notification)
                              : () => updateNotificationList(notification)
                          }
                          active={!notification.seen_at}
                        >
                          <div className="user-avatar">
                            {notification.user &&
                            notification.user.avatar_url ? (
                              <img
                                src={notification.user.avatar_url}
                                alt={`${notification.user.name}'s avatar`}
                              />
                            ) : (
                              <CgProfile alt="Sem foto de perfil" />
                            )}
                          </div>
                          <div className="notification-body">
                            <small>
                              <time>
                                {new Date(
                                  notification.created_at,
                                ).toDateString()}
                              </time>
                            </small>
                            <p>
                              <span className="username">
                                {notification.user
                                  ? notification.user.name
                                  : notification.title}
                              </span>{' '}
                              {notification.user &&
                                // eslint-disable-next-line no-useless-concat
                                'curtiu sua resposta no tópico' + ' '}
                              <span className="topic-name">
                                {notification.topic
                                  ? notification.topic.title
                                  : notification.description}
                              </span>
                            </p>
                          </div>
                        </MenuItem>
                      ) : (
                        <MenuItem
                          onClick={
                            notification?.topic?.title
                              ? () => handleClickItem(notification)
                              : () => updateNotificationList(notification)
                          }
                          active={!notification.seen_at}
                        >
                          <div className="user-avatar">
                            <CgProfile alt="Sem foto de perfil" />
                          </div>
                          <div className="notification-body">
                            <small>
                              <time>
                                {new Date(
                                  notification.created_at,
                                ).toDateString()}
                              </time>
                            </small>
                            <p>
                              <span className="username">
                                {notification.user
                                  ? notification.user.name
                                  : notification.title}
                              </span>{' '}
                              {notification.user &&
                                // eslint-disable-next-line no-useless-concat
                                'respondeu seu comentário no tópico' + ' '}
                              {notification?.description ? (
                                <div className="column">
                                  <span className="topic-name">
                                    {limitString(notification.description, 30)}
                                  </span>
                                </div>
                              ) : (
                                <span className="topic-name">
                                  {notification.topic.title}
                                </span>
                              )}
                            </p>
                          </div>
                        </MenuItem>
                      )}
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <MenuEmpty>
                  <p>Nada por enquanto.</p>
                </MenuEmpty>
              )}
            </MenuBody>

            <MenuFooter>
              <button
                type="button"
                onClick={handleReadNotifications}
                disabled={!notifications.hasNotification && !notificationList}
              >
                Marcar como lidas
              </button>
              <button
                type="button"
                onClick={handleNavigateToHistoryNotification}
              >
                Ver histórico
              </button>
            </MenuFooter>
          </Menu>
        </Overlay>
      )}
    </Container>
  );
};

export default Notification;
