import api from './api';

export function forgotPassword(data) {
  return api.post('/session/forgot-password', data);
}

export function confirmNewPassword(data) {
  return api.post('/session/confirm-new-password', data);
}

export function confirmAccount(data) {
  return api.post('/session/verify-code', data);
}

export function resendCode(email) {
  return api.post('/session/resend-code', { email });
}

export function firstAccessImportedUser(data) {
  return api.post('/import-user/confirm-user-imported', data);
}
