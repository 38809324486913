import api from './api';

export async function getNotifications({
  limit = 10,
  offset = 0,
  with_seen = false,
} = {}) {
  return api.get(`/forum/notifications`, {
    params: {
      limit,
      offset,
      with_seen,
    },
  });
}

export async function updateNotification({
  target_id,
  type,
  seen_at = Date.now(),
}) {
  return api.patch(`/forum/notifications/${type}/${target_id}`, {
    seen_at,
  });
}

export async function getAllUserNotifications(
  contents = [],
  plans = [],
  userId,
) {
  const response = await api.get(
    `/notifications?plans=[${plans}]&contents=[${contents}]&user_id=${userId}`,
  );

  return response;
}

export async function updateNotificationSeen(notificationId, userId) {
  return api.get(`/notifications/${notificationId}?user_id=${userId}`);
}

export async function getHistoryNotifications(plans, contents) {
  return api.get(`/notifications/?plans=[${plans}]&contents=[${contents}]`);
}
