import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { BiChevronRight, BiChevronLeft } from 'react-icons/bi';
import { MdAddAPhoto } from 'react-icons/md';
import ReactPaginate from 'react-paginate';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import backArrow from '../../../assets/icons/icon-back-breadcrumb-mobile.png';
import AvatarFallback from '../../../assets/images/avatar-fallback.png';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { Button } from '../../../components/Button';
import Spinner from '../../../components/Spinner';
import { TextArea } from '../../../components/TextArea';
import { useAuth } from '../../../hooks/auth';
import * as ContentService from '../../../services/Content';
import { FilesService } from '../../../services/fileService';
import {
  getSectionById,
  getTopic,
  sendTopicAnswer,
  TOPICS_FORUM_LIMIT,
} from '../../../services/forum';
import { updateNotification } from '../../../services/notification';
import { UserService } from '../../../services/userService';
import getPageCount from '../../../utils/getPageCount';

import ListDiscussionItem from './components/ListDiscussionItem';
import {
  Container,
  Header,
  BreadcrumbMobile,
  Overlay,
  Content,
  UserInfo,
  UserName,
  Published,
  TopicDescription,
  WriteAnswerContainer,
  ListDiscussion,
  ListDiscussionHeader,
  ListDiscussionOptions,
  ListDiscussionItems,
  Pagination,
  GamificationBadge,
} from './styles';

const schema = yup.object().shape({
  text: yup.string().required('Escreva uma resposta para enviar!'),
});

const Topic = () => {
  const { user } = useAuth();
  const [discussionVisualization, setDiscussionVisualization] =
    useState('best');
  const [topic, setTopic] = useState({});
  const [answers, setAnswers] = useState([]);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');

  const [answerContainerVisible, setAnswerContainerVisible] = useState(false);

  const [loadingTopic, setLoadingTopic] = useState(true);

  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);

  const { topicId } = useParams();
  const inputFileRef = useRef();
  const filesService = new FilesService();
  const history = useHistory();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleChangeDiscussionVisualization = value => {
    setDiscussionVisualization(value);
  };

  const handleShowAnswerContainer = () => {
    setAnswerContainerVisible(true);
  };

  const fetchTopic = useCallback(async () => {
    setLoadingTopic(true);
    try {
      const topicResponse = await getTopic(topicId, discussionVisualization);
      const section = await getSectionById(topicResponse.topic.section_id);

      const content = await ContentService.getByIdDetails(
        section.content_id,
      ).catch(() => {
        toast.error(
          'Não foi possível encontrar os dados para acessar este tópico',
        );
        setTimeout(() => {
          history.goBack();
        }, 2000);
      });

      const userService = new UserService();
      const responsePlansPurchaseIds = await userService.getPlansPurchaseIds();
      const responseContentssPurchaseIds =
        await userService.getContentsPurchaseIds();
      const purchases = responsePlansPurchaseIds.data.concat(
        responseContentssPurchaseIds.data,
      );

      const courseIsFree = Boolean(!content?.data?.info?.price);
      if (
        !user.is_admin &&
        courseIsFree &&
        topicResponse.associated_plans.length !== 0
      ) {
        const intersectionPlans = purchases.filter(plan =>
          section.associated_plans.includes(plan),
        );

        if (intersectionPlans.length === 0) {
          toast.warning(
            'Usuário não um plano com tem permissão para acessar essa seção.',
            {
              toastId: 'user-not-permitted-section',
            },
          );
          setTimeout(() => {
            history.goBack();
          }, 2000);
          return;
        }
      }

      if (!user.is_admin && !courseIsFree) {
        const userContentsPaid = purchases.map(
          contentPaid => contentPaid.content_id,
        );

        const userHasPurchaseContent = userContentsPaid.includes(
          section.content_id,
        );

        if (!userHasPurchaseContent) {
          toast.warning('Usuário não tem permissão para acessar essa seção.', {
            toastId: 'user-not-permitted-section',
          });
          setTimeout(() => {
            history.goBack();
          }, 2000);
          return;
        }
      }

      setPageCount(
        getPageCount(topicResponse.topic.answers.length, TOPICS_FORUM_LIMIT),
      );
      setTopic(topicResponse.topic);
      setAnswers(topicResponse.topic.answers.slice(0, TOPICS_FORUM_LIMIT));

      if (
        location?.state?.notification &&
        !location.state.notification.seen_at
      ) {
        const { notification } = location.state;

        updateNotification({
          target_id:
            notification.type === 'answer'
              ? notification.topic_answer_id
              : notification.like_id,
          seen_at: new Date(),
          type: notification.type,
        });
      }
    } catch (e) {
      toast.error('Ocorreu um erro ao buscar este tópico, tente novamente');
    } finally {
      setLoadingTopic(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicId, discussionVisualization, history]);

  const handleAnswerTopic = async data => {
    const body = { ...data };

    if (imagePreviewUrl) {
      Object.assign(body, { media: imagePreviewUrl });
    }

    try {
      await sendTopicAnswer({ topic_id: topicId }, body);
      toast.success('Resposta enviada');
      setAnswerContainerVisible(false);

      fetchTopic();
    } catch (err) {
      if (err?.response?.status === 403) {
        toast.warn(err?.response?.data?.message);
        return;
      }

      toast.error('Ocorreu um erro ao enviar sua resposta');
    }
  };

  const onDeleteAnswer = answerId => {
    setAnswers(state =>
      state.filter(topic_answer => topic_answer.topic_answer_id !== answerId),
    );
  };

  const handleUploadMedia = async event => {
    const fr = new FileReader();
    const file = event.currentTarget.files[0];

    fr.onloadend = () => {
      setImagePreviewUrl(`${fr.result}`);
    };

    fr.readAsDataURL(file);

    const formData = new FormData();

    formData.append('file', file);

    try {
      const response = await filesService.uploadFile(formData);
      setImagePreviewUrl(response?.data?.reference);
    } catch {
      toast.warning(
        'Ocorreu um erro ao carregar a imagem anexada, selecione novamente',
      );
      setImagePreviewUrl('');
    }
  };

  useEffect(() => {
    fetchTopic();
  }, [fetchTopic]);

  useEffect(() => {
    if (!topic?.answers) return;

    setAnswers(
      topic?.answers?.slice(
        TOPICS_FORUM_LIMIT * currentPage,
        TOPICS_FORUM_LIMIT * (currentPage + 1),
      ),
    );
  }, [topic, currentPage]);

  return (
    <Container>
      <Header>
        <Overlay>
          <Breadcrumbs
            className="hidden-mobile"
            style={{ paddingLeft: '30px', paddingBottom: '10px' }}
            links={[
              { name: 'Fórum', url: '/forum' },
              {
                name: topic?.title,
              },
            ]}
          />
          <BreadcrumbMobile className="hidden-desktop">
            <Link to="/home">
              <img src={backArrow} alt="Voltar" />
              <p>Início</p>
            </Link>
          </BreadcrumbMobile>
          <h1>
            Tópico
            <span>Tópico</span>
          </h1>
        </Overlay>
      </Header>

      <Content>
        {loadingTopic && (
          <div className="spinner">
            <Spinner />
          </div>
        )}

        {!loadingTopic && (
          <>
            <section>
              <h2>{topic?.title}</h2>
              <Button
                className="hidden-mobile"
                title="Responder Tópico"
                type="button"
                containerStyle={{
                  maxWidth: 250,
                  minWidth: 250,
                }}
                onClick={handleShowAnswerContainer}
              />
            </section>

            <UserInfo>
              <img
                src={topic?.user?.images?.avatar || AvatarFallback}
                alt="Avatar"
              />
              <UserName>{topic?.user?.name}</UserName>
              <GamificationBadge>{topic?.user?.level?.name}</GamificationBadge>
              {topic?.created_at && (
                <Published>
                  Iniciado em{' '}
                  {format(new Date(topic?.created_at), 'dd/MM/yyyy')}
                </Published>
              )}
            </UserInfo>

            <TopicDescription
              dangerouslySetInnerHTML={{ __html: topic?.description }}
            />

            <Button
              className="hidden-desktop"
              title="Responder Tópico"
              type="button"
              containerStyle={{
                maxWidth: 250,
                margin: '0 auto 40px auto',
                minWidth: 250,
              }}
              onClick={handleShowAnswerContainer}
            />

            {answerContainerVisible ? (
              <WriteAnswerContainer onSubmit={handleSubmit(handleAnswerTopic)}>
                <MdAddAPhoto
                  onClick={() => {
                    inputFileRef?.current?.click();
                  }}
                />

                <label htmlFor="answer">Escreva sua resposta</label>
                <TextArea
                  id="answer"
                  containerStyle={{
                    marginTop: 8,
                    paddingRight: 50,
                  }}
                  isInvalid={errors.text?.message}
                  errorMessage={errors.text?.message}
                  placeholder="Digite aqui sua resposta..."
                  {...register('text')}
                />

                {imagePreviewUrl && (
                  <img
                    id="imagePreview"
                    src={imagePreviewUrl}
                    alt="Preview de imagem a ser enviada com a resposta"
                  />
                )}

                <input
                  ref={inputFileRef}
                  type="file"
                  name="media"
                  id="media"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={handleUploadMedia}
                />

                {isSubmitting && (
                  <div className="spinner spinner-submit">
                    <Spinner />
                  </div>
                )}

                {!isSubmitting && (
                  <Button
                    title="ENVIAR"
                    type="submit"
                    containerStyle={{
                      marginTop: 8,
                      maxWidth: 150,
                      minWidth: 150,
                      marginLeft: 'auto',
                    }}
                  />
                )}
              </WriteAnswerContainer>
            ) : null}

            <ListDiscussion>
              <ListDiscussionHeader>
                <h3>Discussão</h3>

                <ListDiscussionOptions>
                  <span
                    className={discussionVisualization === 'best' && 'active'}
                    onKeyPress={() =>
                      handleChangeDiscussionVisualization('best')
                    }
                    onClick={() => handleChangeDiscussionVisualization('best')}
                    role="button"
                    tabIndex="0"
                  >
                    Melhores respostas
                  </span>
                  <span
                    className={discussionVisualization === 'recent' && 'active'}
                    onKeyPress={() =>
                      handleChangeDiscussionVisualization('recent')
                    }
                    onClick={() =>
                      handleChangeDiscussionVisualization('recent')
                    }
                    role="button"
                    tabIndex="0"
                  >
                    Mais recentes
                  </span>
                </ListDiscussionOptions>
              </ListDiscussionHeader>

              {answers.length === 0 && (
                <h4>Ainda não há respostas para este tópico</h4>
              )}

              <ListDiscussionItems>
                {answers.map(answer => (
                  <ListDiscussionItem
                    key={answer.answer_id}
                    data={answer}
                    onDeleteAnswer={onDeleteAnswer}
                  />
                ))}
              </ListDiscussionItems>
            </ListDiscussion>

            <Pagination>
              <ReactPaginate
                initialPage={0}
                previousLabel={<BiChevronLeft />}
                nextLabel={<BiChevronRight />}
                nextClassName="nextButton"
                previousClassName="previousButton"
                previousLinkClassName="previousContainerButton"
                nextLinkClassName="nextContainerButton"
                containerClassName="pagination"
                disabledClassName="disabledButtons"
                activeLinkClassName="activeLink"
                pageClassName="page"
                breakClassName="page"
                breakLabel="..."
                pageCount={pageCount}
                marginPagesDisplayed={1}
                onPageChange={page => {
                  setCurrentPage(page.selected);
                }}
              />
            </Pagination>
          </>
        )}
      </Content>
    </Container>
  );
};

export default Topic;
