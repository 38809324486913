import React, { useRef } from 'react';

import { Container } from './styles';

export function Button({
  title,
  icon: Icon,
  bgColor = 'dark',
  containerStyle,
  buttonStyle,
  onClick,
  className,
  disabled,
  img,
  ...rest
}) {
  const ref = useRef(null);

  const handleExecuteClick = () => {
    ref.current.click();
  };

  return (
    <Container
      bgColor={bgColor}
      onClick={disabled ? null : onClick || handleExecuteClick}
      style={containerStyle}
      className={className}
      disabled={disabled}
    >
      {Icon ? <Icon /> : null}
      {img && <img src={img} alt="" />}
      <button ref={ref} style={buttonStyle} disabled={disabled} {...rest}>
        {title}
      </button>
    </Container>
  );
}
