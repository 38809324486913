import React, { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BiChevronDown } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Breadcrumbs from '../../components/Breadcrumbs';
import CardPlan from '../../components/CardPlan';
import FooterLogged from '../../components/FooterLogged';
import HeaderLogged from '../../components/HeaderLogged';
import Spinner from '../../components/Spinner';
import { useAuth } from '../../hooks/auth';
import { usePaymentModal } from '../../hooks/paymentModal';
import { periods, listPlans, updatePlan } from '../../services/plans';
import { getMostRecentPlan } from '../../utils/getMostRecentPlan';
import { goToTop } from '../../utils/helpers';
import { mappedErrors } from '../../utils/paymentsMappedErrors';
import { planConverter } from '../../utils/planConverter';

import ModalPayment from './components/PaymentModal';
import {
  Cards,
  Container,
  Content,
  Header,
  NoPlansFound,
  Overlay,
  ImageFilter,
  PeriodOptions,
  PeriodOptionsContainer,
  PeriodSelectedMobile,
  PeriodSelector,
  PeriodSelectorMobile,
} from './styles';

goToTop();

const Plans = () => {
  const [loading, setLoading] = useState(false);
  const [monthlyPlans, setMonthlyPlans] = useState([]);
  const [quarterlyPlans, setQuarterlyPlans] = useState([]);
  const [semesterPlans, setSemesterPlans] = useState([]);
  const [yearlyPlans, setYearlyPlans] = useState([]);
  const [openPeriodSelect, setOpenPeriodSelect] = useState(false);
  const [periodSelected, setPeriodSelected] = useState({
    label: 'Mensal',
    value: 'monthlyPlans',
  });
  const [planSelected, setPlanSelected] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);

  const { user } = useAuth();
  const history = useHistory();
  const { resetModalData } = usePaymentModal();

  const mapPeriodToPlanState = {
    monthlyPlans,
    quarterlyPlans,
    semesterPlans,
    yearlyPlans,
  };

  const handleTogglePeriodSelect = () => {
    setOpenPeriodSelect(state => {
      if (state) {
        document.querySelector('body').style.overflow = 'auto';
      } else {
        document.querySelector('body').style.overflow = 'hidden';
      }
      return !state;
    });
  };

  const handleSelectPeriod = period => {
    setPeriodSelected(period);
    handleTogglePeriodSelect();
  };

  const handleToggleModal = async planId => {
    const foundPlan = mapPeriodToPlanState[periodSelected.value].find(
      plan => plan.plan_id === planId,
    );

    if (foundPlan?.info?.type === 0) {
      toast.success('Plano atualizado com sucesso!');
      history.goBack();
      return;
    }

    setPlanSelected(foundPlan);

    setModalOpen(state => {
      if (state) {
        document.querySelector('body').style.overflow = 'auto';
      } else {
        document.querySelector('body').style.overflow = 'hidden';
      }

      return !state;
    });

    resetModalData();
  };

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        setLoading(true);
        const responseWithoutFilter = await listPlans();
        const response = responseWithoutFilter.filter(
          plan => plan.is_active === true,
        );

        setMonthlyPlans(
          response
            .filter(plan => plan.period_in_days === 30)
            .sort((a, b) => (a.info.type > b.info.type ? 1 : -1)),
        );
        setQuarterlyPlans(
          response
            .filter(plan => plan.period_in_days === 90)
            .sort((a, b) => (a.info.type > b.info.type ? 1 : -1)),
        );
        setSemesterPlans(
          response
            .filter(plan => plan.period_in_days === 180)
            .sort((a, b) => (a.info.type > b.info.type ? 1 : -1)),
        );
        setYearlyPlans(
          response
            .filter(plan => plan.period_in_days === 365)
            .sort((a, b) => (a.info.type > b.info.type ? 1 : -1)),
        );
        setPeriodSelected({
          label: 'Mensal',
          value: 'monthlyPlans',
        });
      } catch {
        toast.warning(
          'Ocorreu um erro ao buscar os planos, contrate um pelo seu perfil',
          {
            autoClose: false,
          },
        );
        history.push('/logar');
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, [history]);

  useEffect(() => {
    const fetchPlan = async () => {
      try {
        const planActive = getMostRecentPlan(user?.plans_paid);
        setCurrentPlan(planActive);
      } catch {
        toast.error(
          'Não foi possível recuperar seu plano contratado, recarregue a página.',
        );
      }
    };

    fetchPlan();
  }, [user]);

  return (
    <Container>
      <HeaderLogged />
      <Header>
        <Overlay>
          <ImageFilter />
          <Breadcrumbs className="hidden-mobile" links={[{ name: 'Perfil' }]} />
          <Breadcrumbs
            className="hidden-desktop"
            links={[{ name: 'Perfil' }]}
            style={{ margin: '100px 0 0 16px ' }}
          />

          <h1>
            Planos de assinatura
            <span>Planos de assinatura</span>
          </h1>
        </Overlay>
      </Header>

      <Content>
        <h3>Escolha uma das opções de assinatura para alterar:</h3>

        <PeriodSelector>
          {periods.map(period => (
            <li
              className={periodSelected.value === period.value && 'selected'}
              tabIndex="0"
              onKeyPress={() => setPeriodSelected(period)}
              role="menuitem"
              onClick={() => setPeriodSelected(period)}
            >
              {period.label}
            </li>
          ))}
        </PeriodSelector>

        <PeriodSelectorMobile onClick={handleTogglePeriodSelect}>
          <PeriodSelectedMobile>
            <p>{periodSelected.label}</p>
            <BiChevronDown />
          </PeriodSelectedMobile>
        </PeriodSelectorMobile>

        {loading ? (
          <div className="spinner">
            <Spinner color="white" />
          </div>
        ) : (
          <Cards>
            {mapPeriodToPlanState[periodSelected.value].length === 0 ? (
              <NoPlansFound>Não temos planos para essa opção</NoPlansFound>
            ) : null}
            {mapPeriodToPlanState[periodSelected.value].map(item => (
              <CardPlan
                key={item.plan_id}
                data={planConverter(item)}
                onSelect={handleToggleModal}
              />
            ))}
          </Cards>
        )}

        <PeriodOptionsContainer open={openPeriodSelect}>
          <PeriodOptions>
            <AiOutlineClose onClick={handleTogglePeriodSelect} />
            {periods.map(period => (
              <li
                key={period.value}
                onKeyPress={() => handleSelectPeriod(period)}
                role="menuitem"
                tabIndex="0"
                onClick={() => handleSelectPeriod(period)}
              >
                {period.label}
              </li>
            ))}
          </PeriodOptions>
        </PeriodOptionsContainer>
      </Content>

      {modalOpen && (
        <ModalPayment
          plan={planSelected}
          onClose={handleToggleModal}
          currentPlan={currentPlan}
          modalOpen={modalOpen}
        />
      )}

      <FooterLogged />
    </Container>
  );
};

export default Plans;
