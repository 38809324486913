/* eslint-disable react/destructuring-assignment */
import { isPast } from 'date-fns';
import React from 'react';
import { AiOutlineCalendar, AiOutlineClockCircle } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';

import { Button } from '../Button';

import {
  Container,
  Overlay,
  BannerHeader,
  Tag,
  Date,
  Duration,
  BannerContent,
  MobileDateAndDuration,
} from './styles';

const BannerItem = ({ content }) => {
  const {
    id,
    bannerImg,
    tag,
    date,
    duration,
    title,
    author,
    buttonText,
    buttonIcon,
  } = content;

  const history = useHistory();

  function handleGoToCourse() {
    if (content.type === 'retiro') {
      history.push(`/curso/detalhe/live/${id}`);
      return;
    }
    history.push(`/curso-detalhe/${id}`);
  }

  function convertDuration(seconds) {
    const minutes = Math.floor(seconds / 60);
    const hours = (seconds / 3600).toFixed(2);
    return minutes < 60 ? `${minutes} m` : `${hours} h`;
  }

  return (
    <Container className="container" bg={bannerImg}>
      <Overlay className="overlay">
        <BannerHeader>
          <Tag>{tag}</Tag>
          <Date className="hidden-mobile">
            <AiOutlineCalendar alt="Calendário" />
            <span> {isPast(date) ? date : 'Já disponível'}</span>
          </Date>

          <Duration className="hidden-mobile">
            <AiOutlineClockCircle alt="Relógio" />
            <span>{convertDuration(duration)}</span>
          </Duration>

          <MobileDateAndDuration className="hidden-desktop">
            <Date>
              <AiOutlineCalendar alt="Calendário" />
              <span>{isPast(date) ? date : 'Já disponível'}</span>
            </Date>

            <Duration>
              <AiOutlineClockCircle alt="Relógio" />
              <span>{convertDuration(duration)}</span>
            </Duration>
          </MobileDateAndDuration>
        </BannerHeader>

        <BannerContent>
          <h1>{title}</h1>
          <span>
            por <cite>{author}</cite>
          </span>
        </BannerContent>

        <Button
          className="hidden-mobile button"
          onClick={handleGoToCourse}
          icon={buttonIcon}
          title={buttonText}
          containerStyle={{ marginBottom: '60px' }}
        />
        <Button
          className="hidden-desktop button"
          onClick={handleGoToCourse}
          title={buttonText}
          containerStyle={{
            maxWidth: 204,
            alignSelf: 'center',
            marginBottom: '20px',
          }}
          buttonStyle={{ textAlign: 'center' }}
        />
      </Overlay>
    </Container>
  );
};

export default BannerItem;
