import api from './api';

export async function getPaymentProfile() {
  return api.get('/payment-profiles').then(res => res.data);
}

export async function createPayment(
  rememberCard,
  data,
  contentId,
  coupon,
  installments = 1,
  content,
) {
  return api
    .post(`/payments/content/${contentId}`, {
      payment_type: 'credit_card',
      remember_card: rememberCard,
      data,
      installments,
      coupon,
      content,
    })
    .then(res => res.data);
}

export async function createPaymentWithCardSaved(
  contentId,
  coupon,
  installments = 1,
  content,
) {
  return api
    .post(`/payments/content/${contentId}`, {
      payment_type: 'credit_card',
      coupon,
      installments,
      content,
    })
    .then(res => res.data);
}

export async function createPaymentWithBankSlip(
  contentId,
  coupon,
  personalData,
) {
  return api.post(`/payments/content/${contentId}`, {
    payment_type: 'bank_slip',
    coupon,
    bank_slip_data: {
      ...personalData,
    },
  });
}

export async function createPaymentWithPaypal(contentId, coupon) {
  return api.post(`/payments/content/${contentId}`, {
    coupon,
    payment_type: 'paypal',
  });
}

export async function createPaymentWitPix(contentId, coupon) {
  return api.post(`/payments/content/${contentId}`, {
    payment_type: 'pix',
    coupon,
  });
}

export async function createPaymentWithBankSlipPlan(plan_id, personalData) {
  return api.post(`/payments/plan/${plan_id}`, {
    payment_type: 'bank_slip',
    bank_slip_data: {
      ...personalData,
    },
  });
}

export async function createPaymentWithPaypalPlan(plan_id) {
  return api.post(`/payments/plan/${plan_id}`, {
    payment_type: 'paypal',
  });
}

export async function changePaymentWithPaypalPlan(plan_id) {
  return api.put(`/payments/plan/${plan_id}`, {
    payment_type: 'paypal',
  });
}

export async function createPaymentPlanWitPix(planId, bank_slip_data) {
  return api.post(`/payments/plan/${planId}`, {
    payment_type: 'pix',
    bank_slip_data,
  });
}

export async function cancelPlan(plan_id) {
  return api.put(`/payments/${plan_id}/cancel`);
}

export async function checkPaymentStatus(userPaymentId) {
  return api.get(`/payments/${userPaymentId}/checkPayment`);
}

export async function getPaymentHistory() {
  return api.get('/payments/history');
}
