import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding: 48px 24px;

  background: #f7f7f7;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 80px 0px;
  margin: 0 auto;

  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  #contacts {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media (max-width: 992px) {
      margin-bottom: 32px;
    }

    ul#contact-list {
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 12px;

      li {
        a {
          text-decoration: none;
          color: #444;
          display: flex;
          align-items: center;
          gap: 8px;
          transition: all 0.3s;

          &:hover {
            color: var(--default-blue);
            text-decoration: underline;
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    div {
      flex-direction: column;
      align-items: center;
    }
  }

  @media (max-width: 768px) {
    padding: 0px 0px 32px;
    div {
      align-items: flex-center;
    }
  }
`;

export const Title = styled.h2`
  font-size: 42px;
  color: var(--default-blue);
  max-width: 360px;
  line-height: 45px;

  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 40px;
  }
`;

export const ContentForm = styled.form`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 992px) {
    margin-right: 0;
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 1rem auto 0 0;
  }
`;

export const InputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  font-weight: bold;
`;

export const Input = styled.input`
  width: 100%;
  background-color: #f2f2f2;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  padding: 12px 8px;
`;

export const Label = styled.label`
  width: 100%;
  color: #444;
  margin-bottom: 0.5rem;
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 12px 8px;
  background-color: #f2f2f2;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  resize: none;
`;

export const Button = styled.button`
  font-weight: bold;
  font-size: var(--font-large);
  background-color: var(--default-blue);
  border-radius: 6px;
  color: #fff;
  width: 136px;
  height: 48px;
  border: none;
  transition: background-color 0.2s;
  cursor: pointer;
`;
