import React, { useState, useEffect, useRef } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import {
  BiChevronUp,
  BiChevronDown,
  BiChevronRight,
  BiChevronLeft,
} from 'react-icons/bi';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import backArrow from '../../assets/icons/icon-back-breadcrumb-mobile.png';
import Breadcrumbs from '../../components/Breadcrumbs';
import { Button } from '../../components/Button';
import CardCourse from '../../components/CardCourse';
import CardCourseSkeleton from '../../components/CardCourseSkeleton';
import useCardCourse from '../../hooks/cardCourse';
import { CategoriesService } from '../../services/categoriesService';
import {
  ContentsService,
  ContentsServiceConstants,
} from '../../services/contentsService';
import { UserService } from '../../services/userService';
import { theme } from '../../styles/theme';
import getPageCount from '../../utils/getPageCount';
import { goToTop } from '../../utils/helpers';
import { BreadcrumbMobile } from '../Finalized/styles';
import { Overlay } from '../InProgress/styles';

import {
  Container,
  Header,
  ImageFilter,
  Content,
  Filters,
  Filter,
  FilterOptions,
  FilterOption,
  Title,
  CoursesGrid,
  Pagination,
  FiltersMobileButton,
  BadgeFiltersMobile,
  FiltersMobile,
  CloseButtonMobile,
  FilterButtonContainer,
} from './styles';

const Favorites = () => {
  const [courses, setCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesFilterOpen, setCategoriesFilterOpen] = useState(true);
  const [pricesFilterOpen, setPricesFilterOpen] = useState(true);
  const [pricesFilterSelected, setPricesFilterSelected] = useState([]);
  const [typesFilterOpen, setTypesFilterOpen] = useState(true);
  const [filterSelected, setFilterSelected] = useState({
    category_ids: [],
    type: [],
  });
  const [filterPricesSelected, setFilterPricesSelected] = useState({
    to_purchase: false,
    purchased: false,
    free: false,
  });

  const [pageCount, setPageCount] = useState(1);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);

  const cardCourseHook = useCardCourse();

  const mobileFilterRef = useRef(null);
  const mapperFilterToBadge = (() => {
    const mapper = {
      allPrices: 'Todos',
      toBuy: 'Para comprar',
      available: 'Disponível',
    };

    categories.forEach(category => {
      mapper[category.id] = category.title;
    });

    return mapper;
  })();

  function handleToggleCategoriesFilter() {
    setCategoriesFilterOpen(state => !state);
  }

  function handleTogglePriceFilter() {
    setPricesFilterOpen(state => !state);
  }

  function handleToggleTypeFilter() {
    setTypesFilterOpen(state => !state);
  }

  function filterCourses(filter, value, addValue) {
    setOffset(0);
    setPageCount(1);

    if (addValue) {
      return setFilterSelected(state => ({
        ...state,
        [filter]: [...state[filter], value],
      }));
    }

    return setFilterSelected(state => ({
      ...state,
      [filter]: state[filter].filter(item => item !== value),
    }));
  }

  function handleOpenMobileFilter() {
    goToTop();
    mobileFilterRef.current.style.display = 'block';
    document.querySelector('body').style.overflow = 'hidden';
  }

  function handleCloseMobileFilter() {
    mobileFilterRef.current.style.display = 'none';
    document.querySelector('body').style.overflow = 'auto';
  }

  function filterCoursesByPrices(value, addValue) {
    setOffset(0);
    setPageCount(1);

    if (addValue) {
      return setPricesFilterSelected(state => [...state, value]);
    }

    return setPricesFilterSelected(state => [
      ...state.filter(item => item !== value),
    ]);
  }

  useEffect(() => {
    (async () => {
      try {
        const categoriesService = new CategoriesService();
        const categoriesResponse = await categoriesService.getCategories();

        const recoveredCategories = categoriesResponse.map(category => ({
          id: category.category_id,
          title: category.title,
        }));

        setCategories(recoveredCategories);
      } catch {
        toast.error(
          'Desculpe não foi possível recuperar as categorias, tente novamente.',
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      Object.assign(filterSelected, filterPricesSelected, {
        flag: ['course', 'retreat'],
        like: true,
        relations: [
          'reviews',
          'content_user',
          // 'progress',
          'authors',
          'tags',
          'categories',
        ],
      });

      try {
        setLoading(true);
        const userService = new UserService();

        const coursesIds = await userService
          .getContentsPurchaseIds()
          .then(res => res.data);

        const contentsService = new ContentsService();
        const response = await contentsService.searchContents(
          filterSelected,
          offset,
        );

        setCourses(
          response.data.map(item =>
            cardCourseHook.getCardCourseData(item, coursesIds),
          ),
        );

        setPageCount(
          getPageCount(response.count, ContentsServiceConstants.LIMIT),
        );
      } catch {
        toast.error(
          'Não foi possível recuperar os cursos desejados, tente novamente',
        );
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelected, offset, filterPricesSelected]);

  goToTop();

  return (
    <Container>
      <Header>
        <Overlay>
          <ImageFilter />
          <Breadcrumbs
            className="hidden-mobile"
            style={{ paddingLeft: '30px', paddingBottom: '10px' }}
            links={[
              {
                name: 'Meus Cursos',
                url: '/meus-cursos',
              },
              { name: 'Favoritos', url: '/favoritos' },
            ]}
          />
          <BreadcrumbMobile className="hidden-desktop">
            <Link to="/cursos">
              <img src={backArrow} alt="" />
              <p>Meus Cursos</p>
            </Link>
          </BreadcrumbMobile>
          <h1>
            Favoritos
            <span>Favoritos</span>
          </h1>
        </Overlay>
      </Header>

      <Content>
        <FiltersMobileButton onClick={handleOpenMobileFilter}>
          Filtros
        </FiltersMobileButton>
        <BadgeFiltersMobile className="hidden-desktop">
          {filterSelected.category_ids
            .concat(...pricesFilterSelected)
            .map(filter => (
              <span>
                {mapperFilterToBadge[filter]
                  ? mapperFilterToBadge[filter]
                  : filter}

                <button
                  type="button"
                  onClick={() => {
                    filterCourses('category_ids', filter, false);
                    filterCoursesByPrices(filter, false);
                  }}
                >
                  <AiOutlineClose />
                </button>
              </span>
            ))}
        </BadgeFiltersMobile>
        <FiltersMobile ref={mobileFilterRef}>
          <h2>Filtros</h2>

          <CloseButtonMobile onClick={handleCloseMobileFilter}>
            <AiOutlineClose
              style={{
                color: theme.colors.tertiary,
                width: '20px',
                height: '20px',
              }}
            />
          </CloseButtonMobile>

          <Filter>
            <Title>
              <h3>Tipo</h3>
              <button type="button" onClick={handleToggleTypeFilter}>
                {typesFilterOpen ? <BiChevronUp /> : <BiChevronDown />}
              </button>
            </Title>

            <FilterOptions isOpen={typesFilterOpen}>
              <FilterOption>
                <input
                  type="checkbox"
                  name="available"
                  id="available"
                  onChange={event => {
                    filterCourses('type', 'courses', event.target.checked);
                    filterCourses('type', 'on-demand', event.target.checked);
                  }}
                />
                <label htmlFor="available">Cursos</label>
              </FilterOption>

              <FilterOption>
                <input
                  type="checkbox"
                  name="free"
                  id="free"
                  onChange={event =>
                    filterCourses('type', 'retreats', event.target.checked)
                  }
                />
                <label htmlFor="free">Vivências</label>
              </FilterOption>
            </FilterOptions>
          </Filter>

          <Filter>
            <Title>
              <h3>Categorias</h3>
              <button type="button" onClick={handleToggleCategoriesFilter}>
                {categoriesFilterOpen ? <BiChevronUp /> : <BiChevronDown />}
              </button>
            </Title>

            <FilterOptions isOpen={categoriesFilterOpen}>
              {categories.map(category => (
                <FilterOption>
                  <input
                    type="checkbox"
                    name={category.title}
                    id={category.title}
                    onChange={event =>
                      filterCourses(
                        'category_ids',
                        category.id,
                        event.target.checked,
                      )
                    }
                    checked={filterSelected?.category_ids.find(
                      selectedCategory => {
                        if (selectedCategory === category.id) return true;
                        return false;
                      },
                    )}
                  />
                  <label htmlFor={category.title}>{category.title}</label>
                </FilterOption>
              ))}
            </FilterOptions>
          </Filter>

          <Filter>
            <Title>
              <h3>Preço</h3>
              <button type="button" onClick={handleTogglePriceFilter}>
                {pricesFilterOpen ? <BiChevronUp /> : <BiChevronDown />}
              </button>
            </Title>

            <FilterOptions isOpen={pricesFilterOpen}>
              <FilterOption>
                <input
                  type="checkbox"
                  name="toBuy"
                  id="toBuy"
                  onChange={event =>
                    filterCoursesByPrices('toBuy', event.target.checked)
                  }
                  checked={pricesFilterSelected.includes('toBuy')}
                />
                <label htmlFor="toBuy">Para comprar</label>
              </FilterOption>

              <FilterOption>
                <input
                  type="checkbox"
                  name="available"
                  id="available"
                  onChange={event =>
                    filterCoursesByPrices('available', event.target.checked)
                  }
                  checked={pricesFilterSelected.includes('available')}
                />
                {/* TODO a label a seguir pode ser 'Disponíveis' ou 'Comprados' dependendo se o usuário logado possui ou não uma assinatura */}
                <label htmlFor="available">Disponíveis</label>
              </FilterOption>

              <FilterOption>
                <input
                  type="checkbox"
                  name="free"
                  id="free"
                  onChange={event => {
                    setOffset(0);
                    setPageCount(1);

                    setFilterPricesSelected(state => ({
                      ...state,
                      free: !!event.target.checked,
                    }));
                  }}
                />
                <label htmlFor="free">Gratuitos</label>
              </FilterOption>
            </FilterOptions>
          </Filter>

          <FilterButtonContainer>
            <Button
              title="APLICAR FILTROS"
              type="button"
              onClick={handleCloseMobileFilter}
              containerStyle={{
                width: 200,
              }}
            />
          </FilterButtonContainer>
        </FiltersMobile>
        <Filters>
          <h2>Filtros</h2>

          <Filter>
            <Title>
              <h3>Tipo</h3>
              <button type="button" onClick={handleToggleTypeFilter}>
                {typesFilterOpen ? <BiChevronUp /> : <BiChevronDown />}
              </button>
            </Title>

            <FilterOptions isOpen={typesFilterOpen}>
              <FilterOption>
                <input
                  type="checkbox"
                  name="available"
                  id="available"
                  onChange={event => {
                    filterCourses('type', 'courses', event.target.checked);
                    filterCourses('type', 'on-demand', event.target.checked);
                  }}
                />
                <label htmlFor="available">Cursos</label>
              </FilterOption>

              <FilterOption>
                <input
                  type="checkbox"
                  name="free"
                  id="free"
                  onChange={event =>
                    filterCourses('type', 'retreats', event.target.checked)
                  }
                />
                <label htmlFor="free">Vivências</label>
              </FilterOption>
            </FilterOptions>
          </Filter>

          <Filter>
            <Title>
              <h3>Categorias</h3>
              <button type="button" onClick={handleToggleCategoriesFilter}>
                {categoriesFilterOpen ? <BiChevronUp /> : <BiChevronDown />}
              </button>
            </Title>

            <FilterOptions isOpen={categoriesFilterOpen}>
              {categories.map(category => (
                <FilterOption>
                  <input
                    type="checkbox"
                    name={category.title}
                    id={category.title}
                    onChange={event =>
                      filterCourses(
                        'category_ids',
                        category.id,
                        event.target.checked,
                      )
                    }
                  />
                  <label htmlFor={category.title}>{category.title}</label>
                </FilterOption>
              ))}
            </FilterOptions>
          </Filter>

          <Filter>
            <Title>
              <h3>Preço</h3>
              <button type="button" onClick={handleTogglePriceFilter}>
                {pricesFilterOpen ? <BiChevronUp /> : <BiChevronDown />}
              </button>
            </Title>

            <FilterOptions isOpen={pricesFilterOpen}>
              <FilterOption>
                <input
                  type="checkbox"
                  name="toBuy"
                  id="toBuy"
                  onChange={event => {
                    setOffset(0);
                    setPageCount(1);

                    setFilterPricesSelected(state => ({
                      ...state,
                      to_purchase: event.target.checked,
                    }));
                  }}
                />
                <label htmlFor="toBuy">Para comprar</label>
              </FilterOption>

              <FilterOption>
                <input
                  type="checkbox"
                  name="available"
                  id="available"
                  onChange={event => {
                    setOffset(0);
                    setPageCount(1);

                    setFilterPricesSelected(state => ({
                      ...state,
                      purchased: event.target.checked,
                    }));
                  }}
                />
                {/* TODO a label a seguir pode ser 'Disponíveis' ou 'Comprados' dependendo se o usuário logado possui ou não uma assinatura */}
                <label htmlFor="available">Comprados</label>
              </FilterOption>

              <FilterOption>
                <input
                  type="checkbox"
                  name="free"
                  id="free"
                  onChange={event => {
                    setOffset(0);
                    setPageCount(1);

                    setFilterPricesSelected(state => ({
                      ...state,
                      free: !!event.target.checked,
                    }));
                  }}
                />
                <label htmlFor="free">Gratuitos</label>
              </FilterOption>
            </FilterOptions>
          </Filter>
        </Filters>

        {!loading && courses.length === 0 ? (
          <h3 className="unfinished-label">
            Você ainda não favoritou nenhum conteúdo!
          </h3>
        ) : null}

        {loading ? (
          <CoursesGrid>
            {[...Array(12).keys()].map(item => (
              <CardCourseSkeleton key={item} />
            ))}
          </CoursesGrid>
        ) : (
          <CoursesGrid>
            {courses.map(item => (
              <CardCourse key={item.id} data={item} />
            ))}

            <Pagination>
              <ReactPaginate
                initialPage={0}
                forcePage={offset / ContentsServiceConstants.LIMIT}
                disableInitialCallback
                previousLabel={<BiChevronLeft />}
                nextLabel={<BiChevronRight />}
                nextClassName="nextButton"
                previousClassName="previousButton"
                previousLinkClassName="previousContainerButton"
                nextLinkClassName="nextContainerButton"
                containerClassName="pagination"
                disabledClassName="disabledButtons"
                activeLinkClassName="activeLink"
                pageClassName="page"
                breakClassName="page"
                breakLabel="..."
                pageCount={pageCount}
                marginPagesDisplayed={1}
                onPageChange={page => {
                  setOffset(
                    (page.selected + 1) * ContentsServiceConstants.LIMIT -
                      ContentsServiceConstants.LIMIT,
                  );
                }}
              />
            </Pagination>
          </CoursesGrid>
        )}
      </Content>
    </Container>
  );
};

export default Favorites;
