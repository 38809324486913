import axiosApi from './api';

class AuthService {
  constructor() {
    this.api = axiosApi;
  }

  async login({ email, password, keepLoggedIn }) {
    return this.api
      .post('session/login', { email, password, keepLoggedIn })
      .then(res => {
        return res.data;
      });
  }

  async socialLogin({ accessToken, tokenId }) {
    return this.api.post('session/social', {
      access_token: accessToken,
      id_token: tokenId,
    });
  }

  async register({
    email,
    password,
    passwordConfirmation,
    name,
    document,
    address,
  }) {
    return this.api.post(
      'session/signup',
      {
        email,
        password,
        password_confirm: passwordConfirmation,
        name,
        document,
        term_accepted: true,
        address,
      },
      {
        headers: {
          authorization: `Bearer ${process.env.REACT_APP_UNLOGGED_TOKEN}`,
        },
      },
    );
  }

  async registerSocial({ accessToken, tokenId }) {
    return this.api.post('session/signup', {
      accessToken,
      tokenId,
    });
  }

  async firstLogin({ name, email, phoneNumber, password, document }) {
    return axiosApi.put('session/first-login', {
      name,
      email,
      phone_number: phoneNumber,
      password,
      document,
    });
  }
}

export { AuthService };
