import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { BiChevronRight, BiChevronLeft } from 'react-icons/bi';
import ReactPaginate from 'react-paginate';

import HeaderLogged from '../../components/HeaderLogged';
import NewFooterWithIconsLogged from '../../components/NewFooterWithIconsLogged';
import { useAuth } from '../../hooks/auth';
import UnloggedFooter from '../../layouts/unlogged/components/UnloggedFooter';
import UnloggedHeader from '../../layouts/unlogged/components/UnloggedHeader';
import { listFaqs } from '../../services/faq';

import ContactUsForm from './components/ContactUsForm';
import FAQItem from './components/FAQItem';
import {
  Container,
  FAQList,
  IntroHeader,
  Pagination,
  HeaderBackdrop,
} from './style';

export const FAQ = () => {
  const { user } = useAuth();
  const [faqsByPage, setFaqsByPage] = useState([[]]);
  const [pagination, setPagination] = useState({
    page: 0,
    total: 0,
    limit: 8,
  });

  const handleUpdatePagination = updates => {
    setPagination(state => ({
      ...state,
      ...updates,
    }));
  };

  const handleListFaqs = useCallback(
    async (search, reset = false) => {
      const { page, limit } = pagination;
      if (!faqsByPage[page]?.length && !reset) {
        const faqs = await listFaqs({ limit, offset: page * limit });
        faqsByPage[page] = faqs.data;

        setFaqsByPage([...faqsByPage]);
        handleUpdatePagination({ total: faqs.total });
      }
    },
    [faqsByPage, pagination],
  );

  const itemsToShow = useMemo(() => {
    return faqsByPage[pagination.page]?.map(({ faq_id, question, answer }) => (
      <li key={faq_id}>
        <FAQItem id={faq_id} question={question} answer={answer} />
      </li>
    ));
  }, [faqsByPage, pagination]);

  useEffect(() => {
    window.scrollTo(0, 0);
    handleListFaqs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.page]);

  return (
    <>
      <>
        {user ? <HeaderLogged /> : <UnloggedHeader />}
        <HeaderBackdrop />
      </>
      <Container>
        <IntroHeader>
          <h1 id="page-title">Perguntas frequentes</h1>
          <h4 id="page-subtitle">
            Tem alguma dúvida? Não se preocupe, vamos tentar respondê-las.
          </h4>
        </IntroHeader>
        <FAQList>{itemsToShow}</FAQList>
        <Pagination>
          <ReactPaginate
            initialPage={0}
            forcePage={0}
            previousLabel={<BiChevronLeft />}
            nextLabel={<BiChevronRight />}
            nextClassName="nextButton"
            previousClassName="previousButton"
            previousLinkClassName="previousContainerButton"
            nextLinkClassName="nextContainerButton"
            containerClassName="pagination"
            disabledClassName="disabledButtons"
            activeLinkClassName="activeLink"
            pageClassName="page"
            breakClassName="page"
            breakLabel="..."
            pageCount={Math.ceil(pagination.total / pagination.limit)}
            marginPagesDisplayed={1}
            onPageChange={({ selected }) => {
              handleUpdatePagination({ page: selected });
            }}
          />
        </Pagination>
      </Container>
      <ContactUsForm />
      {user ? <NewFooterWithIconsLogged /> : <UnloggedFooter />}
    </>
  );
};
