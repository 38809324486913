import styled from 'styled-components';

import myCourses from '../../assets/images/my-courses.jpg';
import { theme } from '../../styles/theme';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 480px) {
    max-width: 100%;
    margin: 0;
  }
`;

export const ImageFilter = styled.small`
  background: linear-gradient(
    90deg,
    rgba(89, 74, 173, 0.83) -5.53%,
    rgba(159, 151, 201, 0.3818) 60.04%,
    rgba(47, 24, 156, 0.83) 131.32%
  );
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
  width: 100%;
  height: 100%;
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  padding: 192px 24px 24px 24px;
  position: relative;

  @media (max-width: 560px) {
    height: 250px;

    padding: 0;

    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;

    span {
      position: absolute;
      top: 112px;
      left: 24px;
    }

    h1 {
      padding: 0 !important;
      margin: 0 !important;

      font-size: 40px;
      font-weight: bold;
      letter-spacing: 0.05em;

      position: absolute;
      top: 192px;
      left: 24px;

      span {
        padding: 0 !important;
        margin: 0 !important;

        position: absolute;
        top: -24px;
        left: 8px !important;
        bottom: unset !important;
        white-space: nowrap;
      }
    }
  }
`;

export const Background = styled.div`
  width: 100%;
  max-height: 428px;
  padding-top: 120px;
  background: linear-gradient(
    180deg,
    rgba(47, 24, 156, 0.35) 0%,
    rgba(47, 24, 156, 0.7) 100%
  );
  @media (max-width: 1280px) {
    height: 400px;
  }
  @media (max-height: 1080px) {
    height: 500px;
  }
  @media (max-height: 900px) {
    width: 100%;
    height: 657px;
  }
  @media (max-width: 480px) {
    width: 100%;
    height: 226px;
  }

  > img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    max-height: 428px;
    object-fit: cover;
    @media (max-width: 1280px) {
      max-height: 328px;
    }
    @media (max-height: 900px) {
      width: 100%;
      height: 657px;
    }
    @media (max-width: 480px) {
      width: 100%;
      height: 226px;
    }
  }

  h1 {
    font-weight: bold;
    font-size: 80px;
    padding: 30px 90px;
    letter-spacing: 0.05em;
    color: #ffffff;
  }

  h2 {
    position: absolute;
    left: 40px;
    top: 155px;
    font-weight: bold;
    font-size: 90px;
    line-height: 120px;
    letter-spacing: 0.05em;
    color: #2f189c;
    mix-blend-mode: multiply;
    opacity: 0.1;
    text-shadow: 0 2px 4px rgba(24, 39, 75, 0.12),
      0 4px 4px rgba(24, 39, 75, 0.08);
  }
`;

export const Header = styled.section`
  background: url(${myCourses});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  @media (max-width: 480px) {
    margin-bottom: 30px;
  }

  h1 {
    padding-left: 63px;
    font-size: 7rem;
    color: ${theme.colors.textLight};
    position: relative;
    z-index: 1;

    span {
      color: ${theme.colors.text};
      position: absolute;
      font-size: 8rem;
      opacity: 0.2;
      bottom: 10px;
      left: 24px;
      z-index: -1;
    }
  }

  @media (max-width: 1100px) {
    h1 {
      font-size: 2rem;
      z-index: 0;
      width: 90%;
      span {
        font-size: 5rem;
      }
    }
  }

  @media (max-width: 700px) {
    h1 {
      font-size: 2rem;

      span {
        font-size: 3rem;
      }
    }
  }

  @media (max-width: 444px) {
    h1 {
      font-size: 1.5rem;

      span {
        font-size: 2rem;
      }
    }
  }

  @media (max-width: 370px) {
    h1 {
      font-size: 2rem !important;

      span {
        font-size: 1.6rem;
      }
    }
  }
`;

export const CardsTop = styled.div`
  display: flex;
  align-items: center;

  @media screen and (min-width: 1101px) {
    padding-left: 80px;
  }
  @media (max-width: 700px) {
    flex-direction: column;
    padding: 0 24px;
  }
`;

export const CardsBottom = styled.div`
  display: flex;
  align-items: center;
  padding-left: 80px;
  @media (max-width: 480px) {
    flex-direction: column;
    padding: 0 16px;
  }
`;

export const BreadcrumbMobile = styled.span`
  display: flex;
  margin-bottom: 20px;

  a {
    display: flex;
    text-decoration: unset;

    img {
      padding-top: 2px;
      margin-right: 16px;
    }

    p {
      color: #fff;
      font-weight: 600;
      font-size: 16px;
    }
  }
`;
