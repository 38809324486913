import styled from 'styled-components';

import { theme } from '../../styles/theme';

export const ErrorMessage = styled.span``;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 480px) {
    padding: 0 16px;
  }

  h1,
  h2 {
    color: ${theme.colors.textLight};
    text-align: center;
  }

  h1 {
    margin-bottom: 40px;
    margin-top: 8px;
    @media (max-width: 480px) {
      font-weight: 600;
      font-size: 32px;
    }
  }

  h2 {
    margin-bottom: 8px;
    margin-top: 40px;
    @media (max-width: 480px) {
      font-weight: 500;
      font-size: 16px;
    }
  }

  @media (max-width: 900px) {
    height: auto;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.colors.light};
  border-radius: 20px;
  text-align: center;
  padding: 24px 20px;
  width: 445px;
  @media (max-width: 480px) {
    width: unset;
  }

  > span {
    color: ${theme.colors.primary};
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 16px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 445px;
    @media (max-width: 480px) {
      width: unset;
      max-width: 100%;
    }

    label {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 40px;
      font-size: 0.9rem;

      @media (max-width: 480px) {
        font-size: 14px;
        color: #657787;
      }

      input {
        margin-right: 6px;
        width: 20px;
        height: 20px;
        border: 1px solid #657787;
        border-radius: 2px;

        @media (max-width: 480px) {
          font-size: 14px;
          line-height: 20px;
        }
      }

      a {
        margin: 0 2px;
        color: #74b6d1;
        font-size: 14px;
        @media (max-width: 480px) {
          margin-left: 10px;
        }
      }
    }

    a {
      @media (max-width: 480px) {
        margin-left: 0;
        padding-top: 5px;
        width: 100%;
        text-align: start;
        padding-left: 25px;
        color: #74b6d1;
      }
    }
  }

  ${ErrorMessage} {
    display: block;
    font-size: 0.8rem;
    color: ${theme.colors.error};
    text-align: start;
    width: 100%;
    margin: 8px 0 0 10px;
  }

  @media (max-width: 480px) {
    padding: 10px;
    margin: 0;
  }
`;

export const AlreadyRegister = styled.section`
  display: flex;
  margin-top: 12px;
  margin-bottom: 76px;
  max-width: 445px;
  width: 100%;
  @media (max-width: 480px) {
    justify-content: center;
    padding-top: 32px;
  }

  button {
    text-align: start;

    h4 {
      font-size: 0.9rem;
      font-weight: 500;
      /* font-family: 'OpenSans'; */
      color: ${theme.colors.textLight};
    }
  }
`;

export const SpinnerWrapper = styled.div`
  margin-top: 32px;
`;
