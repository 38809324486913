import React, { useEffect, useState } from 'react';

import WindowSizeContext from '../contexts/WindowSizeContext';

const WindowSizeContextProvider = ({ children }) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    window.onresize = () => {
      setWindowSize(window.innerWidth);
    };
  }, []);

  return (
    <WindowSizeContext.Provider value={{ windowSize, setWindowSize }}>
      {children}
    </WindowSizeContext.Provider>
  );
};

export default WindowSizeContextProvider;
