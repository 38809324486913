import styled from 'styled-components';

const HomeAboutContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
  padding: 63px 16px;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const HomeAboutLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  & > div:first-child {
    padding: 0;
  }
`;

const HomeAboutRightContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  div.title {
    display: flex;
    align-items: flex-end;
    gap: 16px;

    > * {
      color: var(--default-blue);
    }

    > h2 {
      font-size: 1.6em;
      line-height: 90%;
    }

    > a {
      text-decoration: underline;
      font-size: 0.9em;
      font-weight: 600;
    }
  }

  > p {
    color: var(--default-font-black);
    font-size: 1.2em;
    font-weight: 500;
    line-height: 1.8em;
    text-align: left;
  }

  > button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 8px 32px;
    background: var(--default-blue);
    border-radius: 24px;
    height: 48px;
    max-width: 400px;

    font-size: 0.9em;
    font-weight: 600;
    color: white;
  }

  @media (min-width: 470px) {
    div.title {
      > h2 {
        font-size: 2em;
      }
    }

    > button {
      align-self: center;
      font-size: 1.2rem;
      width: 100%;
    }
  }
`;

export { HomeAboutContainer, HomeAboutLeftContent, HomeAboutRightContent };
