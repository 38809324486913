import React from 'react';
import { AiFillShopping } from 'react-icons/ai';
import { BsMailbox } from 'react-icons/bs';
import { MdOutlineWeb } from 'react-icons/md';
import { SiFacebook, SiInstagram, SiYoutube, SiWhatsapp } from 'react-icons/si';
import { Link } from 'react-router-dom';

import logoFooter from '../../assets/images/logo-footer.png';

import {
  Container,
  FooterHeader,
  HeaderLinks,
  SocialLinks,
  Copyright,
} from './styles';

const NewFooterWithIcons = () => {
  return (
    <Container>
      <FooterHeader>
        <img src={logoFooter} alt="IMA" />

        <hr />

        <HeaderLinks>
          <Link to="/leia-os-termos-de-uso">Termos de Uso</Link>
          <Link to="/politica-de-privacidade">Política de Privacidade</Link>
          <Link to="/faq">Fale Conosco</Link>
        </HeaderLinks>

        <hr />
      </FooterHeader>

      <SocialLinks>
        <a
          href="https://www.facebook.com/HaluGamashi.HG"
          target="_blank"
          rel="noreferrer"
        >
          <SiFacebook />
          Facebook
        </a>

        <a
          href="https://www.facebook.com/HaluGamashi.HG"
          target="_blank"
          rel="noreferrer"
        >
          <SiInstagram />
          Instagram
        </a>

        <a
          href="https://www.facebook.com/HaluGamashi.HG"
          target="_blank"
          rel="noreferrer"
        >
          <SiYoutube />
          Youtube
        </a>

        <a href="https://wa.me/5511950578895" target="_blank" rel="noreferrer">
          <SiWhatsapp />
          Whatsapp
        </a>

        <a href="https://halugamashi.com.br/" target="_blank" rel="noreferrer">
          <MdOutlineWeb />
          Site Halu Gamashi
        </a>

        <a href="https://maberu.com.br/" target="_blank" rel="noreferrer">
          <AiFillShopping />
          Nossa Loja
        </a>
      </SocialLinks>

      <hr />

      <Copyright>
        <span>
          &copy; Copyright {new Date().getUTCFullYear()} - Todos direitos
          reservados - HG CURSOS LTDA.
        </span>
      </Copyright>
    </Container>
  );
};

export default NewFooterWithIcons;
