import { yupResolver } from '@hookform/resolvers/yup';
import { format, differenceInDays } from 'date-fns';
import React, { useState, useEffect, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaRegAddressCard } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { ReactComponent as FileImg } from '../../assets/file-name.svg';
import { ReactComponent as ChakraIcon } from '../../assets/icons/fire.svg';
import { ReactComponent as TelephoneIcon } from '../../assets/icons/telephone.svg';
import { ReactComponent as UnlockedIcon } from '../../assets/unlocked.svg';
import AddressContainerIcon from '../../components/AddressIcon';
import Breadcrumbs from '../../components/Breadcrumbs';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import PaymentHistory from '../../components/PaymentHistory';
import Spinner from '../../components/Spinner';
import VerticalDivider from '../../components/VerticalDivider';
import brStates from '../../data/brStatesISO.json';
import countries from '../../data/countries.json';
import { useAuth } from '../../hooks/auth';
import * as gamification from '../../services/gamification';
import { cancelPlan } from '../../services/paymentsService';
import { getUserPayments, getUserSubscription } from '../../services/plans';
import { getUserPersonalData, putUserAddress } from '../../services/profile';
import { UserService } from '../../services/userService';
import { theme } from '../../styles/theme';
import { getMostRecentPlan } from '../../utils/getMostRecentPlan';
import { goToTop } from '../../utils/helpers';
import {
  regexPasswordPhrase,
  regexPasswordValidation,
} from '../../utils/regexPasswordValidation';
import ProgressBar from '../Course/RateCourse/ProgressBar';

import FlagIcon from './components/FlagIcon';
import GlobeIcon from './components/GlobeIcon';
import ModalPayment from './components/ModalPayment';
import PersonalData from './components/PersonalData';
import {
  AddressContainer,
  Container,
  Header,
  Overlay,
  ImageFilter,
  Cards,
  ProfileCard,
  SelectCustomStyle,
  ErrorMessage,
  SelectContainer,
  Card,
  PurchasesAndPlans,
  PlanInfo,
  Plan,
  ScoreStatus,
  PointsInfo,
  PaymentButtons,
  ChangePasswordContainer,
  SelectContainerState,
  HorizontalDivider,
  PaymentInfo,
  LevelsContainer,
  LevelInfo,
  PointsContainer,
  BadgeCanceledPlan,
  AddressRowContainer,
  SelectCustomAddressStyle,
} from './styles';

const schema = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),
  telephone: yup.string().required('Telefone é obrigatório'),
  country: yup.string().required('País é obrigatório'),
  state: yup.string().required('Estado é obrigatório'),
});

const passwordSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .required('Confirmação de senha atual é obrigatória'),
  newPassword: yup
    .string()
    .required('Nova senha é obrigatória')
    .matches(regexPasswordValidation, regexPasswordPhrase),
  confirmNewPassword: yup
    .string()
    .required('Nova senha é obrigatória')
    .oneOf([yup.ref('newPassword'), null], 'Senhas não conferem'),
});

const addressSchema = yup.object().shape({
  cpf: yup.string().required('CPF é obrigatório'),
  street: yup.string().required('Rua é obrigatório'),
  number: yup.string().required('Número é obrigatório'),
  neighborhood: yup.string().required('Bairro é obrigatório'),
  additional_details: yup.string().required('Complemento é obrigatório'),
  zipcode: yup.string().required('CEP é obrigatório'),
  city: yup.string().required('Cidade é obrigatório'),
  state: yup.string().required('Estado é obrigatório'),
});

goToTop();

const Profile = () => {
  const { storeUser } = useAuth();
  const history = useHistory();

  const [user, setUser] = useState({});
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countrySelected, setCountrySelected] = useState('');
  const [stateSelected, setStateSelected] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [modalCardData, setModalCardData] = useState(false);
  const [levels, setLevels] = useState([]);
  const [userLevel, setUserLevel] = useState({});
  const [percentageCompleted, setPercentageCompleted] = useState(0);
  const [dataPlan, setDataPlan] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [userLocation, setUserLocation] = useState({});
  const [selectStateAddress, setSelectStateAddress] = useState('');

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    formState: { errors: errorsPassword, isSubmitting: isSubmittingPassword },
  } = useForm({
    resolver: yupResolver(passwordSchema),
  });

  const {
    control: controlAddress,
    register: registerAddress,
    handleSubmit: handleSubmitAddress,
    setValue: setValueAddress,
    formState: { errors: errorsAddress, isSubmitting: isSubmittingAddress },
  } = useForm({
    resolver: yupResolver(addressSchema),
  });

  const toggleModalCardData = () => {
    setModalCardData(state => {
      if (state) {
        document.querySelector('body').style.overflow = 'auto';
      } else {
        document.querySelector('body').style.overflow = 'hidden';
      }

      return !state;
    });

    recoverPaymentData();
    getSubscription();
  };

  const openModal = () => {
    setModalOpen(true);
    document.querySelector('body').style.overflow = 'hidden';
  };

  const handleShowPurchasesHistory = async () => {
    openModal();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    document.querySelector('body').style.overflow = 'auto';
  };

  const handleUpdateProfile = async data => {
    try {
      const userService = new UserService();

      const updatedUserInfo = {
        name: data.name,
        info: {
          ...user.info,
          phone_number: data.telephone || '',
          country: data.country || '',
          state: data.state || '',
        },
      };

      const response = await userService.updateUser(updatedUserInfo);

      storeUser(response.data);
      toast.success('Dados atualizados com sucesso.');
    } catch (e) {
      toast.error(
        'Ocorreu um erro ao atualizar seus dados, por favor tente novamente.',
      );
    }
  };

  const handleUpdatePassword = async data => {
    try {
      const userService = new UserService();

      await userService.updatePassword(data.currentPassword, data.newPassword);

      toast.success('Senha atualizada com sucesso.');
      setChangePassword(false);
    } catch {
      toast.error('Senha atual inválida');
    }
  };

  const handleCancelSubscription = async () => {
    try {
      setSubscriptionLoading(true);
      await cancelPlan(currentPlan.plan_id);
      toast.success('Assinatura cancelada com sucesso!');
      recoverUserData();
    } catch {
      toast.error('Não foi possível cancelar a assinatura, tente novamente');
    } finally {
      setSubscriptionLoading(false);
    }
  };

  const handleChangeSubscription = () => {
    history.push('/perfil/selecionar-plano');
  };

  const levelIcons = (() => {
    return [
      ...Array(
        Math.abs(
          levels.findIndex(level => level.level_id === userLevel?.level_id) - 4,
        ),
      ).keys(),
    ];
  })();

  const opacity = index => {
    let bgColorOpacity = 1;
    if (index === 0) {
      bgColorOpacity = 1;
    } else if (index === 1) {
      bgColorOpacity = 0.9;
    } else if (index === 2) {
      bgColorOpacity = 0.8;
    } else if (index === 3) {
      bgColorOpacity = 0.7;
    } else {
      bgColorOpacity = 0.5;
    }

    return bgColorOpacity;
  };

  const calculatePercentage = (points, maxLevelPoints) => {
    return Number(((points / maxLevelPoints) * 100).toFixed(0));
  };

  const recoverUserData = useCallback(async () => {
    const userService = new UserService();

    const response = await userService.getUserData();

    storeUser(response.data);

    if (response.data?.info?.country && response.data?.info?.state) {
      setValue('country', response.data?.info?.country, {
        shouldDirty: true,
      });

      setValue('state', response.data?.info?.state, {
        shouldDirty: true,
      });
    }

    if (response.data?.info?.phone_number) {
      setValue('telephone', response.data?.info?.phone_number, {
        shouldDirty: true,
      });
    }

    setValue('name', response.data?.name, {
      shouldDirty: true,
    });

    const mostRecentPlan = getMostRecentPlan(response.data?.plans_paid);

    setUser(response.data);
    setUserLevel(response.data.level);

    setCurrentPlan(mostRecentPlan?.plan);
    setStateSelected(response.data?.info?.state);
    setCountrySelected(response.data?.info?.country);
    setName(response.data?.name);
    setPhoneNumber(response.data?.info?.phone_number);
    setDataPlan(mostRecentPlan);

    if (response.data?.level?.range?.end) {
      setPercentageCompleted(
        calculatePercentage(
          response.data.points,
          response.data.level.range.end,
        ),
      );
    } else {
      setPercentageCompleted(100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue]);

  const recoverPaymentData = useCallback(async () => {
    if (!currentPlan) return;

    const { data } = await getUserPayments();

    const lastTransaction = data.find(transaction => transaction.plan_id);

    if (
      lastTransaction &&
      lastTransaction?.status === 'refused' &&
      differenceInDays(new Date(), new Date(lastTransaction?.start_at)) <= 1
    ) {
      toast.error(
        '⚠️ A compra do seu plano foi recusada, por favor verifique seu email para tentar novamente, ou entre em contato com a administradora do cartão',
        {
          autoClose: false,
          position: 'bottom-right',
        },
      );
    }

    if (
      lastTransaction &&
      lastTransaction?.status === 'processing' &&
      differenceInDays(new Date(), new Date(lastTransaction?.start_at)) <= 1
    ) {
      toast.info('⚠️ O pagamento do seu plano esta sendo processado.', {
        autoClose: false,
        position: 'bottom-right',
        toastId: 'toast-payment-processing',
      });
    }
  }, [currentPlan]);

  const getSubscription = useCallback(async () => {
    const response = await getUserSubscription();

    setSubscription(response.data);
  }, []);

  const getUserLocation = useCallback(async () => {
    const response = await getUserPersonalData();
    const state = brStates.find(item => item.value === response.state)?.label;

    response.state = state;

    setUserLocation(response);

    setValueAddress('cpf', response.registry_code, {
      shouldDirty: true,
    });

    setValueAddress('street', response.street, {
      shouldDirty: true,
    });

    setValueAddress('neighborhood', response.neighborhood, {
      shouldDirty: true,
    });

    setValueAddress('number', response.number, {
      shouldDirty: true,
    });

    setValueAddress('zipcode', response.zipcode, {
      shouldDirty: true,
    });

    setValueAddress('city', response.city, {
      shouldDirty: true,
    });

    setValueAddress('additional_details', response.additional_details, {
      shouldDirty: true,
    });

    setValueAddress(
      'state',
      brStates.find(item => item.value === response.state),
      {
        shouldDirty: true,
      },
    );
  }, []);

  const handleUpdateAddress = async data => {
    try {
      const formattedParams = {
        ...data,
        zipcode: data.zipcode.replace(/\D/g, ''),
        country: 'BR',
        state: selectStateAddress,
      };

      await putUserAddress(formattedParams);
      toast.success('Dados atualizados com sucesso.');
    } catch (e) {
      toast.error(
        'Ocorreu um erro ao atualizar seus dados, por favor tente novamente.',
      );
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await gamification.getLevels();
        const levelsOrdered = response.data.sort((a, b) =>
          a.range.start < b.range.start ? 1 : -1,
        );

        setLevels(levelsOrdered);
      } catch {
        toast.error('Ocorreu um erro ao buscar os levels, tente novamente.');
      }
    })();

    recoverUserData();
  }, [recoverUserData]);

  useEffect(() => {
    recoverPaymentData();
  }, [recoverPaymentData]);

  useEffect(() => {
    getSubscription();
  }, [getSubscription]);

  useEffect(() => {
    getUserLocation();
  }, [getUserLocation]);

  function formatString(inputString) {
    if (inputString?.length !== 8) {
      return '';
    }

    const firstPart = inputString?.slice(0, 5);
    const secondPart = inputString?.slice(5);

    const formattedString = `${firstPart} ${secondPart}`;

    return formattedString;
  }

  return (
    <Container>
      <Header id="profile">
        <Overlay>
          <ImageFilter />
          <Breadcrumbs className="hidden-mobile" links={[{ name: 'Perfil' }]} />
          <Breadcrumbs
            className="hidden-desktop"
            links={[{ name: 'Perfil' }]}
            style={{ margin: '100px 0 0 16px ' }}
          />

          <PersonalData />
        </Overlay>
      </Header>

      <Cards>
        <ProfileCard>
          <h3>Perfil</h3>
          <h4>Veja aqui as suas informações pessoais.</h4>

          <form onSubmit={handleSubmit(handleUpdateProfile)}>
            <div>
              <Controller
                control={control}
                defaultValue={name}
                name="name"
                render={({ field: { onChange, ref } }) => (
                  <Input
                    ref={ref}
                    className="input-profile"
                    wrapperStyle={{
                      width: 'auto',
                      minWidth: 200,
                    }}
                    containerStyle={{
                      maxWidth: 397,
                      backgroundColor: '#F5F8FA',
                    }}
                    style={{ backgroundColor: '#F5F8FA' }}
                    placeholder="Nome"
                    icon={<FileImg />}
                    value={name}
                    onChange={event => {
                      setName(event.target.value);
                      onChange(event.target.value);
                    }}
                    isInvalid={errors.name?.message}
                    errorMessage={errors.name?.message}
                  />
                )}
              />

              <Controller
                control={control}
                defaultValue={phoneNumber}
                name="telephone"
                render={({ field: { onChange, ref } }) => (
                  <Input
                    ref={ref}
                    className="input-profile"
                    wrapperStyle={{
                      width: 'auto',
                      minWidth: 200,
                    }}
                    containerStyle={{
                      maxWidth: 397,
                      backgroundColor: '#F5F8FA',
                    }}
                    style={{ backgroundColor: '#F5F8FA' }}
                    placeholder="Telefone"
                    icon={<TelephoneIcon />}
                    isInvalid={errors.telephone?.message}
                    errorMessage={errors.telephone?.message}
                    type="number"
                    value={phoneNumber}
                    onChange={event => {
                      setPhoneNumber(event.target.value);
                      onChange(event.target.value);
                    }}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                control={control}
                defaultValue={countrySelected}
                name="country"
                render={({ field: { onChange, ref } }) => (
                  <SelectContainer error={errors.country?.message}>
                    <Select
                      ref={ref}
                      options={countries}
                      components={{ ValueContainer: GlobeIcon }}
                      placeholder={<span>País</span>}
                      styles={SelectCustomStyle}
                      value={
                        countrySelected
                          ? {
                              label: countrySelected,
                              value: countrySelected,
                            }
                          : null
                      }
                      defaultValue={countrySelected}
                      noOptionsMessage={() => 'Sem opções disponíveis'}
                      onChange={option => {
                        setCountrySelected(option.value);
                        onChange(option.value);
                      }}
                    />
                    {errors.country?.message ? (
                      <ErrorMessage>{errors.country?.message}</ErrorMessage>
                    ) : null}
                  </SelectContainer>
                )}
              />

              <Controller
                control={control}
                defaultValue={stateSelected}
                name="state"
                render={({ field: { onChange, ref } }) => (
                  <SelectContainerState error={errors.state?.message}>
                    <CreatableSelect
                      ref={ref}
                      options={countrySelected === 'Brasil' ? brStates : []}
                      components={{ ValueContainer: FlagIcon }}
                      placeholder={<span>Estado</span>}
                      styles={SelectCustomStyle}
                      formatCreateLabel={option => `Selecionar ${option}`}
                      noOptionsMessage={() => 'Sem opções disponíveis'}
                      defaultValue={stateSelected}
                      value={
                        stateSelected
                          ? {
                              label: stateSelected,
                              value: stateSelected,
                            }
                          : null
                      }
                      onChange={option => {
                        setStateSelected(option.value);
                        onChange(option.value);
                      }}
                    />
                    {errors.state?.message ? (
                      <ErrorMessage>{errors.state?.message}</ErrorMessage>
                    ) : null}
                  </SelectContainerState>
                )}
              />
            </div>

            {isSubmitting ? (
              <Spinner className="spinner" />
            ) : (
              <Button
                className="profile-button"
                title="ALTERAR DADOS"
                type="submit"
                containerStyle={{
                  maxWidth: 164,
                  marginTop: 40,
                  justifyContent: 'center',
                }}
              />
            )}
          </form>
        </ProfileCard>

        <Card>
          <h3>Alterar dados do Endereço</h3>
          <h4>
            Visualize ou altere os dados do seu endereço a qualquer momento.
          </h4>
          <form onSubmit={handleSubmitAddress(handleUpdateAddress)}>
            <AddressContainer>
              <AddressRowContainer>
                <Controller
                  control={controlAddress}
                  defaultValue={userLocation?.cpf}
                  name="cpf"
                  render={({ field: { onChange, ref } }) => (
                    <Input
                      ref={ref}
                      className="input-profile"
                      containerStyle={{
                        maxWidth: 397,
                        backgroundColor: '#F5F8FA',
                      }}
                      style={{ backgroundColor: '#F5F8FA' }}
                      placeholder="CPF"
                      icon={<FaRegAddressCard />}
                      value={userLocation?.cpf}
                      onChange={event => {
                        setUserLocation({
                          ...userLocation,
                          cpf: event.target.value,
                        });
                        onChange(event.target.value);
                      }}
                      isInvalid={errorsAddress.cpf?.message}
                      errorMessage={errorsAddress.cpf?.message}
                    />
                  )}
                />

                <Controller
                  control={controlAddress}
                  defaultValue={userLocation?.street}
                  name="street"
                  render={({ field: { onChange, ref } }) => (
                    <Input
                      ref={ref}
                      className="input-profile"
                      containerStyle={{
                        maxWidth: 397,
                        backgroundColor: '#F5F8FA',
                      }}
                      style={{ backgroundColor: '#F5F8FA' }}
                      placeholder="Rua"
                      icon={<FaRegAddressCard />}
                      value={userLocation?.street}
                      onChange={event => {
                        setUserLocation({
                          ...userLocation,
                          street: event.target.value,
                        });
                        onChange(event.target.value);
                      }}
                      isInvalid={errorsAddress.street?.message}
                      errorMessage={errorsAddress.street?.message}
                    />
                  )}
                />
              </AddressRowContainer>

              <AddressRowContainer>
                <Controller
                  control={controlAddress}
                  defaultValue={userLocation?.neighborhood}
                  name="neighborhood"
                  render={({ field: { onChange, ref } }) => (
                    <Input
                      ref={ref}
                      className="input-profile"
                      containerStyle={{
                        maxWidth: 397,
                        backgroundColor: '#F5F8FA',
                      }}
                      style={{ backgroundColor: '#F5F8FA' }}
                      placeholder="Bairro"
                      icon={<FaRegAddressCard />}
                      value={userLocation?.neighborhood}
                      onChange={event => {
                        setUserLocation({
                          ...userLocation,
                          neighborhood: event.target.value,
                        });
                        onChange(event.target.value);
                      }}
                      isInvalid={errorsAddress.neighborhood?.message}
                      errorMessage={errorsAddress.neighborhood?.message}
                    />
                  )}
                />

                <Controller
                  control={controlAddress}
                  defaultValue={formatString(userLocation?.zipcode)}
                  name="zipcode"
                  render={({ field: { onChange, ref } }) => (
                    <Input
                      ref={ref}
                      className="input-profile"
                      containerStyle={{
                        maxWidth: 397,
                        backgroundColor: '#F5F8FA',
                      }}
                      style={{ backgroundColor: '#F5F8FA' }}
                      placeholder="Código Postal"
                      icon={<FaRegAddressCard />}
                      value={userLocation?.zipcode}
                      mask="99999 999"
                      onChange={event => {
                        setUserLocation({
                          ...userLocation,
                          zipcode: event.target.value,
                        });
                        onChange(event.target.value);
                      }}
                      isInvalid={errorsAddress.zipcode?.message}
                      errorMessage={errorsAddress.zipcode?.message}
                    />
                  )}
                />
              </AddressRowContainer>

              <AddressRowContainer>
                <Controller
                  control={controlAddress}
                  defaultValue={userLocation?.number}
                  name="number"
                  render={({ field: { onChange, ref } }) => (
                    <Input
                      ref={ref}
                      className="input-profile"
                      containerStyle={{
                        maxWidth: 397,
                        backgroundColor: '#F5F8FA',
                      }}
                      style={{ backgroundColor: '#F5F8FA' }}
                      placeholder="Número"
                      icon={<FaRegAddressCard />}
                      value={userLocation?.number}
                      onChange={event => {
                        setUserLocation({
                          ...userLocation,
                          number: event.target.value,
                        });
                        onChange(event.target.value);
                      }}
                      isInvalid={errorsAddress.number?.message}
                      errorMessage={errorsAddress.number?.message}
                    />
                  )}
                />

                <Controller
                  control={controlAddress}
                  name="additional_details"
                  defaultValue={userLocation?.additional_details}
                  render={({ field: { onChange, ref } }) => (
                    <>
                      <Select
                        ref={ref}
                        options={[
                          { label: 'Casa', value: 'Casa' },
                          {
                            label: 'Apartamento',
                            value: 'Apartamento',
                          },
                        ]}
                        components={{
                          ValueContainer: AddressContainerIcon,
                        }}
                        value={
                          userLocation?.additional_details
                            ? {
                                label: userLocation?.additional_details,
                                value: userLocation?.additional_details,
                              }
                            : null
                        }
                        defaultValue={userLocation?.additional_details}
                        placeholder={<span>Complemento</span>}
                        styles={SelectCustomAddressStyle}
                        noOptionsMessage={() => 'Sem opções disponíveis'}
                        onChange={option => {
                          setUserLocation({
                            ...userLocation,
                            additional_details: option.value,
                          });
                          onChange(option.value);
                        }}
                      />
                      {errors.additional_details?.message ? (
                        <ErrorMessage>
                          {errors.additional_details?.message}
                        </ErrorMessage>
                      ) : null}
                    </>
                  )}
                />
              </AddressRowContainer>

              <AddressRowContainer>
                <Controller
                  control={controlAddress}
                  defaultValue={userLocation?.city}
                  name="city"
                  render={({ field: { onChange, ref } }) => (
                    <Input
                      ref={ref}
                      className="input-profile"
                      containerStyle={{
                        maxWidth: 397,
                        backgroundColor: '#F5F8FA',
                      }}
                      style={{ backgroundColor: '#F5F8FA' }}
                      placeholder="Cidade"
                      icon={<FaRegAddressCard />}
                      value={userLocation?.city}
                      onChange={event => {
                        setUserLocation({
                          ...userLocation,
                          city: event.target.value,
                        });
                        onChange(event.target.value);
                      }}
                      isInvalid={errorsAddress.city?.message}
                      errorMessage={errorsAddress.city?.message}
                    />
                  )}
                />

                <Controller
                  control={controlAddress}
                  name="state"
                  defaultValue={userLocation?.state}
                  render={({ field: { onChange, ref } }) => (
                    <>
                      <Select
                        ref={ref}
                        options={brStates}
                        components={{
                          ValueContainer: AddressContainerIcon,
                        }}
                        placeholder={<span>Estado</span>}
                        defaultValue={userLocation?.state}
                        value={
                          userLocation?.state
                            ? {
                                label: userLocation?.state,
                                value: userLocation?.state,
                              }
                            : null
                        }
                        styles={SelectCustomAddressStyle}
                        noOptionsMessage={() => 'Sem opções disponíveis'}
                        onChange={option => {
                          setUserLocation({
                            ...userLocation,
                            state: option.label,
                          });
                          setSelectStateAddress(option.value);
                          onChange(option.value);
                        }}
                      />
                      {errors.state?.message ? (
                        <ErrorMessage>{errors.state?.message}</ErrorMessage>
                      ) : null}
                    </>
                  )}
                />
              </AddressRowContainer>
            </AddressContainer>

            {isSubmittingAddress ? (
              <Spinner className="spinner" />
            ) : (
              <Button
                className="profile-button"
                title="ALTERAR DADOS DO ENDEREÇO"
                type="submit"
                containerStyle={{
                  maxWidth: 350,
                  marginTop: 20,
                  justifyContent: 'center',
                }}
              />
            )}
          </form>
        </Card>

        <Card planCanceled={dataPlan?.canceled_by_user} id="plan">
          <h3>Compras e Planos de assinatura</h3>
          <h4>
            Visualize, altere ou cancele seu plano de assinatura a qualquer
            momento.
          </h4>

          <PurchasesAndPlans>
            {dataPlan?.canceled_by_user && (
              <BadgeCanceledPlan>
                <span>Assinatura Cancelada</span>
              </BadgeCanceledPlan>
            )}

            <PlanInfo>
              <Plan planCanceled={dataPlan?.canceled_by_user}>
                <h5>{currentPlan?.title}</h5>
                <p>R$ {currentPlan?.price?.toFixed(2).replace('.', ',')}</p>

                {dataPlan?.finish_at && (
                  <span>
                    Valido até:{' '}
                    {format(new Date(dataPlan?.finish_at), 'dd/MM/yyyy')}
                  </span>
                )}
              </Plan>

              <HorizontalDivider />
              {currentPlan &&
                currentPlan?.info?.type !== 0 &&
                !dataPlan?.canceled_by_user && (
                  <PaymentInfo>
                    <p>Forma de pagamento:</p>

                    <h5>
                      {subscription?.payment_method?.code === 'credit_card' &&
                        'Cartão de crédito'}
                      {subscription?.payment_method?.code === 'bank_slip' &&
                        'Boleto'}
                      {subscription?.payment_method?.code === 'offsite' &&
                        'Paypal'}

                      {subscription?.payment_method?.code === 'pix' && 'Pix'}

                      {subscription?.payment_profile &&
                        ` :  •••• . •••• . •••• . ${subscription?.payment_profile?.card_number_last_four}`}
                    </h5>

                    <PaymentButtons>
                      <Button
                        className="profile-button"
                        title="ALTERAR DADOS DE PAGAMENTO"
                        type="submit"
                        onClick={toggleModalCardData}
                        containerStyle={{
                          justifyContent: 'center',
                          marginTop: 18,
                          height: 30,
                          maxWidth: 'max-content',
                          padding: 20,
                        }}
                      />

                      {subscriptionLoading && (
                        <div className="spinner">
                          <Spinner />
                        </div>
                      )}
                      {currentPlan?.info?.type !== 0 &&
                        !subscriptionLoading &&
                        !dataPlan?.canceled_by_user && (
                          <Button
                            className="profile-button"
                            title="CANCELAR ASSINATURA"
                            onClick={handleCancelSubscription}
                            type="submit"
                            containerStyle={{
                              justifyContent: 'center',
                              marginTop: 18,
                              height: 30,
                              maxWidth: 'max-content',
                              padding: 20,
                              background: 'transparent',
                            }}
                            buttonStyle={{
                              color: '#240F85',
                            }}
                          />
                        )}
                    </PaymentButtons>
                  </PaymentInfo>
                )}
            </PlanInfo>
          </PurchasesAndPlans>

          <PaymentButtons>
            <Button
              className="profile-button"
              title="VER HISTÓRICO DE COMPRAS"
              type="button"
              containerStyle={{
                justifyContent: 'center',
                marginTop: 18,
                height: 30,
                maxWidth: 'max-content',
                padding: 20,
              }}
              onClick={handleShowPurchasesHistory}
            />

            <Button
              className="profile-button"
              title="ALTERAR PLANO DE ASSINATURA"
              type="button"
              onClick={handleChangeSubscription}
              containerStyle={{
                justifyContent: 'center',
                marginTop: 18,
                height: 30,
                maxWidth: 'max-content',
                padding: 20,
                background: 'transparent',
              }}
              buttonStyle={{
                color: '#240F85',
              }}
            />
          </PaymentButtons>
        </Card>

        <Card id="password">
          <h3>Alterar senha</h3>
          <h4>Edite sua senha a qualquer momento para manter sua segurança!</h4>

          <form onSubmit={handleSubmitPassword(handleUpdatePassword)}>
            <ChangePasswordContainer alignCenter={changePassword}>
              <Input
                className="input-profile"
                containerStyle={{
                  maxWidth: 397,
                  backgroundColor: '#F5F8FA',
                }}
                disabled={!changePassword}
                style={{ backgroundColor: '#F5F8FA' }}
                placeholder={changePassword ? 'Senha atual' : '*********'}
                icon={<UnlockedIcon />}
                type="password"
                isInvalid={errorsPassword.currentPassword?.message}
                errorMessage={errorsPassword.currentPassword?.message}
                {...registerPassword('currentPassword')}
              />

              {changePassword ? (
                <>
                  <Input
                    className="input-profile"
                    containerStyle={{
                      maxWidth: 397,
                      backgroundColor: '#F5F8FA',
                    }}
                    style={{ backgroundColor: '#F5F8FA' }}
                    placeholder="Nova senha"
                    icon={<UnlockedIcon />}
                    type="password"
                    isInvalid={errorsPassword.newPassword?.message}
                    errorMessage={errorsPassword.newPassword?.message}
                    {...registerPassword('newPassword')}
                  />

                  <Input
                    className="input-profile"
                    containerStyle={{
                      maxWidth: 397,
                      backgroundColor: '#F5F8FA',
                    }}
                    style={{ backgroundColor: '#F5F8FA' }}
                    placeholder="Confirmar nova senha"
                    icon={<UnlockedIcon />}
                    type="password"
                    isInvalid={errorsPassword.confirmNewPassword?.message}
                    errorMessage={errorsPassword.confirmNewPassword?.message}
                    {...registerPassword('confirmNewPassword')}
                  />
                </>
              ) : null}
            </ChangePasswordContainer>

            <ChangePasswordContainer>
              {isSubmittingPassword ? (
                <Spinner className="spinner" />
              ) : (
                <Button
                  className="profile-button"
                  title={changePassword ? 'SALVAR NOVA SENHA' : 'ALTERAR SENHA'}
                  type={changePassword ? 'submit' : 'button'}
                  onClick={() =>
                    !changePassword
                      ? setTimeout(() => {
                          setChangePassword(true);
                        }, 200)
                      : null
                  }
                  containerStyle={{
                    maxWidth: 164,
                    marginTop: 40,
                    justifyContent: 'center',
                    marginRight: 16,
                  }}
                />
              )}

              {changePassword && !isSubmittingPassword ? (
                <Button
                  className="profile-button"
                  title="CANCELAR"
                  type="button"
                  onClick={() => setChangePassword(false)}
                  containerStyle={{
                    maxWidth: 164,
                    marginTop: 40,
                    justifyContent: 'center',
                    backgroundColor: 'transparent',
                    border: `1px solid ${theme.colors.secondary}`,
                    borderRadius: 28,
                  }}
                  buttonStyle={{
                    color: theme.colors.secondary,
                    letterSpacing: 0,
                  }}
                />
              ) : null}
            </ChangePasswordContainer>
          </form>
        </Card>

        <Card>
          <PointsContainer>
            <LevelsContainer>
              <h2>Jornada</h2>
              <h4>Confira a sua caminhada nos Mistérios Ascensionais</h4>

              <ScoreStatus>
                <h5>{userLevel?.name || levels[4]?.name}</h5>

                {userLevel?.name ? (
                  levelIcons.map(item => <ChakraIcon key={item} />)
                ) : (
                  <ChakraIcon />
                )}
              </ScoreStatus>

              {userLevel?.name ? (
                <ProgressBar
                  completed={Number(percentageCompleted) || 0}
                  ariaValuetext={percentageCompleted || 0}
                  maxCompleted={100}
                  width="100%"
                  isLabelVisible={!user.points ? 'none' : 'initial'}
                  bgColor={theme.colors.primary}
                  baseBgColor={theme.colors.shapeSecondary}
                />
              ) : (
                <ProgressBar
                  completed={0}
                  ariaValuetext={0}
                  maxCompleted={100}
                  width="100%"
                  isLabelVisible={!user.points ? 'none' : 'initial'}
                  bgColor={theme.colors.primary}
                  baseBgColor={theme.colors.shapeSecondary}
                />
              )}

              {userLevel?.range?.end ? (
                <PointsInfo>
                  <p>
                    <b>{user.points}</b>/{userLevel?.range?.end} pontos
                  </p>
                  <p>
                    <i>
                      <b>{Math.abs(userLevel?.range?.end - user.points)}</b>{' '}
                      para o próximo Passo da Caminhada
                    </i>
                  </p>
                </PointsInfo>
              ) : (
                <PointsInfo>
                  <p>{user.points} pontos</p>
                </PointsInfo>
              )}
            </LevelsContainer>

            <VerticalDivider />

            <LevelsContainer>
              <h3>Passos da Caminhada</h3>
              <h4>Confira os passos que você pode vivenciar nesta Caminhada</h4>
              {levels.map((level, index) => (
                <div>
                  <LevelInfo>
                    <h6>{level.name}</h6>

                    <div>
                      {[...Array(levels.length - index).keys()].map(item => (
                        <ChakraIcon
                          key={item.level_id}
                          opacity={opacity(index)}
                        />
                      ))}
                    </div>
                  </LevelInfo>
                  <ProgressBar
                    completed={100}
                    customLabel={`${level.range.start} + pontos`}
                    maxCompleted={100}
                    width="100%"
                    bgColor={theme.colors.primary}
                    baseBgColor={theme.colors.shapeSecondary}
                    opacity={opacity(index)}
                    labelAlignment="center"
                  />
                </div>
              ))}
            </LevelsContainer>
          </PointsContainer>
        </Card>
      </Cards>

      <PaymentHistory open={modalOpen} onClose={handleCloseModal} />

      {modalCardData && (
        <ModalPayment
          onFinishChange={toggleModalCardData}
          paymentType={subscription?.payment_method?.code}
        />
      )}
    </Container>
  );
};

export default Profile;
