import styled from 'styled-components';

import { theme } from '../../../styles/theme';

export const Container = styled.div`
  padding: 40px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
  position: relative;
  margin: 0 auto;

  & > svg {
    margin-bottom: 27px;
  }

  & > p {
    font-size: 1.5rem;
    margin-bottom: 24px;
    text-align: center;

    & > b {
      color: ${theme.colors.secondary};
    }

    b ~ b {
      color: ${theme.colors.text};
    }
  }

  span {
    font-weight: 400;
  }

  svg ~ svg {
    margin-top: 8px;
    margin-bottom: 40px;
  }

  @media (min-width: 768px) {
    max-width: 700px;

    & > p {
      font-size: 2.1rem;
    }

    & > svg {
      width: 162px;
      height: 136px;
    }

    & > span {
      width: 284px;
      text-align: start;
    }

    svg ~ svg {
      width: 420px;
      height: 16px;
    }
  }
`;

export const RateContainer = styled.button.attrs({ type: 'button' })`
  display: flex;
  align-items: center;
  margin-bottom: 48px;

  p {
    color: ${theme.colors.secondary};
    margin-right: 18px;
    font-weight: 500;
    font-size: 1rem;
  }

  & > img {
    width: 166px;
    height: 25px;
  }
`;

export const GamificationProgress = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > span {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    color: ${theme.colors.primary};
    font-weight: 600;
  }

  svg {
    width: 18px;
    height: 18px;
    margin-left: 8px;
  }
`;

export const NextLevelInfo = styled.div`
  margin-top: 4px;
  margin-right: 5px;
  margin-left: auto;
  text-align: right;

  span {
    color: ${theme.colors.textSecondary};

    b {
      color: ${theme.colors.text};
    }
  }

  p {
    font-weight: 500;
    font-size: 0.9rem;
    margin-top: 4px;
  }
`;
