import styled from 'styled-components';

export const ExamsContainer = styled.section`
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
  }
`;

export const ExamItem = styled.div`
  cursor: pointer;
  padding: 16px 0 16px 36px;
  background: ${props => (props.active ? '#f5f8fa' : 'none')};
  :hover {
    background: #f5f8fa;
  }
  position: relative;
  ::before {
    ${props =>
      props.active &&
      `content: '';
    background: #2F189C;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 6px;`};
  }
  display: flex;
  align-items: center;
  gap: 8px;
  h5 {
    font-weight: 500;
    font-size: 16px;
    color: #25282b;
  }
`;
