import { getHours, getMinutes } from 'date-fns';
import React from 'react';

import aoVivo from '../../assets/icons/icon-ao-vivo.svg';
import iconPlay from '../../assets/icons/icon-play.svg';

import {
  StatusLive,
  ContainerAccordion,
  Box,
  BoxLeft,
  BoxRight,
  BoxTitle,
} from './styles';

const BoxLiveDetail = ({ item, onClick }) => {
  function getStatus(statusLive) {
    let response = '';
    const statusUpper = statusLive?.toUpperCase();
    switch (statusUpper) {
      case 'CREATING':
        response = 'Em breve';
        break;

      case 'STARTING':
        response = 'Em breve';
        break;

      case 'ACTIVE':
        response = 'Ao vivo';
        break;

      case 'STOPPING':
        response = 'Parado';
        break;

      case 'STOPPED':
        response = 'Parado';
        break;

      case 'UNKNOWN':
        response = 'Indisponível';
        break;

      case 'SCHEDULED':
        response = 'Em breve';
        break;

      case 'FINISHED':
        response = 'Encerrada';
        break;

      case 'ERROR':
        response = 'Indisponível';
        break;

      default:
        response = '';
        break;
    }
    return response;
  }

  function getDate(dateLive) {
    const date = new Date(dateLive);
    const day = date.getDate();
    const weekDay = [
      'Domingo',
      'Segunda-feira',
      'Terça-feira',
      'Quarta-feira',
      'Quinta-feira',
      'Sexta-feira',
      'Sábado',
    ][date.getDay()];
    const month = [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ][date.getMonth()];
    return (
      <>
        <h4> {weekDay} </h4>
        <h2>
          {day} {month}
        </h2>
      </>
    );
  }

  const startAt = (() => {
    const date = new Date(item?.live?.start);
    return `${getHours(date)}:${getMinutes(date)}h`;
  })();

  const endAt = (() => {
    const date = new Date(item?.live?.stop);
    return `${getHours(date)}:${getMinutes(date)}h`;
  })();

  return (
    <ContainerAccordion onClick={onClick}>
      <Box>
        <BoxLeft>{item.live?.start && getDate(item.live?.start)}</BoxLeft>
        <div className="row">
          <BoxRight>
            <BoxTitle>
              <img src={iconPlay} alt="Play" />
              <h1>{item.name}</h1>
            </BoxTitle>

            <p>
              {startAt} as {endAt}
            </p>
          </BoxRight>
          <StatusLive>
            {item.live?.status === 'ACTIVE' && (
              <img className={item.live?.status} src={aoVivo} alt="ao Vivo" />
            )}
            <h6 className={item.live?.status}>
              {getStatus(item.live?.status)}
            </h6>
          </StatusLive>
        </div>
      </Box>
    </ContainerAccordion>
  );
};
export default BoxLiveDetail;
