import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  AiOutlineUser,
  AiFillCreditCard,
  AiFillIdcard,
  AiOutlineClose,
} from 'react-icons/ai';
import { BsFillCalendarFill } from 'react-icons/bs';
import { FaRegAddressCard } from 'react-icons/fa';
import Select from 'react-select';

import { ReactComponent as CheckCircle } from '../../../../assets/icons/check.svg';
import iconGenerateBillet from '../../../../assets/icons/icon-generate-billet.svg';
import iconTicket from '../../../../assets/icons/icon-ticket.png';
import paypalImage from '../../../../assets/icons/paypal-2700934-2239070.png';
import PixIcon from '../../../../assets/icons/pix.png';
import { ReactComponent as StarIcon } from '../../../../assets/icons/star.svg';
import { ReactComponent as WalletIcon } from '../../../../assets/icons/wallet.svg';
import brStates from '../../../../data/brStatesISO.json';
import { useAuth } from '../../../../hooks/auth';
import { usePaymentModal } from '../../../../hooks/paymentModal';
import { validateCoupon } from '../../../../services/coupon';
import {
  checkPaymentStatus,
  createPaymentWithBankSlip,
  createPaymentWithPaypal,
  createPaymentWitPix,
} from '../../../../services/paymentsService';
import { UserService } from '../../../../services/userService';
import { theme } from '../../../../styles/theme';
import { numToMoneyBr } from '../../../../utils/numToMoneyBr';
import { mappedErrors } from '../../../../utils/paymentsMappedErrors';
import {
  paymentYupValidation,
  paymentYupValidationBankSlip,
  paymentYupValidationGeneric,
} from '../../../../utils/paymentYupValidation';
import AddressContainerIcon from '../../../AddressIcon';
import { Button } from '../../../Button';
import ValueContainerCardIcon from '../../../CardIcon';
import { Input } from '../../../Input';
import Spinner from '../../../Spinner';
import { toast } from '../../../Toast/Toast';

import PaymentConfirmed from './PaymentConfirmed';
import {
  Overlay,
  Container,
  PurchaseDetails,
  ProductInfo,
  ProductPrice,
  PaymentForm,
  FormInputs,
  RowInputs,
  FormButtons,
  ProductInfoFooter,
  SavedCardsList,
  SavedCard,
  CardInfo,
  CloseButtonMobile,
  SelectFormOfPayment,
  ViewBilletCamp,
  TicketNumberCamp,
  ViewBillet,
  SelectContainer,
  SelectCustomStyle,
  ErrorMessage,
  ViewBilletPaypal,
  SelectFormOfPaymentOption,
  PixContainer,
  PixCopyAndPasteContainer,
  PixCopyAndPaste,
  CouponSection,
  AppliedCoupon,
  LabelDebitCreditCard,
  ContainerDebitCreditCard,
} from './styles';

const PaymentModal = () => {
  const [rememberCard, setRememberCard] = useState(false);
  const [newCardInputCheck, setNewCardInputCheck] = useState(false);
  const [savedCardSelected, setSavedCardSelected] = useState(false);
  const [coupon, setCoupon] = useState();
  const [payment, setPayment] = useState('credit_card');
  const [paymentBankSlipSuccess, setPaymentBankSlipSuccess] = useState(false);
  const [paymentBankSlip, setPaymentBankSlip] = useState(false);
  const [paymentPaypal, setPaymentPaypal] = useState(false);
  const [paymentPaypalSuccess, setPaymentPaypalSuccess] = useState(false);
  const [showUserAddressForm, setShowUserAddressForm] = useState(false);
  const [pixInfo, setPixInfo] = useState(null);
  const [installments, setInstallments] = useState('1');
  const [activeInstallment, setActiveInstallment] = useState();
  const [intervalId, setIntervalId] = useState(0);
  const pixLinkRef = useRef();

  const {
    modalData,
    closeModal,
    handleClickPayment,
    changeStatusLoading,
    changePaymentSuccess,
  } = usePaymentModal();
  const { token } = useAuth();
  const { product, isLoading, storedCard } = modalData;

  function getScheme() {
    if (payment === 'credit_card') {
      return paymentYupValidation;
    }
    if (payment === 'bank_slip') {
      return paymentYupValidationBankSlip;
    }
    if (payment === 'paypal') {
      return paymentYupValidationGeneric;
    }
    if (payment === 'pix') {
      return paymentYupValidationGeneric;
    }
    return null;
  }

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    setError,
    getValues,
  } = useForm({
    resolver: yupResolver(getScheme()),
  });

  const handleCopyBarCode = barCode => {
    navigator.clipboard.writeText(barCode);
    toast.info('Texto copiado!');
  };

  const handleSubmitPayment = async data => {
    const formattedData = {
      ...data,
    };

    const userService = new UserService();

    const userAddress = await userService
      .getUserCustomerAddress()
      .then(res => res.data)
      .catch(() => {
        changeStatusLoading(true);
        toast.error(
          'Erro ao tentar recuperar dados do usuário, tente novamente.',
        );
        return null;
      });

    delete userAddress.additional_details;

    const allFieldsOk = Object.values(userAddress).every(
      value => value !== null,
    );

    if (!allFieldsOk) {
      changeStatusLoading(false);
      setShowUserAddressForm(true);
      toast.info('Preencha os dados do endereço para continuar.');
      return;
    }

    delete formattedData.installments;
    delete formattedData.coupon;

    await handleClickPayment(
      rememberCard,
      formattedData,
      product.id,
      token,
      coupon?.code,
      installments,
      false,
      product,
    );
  };

  const handleSubmitPaymentStoredCard = async data => {
    const formattedData = {
      ...data,
    };

    const userService = new UserService();

    const userAddress = await userService
      .getUserCustomerAddress()
      .then(res => res.data)
      .catch(() => {
        changeStatusLoading(true);
        toast.error(
          'Erro ao tentar recuperar dados do usuário, tente novamente.',
        );
        return null;
      });

    delete userAddress.additional_details;

    const allFieldsOk = Object.values(userAddress).every(
      value => value !== null,
    );

    if (!allFieldsOk) {
      changeStatusLoading(false);
      setShowUserAddressForm(true);
      toast.info('Preencha os dados do endereço para continuar.');
      return;
    }

    delete formattedData.installments;
    delete formattedData.coupon;

    await handleClickPayment(
      null,
      formattedData,
      product.id,
      token,
      coupon?.code,
      installments,
      true,
      product,
    );
  };

  const handleSubmitPaymentBankSlip = async data => {
    changeStatusLoading(true);
    const objectFormatted = data;

    delete objectFormatted.expirationDate;
    delete objectFormatted.installments;
    delete objectFormatted.cardNumber;
    delete objectFormatted.cvv;
    delete objectFormatted.name;
    delete objectFormatted.document;
    delete objectFormatted.coupon;

    objectFormatted.cpf = objectFormatted.cpf
      .replaceAll('.', '')
      .replace('-', '');

    objectFormatted.zipcode = objectFormatted.zipcode.replace('-', '');
    objectFormatted.zipcode = objectFormatted.zipcode.replaceAll(' ', '');

    await createPaymentWithBankSlip(product.id, coupon?.code, objectFormatted)
      .then(res => {
        setPaymentBankSlip(res.data);
        setPaymentBankSlipSuccess(true);
      })
      .catch(err => {
        const parsedErrorMessage = mappedErrors.find(
          option => option.error === err?.response?.data?.message,
        );

        if (parsedErrorMessage) {
          toast.warning(parsedErrorMessage.message);
          return;
        }
        toast.error(
          'Erro ao registrar boleto, verifique os dados e tente novamente.',
        );
      })
      .finally(() => {
        changeStatusLoading(false);
      });
  };

  const handleSubmitPaymentPaypal = async () => {
    changeStatusLoading(true);

    await createPaymentWithPaypal(product.id, coupon?.code)
      .then(res => {
        setPaymentPaypal(res.data);
        setPaymentPaypalSuccess(true);
      })
      .catch(err => {
        const parsedErrorMessage = mappedErrors.find(
          option => option.error === err?.response?.data?.message,
        );

        if (parsedErrorMessage) {
          toast.warning(parsedErrorMessage.message);
          return;
        }

        toast.error('Erro ao enviar compra com o PayPal, tente novamente.');
      })
      .finally(() => {
        changeStatusLoading(false);
      });
  };

  const updateCustomerProfile = async (data, isCreditCard) => {
    changeStatusLoading(true);
    const objectFormatted = {
      ...data,
      country: 'BR',
    };

    delete objectFormatted.expirationDate;
    delete objectFormatted.installments;
    delete objectFormatted.cardNumber;
    delete objectFormatted.cvv;
    delete objectFormatted.name;
    delete objectFormatted.document;
    delete objectFormatted.coupon;

    objectFormatted.cpf = objectFormatted.cpf
      .replaceAll('.', '')
      .replace('-', '');

    objectFormatted.zipcode = objectFormatted.zipcode.replaceAll(' ', '');

    const userService = new UserService();

    try {
      await userService.updateUserCustomerAddress(objectFormatted);
      if (isCreditCard) {
        toast.info(
          'Dados do cartão atualizado com sucesso! Realize a confirmação do pagamento novamente.',
        );
        setShowUserAddressForm(false);
        return;
      }
      toast.info(
        'Endereço atualizado com sucesso! Realize a confirmação do Pix novamente.',
      );
      setShowUserAddressForm(false);
    } catch {
      toast.error('Erro ao atualizar endereço, tente novamente.');
    } finally {
      changeStatusLoading(false);
    }
  };

  const pollingCheckPixPaymentStatus = async userPaymentId => {
    try {
      const userPayment = await checkPaymentStatus(userPaymentId);

      if (userPayment?.data.status === 'paid') {
        setPixInfo(state => ({ ...state, status: 'paid' }));
        return;
      }
    } catch {
      toast.error(
        'Ocorreu um error ao verificar o status do pagamento, vamos tentar novamente aguarde...',
        { toastId: 'pix-polling-error' },
      );
    }
  };

  const handleSubmitPaymentPix = async () => {
    changeStatusLoading(true);

    const userService = new UserService();

    const userAddress = await userService
      .getUserCustomerAddress()
      .then(res => res.data)
      .catch(() => {
        changeStatusLoading(true);
        toast.error(
          'Erro ao tentar recuperar dados do usuário, tente novamente.',
        );
        return null;
      });

    delete userAddress.additional_details;

    const allFieldsOk = Object.values(userAddress).every(
      value => value !== null,
    );

    if (!allFieldsOk) {
      changeStatusLoading(false);
      setShowUserAddressForm(true);
      toast.info('Preencha os dados do endereço para continuar.');
      return;
    }

    const userPaymentPix = await createPaymentWitPix(product.id, coupon?.code)
      .then(response => {
        const { data } = response;

        const userPayment = {
          qrCode: data.qrcode,
          paymentUrl: data.payment_url,
          pixKey: data.pix_key,
          status: data.status,
          userPaymentId: data.user_payment_id,
        };

        setPixInfo(userPayment);
        return userPayment;
      })
      .catch(err => {
        const parsedErrorMessage = mappedErrors.find(
          option => option.error === err?.response?.data?.message,
        );

        if (parsedErrorMessage) {
          toast.warning(parsedErrorMessage.message, { autoClose: false });
          return null;
        }

        toast.error('Ocorreu um erro ao gerar o Pix, tente novamente.');
        return null;
      })
      .finally(() => {
        changeStatusLoading(false);
        if (pixLinkRef?.current) {
          pixLinkRef?.current?.scrollIntoView();
        }
      });

    if (!userPaymentPix) return;

    const id = setInterval(async () => {
      await pollingCheckPixPaymentStatus(userPaymentPix.userPaymentId);
    }, 2000);

    setIntervalId(id);
  };

  function paymentName(paymentType) {
    switch (paymentType) {
      case 'credit_card':
        return 'Dados do Cartão';
      case 'bank_slip':
        return 'Dados do Boleto';
      case 'paypal':
        return null;
      default:
        return '';
    }
  }

  const handleToggleNewCardInput = () => {
    setNewCardInputCheck(state => !state);
    setSavedCardSelected(() => false);
  };

  const handleSelectSavedCard = () => {
    setSavedCardSelected(state => !state);
    setNewCardInputCheck(() => false);
  };

  const handleClose = () => {
    reset({
      name: '',
      document: '',
      cardNumber: '',
      expirationDate: '',
      cvv: '',
    });
    setCoupon();
    closeModal();
    changePaymentSuccess(true);
    setPixInfo(null);
  };

  const handleCopyPixKey = pixKey => {
    navigator.clipboard.writeText(pixKey);
    toast.info('Chave copiada!');
  };

  const handleValidateCoupon = async () => {
    try {
      const userCoupon = await validateCoupon(getValues()?.coupon, product.id);
      setError('coupon', null);
      setCoupon(userCoupon);
    } catch (e) {
      setCoupon();
      setError('coupon', { message: 'Cupom invalido!' }, { shouldFocus: true });
    }
  };

  const couponValue = useMemo(() => {
    if (!coupon) return null;

    if (coupon?.coupon_type === 'amount') return numToMoneyBr(coupon.amount);
    return `${coupon.percentage}%`.replace('.', ',');
  }, [coupon]);

  const productFinalPrice = useMemo(() => {
    let price = +product?.info?.price;

    if (product.discount) {
      price -= product.discount;
    }

    if (!coupon) return price;

    if (coupon?.coupon_type === 'amount') {
      price -= coupon.amount;
    } else {
      price -= (price * coupon.percentage) / 100;
    }

    return price < 0 ? 0 : +price.toFixed(2);
  }, [coupon, product]);

  const installmentOptions = useMemo(() => {
    const options = [];

    if (!product?.info?.max_installments) return [];

    for (let index = 0; index < product.info.max_installments; index += 1) {
      const removeBRLCurrencyString = productFinalPrice;
      const numInstallment = index + 1;
      const installmentValue = (
        parseFloat(removeBRLCurrencyString, 2) / numInstallment
      )
        .toFixed(2)
        .replace('.', ',');

      const getInstallment = () => {
        if (numInstallment === 1) {
          return `${numInstallment}x parcela de R$ ${installmentValue}`;
        }
        if (numInstallment !== 1) {
          return `${numInstallment}x parcelas de R$ ${installmentValue}`;
        }
        return null;
      };
      options.push({
        label: `${getInstallment()}`,
        value: numInstallment,
      });
    }

    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFinalPrice, product, coupon]);

  useEffect(() => {
    setValue('installments', installmentOptions[0]?.value);
    setActiveInstallment(installmentOptions[0]);
  }, [installmentOptions, setValue]);

  useEffect(() => {
    if (!modalData.modalOpen && intervalId) {
      clearInterval(intervalId);
    }
  }, [modalData.modalOpen, intervalId]);

  return (
    <Overlay open={modalData.modalOpen}>
      <Container open={modalData.modalOpen}>
        <WalletIcon />

        <CloseButtonMobile onClick={handleClose}>
          <AiOutlineClose
            style={{
              color: theme.colors.tertiary,
              width: '20px',
              height: '20px',
            }}
          />
        </CloseButtonMobile>

        {modalData.paymentSuccess ? (
          <PaymentConfirmed />
        ) : (
          <>
            <h1>Faça seu pagamento</h1>
            <small>
              Após a confirmação do pagamento, aguarde 5 minutos e atualize sua
              página para liberar o acesso.
            </small>
            <SelectFormOfPayment>
              <SelectFormOfPaymentOption>
                <input
                  type="radio"
                  checked={payment === 'credit_card'}
                  onChange={() => setPayment('credit_card')}
                />
                <label>Cartão de crédito</label>
              </SelectFormOfPaymentOption>

              <SelectFormOfPaymentOption>
                <input
                  type="radio"
                  checked={payment === 'bank_slip'}
                  onChange={() => setPayment('bank_slip')}
                />
                <label>Boleto</label>
              </SelectFormOfPaymentOption>

              <SelectFormOfPaymentOption>
                <input
                  type="radio"
                  checked={payment === 'paypal'}
                  onChange={() => setPayment('paypal')}
                />
                <label>PayPal</label>
              </SelectFormOfPaymentOption>

              <SelectFormOfPaymentOption>
                <input
                  type="radio"
                  checked={payment === 'pix'}
                  onChange={() => setPayment('pix')}
                />
                <label>Pix</label>
              </SelectFormOfPaymentOption>
            </SelectFormOfPayment>

            {payment === 'credit_card' && (
              <ContainerDebitCreditCard>
                <LabelDebitCreditCard>
                  O pagamento via cartão de crédito é feito via débito
                  automático.
                </LabelDebitCreditCard>
              </ContainerDebitCreditCard>
            )}
            <PurchaseDetails>
              <ProductInfo>
                <h2>{product.name}</h2>

                <ProductInfoFooter>
                  <p>
                    por <span>{product.author}</span>
                  </p>
                  <p>
                    <StarIcon />
                    <span>{product.rating.value}</span>(
                    {product.rating.totalRatings})
                  </p>
                </ProductInfoFooter>
              </ProductInfo>

              <ProductPrice>
                <p>Por apenas</p>
                <h4>{product.price}</h4>
              </ProductPrice>
            </PurchaseDetails>

            {product.type === 'on-demand' && (
              <CouponSection>
                <div>
                  <h5>% Cupom</h5>
                  <Input
                    control={control}
                    wrapperStyle={{
                      maxWidth: 300,
                      margin: 0,
                    }}
                    placeholder="Código do cupom"
                    isInvalid={errors.coupon?.message}
                    name="coupon"
                  />
                  <button onClick={handleValidateCoupon} id="apply-coupon">
                    Aplicar cupom
                  </button>
                </div>
                {errors.coupon?.message ? (
                  <span id="error">{errors.coupon?.message}</span>
                ) : null}
              </CouponSection>
            )}

            {coupon ? (
              <AppliedCoupon>
                <div id="coupon-info">
                  <h5>Desconto aplicado</h5>
                  <span>Valor do desconto = {couponValue}</span>
                </div>
                <div id="final-price">
                  <h5>Valor final</h5>
                  <span>{numToMoneyBr(productFinalPrice)}</span>
                </div>
              </AppliedCoupon>
            ) : null}

            {payment === 'credit_card' && (
              <>
                {storedCard?.payment_company?.name &&
                !paymentBankSlipSuccess ? (
                  <SavedCardsList>
                    <SavedCard
                      aria-hidden="true"
                      onKeyDown={handleSelectSavedCard}
                      onClick={handleSelectSavedCard}
                    >
                      <label htmlFor="savedCard">
                        <input
                          id="savedCard"
                          type="radio"
                          checked={savedCardSelected}
                        />
                      </label>

                      <CardInfo>
                        <span>CARTÃO SALVO</span>
                        <p>
                          {storedCard?.payment_company?.name}
                          <span>{` •••• ${storedCard.card_number_last_four}`}</span>
                        </p>
                      </CardInfo>
                    </SavedCard>

                    {newCardInputCheck ? null : (
                      <SavedCard
                        aria-hidden="true"
                        onKeyDown={handleToggleNewCardInput}
                        onClick={handleToggleNewCardInput}
                      >
                        <label htmlFor="newCard">
                          <input
                            id="newCard"
                            type="radio"
                            checked={newCardInputCheck}
                          />
                        </label>

                        <CardInfo>
                          <p>Adicionar novo cartão</p>
                        </CardInfo>
                      </SavedCard>
                    )}
                  </SavedCardsList>
                ) : null}
              </>
            )}
            {payment === 'pix' && (
              <PixContainer>
                <h5>Pix</h5>

                {pixInfo?.qrCode && pixInfo?.status !== 'paid' ? (
                  <>
                    <p>Leia o QrCode abaixo para realizar o pagamento</p>

                    <iframe src={pixInfo?.qrCode} title="QrCode Pix" />
                  </>
                ) : (
                  <img src={PixIcon} alt="Pagamento com Pix" />
                )}

                {pixInfo && pixInfo?.status !== 'paid' && (
                  <PixCopyAndPasteContainer>
                    <p>Aguardando seu pagamento...</p>
                    <p>
                      Esta operação poderá levar até
                      <br />
                      30 minutos para ser reconhecida após o pagamento
                    </p>
                    <div className="spinner">
                      <Spinner />
                    </div>

                    <PixCopyAndPaste>
                      <p>Pix Copia e Cola</p>

                      <textarea readOnly>{pixInfo?.pixKey}</textarea>

                      <button
                        type="button"
                        onClick={() => handleCopyPixKey(pixInfo?.pixKey)}
                      >
                        COPIAR
                      </button>
                    </PixCopyAndPaste>
                    <a
                      href={pixInfo?.paymentUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      ref={pixLinkRef}
                    >
                      Ou clique aqui...
                    </a>
                  </PixCopyAndPasteContainer>
                )}

                {pixInfo && pixInfo?.status === 'paid' && (
                  <PixCopyAndPasteContainer>
                    <h1>Pagamento realizado com sucesso!</h1>
                  </PixCopyAndPasteContainer>
                )}
              </PixContainer>
            )}
            <form id="payment-form">
              {(newCardInputCheck || !storedCard) && !paymentBankSlipSuccess ? (
                <PaymentForm>
                  <h5
                    storedCard={storedCard}
                    aria-hidden="true"
                    onKeyDown={handleToggleNewCardInput}
                    onClick={handleToggleNewCardInput}
                  >
                    <CheckCircle />
                    {paymentName(payment)}
                  </h5>

                  <FormInputs>
                    {payment === 'credit_card' && (
                      <>
                        <Input
                          control={control}
                          containerStyle={{
                            minWidth: 90,
                          }}
                          placeholder={
                            payment === 'credit_card'
                              ? 'Nome do titular do cartão'
                              : 'Nome completo'
                          }
                          icon={<AiOutlineUser color="#909599" />}
                          isInvalid={errors.name?.message}
                          errorMessage={errors.name?.message}
                          name="name"
                        />

                        <Input
                          control={control}
                          containerStyle={{
                            minWidth: 90,
                          }}
                          placeholder="CPF ou CNPJ"
                          icon={<AiFillIdcard color="#909599" />}
                          isInvalid={errors.document?.message}
                          errorMessage={errors.document?.message}
                          mask="999.999.999-99"
                          name="document"
                        />

                        <Input
                          control={control}
                          containerStyle={{
                            minWidth: 90,
                          }}
                          placeholder="Número do cartão"
                          icon={<AiFillCreditCard color="#909599" />}
                          isInvalid={errors.cardNumber?.message}
                          errorMessage={errors.cardNumber?.message}
                          mask="9999 9999 9999 9999"
                          name="cardNumber"
                        />

                        <RowInputs>
                          <Input
                            control={control}
                            containerStyle={{
                              minWidth: 90,
                            }}
                            placeholder="Validade"
                            icon={<BsFillCalendarFill color="#909599" />}
                            isInvalid={errors.expirationDate?.message}
                            errorMessage={errors.expirationDate?.message}
                            mask="99/9999"
                            name="expirationDate"
                          />

                          <Input
                            control={control}
                            containerStyle={{
                              minWidth: 90,
                            }}
                            type="number"
                            placeholder="CVV"
                            icon={<AiFillCreditCard color="#909599" />}
                            isInvalid={errors.cvv?.message}
                            errorMessage={errors.cvv?.message}
                            name="cvv"
                          />
                        </RowInputs>
                        <label htmlFor="rememberCard">
                          <input
                            id="rememberCard"
                            type="checkbox"
                            onChange={() => setRememberCard(state => !state)}
                          />
                          Quero salvar os dados do cartão para futuras compras.
                        </label>
                      </>
                    )}
                  </FormInputs>
                </PaymentForm>
              ) : null}

              {!paymentBankSlipSuccess ? (
                <PaymentForm>
                  {payment === 'pix' && showUserAddressForm && (
                    <h2>Preencha seus dados</h2>
                  )}
                  {(payment === 'bank_slip' || showUserAddressForm) && (
                    <FormInputs>
                      <Input
                        control={control}
                        containerStyle={{
                          minWidth: 90,
                        }}
                        placeholder="CPF ou CNPJ"
                        icon={<AiFillIdcard color="#909599" />}
                        isInvalid={errors.cpf?.message}
                        errorMessage={errors.cpf?.message}
                        mask="999.999.999-99"
                        name="cpf"
                      />

                      <Input
                        control={control}
                        placeholder="Rua"
                        icon={<FaRegAddressCard />}
                        isInvalid={errors.street?.message}
                        errorMessage={errors.street?.message}
                        name="street"
                      />

                      <Input
                        control={control}
                        placeholder="Número"
                        icon={<FaRegAddressCard />}
                        isInvalid={errors.number?.message}
                        errorMessage={errors.number?.message}
                        name="number"
                      />

                      <Input
                        control={control}
                        placeholder="Bairro"
                        icon={<FaRegAddressCard />}
                        isInvalid={errors.neighborhood?.message}
                        errorMessage={errors.neighborhood?.message}
                        name="neighborhood"
                      />

                      <Controller
                        control={control}
                        name="additional_details"
                        render={({ field: { onChange, ref } }) => (
                          <SelectContainer
                            error={errors.additional_details?.message}
                          >
                            <Select
                              ref={ref}
                              options={[
                                { label: 'Casa', value: 'Casa' },
                                {
                                  label: 'Apartamento',
                                  value: 'Apartamento',
                                },
                              ]}
                              components={{
                                ValueContainer: AddressContainerIcon,
                              }}
                              placeholder={<span>Complemento</span>}
                              styles={SelectCustomStyle}
                              noOptionsMessage={() => 'Sem opções disponíveis'}
                              onChange={option => {
                                onChange(option.value);
                              }}
                            />
                            {errors.additional_details?.message ? (
                              <ErrorMessage>
                                {errors.additional_details?.message}
                              </ErrorMessage>
                            ) : null}
                          </SelectContainer>
                        )}
                      />

                      <Input
                        control={control}
                        placeholder="Código Postal"
                        icon={<FaRegAddressCard />}
                        mask="99999 999"
                        isInvalid={errors.zipcode?.message}
                        errorMessage={errors.zipcode?.message}
                        name="zipcode"
                      />

                      <Input
                        control={control}
                        placeholder="Cidade"
                        icon={<FaRegAddressCard />}
                        isInvalid={errors.city?.message}
                        errorMessage={errors.city?.message}
                        name="city"
                      />

                      <Controller
                        control={control}
                        name="state"
                        render={({ field: { onChange, ref } }) => (
                          <SelectContainer
                            style={{ marginBottom: 32 }}
                            error={errors.state?.message}
                          >
                            <Select
                              ref={ref}
                              options={brStates}
                              components={{
                                ValueContainer: AddressContainerIcon,
                              }}
                              placeholder={<span>Estado</span>}
                              styles={SelectCustomStyle}
                              noOptionsMessage={() => 'Sem opções disponíveis'}
                              onChange={option => {
                                onChange(option.value);
                              }}
                            />
                            {errors.state?.message ? (
                              <ErrorMessage>
                                {errors.state?.message}
                              </ErrorMessage>
                            ) : null}
                          </SelectContainer>
                        )}
                      />
                    </FormInputs>
                  )}
                </PaymentForm>
              ) : null}

              {payment === 'credit_card' && product?.price ? (
                <>
                  <h6>Parcelamento</h6>

                  <Controller
                    control={control}
                    name="installments"
                    render={({ field: { onChange, ref } }) => (
                      <SelectContainer error={errors.installments?.message}>
                        <Select
                          ref={ref}
                          options={installmentOptions}
                          components={{
                            ValueContainer: ValueContainerCardIcon,
                          }}
                          placeholder="Escolha um plano de parcelamento"
                          styles={SelectCustomStyle}
                          noOptionsMessage={() => 'Sem opções disponíveis'}
                          value={activeInstallment}
                          onChange={option => {
                            setInstallments(option.value);
                            setActiveInstallment(option);
                            onChange(option.value);
                          }}
                          menuPlacement="top"
                        />
                        {errors.installments?.message ? (
                          <ErrorMessage>
                            {errors.installments?.message}
                          </ErrorMessage>
                        ) : null}
                      </SelectContainer>
                    )}
                  />
                </>
              ) : null}

              {payment === 'bank_slip' ? (
                <ViewBilletCamp>
                  {paymentBankSlipSuccess && (
                    <>
                      <h5>Boleto gerado!</h5>
                      <p>
                        Você poderá pagar o boleto abaixo impresso ou por
                        aplicativo.
                      </p>
                      <ViewBillet>
                        <img src={iconTicket} alt="Visualizar boleto" />
                        <a
                          href={paymentBankSlip?.bank_slip_url}
                          download="boleto.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          VISUALIZAR BOLETO
                        </a>
                      </ViewBillet>
                      <h4>Código do boleto</h4>
                      <TicketNumberCamp>
                        <span>{paymentBankSlip?.barcode}</span>
                        <button
                          onClick={() =>
                            handleCopyBarCode(paymentBankSlip?.barcode)
                          }
                        >
                          <img src={iconGenerateBillet} alt="Gerar boleto" />
                        </button>
                      </TicketNumberCamp>
                    </>
                  )}
                </ViewBilletCamp>
              ) : null}

              {payment === 'paypal' && (
                <>
                  {paymentPaypalSuccess && (
                    <ViewBilletCamp>
                      <>
                        <h5>Pagamento com o Paypal gerado com sucesso!</h5>

                        <ViewBilletPaypal>
                          <img src={paypalImage} alt="Pagamento com PayPal" />
                          <a
                            href={paymentPaypal?.payment_url}
                            target="_blank"
                            rel="noreferrer"
                            onClick={closeModal}
                          >
                            ACESSAR PAYPAL
                          </a>
                        </ViewBilletPaypal>
                      </>
                    </ViewBilletCamp>
                  )}
                </>
              )}
              <FormButtons>
                {isLoading ? (
                  <Spinner />
                ) : (
                  <>
                    <Button
                      title="VOLTAR"
                      onClick={handleClose}
                      type="button"
                      containerStyle={{
                        justifyContent: 'center',
                        height: 30,
                        padding: 20,
                        background: 'transparent',
                        maxWidth: 280,
                        minWidth: 300,
                      }}
                      buttonStyle={{
                        color: '#240F85',
                      }}
                    />

                    {payment === 'credit_card' && !paymentBankSlipSuccess && (
                      <Button
                        title="CONFIRMAR COMPRA"
                        type="button"
                        onClick={
                          // eslint-disable-next-line consistent-return
                          () => {
                            if (savedCardSelected && showUserAddressForm) {
                              return updateCustomerProfile(getValues(), true);
                            }

                            if (savedCardSelected && !showUserAddressForm) {
                              return handleSubmitPaymentStoredCard(getValues());
                            }

                            if (!savedCardSelected && !showUserAddressForm) {
                              return handleSubmitPayment(getValues());
                            }

                            if (!savedCardSelected && showUserAddressForm) {
                              return updateCustomerProfile(getValues(), true);
                            }
                          }
                        }
                        containerStyle={{
                          justifyContent: 'center',
                          height: 30,
                          maxWidth: 280,
                          padding: 20,
                        }}
                      />
                    )}

                    {payment === 'bank_slip' && !paymentBankSlipSuccess && (
                      <Button
                        title="GERAR BOLETO"
                        type="button"
                        onClick={() =>
                          handleSubmit(handleSubmitPaymentBankSlip)()
                        }
                        containerStyle={{
                          justifyContent: 'center',
                          height: 30,
                          maxWidth: 280,
                          padding: 20,
                        }}
                      />
                    )}

                    {payment === 'paypal' &&
                      !paymentBankSlipSuccess &&
                      !paymentPaypalSuccess && (
                        <Button
                          title="PAGAR COM PAYPAL"
                          type="button"
                          onClick={() =>
                            handleSubmit(handleSubmitPaymentPaypal)()
                          }
                          containerStyle={{
                            justifyContent: 'center',
                            height: 30,
                            maxWidth: 280,
                            padding: 20,
                          }}
                        />
                      )}

                    {payment === 'pix' &&
                      !paymentBankSlipSuccess &&
                      !paymentPaypalSuccess &&
                      !pixInfo && (
                        <Button
                          title="CONFIRMAR PIX"
                          type="button"
                          onClick={
                            showUserAddressForm
                              ? handleSubmit(updateCustomerProfile)
                              : handleSubmitPaymentPix
                          }
                          containerStyle={{
                            maxWidth: 280,
                          }}
                        />
                      )}
                  </>
                )}
              </FormButtons>
            </form>
          </>
        )}
      </Container>
    </Overlay>
  );
};

export default PaymentModal;
