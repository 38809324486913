import React from 'react';
import styled from 'styled-components';

import BackgroundImg from '../assets/images/login-bg.png';
import FooterLoginAndRegister from '../components/FooterLoginAndRegister';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: url(${BackgroundImg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
`;

const RegisterLayoutRoutes = ({ children }) => {
  return (
    <Container>
      {children}
      <FooterLoginAndRegister />
    </Container>
  );
};

export { RegisterLayoutRoutes };
