import styled from 'styled-components';

import { theme } from '../../../../../../../styles/theme';

export const Container = styled.li`
  display: flex;
  flex-direction: column;
  list-style: none;
  background-color: transparent;
  padding: 24px 32px;
  position: relative;
  padding: 32px 100px;

  &:hover {
    background-color: ${theme.colors.light};
  }

  > img {
    max-width: 700px;
    max-height: 400px;
    margin-bottom: 24px;
  }

  @media (max-width: 800px) {
    padding: 12px;
  }

  @media (max-width: 500px) {
    > img {
      max-width: 200px;
      max-height: 200px;
    }
  }

  @media (max-width: 900px) {
    > img {
      max-width: 350px;
      max-height: 350px;
    }
  }
`;

export const Answer = styled.p`
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 28px;

  @media (max-width: 650px) {
    font-size: 0.9rem;
  }

  @media (max-width: 500px) {
    text-align: center;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;

  img {
    margin-right: 9px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  @media (max-width: 650px) {
    margin-top: 21px;
  }

  @media (max-width: 500px) {
    flex-direction: column;

    img {
      margin-right: 0;
    }
  }
`;

export const GamificationBadge = styled.span`
  margin: 0 16px;
  padding: 4px 12px;

  font-size: 0.8rem;
  font-weight: 600;
  color: ${theme.colors.light};

  background: ${theme.colors.primary};
  border-radius: 30px;
`;

export const UserName = styled.span`
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const Published = styled.span`
  font-size: 0.8rem;
  color: ${theme.colors.textSecondary};

  @media (max-width: 500px) {
    margin-top: 8px;
  }
`;

export const ActionIcons = styled.div`
  display: flex;
  align-items: center;
  max-width: 550px;
  margin-top: 24px;
  padding-bottom: 10px;

  span {
    display: flex;h
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:not(:first-of-type) {
      margin: 0 21px;
    }
  }

  svg {
    fill: ${theme.colors.secondary};
    margin-right: 8px;
    font-size: 18px;

    &.invert-orientation {
      transform: scaleX(-1);
    }
  }
`;

export const AnswerContainer = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 32px 40px 0;

  div.input {
    flex: 1;
  }

  .spinner {
    margin-left: 19px;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 19px;
  }
`;

export const MoreAnswers = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
  margin: 32px 0 auto;
  color: ${theme.colors.secondary};

  span {
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const CommentsList = styled.ul`
  scroll-behavior: smooth;
`;

export const TrashIcon = styled.span`
  display: flex;
  align-items: center;

  color: #ff0000;
  font-size: 0.9rem;
  z-index: 1;

  > svg {
    fill: #ff0000;
    margin-right: 10px;
  }

  @media (max-width: 650px) {
    position: static;
    margin-top: 12px;
  }

  @media (max-width: 500px) {
    justify-content: center;
  }
`;
