import styled, { css, keyframes } from 'styled-components';

import { theme } from '../../styles/theme';

const fadeInDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-1.25em);
  }
  
  100% {
    opacity: 1;
    transform: translateY(0);
}`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: ${theme.colors.light};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  position: relative;
  margin-top: 53px;
  width: 300px;

  ${({ isRecommended }) =>
    isRecommended &&
    css`
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;

      @media (max-width: 1100px) {
        margin-top: calc(53px + 32px);
      }
    `};

  @media (min-width: 400px) {
    width: 380px;
  }
`;

export const RecommendedLabel = styled.span`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  padding: 16px 0;
  background: ${theme.colors.secondary};
  color: ${theme.colors.textLight};
  font-weight: 500;
  font-size: 1.1rem;
  border-radius: 17px 17px 0 0;
  position: absolute;
  top: -53px;
  border: 2px solid #fff;
`;

export const Content = styled.div`
  padding: 20px 24px 40px 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1100px) {
    > .subscribe {
      margin: 20px 0 !important;
      min-width: 100% !important;
    }
  }
`;

export const PlanTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 32px;

  h6 {
    color: ${({ isFree }) =>
      isFree ? theme.colors.success : theme.colors.primary};
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
    margin: 0;
    padding: 0;
  }

  h3 {
    color: ${({ type }) =>
      type === 2 ? theme.colors.gold : theme.colors.text};
    font-weight: 700;
    font-size: 2.1rem;
    margin: 0;
    padding: 0;
    max-width: 90%;
  }
`;

export const IsFreeContainer = styled.div`
  background-color: #f5f8fa;
  background-position: center;
  min-height: 114px;
  width: 114%;
  margin-left: 50%;
  transform: translateX(-50%);
  padding: 5px 24px;
  position: relative;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 120px;
    height: 120px;
    z-index: -1;
  }

  h5 {
    color: ${theme.colors.primary};
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 8px;
    font-size: 1.8rem;
    text-align: center;
  }

  span {
    font-size: 0.8rem;
  }
`;

export const ValueInfo = styled.div`
  background-color: #f5f8fa;
  background-position: center;
  width: 112%;
  margin-left: 50%;
  transform: translateX(-50%);
  padding: 10px 24px;
  position: relative;

  img {
    position: absolute;
    right: 0;
    top: 0;
    width: 155px;
    height: 155px;
    z-index: -1;
  }

  p {
    color: ${theme.colors.text};
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 8px;
  }

  span {
    font-size: 0.8rem;
  }

  h5 {
    color: ${theme.colors.primary};
    display: flex;
    align-items: baseline;
    font-size: 2.7rem;

    h6 {
      font-size: 1.7rem;
      margin-right: 8px;
    }
  }
`;

export const Benefits = styled.ul`
  animation: ${fadeInDown} 0.5s;
  display: ${({ open }) => (open ? 'block' : 'none')};
  list-style: none;
  margin-top: 32px;
  flex: 1;

  li {
    display: flex;
    align-items: center;
    padding: 8px 0;

    img {
      width: 12px;
      height: 12px;
      margin-right: 8px;
    }
  }

  @media (min-width: 810px) {
    display: block;
  }
`;

export const BenefitsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
  font-weight: bold;
  color: ${theme.colors.secondary};
  font-size: 1rem;

  svg {
    font-size: 1.5rem;
  }

  @media (min-width: 810px) {
    display: none;
  }
`;
