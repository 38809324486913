import styled from 'styled-components';

import { theme } from '../../styles/theme';

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-top: 45px;

  span {
    z-index: 10;
    cursor: pointer;
    color: ${theme.colors.textLight};
    padding: 0 0 4px 15px;
  }

  div {
    display: flex;
    align-items: center;
  }

  h1 {
    padding-left: 63px;
    font-size: 7rem;
    color: ${theme.colors.textLight};
    position: relative;
    z-index: 1;

    span {
      color: ${theme.colors.text};
      position: absolute;
      font-size: 8rem;
      opacity: 0.2;
      bottom: 10px;
      left: 24px;
      z-index: -1;
    }
  }

  a {
    font-size: 16px;
    line-height: 24px;
    color: ${theme.colors.textLight};
    padding-left: 24px;
    z-index: 20;
  }

  @media (max-width: 1100px) {
    margin: 0;

    h1 {
      font-weight: 700;
      font-size: 31px;
      line-height: 44px;
      padding-bottom: 14px;

      span {
        display: none;
      }
    }
  }
`;

export const Content = styled.section`
  padding: 60px 190px;

  > div {
    h1 {
      margin-bottom: 30px;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 40px;
    }
    p {
      margin-bottom: 10px;
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: ${theme.colors.text};
    }
  }

  @media (max-width: 480px) {
    width: 100%;
    padding: 0;
    margin-bottom: 30px;

    > div {
      min-width: 300px;
      width: 80%;
      margin: 0 auto;

      h1 {
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        margin-top: 30px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  * {
    background-color: ${theme.colors.shape};
  }
`;
