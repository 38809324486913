import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// import { GoogleLogin } from 'react-google-login';
import { useForm } from 'react-hook-form';
import { useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';
// import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg';
import { ReactComponent as FileName } from '../../assets/icons/file-name.svg';
// import { ReactComponent as GoogleIcon } from '../../assets/icons/google.svg';
import { ReactComponent as UnlockedIcon } from '../../assets/icons/unlocked.svg';
import { Button } from '../../components/Button';
import HeaderLoginAndRegister from '../../components/HeaderLoginAndRegister';
import { Input } from '../../components/Input';
import Spinner from '../../components/Spinner';
import { useAuth } from '../../hooks/auth';
import { RegisterLayoutRoutes } from '../../routes/RegisterLayoutRoutes';
import { AuthService } from '../../services/authService';
import { goToTop } from '../../utils/helpers';
import {
  regexPasswordPhrase,
  regexPasswordValidation,
} from '../../utils/regexPasswordValidation';

import {
  Container,
  FormContainer,
  AlreadyRegister,
  ErrorMessage,
  SpinnerWrapper,
} from './styles';

const schema = yup.object().shape({
  name: yup
    .string()
    .min(3, 'Mínimo de 3 letras')
    .required('Nome é obrigatório'),
  email: yup
    .string()
    .email('Digite um email válido')
    .required('Email é obrigatório'),
  password: yup
    .string()
    .required('Senha é obrigatória')
    .matches(regexPasswordValidation, regexPasswordPhrase),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Senhas não conferem'),
  terms: yup
    .boolean()
    .required('Leia e aceite para continuar')
    .oneOf([true], 'Leia e aceite para continuar'),
});

const Register = () => {
  const [loading, setLoading] = useState(false);

  const auth = useAuth();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  // ! Login social temporariamente desabilitado
  // async function handleSocialRegister(data) {
  //   const authService = new AuthService();

  //   const { accessToken, tokenId } = data;

  //   setLoading(true);
  //   try {
  //     const response = await authService.registerSocial({
  //       accessToken,
  //       tokenId,
  //     });

  //     const user = {
  //       email: response.data.email,
  //       name: response.data.name,
  //       avatarUrl: response.data.avatarUrl,
  //     };

  //     auth.storeUser(user, response.data.token);
  //     setLoading(false);
  //     history.push('/obrigado-por-registrar');

  //     setLoading(false);
  //   } catch {
  //     setLoading(false);
  //     toast.error(
  //       'Ocorreu um erro ao realizar seu cadastro, por favor tente novamente',
  //     );
  //   }
  // }

  async function handleRegister(data) {
    const authService = new AuthService();
    const { email, password, passwordConfirmation, name, document, terms } =
      data;

    setLoading(true);
    try {
      const response = await authService.register({
        email,
        password,
        passwordConfirmation,
        name,
        document,
        terms,
      });

      const user = {
        email: response.data.email,
        name: response.data.name,
        document,
      };

      auth.storeUser(user, response.data.token);
      setLoading(false);
      localStorage.setItem('@HaluGamashi:email', email);
      history.push('/registro/confirmar-conta');
    } catch (err) {
      setLoading(false);
      toast.error(
        err.response?.data?.message ||
          'Ocorreu um erro ao realizar seu cadastro, por favor tente novamente',
      );
    }
  }

  function handleGoToLogin() {
    history.push('/logar');
  }

  goToTop();

  return (
    <RegisterLayoutRoutes>
      <Container>
        <HeaderLoginAndRegister
          className="hidden-mobile"
          buttonText="JÁ SOU ASSINANTE"
        />
        <HeaderLoginAndRegister
          className="hidden-desktop"
          buttonText="JÁ SOU "
          buttonSubText="ASSINANTE"
        />

        <h2 className="hidden-mobile">Passo 1 de 3</h2>
        <h2 className="hidden-desktop">Passo 1 de 3</h2>
        <h1>Crie sua conta</h1>

        <FormContainer>
          {/* <span>Cadastre com</span>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          onSuccess={res => handleSocialRegister(res)}
          onFailure={() => {
            toast.error(
              'Ocorreu um erro ao logar com o Google, tente novamente.',
            );
          }}
          cookiePolicy="single_host_origin"
          render={renderProps => (
            <Button
              title="Cadastrar com o Google"
              containerStyle={{
                margin: 4,
                backgroundColor: 'transparent',
                border: '1px solid #E1E3E6',
                borderRadius: 28,
              }}
              buttonStyle={{
                color: '#1372E5',
                fontFamily: 'Poppins',
                letterSpacing: 0,
              }}
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              icon={GoogleIcon}
            />
          )}
        />

        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          callback={res =>
            handleSocialRegister({
              accessToken: res.accessToken,
              tokenId: res.id,
            })
          }
          render={renderProps => (
            <Button
              title="Cadastrar com o Facebook"
              onClick={renderProps.onClick}
              icon={FacebookIcon}
              containerStyle={{
                margin: 4,
                backgroundColor: '#3B5998',
                borderRadius: 28,
              }}
              buttonStyle={{
                fontFamily: 'Poppins',
                letterSpacing: 0,
              }}
            />
          )}
        />

        <DividerWrapper>
          <Divider /> <span>ou</span> <Divider />
        </DividerWrapper> */}

          <form>
            <Input
              placeholder="Nome"
              icon={<FileName />}
              isInvalid={errors.name?.message}
              errorMessage={errors.name?.message}
              {...register('name')}
            />
            <Input
              placeholder="Email"
              type="email"
              icon={<EmailIcon />}
              isInvalid={errors.email?.message}
              errorMessage={errors.email?.message}
              {...register('email')}
            />

            <Input
              placeholder="Senha"
              type="password"
              icon={<UnlockedIcon />}
              isInvalid={errors.password?.message}
              errorMessage={errors.password?.message}
              {...register('password')}
            />

            <Input
              placeholder="Confirme a senha"
              type="password"
              icon={<UnlockedIcon />}
              isInvalid={errors.passwordConfirmation?.message}
              errorMessage={errors.passwordConfirmation?.message}
              {...register('passwordConfirmation')}
            />

            <label htmlFor="terms">
              <input type="checkbox" id="terms" {...register('terms')} />
              Li e aceito as
              <Link
                to="/politica-privacidade"
                target="_blank"
                className="hidden-mobile"
              >
                Políticas de Privacidade
              </Link>
              <span className="hidden-mobile"> e</span>
              <Link
                to="/termos-de-uso"
                target="_blank"
                className="hidden-mobile"
              >
                Termos de Uso
              </Link>
            </label>
            <Link
              to="/politica-privacidade"
              target="_blank"
              className="hidden-desktop"
            >
              Políticas de privacidade
            </Link>
            <span className="hidden-desktop"> e</span>
            <Link
              to="/termos-de-uso"
              target="_blank"
              className="hidden-desktop"
            >
              Termos de Uso
            </Link>
            {errors.terms?.message ? (
              <ErrorMessage>{errors.terms.message}</ErrorMessage>
            ) : null}

            {loading ? (
              <SpinnerWrapper>
                <Spinner />
              </SpinnerWrapper>
            ) : (
              <Button
                onClick={handleSubmit(handleRegister)}
                title="CADASTRAR"
                containerStyle={{ marginTop: 32 }}
              />
            )}
          </form>
        </FormContainer>

        <AlreadyRegister>
          <button onClick={handleGoToLogin}>
            <h4>Já tem sua conta? Entre agora</h4>
          </button>
        </AlreadyRegister>
      </Container>
    </RegisterLayoutRoutes>
  );
};

export default Register;
