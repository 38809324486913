import React, { useState, useRef, useEffect } from 'react';
import { CgProfile } from 'react-icons/cg';
import { FiLogOut } from 'react-icons/fi';
import { VscChevronDown } from 'react-icons/vsc';
import { useHistory } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { toast } from 'react-toastify';

import CloseButtonImage from '../../assets/icons/close-button.svg';
import sidebarMobile from '../../assets/icons/icones-sidebar-mobile.svg';
import LogoSmallImg from '../../assets/icons/logo-small.svg';
import { useAuth } from '../../hooks/auth';
import useMediaQuery from '../../hooks/useMediaQuery';
import useWindowSize from '../../hooks/useWindowSize';
import debounce from '../../utils/debounce';
import { Button } from '../Button';
import Notification from '../Notification';

import {
  Container,
  Dropdown,
  ProfileArea,
  ProfileIcon,
  SearchIconWrapper,
  SearchIcon,
  SearchInput,
  Box,
  LinksMobileHome,
  ContainerMobile,
  SidebarContainer,
  LinksList,
} from './styles';

const HeaderLogged = () => {
  const [openSearch, setOpenSearch] = useState();
  const history = useHistory();
  const auth = useAuth();
  const { user } = useAuth();
  const dropDownMobile = useRef(null);
  const searchInputRef = useRef(null);

  const isLargerThan1100 = useMediaQuery('(min-width: 1100px)');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const { windowSize } = useWindowSize();

  useEffect(() => {
    setIsSidebarOpen(false);
  }, [history.location]);

  function onOpen() {
    setIsSidebarOpen(true);
  }

  function onClose() {
    setIsSidebarOpen(false);
  }

  function handleGoToHome() {
    history.push('/home');
  }

  function openSearchInput() {
    setOpenSearch(true);
    searchInputRef?.current?.focus();
  }

  function handleGoToOfferPlans() {
    history.push('/perfil/selecionar-plano');
  }

  function handleSignOut() {
    auth.logout();
    history.push('/');
  }

  function closeDropdown() {
    if (dropDownMobile.current.children[1].style.display === 'block') {
      dropDownMobile.current.children[1].style.display = 'none';
    } else {
      dropDownMobile.current.children[1].style.display = 'block';
    }
  }

  function exclusiveAccess() {
    // TODO forum
    // if (userIsFree) {
    //   <a href="/registro/selecionar-plano">Fórum</a>;
    //   history.push('/registro/selecionar-plano');
    //   toast.error(
    //     'Conteúdo exclusivo para assinantes, verifique as opções de planos para ter acesso',
    //   );
    // } else {
    //   <a href="/registro/selecionar-plano">Fórum</a>;
    //   history.push('/forum');
    // }
    history.push('/forum');
  }

  async function handleSearchContents(inputSearch) {
    try {
      history.push(`/pesquisa?name=${inputSearch}`);
    } catch {
      toast.error(
        'Ocorreu um erro ao buscar por este conteúdo, tente novamente.',
      );
    }
  }

  const debounceSearchContents = debounce(handleSearchContents, 500);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropDownMobile.current &&
        !dropDownMobile.current.contains(event.target)
      ) {
        dropDownMobile.current.children[1].style.display = 'none';
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropDownMobile]);

  if (isLargerThan1100) {
    return (
      <Container>
        <Box>
          <img
            src={LogoSmallImg}
            alt="IMA"
            onClick={handleGoToHome}
            onKeyPress={handleGoToHome}
            aria-hidden="true"
          />
        </Box>

        <ul className="hidden-mobile">
          <li>
            <Link to="/home">Início</Link>
          </li>
          <li>
            <Link to="/cursos">Cursos</Link>
          </li>
          <li>
            <Link to="/vivencias">Vivências</Link>
          </li>
          <li>
            <a href="https://maberu.com.br/" rel="noreferrer" target="_blank">
              Loja
            </a>
          </li>
          <Dropdown>
            <span>
              <Link to="/meus-cursos">Meus Cursos</Link>
              <div>
                <Link to="/favoritos">Favoritos</Link>
                <Link to="/em-andamento">Em andamento</Link>
                <Link to="/finalizados">Finalizados</Link>
              </div>
            </span>
            <VscChevronDown />
          </Dropdown>
          <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link to="#" onClick={exclusiveAccess}>
              Fórum
            </Link>
          </li>
          <li>
            <Link to="/faq">Contato</Link>
          </li>
          <Dropdown>
            <span>
              Sobre
              <div>
                <Link to="/sobre-o-ima">Sobre o Instituto</Link>
                <Link to="/sobre-halu">Sobre Halu Gamashi</Link>
              </div>
            </span>
            <VscChevronDown />
          </Dropdown>
        </ul>

        <ProfileArea>
          {!openSearch ? (
            <>
              {user?.plans_paid ? null : (
                <Button
                  className="hidden-mobile"
                  title="ASSINE AGORA"
                  onClick={handleGoToOfferPlans}
                  containerStyle={{
                    minWidth: '20px',
                  }}
                  buttonStyle={{
                    fontSize: 14,
                  }}
                />
              )}
            </>
          ) : null}

          {openSearch ? (
            <SearchInput
              ref={searchInputRef}
              onMouseLeave={() => setOpenSearch(false)}
              onChange={event => debounceSearchContents(event.target.value)}
            />
          ) : (
            <SearchIconWrapper
              onClick={openSearchInput}
              onKeyPress={openSearchInput}
              aria-hidden="true"
            >
              <SearchIcon />
            </SearchIconWrapper>
          )}

          <Notification />

          <ProfileIcon className="ProfileIcon hidden-mobile">
            <ul>
              <Dropdown>
                {auth?.user?.images?.avatar ? (
                  <img src={auth.user.images.avatar} alt="Sua foto de perfil" />
                ) : (
                  <CgProfile alt="Sem foto de perfil" />
                )}
                <span>
                  Perfil
                  <div className="lastOpition">
                    <Link smooth to="/perfil#profile">
                      Meu perfil
                    </Link>
                    <Link smooth to="/history-notifications">
                      Notificações
                    </Link>
                    <Link smooth to="/perfil#plan">
                      Minha assinatura
                    </Link>
                    <Link smooth to="/perfil#password">
                      Alterar senha
                    </Link>
                    <button onClick={handleSignOut}>
                      <FiLogOut color="red" />
                      Sair
                    </button>
                  </div>
                </span>
              </Dropdown>
            </ul>
          </ProfileIcon>

          <ProfileIcon className="hidden-desktop">
            <Dropdown
              id="menu-mobile"
              ref={dropDownMobile}
              onClick={closeDropdown}
            >
              <span>
                <img src={sidebarMobile} alt="Opções" />
              </span>
              <LinksMobileHome style={{ display: 'none' }}>
                <Link className="LinkHome" to="/home">
                  <p>Início</p>
                </Link>
                <Link className="LinkHome" to="/cursos">
                  <p>Cursos</p>
                </Link>
                <Link className="LinkHome" to="/vivencias">
                  <p>Vivências</p>
                </Link>
                <a
                  className="LinkHome"
                  href="https://maberu.com.br/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <p>Loja</p>
                </a>
                <Link className="LinkHome" to="/favoritos">
                  <p>Favoritos</p>
                </Link>
                <Link className="LinkHome" to="/em-andamento">
                  <p>Em andamento</p>
                </Link>
                <Link className="LinkHome" to="/finalizados">
                  <p>Finalizados</p>
                </Link>
                <Link className="LinkHome" to="/forum">
                  <p>Fórum</p>
                </Link>
                <Link className="LinkHome" to="/perfil">
                  <p>Meu perfil</p>
                </Link>
                <Link className="LinkHome" to="/perfil">
                  <p>Minha assinatura</p>
                </Link>
                <Link className="LinkHome" to="/sobre-o-ima">
                  <p>Sobre o Instituto</p>
                </Link>
                <Link to="/sobre-halu" className="last">
                  <p> Sobre Halu Gamashi</p>
                </Link>
                <button type="button" onClick={handleSignOut}>
                  <FiLogOut color="red" />
                  <p>Logout</p>
                </button>
              </LinksMobileHome>
            </Dropdown>
          </ProfileIcon>
        </ProfileArea>
      </Container>
    );
  }

  return (
    <ContainerMobile>
      <SidebarContainer isOpen={isSidebarOpen}>
        <span className="logo-close-button-wrapper">
          <img
            src={LogoSmallImg}
            alt="IMA"
            onClick={handleGoToHome}
            onKeyPress={handleGoToHome}
            aria-hidden="true"
            className="logo"
          />
          <button type="button" onClick={onClose}>
            <img className="close-button" src={CloseButtonImage} alt="Close" />
          </button>
        </span>

        <LinksList>
          <Link className="link" to="/home">
            <p>Início</p>
          </Link>
          <Link className="link" to="/cursos">
            <p>Cursos</p>
          </Link>
          <Link className="link" to="/vivencias">
            <p>Vivências</p>
          </Link>
          <a
            className="link"
            href="https://maberu.com.br/"
            rel="noreferrer"
            target="_blank"
          >
            <p>Loja</p>
          </a>
          <Link className="link" to="/favoritos">
            <p>Favoritos</p>
          </Link>
          <Link className="link" to="/em-andamento">
            <p>Em andamento</p>
          </Link>
          <Link className="link" to="/finalizados">
            <p>Finalizados</p>
          </Link>
          <Link className="link" to="/forum">
            <p>Fórum</p>
          </Link>
          <Link className="link" to="/perfil">
            <p>Meu perfil</p>
          </Link>
          <Link className="link" to="/perfil">
            <p>Minha assinatura</p>
          </Link>
          <Link className="link" to="/sobre-o-ima">
            <p>Sobre o Instituto</p>
          </Link>
          <Link to="/sobre-halu" className="link">
            <p>Sobre Halu Gamashi</p>
          </Link>
        </LinksList>
        <button className="signout" type="button" onClick={handleSignOut}>
          <FiLogOut color="white" />
          <p>Logout</p>
        </button>
      </SidebarContainer>

      {windowSize > 430 && (
        <img
          src={LogoSmallImg}
          alt="IMA"
          onClick={handleGoToHome}
          onKeyPress={handleGoToHome}
          aria-hidden="true"
          className="logo"
        />
      )}

      {windowSize <= 430 && !openSearch && (
        <img
          src={LogoSmallImg}
          alt="IMA"
          onClick={handleGoToHome}
          onKeyPress={handleGoToHome}
          aria-hidden="true"
          className="logo"
        />
      )}

      <span className="buttons-wrapper">
        {openSearch ? (
          <SearchInput
            ref={searchInputRef}
            onMouseLeave={() => setOpenSearch(false)}
            onChange={event => debounceSearchContents(event.target.value)}
          />
        ) : (
          <SearchIconWrapper
            onClick={openSearchInput}
            onKeyPress={openSearchInput}
            aria-hidden="true"
          >
            <SearchIcon />
          </SearchIconWrapper>
        )}

        <Notification />

        <button type="button" onClick={onOpen}>
          <img className="menu-button" src={sidebarMobile} alt="Opções" />
        </button>
      </span>
    </ContainerMobile>
  );
};

export default HeaderLogged;
