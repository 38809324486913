import styled, { keyframes } from 'styled-components';

import profileBanner from '../../assets/images/profile-banner.png';
import { theme } from '../../styles/theme';

const fadeInDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-1.25em);
  }
  
  100% {
    opacity: 1;
    transform: translateY(0);
}`;

export const Container = styled.div`
  background: ${theme.colors.shape};
`;

export const Header = styled.section`
  background: url(${profileBanner});
  background-repeat: no-repeat;
  background-size: cover;

  h1 {
    padding-left: 63px;
    font-size: 7rem;
    color: ${theme.colors.textLight};
    position: relative;
    z-index: 1;
    @media (max-width: 480px) {
      font-weight: bold;
      font-size: 40px;
    }

    span {
      color: ${theme.colors.text};
      position: absolute;
      font-size: 7rem;
      opacity: 0.2;
      bottom: 35px;
      left: 15px;
      z-index: -1;
    }
  }

  @media (max-width: 1100px) {
    h1 {
      font-size: 2rem;
      z-index: 0;
      padding-left: 16px;
      span {
        font-size: 3rem;
        display: none;
      }
    }
  }

  @media (max-width: 860px) {
    background-position: center;
  }

  @media (max-width: 700px) {
    h1 {
      font-size: 2rem;

      span {
        font-size: 2.5rem;
      }
    }
  }
`;

export const ImageFilter = styled.small`
  background: linear-gradient(
    90deg,
    rgba(89, 74, 173, 0.83) -5.53%,
    rgba(159, 151, 201, 0.3818) 60.04%,
    rgba(47, 24, 156, 0.83) 131.32%
  );
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
  width: 100%;
  height: 100%;
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  padding: 152px 24px 24px 24px;
  position: relative;
  @media (max-width: 480px) {
    padding: 32px 10px;
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  h3 {
    font-size: 2.4rem;
    font-weight: 600;
  }

  @media (min-width: 1101px) {
    padding: 48px 80px;
  }

  @media (max-width: 1100px) {
    padding: 64px 20px 0; 
    h3 {
      font-size: 1.3rem;
      text-align: center;
    }
  }
`;

export const PeriodSelector = styled.ul`
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
  list-style: none;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${theme.colors.light};
  margin: 48px 0;

  li:first-of-type {
    border-left: none;
    border-bottom-left-radius: 14px;
    border-top-left-radius: 14px;
  }

  li:last-of-type {
    border-bottom-right-radius: 14px;
    border-top-right-radius: 14px;
  }

  li {
    position: relative;
    background: ${theme.colors.light};
    padding: 7px 0;
    min-width: 220px;
    text-align: center;
    border-left: 1px solid ${theme.colors.shapeSecondary};
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      background: transparent;
      transform: rotate(45deg);
      border: 6px solid ${theme.colors.light};
      width: 0;
      height: 0;
      bottom: -7px;
      left: 48%;
      border-right: 5px solid transparent;
      border-bottom: 5px solid transparent;
    }
  }

  li:first-of-type.selected {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
  }

  li:last-of-type.selected {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
  }

  li.selected {
    background: ${theme.colors.secondary};
    color: ${theme.colors.textLight};
  }

  @media (max-width: 1100px) {
    display: none;
  }
`;

export const PeriodSelectorMobile = styled.div`
  width: 90%;
  background: transparent;
  border-radius: 20px;
  border: 1px solid ${theme.colors.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 24px;
  margin: 48px 0 0;

  ul {
    display: none;
  }

  @media (min-width: 1100px) {
    display: none;
  }
`;

export const PeriodSelectedMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  cursor: pointer;

  color: ${theme.colors.secondary};
  font-weight: 700;

  span {
    margin-left: auto;
  }

  svg {
    position: absolute;
    font-size: 2rem;
    right: 0;
  }
`;

export const PeriodOptionsContainer = styled.div`
  animation: ${fadeInDown} 0.5s;
  display: ${({ open }) => (open ? 'block' : 'none')};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(37, 40, 43, 0.6);
`;

export const PeriodOptions = styled.ul`
  background: ${theme.colors.light};
  list-style: none;
  padding: 32px 30px;
  bottom: 0;
  
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.1);

  svg {
    float: right;
    font-size: 1.2rem;
    font-weight: bold;
    color: ${theme.colors.secondary};
    cursor: pointer;
  }

  li:first-of-type {
    margin-top: 20px;
  }

  li {
    cursor: pointer;
    padding: 16px;
    font-weight: 700;
    color: ${theme.colors.secondary};
  }

  li:hover {
    border-radius: 20px;
    background: ${theme.colors.secondary};
    color: ${theme.colors.textLight};
  }

  @media (min-width: 1101px) {
    height: 30%;
    position: absolute;

  }
  @media (max-width: 1100px) {
    height: 95vh;
    position: fixed;
    left: 0;
    z-index: 10000;
  }
`;

export const Cards = styled.article`
  margin-top: 32px;
  flex: 1;
  @media (min-width: 1101px){
    padding: 0 16px;
    gap: 16px;
  }
  @media (min-width: 1100px) {
    display: flex;
  }
  @media (max-width: 1100px){
    width: 100%;
    > div {
      margin: 63px auto 0;
    }
  }
`;

export const NoPlansFound = styled.h3`
  font-size: 2.5rem;
  color: ${theme.colors.secondary};
  padding: 20px 0;
  text-align: center;
`;
