import {
  CarouselProvider,
  Slide,
  Slider,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';

import 'pure-react-carousel/dist/react-carousel.es.css';
// eslint-disable-next-line import-helpers/order-imports
import useWindowSize from '../../../../hooks/useWindowSize';

// eslint-disable-next-line import-helpers/order-imports
import { getAll as getMastersService } from '../../../../services/ascendedMastersService';
import MasterCard from './components/MasterCard';
import { HomeMastersContainer } from './style';

const HomeMasters = () => {
  const [masters, setMasters] = useState([]);
  const { windowSize } = useWindowSize();

  const getMasters = useCallback(async () => {
    const { data: localMasters } = await getMastersService();
    setMasters(localMasters);
  }, []);

  useEffect(() => {
    getMasters();
  }, [getMasters]);

  const visibleSlides = useMemo(() => {
    let slides = 0;

    if (windowSize > 1540) {
      slides = 4;
    } else if (windowSize > 1100) {
      slides = 3;
    } else if (windowSize > 1000) {
      slides = 2.8;
    } else if (windowSize > 800) {
      slides = 2.4;
    } else if (windowSize > 600) {
      slides = 1.8;
    } else {
      slides = 1;
    }

    return slides;
  }, [windowSize]);

  return (
    !!masters.length && (
      <HomeMastersContainer>
        <h2>Mestres Ascencionados</h2>

        <p>Mestres da Sabedoria que Inspiram e Orientam o IMA</p>

        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={100}
          totalSlides={masters.length}
          visibleSlides={visibleSlides}
        >
          <Slider className="max-content">
            {masters.map((m, index) => (
              <Slide key={m.ascended_master_id} index={index}>
                <MasterCard master={m} />
              </Slide>
            ))}
          </Slider>

          <ButtonBack>
            <AiOutlineLeft size={32} />
          </ButtonBack>
          <ButtonNext>
            <AiOutlineRight size={32} />
          </ButtonNext>

          <DotGroup />
        </CarouselProvider>
      </HomeMastersContainer>
    )
  );
};

export default HomeMasters;
