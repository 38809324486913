import styled from 'styled-components';

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  position: relative;
`;

export { HomeContainer };
