import api from './api';

export async function getExam(examId) {
  return api.get(`/exams/${examId}`, {
    params: {
      relations: ['exam_questions'],
    },
  });
}

export async function getExamUser(examId, userId) {
  const response = await api.get(
    `/exam-users?exam_id=${examId}&user_id=${userId}`,
  );
  if (response?.data[0]?.finish_at) {
    return api.get(
      `/exam-users?exam_id=${examId}&user_id=${userId}&relations[]=exam_question_users`,
    );
  }
  return response;
}

export async function finishExam(examId) {
  return api.post(`/exam-users/${examId}/finish`);
}

export async function answerQuestions(questionId, data) {
  return api.post(`/exam-users/${questionId}/answer-question`, data);
}

export async function startExam(examId) {
  return api
    .post(`/exam-users/`, {
      exam_id: examId,
    })
    .then(res => {
      return res.data;
    });
}
