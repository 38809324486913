import React, { useEffect, useRef } from 'react';

const validUrlStart = 'https://fast.player.liquidplatform.com/pApiv2/embed/';

const PlayerSambaVideos = ({
  contentUrl,
  controlsToEnable,
  resume = false,
  getEventListeners,
  id,
  styles,
  className,
}) => {
  const sambaPlayer = useRef(null);

  useEffect(() => {
    const localContentUrl = contentUrl?.replace(validUrlStart, '');

    if (!localContentUrl?.includes('/')) return;

    const splittedLocalContentUrl = localContentUrl?.split('/');
    const playerHash = splittedLocalContentUrl[0];
    const mediaId = splittedLocalContentUrl[1];

    const divPlayer = document.getElementById(id);

    if (divPlayer && !divPlayer.children.length) {
      const playerParams = {
        enableShare: false,
        resume,
        keyboardShortcuts: false,
        enableControls: controlsToEnable,
      };

      window.SambaPlayer.clearAllMediaEvents();

      sambaPlayer.current = new window.SambaPlayer(divPlayer, {
        ph: playerHash,
        m: mediaId,
        playerParams,
        events: {
          '*': getEventListeners,
        },
      });
    }
  }, [contentUrl, controlsToEnable, getEventListeners, resume, id]);

  return <div id={id} style={styles} className={className} />;
};

export default PlayerSambaVideos;
