import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import iconBack from '../../assets/icons/icon-back.svg';
import bannerAboutHaluGamashi from '../../assets/images/banner-about-halu-gamashi.png';
import ResponsivePlayer from '../../components/ResponsivePlayer';
import { controlsToEnable } from '../../configs/SvPlayer';
import * as HomeInfoService from '../../services/HomeInfo';
import { goToTop } from '../../utils/helpers';
import { TextInfo } from '../AboutHaluGamashi/styles';

import {
  ContentHeader,
  AboutTextHaluGamashi,
  Container,
  BackgroundLogged,
  Video,
} from './styles';

const AboutHaluGamashiLogged = () => {
  const [content, setContent] = useState({});
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    HomeInfoService.getAboutHaluContent()
      .then(response => {
        setContent(response.data[0]);
      })
      .catch(() => {
        toast.error(
          'Ocorreu um erro ao buscar os conteúdos, recarregue a página.',
        );
      });
  }, []);

  goToTop();

  return (
    <Container>
      <BackgroundLogged>
        <span />
        <img src={bannerAboutHaluGamashi} alt="bannerAboutHaluGamashi" />
      </BackgroundLogged>

      <ContentHeader>
        <div>
          <img src={iconBack} alt="Icone Voltar" />
          <span
            onKeyPress={handleGoBack}
            role="button"
            tabIndex="0"
            onClick={handleGoBack}
          >
            Voltar
          </span>
        </div>
        <h2 className="hidden-mobile">Sobre H. G.</h2>
        <h1>Sobre H. G.</h1>
      </ContentHeader>

      <AboutTextHaluGamashi>
        <article>
          <h1>Conheça Halu Gamashi</h1>

          {content.info?.mediaUrl && (
            <Video>
              <ResponsivePlayer
                className="hidden-mobile"
                contentUrl={content.info.mediaUrl}
                controlsToEnable={controlsToEnable}
                id="playerdesktopSobreHalu"
              />
              <ResponsivePlayer
                className="hidden-desktop"
                contentUrl={content.info.mediaUrl}
                controlsToEnable={controlsToEnable}
                id="playerMobileSobreHalu"
              />
            </Video>
          )}
          <TextInfo dangerouslySetInnerHTML={{ __html: content.text }} />
        </article>
      </AboutTextHaluGamashi>
    </Container>
  );
};
export default AboutHaluGamashiLogged;
