import { pdf } from '@react-pdf/renderer';
import { isAfter } from 'date-fns';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import CalendarImg from '../../assets/icons/calendar.svg';
import ClockImg from '../../assets/icons/clock.svg';
import HeartLikedImg from '../../assets/icons/heart-liked.svg';
import HeartUnLikedImg from '../../assets/icons/heart-unliked.svg';
import iconCertificates from '../../assets/icons/icon-certificates.png';
import iconDownloadCertificates from '../../assets/icons/icon-download-certificates.svg';
import StarImg from '../../assets/icons/star.svg';
import useCardCourse from '../../hooks/cardCourse';
import * as CertificateService from '../../services/Certificates';
import { ContentsService } from '../../services/contentsService';
import { convertDuration } from '../../utils/helpers';
import { Button } from '../Button';
import Certificate from '../Certificate';
import Rater from '../Rater';

import {
  Container,
  ImageCourseBackground,
  Overlay,
  Header,
  Rating,
  Like,
  Tag,
  Content,
  DateInfo,
  RateDivider,
  ThanksForRating,
  BoxLeftFinalized,
  CalendarInfo,
  ClockInfo,
  RateYouCourse,
  BoxRightFinalized,
  Certificates,
  Box,
} from './styles';

const CardCourseFinalized = ({ data: item }) => {
  const { getCardCourseData } = useCardCourse();
  const data = getCardCourseData(item);

  const [liked, setLiked] = useState(data?.courseLiked);
  const [mouseLeave, setMouseLeave] = useState(false);
  const [initialRating, setInitialRating] = useState(
    data?.rating.initialRating.toFixed(1).replace('.', ','),
  );

  const contentsService = new ContentsService();

  const history = useHistory();

  async function handleToggleLikeCourse() {
    setLiked(state => !state);

    try {
      await contentsService.likeContent(data.id, !liked);
    } catch {
      setLiked(state => !state);
      toast.error(
        'Ocorreu um erro ao favoritar este conteúdo, tente novamente',
      );
    }
  }

  function handleEditRating() {
    setInitialRating('0,0');
  }

  async function handleRate(value) {
    try {
      await contentsService.reviewContent(data.id, value);
      setInitialRating(value.toFixed(1).replace('.', ','));
    } catch {
      toast.error('Ocorreu um erro ao avaliar este conteúdo, tente novamente');
    }
  }

  function handleFindCertificate(doDownload = false) {
    CertificateService.findCertificate(item.content_id)
      .then(response => {
        generateCertificate(response.data, doDownload);
      })
      .catch((e) => {
        toast.error(
          'Ocorreu um erro ao gerar seu certificado, tente mais tarde',
        );
      });
  }

  async function generateCertificate(certificate, download) {
    if (download) {
      const cert = await pdf(<Certificate data={certificate} />).toBlob();
      if (cert) {
        const url = window.URL.createObjectURL(cert);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'certificado.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }
    } else {
      history.push(`/certificado/${certificate.certificate_id}`);
    }
  }

  function limitText(text) {
    if (text.length > 40) {
      return `${text.substring(0, 40)}...`;
    }
    return text;
  }

  return (
    <Container onMouseLeave={() => setMouseLeave(true)}>
      <Box>
        <ImageCourseBackground
          mouseLeave={mouseLeave}
          courseImg={data?.courseImg}
        >
          <Overlay>
            <Header>
              <Rating>
                <img src={StarImg} alt="Avaliações" />
                <span>
                  {data?.rating?.value || 0}{' '}
                  <span>
                    {data?.rating?.totalRatings
                      ? `(${data?.rating?.totalRatings})`
                      : null}
                  </span>
                </span>
              </Rating>
            </Header>

            <Tag>
              <span>{data?.tag}</span>
            </Tag>
          </Overlay>
        </ImageCourseBackground>
        <button type="button" onClick={handleToggleLikeCourse}>
          <Like>
            <img
              className={liked ? '' : 'hidden'}
              src={HeartLikedImg}
              alt="Marcado como favorito"
            />
            <img
              className={liked ? 'hidden' : ''}
              src={HeartUnLikedImg}
              alt="Não favorito"
            />
          </Like>
        </button>
      </Box>

      <Content>
        <BoxLeftFinalized>
          <p>{limitText(data?.name)} </p>
          <span>
            por
            <h4>{data?.author}</h4>
          </span>
          <DateInfo>
            <CalendarInfo>
              <img src={CalendarImg} alt="Calendário" />
              <span>
                {isAfter(new Date(data?.date), new Date())
                  ? data?.date
                  : 'Já disponível'}
              </span>
            </CalendarInfo>

            <ClockInfo>
              <img src={ClockImg} alt="Relógio" />
              <span>{convertDuration(data?.duration)}</span>
            </ClockInfo>
          </DateInfo>
        </BoxLeftFinalized>

        <BoxRightFinalized>
          {data?.progress && initialRating === '0,0' && data?.showRater ? (
            <RateYouCourse>
              <Rater
                title="Avalie seu curso"
                initialRating={initialRating}
                changeRating={value => handleRate(value)}
              />
            </RateYouCourse>
          ) : null}
          {initialRating !== '0,0' && data?.showRater ? (
            <>
              <RateDivider />
              <ThanksForRating>
                <p>Muito obrigado!</p>

                <div>
                  <img src={StarImg} alt="Estrela de avaliação" />
                  <span>{initialRating}</span>
                  <button onClick={handleEditRating}>Editar</button>
                </div>
              </ThanksForRating>
            </>
          ) : null}

          {!data.courseProgress && data?.buttonText ? (
            <Certificates>
              <Button
                className="hidden-mobile"
                title="Visualizar certificado "
                icon={data?.buttonIcon}
                containerStyle={{
                  marginBottom: '14px',
                  background: '#2F189C',
                }}
                onClick={() => handleFindCertificate()}
                img={iconCertificates}
              />
              <Button
                className="hidden-mobile"
                title="Baixar certificado"
                icon={data?.buttonIcon}
                containerStyle={{
                  marginBottom: '14px',
                  background: '#2F189C',
                }}
                onClick={() => handleFindCertificate(true)}
                img={iconDownloadCertificates}
              />

              <Button
                className="hidden-desktop"
                title="Visualizar certificado "
                icon={data?.buttonIcon}
                containerStyle={{
                  marginBottom: '14px',
                  background: '#2F189C',
                }}
                onClick={() => handleFindCertificate()}
                img={iconCertificates}
              />
              <Button
                className="hidden-desktop"
                title="Baixar certificado"
                icon={data?.buttonIcon}
                containerStyle={{
                  marginBottom: '14px',
                  background: '#2F189C',
                  alignSelf: 'center',
                  maxWidth: '100%',
                  minWidth: 'unset',
                }}
                onClick={() => handleFindCertificate(true)}
                img={iconDownloadCertificates}
              />
            </Certificates>
          ) : null}
        </BoxRightFinalized>
      </Content>
    </Container>
  );
};

export default CardCourseFinalized;
