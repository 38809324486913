import styled from 'styled-components';

import BackgroundImg from '../../assets/images/login-bg.png';
import { theme } from '../../styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: url(${BackgroundImg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Presentation = styled.section`
  color: ${theme.colors.light};
  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  h1,
  h2 {
    padding: 8px 0;
    width: 100%;
  }

  h1 {
    font-weight: bold;
    font-size: 3rem;
    text-align: center;
    @media (max-width: 480px) {
      font-weight: bold;
      font-size: 32px;
      line-height: 40px;
      padding: 70px 0 0 0;
      margin: 0;
    }
  }

  h2 {
    font-weight: 400;
    font-size: 1.25rem;
    text-align: center;
    @media (max-width: 480px) {
      font-size: 16px;
      line-height: 24px;
      padding: 16px 0 0 0;
      margin: 0;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 900px) {
    margin-bottom: 32px;
  }
`;

export const LoginArea = styled.section`
  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 16px 0 16px;
    margin: 0;
  }

  button {
    h4,
    h5 {
      font-size: 16px;
      font-weight: 500;
      color: ${theme.colors.textLight};
      cursor: pointer;
    }
  }

  section {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    @media (max-width: 480px) {
      justify-content: center;
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.colors.light};
  border-radius: 20px;
  text-align: center;
  padding: 24px;
  max-width: 445px;

  span {
    color: ${theme.colors.primary};
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 16px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const DividerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 32px;

  span {
    font-size: 0.9rem;
    color: ${theme.colors.primary};
    font-weight: 600;
    margin: 0 19px;
  }
`;

export const Divider = styled.div`
  background-color: ${theme.colors.primary};
  height: 1px;
  min-width: 170px;

  @media (max-width: 470px) {
    min-width: 100px;
  }
`;

export const Checkbox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.4rem;

  > label {
    font-size: 0.8rem;
    color: #3a3a3a;
    cursor: pointer;
  }

  > input {
    width: 12px;
    height: 12px;
    accent-color: ${theme.colors.primary};
  }
`;
