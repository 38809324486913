import styled from 'styled-components';

import { theme } from '../../../../styles/theme';

export const Container = styled.div`
  margin: 55px 120px;
  padding: 16px 0;
  display: flex;
  z-index: 2;

  img {
    width: 140px;
    height: 140px;
    border-radius: 50%;
  }
  @media (max-width: 715px) {
    margin: 40px 10px;
  }
  @media (max-width: 375px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Avatar = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;

  img {
    border-radius: 100%;
    object-fit: cover;
  }

  > svg {
    width: 140px;
    height: 140px;
    color: ${theme.colors.light};
  }

  @media (max-width: 860px) {
    > svg {
      width: 100px;
      height: 100px;
    }
  }

  @media (max-width: 715px) {
    > svg {
      width: 80px;
      height: 80px;
    }
  }
`;

export const IconEdit = styled.label`
  padding: 8px;
  border-radius: 50%;
  background: ${theme.colors.secondary};
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  width: 40px;
  height: 40px;

  svg {
    font-size: 1.5rem;
  }

  @media (max-width: 860px) {
    svg {
      font-size: 1.2rem;
    }
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  padding-bottom: 130px;

  h1 {
    padding: 0;
    font-weight: bold;
    font-size: 3.7rem;
    color: ${theme.colors.textLight};
    margin-bottom: 16px;
  }

  h2 {
    font-weight: normal;
    font-size: 2rem;
    color: ${theme.colors.textLight};
  }
  @media (max-width: 1100px) {
    padding-bottom: 220px;
  }
  @media (max-width: 860px) {
    margin-left: 12px;

    h1 {
      font-size: 1.5rem;
      margin-bottom: 8px;
    }

    h2 {
      font-size: 1rem;
    }
  }

  @media (max-width: 715px) {
    h1 {
      font-size: 1rem;
    }

    h2 {
      font-size: 0.8rem;
    }
  }
  @media (max-width: 375px) {
    padding-bottom: 40px;
    width: 100%;
  }
`;

export const Badge = styled.span`
  margin-bottom: 8px;
  background: ${theme.colors.secondary};
  padding: 4px 16px;
  color: ${theme.colors.textLight};
  font-weight: 600;
  font-size: 0.8rem;
  width: fit-content;
  border-radius: 20px;

  @media (max-width: 715px) {
    font-size: 0.7rem;
  }
`;

export const ImageCropperWrapper = styled.div`
  > div {
    display: flex;
    flex-direction: column;
    canvas {
      border-radius: 50%;
    }
    > img {
      width: 30px;
      height: 30px;
      margin: 0 auto;
    }
    input[type='range']::-webkit-slider-thumb {
      background: var(--default-blue);
    }
    input[type='range']::-moz-range-thumb {
      background: var(--default-blue);
    }
    input[type='range']::-ms-thumb {
      background: var(--default-blue);
    }
    > div {
      margin-top: 10px;
      min-width: 100%;
      height: 30px;
      padding: 0 20px;
    }
    > button {
      margin-top: 10px;
      min-width: 100%;
      height: 30px;
      padding: 0 20px;
      background-color: #fff;
      color: #000;
      font-size: 16px;
      border-radius: 20px;
      font-weight: bold;
    }
    @media (max-width: 375px) {
      margin-bottom: 20px;
    }
  }
`;
