import styled from 'styled-components';

export const ContainerHeaderTop = styled.div`
  min-height: 350px;
  min-width: 100%;
  padding-bottom: 40px;
  width: 100%;
  position: relative;
  @media (max-width: 1100px) {
    height: 220px;
    min-height: 220px;
    padding: 0;
  }
`;

export const Background = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  span {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: linear-gradient(
      90deg,
      rgba(89, 74, 173, 0.83) -5.53%,
      rgba(159, 151, 201, 0.3818) 60.04%,
      rgba(47, 24, 156, 0.83) 131.32%
   );
  }
`;

export const ContentHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 72px;
  padding-top: 34px;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  @media (max-width: 480px) {
    width: 100%;
    padding: 0 16px;
    height: 55px;
  }
`;

export const TopHeaderLeft = styled.div`
  display: flex;
  flex: 1;
  font-size: 14px;
  align-items: center;

  img {
    padding-right: 20px;
  }
`;

export const TopHeaderRight = styled.div`
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;

  a {
    color: #ffffff;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    margin-right: 48px;
    text-decoration: none;
    @media (max-width: 480px) {
      max-width: 100%;
      text-align: center;
      padding: 0;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      margin:0 ;
    }
`;

export const Content = styled.div`
  padding: 0 40px;
  @media (max-width: 480px) {
    max-width: 100%;
    padding: 0 16px;
  }
`;
