import * as React from 'react';

const ProgressBar = ({
  bgColor,
  completed,
  baseBgColor,
  opacity,
  height,
  width,
  margin,
  padding,
  borderRadius,
  labelAlignment,
  labelColor,
  labelSize,
  isLabelVisible,
  transitionDuration,
  transitionTimingFunction,
  dir,
  ariaValuemin,
  ariaValuemax,
  ariaValuetext,
  maxCompleted,
  customLabel,
  animateOnRender,
}) => {
  const getAlignment = alignmentOption => {
    if (alignmentOption === 'left') {
      return 'flex-start';
    }
    if (alignmentOption === 'center') {
      return 'center';
    }
    if (alignmentOption === 'right') {
      return 'flex-end';
    }
    return null;
  };

  const alignment = getAlignment(labelAlignment);

  const getFillerWidth = (maxCompletedValue, completedValue) => {
    if (maxCompletedValue) {
      const ratio = Number(completedValue) / maxCompletedValue;
      return ratio > 1 ? '100%' : `${ratio * 100}%`;
    }
    return 0;
  };

  const fillerWidth = getFillerWidth(maxCompleted, completed);

  const [initWidth, setInitWidth] = React.useState(0);

  const containerStyles = {
    height,
    backgroundColor: baseBgColor,
    opacity,
    borderRadius,
    padding,
    width,
    margin,
  };

  const fillerStyles = {
    height,
    width: animateOnRender ? initWidth : fillerWidth,
    backgroundColor: bgColor,
    opacity,
    transition: `width ${transitionDuration || '1s'} ${
      transitionTimingFunction || 'ease-in-out'
    }`,
    borderRadius: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent:
      labelAlignment !== 'outside' && alignment ? alignment : 'normal',
    padding: 10,
    paddingLeft: 30,
  };

  const labelStyles = {
    padding: labelAlignment === 'outside' ? '0 0 0 5px' : 0,
    color: labelColor,
    fontWeight: 'normal',
    fontSize: labelSize,
    display: !isLabelVisible ? 'none' : 'initial',
  };

  const outsideStyles = {
    display: labelAlignment === 'outside' ? 'flex' : 'initial',
    alignItems: labelAlignment === 'outside' ? 'center' : 'initial',
  };

  const completedStr =
    typeof completed === 'number' ? `${completed}%` : `${completed}`;

  const labelStr = customLabel || completedStr;

  React.useEffect(() => {
    if (animateOnRender) {
      requestAnimationFrame(() => setInitWidth(fillerWidth));
    }
  }, [fillerWidth, animateOnRender]);

  return (
    <div
      style={outsideStyles}
      dir={dir}
      role="progressbar"
      aria-valuenow={parseFloat(labelStr)}
      aria-valuemin={ariaValuemin}
      aria-valuemax={ariaValuemax}
      aria-valuetext={`${ariaValuetext === null ? labelStr : ariaValuetext}`}
    >
      <div style={containerStyles}>
        <div style={fillerStyles}>
          {labelAlignment !== 'outside' && (
            <span style={labelStyles}>{labelStr}</span>
          )}
        </div>
      </div>
      {labelAlignment === 'outside' && (
        <span style={labelStyles}>{labelStr}</span>
      )}
    </div>
  );
};

ProgressBar.defaultProps = {
  bgColor: '#6a1b9a',
  height: '20px',
  width: '100%',
  borderRadius: '50px',
  labelAlignment: 'right',
  baseBgColor: '#e0e0de',
  opacity: '1',
  labelColor: '#fff',
  labelSize: '15px',
  isLabelVisible: true,
  dir: 'ltr',
  ariaValuemin: 0,
  ariaValuemax: 100,
  ariaValuetext: null,
  maxCompleted: 100,
  animateOnRender: false,
};

export default ProgressBar;
