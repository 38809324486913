import React, { useState, useEffect, useCallback } from 'react';
import {
  Route as ReactDOMRoute,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';

import { toast } from '../components/Toast/Toast';
import { useAuth } from '../hooks/auth';
import { sendEventRdStation } from '../services/SendEventRdStation';
import { UserService } from '../services/userService';

const Route = ({ isPrivate = false, component: Component, ...rest }) => {
  const user = localStorage.getItem('@HaluGamashi:user');
  const routerLocation = useLocation();
  const [pathname, setPathname] = useState('');

  const history = useHistory();
  const searchParams = new URLSearchParams(useLocation().search);
  const tokenParam = searchParams.get('token');

  const { storeUser } = useAuth();

  const getUserWithToken = useCallback(async () => {
    try {
      const userServices = new UserService();

      const userData = await userServices.getUserData(tokenParam);

      storeUser(userData, tokenParam);
    } catch (error) {
      history.push('/logar');
    } finally {
      searchParams.delete('token');
      history.replace({
        search: searchParams.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenParam]);

  useEffect(() => {
    if (tokenParam && isPrivate) {
      getUserWithToken();
    }
  }, [getUserWithToken, tokenParam, isPrivate]);

  useEffect(() => {
    const enableRdStation = process.env.REACT_APP_ENABLE_RD_STATION;
    if (enableRdStation === 'true') {
      setPathname(window.location.href);
    }
  }, [routerLocation]);

  useEffect(() => {
    const sendRdStation = async () => {
      try {
        await sendEventRdStation(
          {
            event_type: 'CONVERSION',
            event_family: 'CDP',
            payload: {
              conversion_identifier: `Visitou a página: ${pathname}`,
            },
          },
          tokenParam,
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };

    if (isPrivate && pathname) {
      sendRdStation();
    }
  }, [pathname, isPrivate, tokenParam]);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        if (isPrivate && user) {
          return <Component />;
        }

        if (isPrivate && !user && !tokenParam) {
          toast.warn('Faça login antes de acessar essa página');
          return (
            <Redirect
              to={{
                pathname: '/logar',
                state: { from: location },
              }}
            />
          );
        }

        return <Component />;
      }}
    />
  );
};

export default Route;
