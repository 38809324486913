import styled from 'styled-components';

import inProgressBanner from '../../assets/images/in-progress-banner.png';
import { theme } from '../../styles/theme';

export const Container = styled.div`
  display: contents;
  height: 100%;
  position: relative;
  width: 100%;
`;

export const Header = styled.section`
  background: url(${inProgressBanner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  object-fit: contain;
  @media (max-width: 480px) {
    margin-bottom: 30px;
  }

  h1 {
    padding-left: 63px;
    font-size: 7rem;
    color: ${theme.colors.textLight};
    position: relative;
    z-index: 1;

    span {
      color: ${theme.colors.text};
      position: absolute;
      font-size: 8rem;
      opacity: 0.2;
      bottom: 10px;
      left: 24px;
      z-index: -1;
    }
  }

  @media (max-width: 1100px) {
    h1 {
      font-size: 2rem;
      z-index: 0;

      span {
        font-size: 5rem;
      }
    }
  }

  @media (max-width: 700px) {
    h1 {
      font-size: 2rem;

      span {
        font-size: 3rem;
      }
    }
  }
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  padding: 192px 24px 24px 24px;
  background: linear-gradient(
    90deg,
    rgba(89, 74, 173, 0.83) -5.53%,
    rgba(159, 151, 201, 0.3818) 60.04%,
    rgba(47, 24, 156, 0.83) 131.32%
  );

  @media (max-width: 650px) {
    padding: 80px 24px 24px 24px;
  }

  @media (max-width: 480px) {
    h1 {
      font-weight: bold;
      font-size: 40px;
      padding: 0;
    }

    span {
      font-size: 36px;
    }
  }
`;

export const BreadcrumbMobile = styled.span`
  display: flex;
  margin-bottom: 20px;

  a {
    display: flex;
    text-decoration: unset;

    img {
      padding-top: 2px;
      margin-right: 24px;
    }

    p {
      color: #fff;
      font-weight: 600;
      font-size: 16px;
    }
  }
`;

export const Content = styled.main`
  align-self: center;
  padding: 64px 187px;

  section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 2.1rem;
      font-weight: 400;
      color: ${theme.colors.textSecondary};
      margin-right: 25px;
    }
  }

  @media (max-width: 1100px) {
    section {
      h2 {
        font-size: 1.5rem;
      }
    }
  }

  @media (max-width: 900px) {
    padding: 32px 93px;

    section {
      h2 {
        font-size: 1rem;
      }
    }
  }

  @media (max-width: 650px) {
    section {
      flex-direction: column;

      h2 {
        margin-bottom: 16px;
        text-align: center;
        margin-right: 0;
      }
    }
  }

  @media (max-width: 500px) {
    padding: 16px 25px;
  }
`;

export const List = styled.div`
  margin-top: 48px;

  .spinner {
    margin: 0 auto;
    width: fit-content;
  }

  @media (max-width: 650px) {
    margin-top: 24px;
  }
`;

export const ListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

export const ListHeaderOptions = styled.div`
  display: flex;
  align-self: center;

  span {
    cursor: pointer;
    font-size: 1.5rem;
    color: ${theme.colors.text};

    font-weight: 600;
    border-bottom: 4px solid ${theme.colors.secondary};

    &::after {
      padding-bottom: 5px;
      content: '';
      display: block;
      width: 0;
      border-bottom: 4px solid ${theme.colors.secondary};
      transition: width 0.3s;
    }
  }

  @media (max-width: 1100px) {
    span {
      font-size: 1rem;
    }
  }

  @media (max-width: 650px) {
    order: 2;
  }
`;

export const SearchTopicsInput = styled.div`
  display: flex;
  align-items: center;
  background: ${theme.colors.light};
  border: 1px solid ${theme.colors.textSecondary};
  border-radius: 30px;
  padding: 7px 16px;

  input {
    font-size: 1rem;
    margin-left: 9px;
    min-width: 250px;
  }

  @media (max-width: 1100px) {
    input {
      font-size: 0.8rem;
      min-width: 200px;
    }
  }

  @media (max-width: 650px) {
    margin-bottom: 24px;
    order: 1;
  }
`;

export const Pagination = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0;

  ul {
    margin: 0 auto;
  }

  .pagination {
    display: flex;
    list-style-type: none;
  }

  .page {
    display: flex;
    align-items: center;
    margin: 0 20px;
    cursor: pointer;
  }

  .activeLink {
    color: ${theme.colors.secondary};
    font-weight: 700;
  }

  .nextButton {
    margin-left: 72px;
  }

  .previousButton {
    margin-right: 72px;
  }

  .previousContainerButton,
  .nextContainerButton {
    display: flex;
  }

  .nextButton,
  .previousButton {
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      font-size: 2rem;
      fill: ${theme.colors.secondary};
    }
  }

  .disabledButtons {
    cursor: default;

    svg {
      fill: #cfd2d4;
    }
  }
`;
