import {
  CarouselProvider,
  Slide,
  Slider,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import React from 'react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

import imageContents from '../../../../assets/images/image-contents.png';
import imageCourses from '../../../../assets/images/image-courses.png';
import imageExclusiveForum from '../../../../assets/images/image-exclusive-forum.png';
import schoolOfMysteries from '../../../../assets/images/school-of-mysteries.png';
import useWindowSize from '../../../../hooks/useWindowSize';

import { HomeFeaturesContainer } from './style';

const HomeFeatures = () => {
  const { windowSize } = useWindowSize();

  const featureCards = [
    <div className="feature-card">
      <span>
        <img src={schoolOfMysteries} alt="Escola de Mistérios" />
      </span>
      <span>
        Escola
        <br /> de Mistérios
      </span>
    </div>,

    <div className="feature-card">
      <span>
        <img src={imageCourses} alt="Mais de 12 Cursos" />
      </span>
      <span>
        + de
        <br /> 12 Cursos
      </span>
    </div>,

    <div className="feature-card">
      <span>
        <img src={imageContents} alt="Conteúdos Ao Vivo" />
      </span>
      <span>
        Conteúdos
        <br /> Ao Vivo
      </span>
    </div>,

    <div className="feature-card">
      <span>
        <img src={imageExclusiveForum} alt="Fórum Exclusivo" />
      </span>
      <span>
        Fórum
        <br /> Exclusivo
      </span>
    </div>,
  ];

  return (
    <HomeFeaturesContainer className="max-content">
      {windowSize > 768 ? (
        <>{featureCards}</>
      ) : (
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={100}
          totalSlides={4}
          visibleSlides={1}
        >
          <Slider>
            {featureCards.map((f, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Slide key={index}>{f}</Slide>
            ))}
          </Slider>

          <ButtonBack>
            <AiOutlineLeft size={32} />
          </ButtonBack>
          <ButtonNext>
            <AiOutlineRight size={32} />
          </ButtonNext>

          <DotGroup />
        </CarouselProvider>
      )}
    </HomeFeaturesContainer>
  );
};

export default HomeFeatures;
