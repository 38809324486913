import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

import { theme } from '../../styles/theme';

const Spinner = ({ color = theme.colors.primary, size = 15 }) => {
  return <BeatLoader color={color} size={size} />;
};

export default Spinner;
