/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';

import fire from '../../assets/icons/fire.svg';
import iconPlay from '../../assets/icons/icon-play.svg';
import playIcon from '../../assets/icons/play.svg';
import vectorDownWhite from '../../assets/icons/vector-down.svg';
import vectorUpWhite from '../../assets/images/vector-up-white.png';
import { convertDuration, goToTop } from '../../utils/helpers';
import Spacer from '../Spacer';

import {
  AccordionStyle,
  ContainerAccordion,
  Header,
  InfoPoints,
  FireImagePoints,
} from './styles';

const AccordionHalu = ({
  title,
  lessons,
  parentUuid,
  inProgress,
  course,
  moduleIndex,
  examMode,
  examUser,
}) => {
  const [expanded, setExpanded] = useState(false);

  const history = useHistory();

  function handleGoToCourse(lesson) {
    if (!course?.info?.lockModules) {
      const destination = !examMode ? '' : '/exam';
      history.push(`/curso/${lesson.content_id}/${parentUuid}${destination}`);
      return;
    }

    const prevModule = course.children[moduleIndex - 1];
    if (!prevModule) {
      history.push(`/curso/${lesson.content_id}/${parentUuid}`);
      return;
    }

    if (prevModule?.content_user?.finish_at) {
      history.push(`/curso/${lesson.content_id}/${parentUuid}`);
    }
  }

  function handleClickToAccess() {
    goToTop();
    document.getElementById('buttonAccessContent').click();
  }

  return (
    <AccordionStyle show={expanded} onClick={() => setExpanded(!expanded)}>
      <article className="title">
        <Header show={expanded}>
          <button
            onClick={() => setExpanded(!expanded)}
            className="question-title"
          >
            {title}
          </button>
          <button className="btn" onClick={() => setExpanded(!expanded)}>
            {expanded ? (
              <img src={vectorUpWhite} alt="Esconder" />
            ) : (
              <img src={vectorDownWhite} alt="Mostrar" />
            )}
          </button>
        </Header>
      </article>
      {expanded &&
        lessons?.map(item => (
          <ContainerAccordion>
            <div
              aria-hidden="true"
              onClick={event => {
                event.stopPropagation();
                if (inProgress || course?.content_user?.finish_at)
                  handleGoToCourse(item);
                else handleClickToAccess();
              }}
            >
              {examMode ? (
                examUser?.finish_at ? (
                  <img src={playIcon} alt="" />
                ) : (
                  <BiEdit size={24} color="inherit" />
                )
              ) : (
                <img
                  src={item?.content_user?.finish_at ? playIcon : iconPlay}
                  alt=""
                />
              )}
              <p>{item.name || item.title}</p>
              <span className="hidden-mobile">
                {item.duration > 0 && convertDuration(item.duration)}
              </span>
              <Spacer />
              {item.info?.gamification_points ? (
                <InfoPoints>
                  <span className="hidden-desktop">
                    {item.duration > 0 && convertDuration(item.duration)}
                  </span>
                  <FireImagePoints>
                    <img className="fire" src={fire} alt="Pontos" />
                    <div>{item.info?.gamification_points || 0}</div>
                    <p className="hidden-mobile">pontos</p>
                  </FireImagePoints>
                </InfoPoints>
              ) : null}
            </div>
          </ContainerAccordion>
        ))}
    </AccordionStyle>
  );
};
export default AccordionHalu;
