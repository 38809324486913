const { createGlobalStyle } = require('styled-components');

const { theme } = require('./theme');

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    border-style: none;
  }

  :root {
    --default-blue: #2F189C;
    --default-font-black: rgb(58, 57, 70);
  }

  @font-face {
    font-family: "Abuget";
    src: url("../assets/Abuget.ttf");
  }

  body {
    background: ${theme.colors.shape};
    -webkit-font-smoothing: antialiased;
  }

  body, html, #root {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    min-height: 100vh;
    width: 100%;
  }

  body, input, button {
    font-family: 'Montserrat', 'OpenSans', sans-serif;
  }

  button {
    background-color: transparent;
    cursor: pointer;
    border: none;
  }

  .max-content {
    max-width: 1400px;
    margin: 0 auto;
    
    @media (min-width: 768px) {
      padding-left: 24px;
      padding-right: 24px;
      width: calc(100% - 24px);
    }

    @media (min-width: 400px) {
      padding-left: 12px;
      padding-right: 12px;
      width: calc(100% - 12px);
    }
  }

  .hidden-mobile {
    @media only screen and (max-device-width: 1100px) {
      display: none !important;
    }
  }

  .hidden-desktop {
    @media only screen and (min-device-width: 1100px) {
      display: none !important;
    }
  }

  // override carousel mobile dots
  .dot {
    width: 24px !important;
    height: 0px !important;
    border: 3px solid #2F189C !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }

  @media (max-width: 480px) {
    overflow-x: hidden;
  }

  // overrides for sweetalert2
  body.swal2-shown > [aria-hidden="true"] {
    transition: 0.1s filter;
    filter: blur(10px);
  }

  .swal2-input input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  .swal2-input input[type=number] {
    -moz-appearance: textfield;
  }
`;
