import React from 'react';

import cardMasterDefault from '../../assets/images/card-masters-default.png';

import { Container, Content, CardTop, CardBottom } from './styles';

const CardMasters = ({ image, name, description }) => {
  return (
    <Container>
      <Content>
        <img src={image ?? cardMasterDefault} alt={name} />
        <CardTop>
          <p> {name} </p>
        </CardTop>
        <CardBottom>
          <p>
            {description &&
              description.substring(0, 100) +
                (description.length > 100 ? '...' : '')}
          </p>
        </CardBottom>
      </Content>
    </Container>
  );
};
export default CardMasters;
