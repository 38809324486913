import styled from 'styled-components';

const UnloggedContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
`;

const UnloggedContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export { UnloggedContainer, UnloggedContent };
