import { format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import HistoryIcon from '../../assets/history-icon.svg';
import { getPaymentHistory } from '../../services/paymentsService';
import { Button } from '../Button';
import Spinner from '../Spinner';

import {
  Overlay,
  Container,
  Header,
  BuyDate,
  Description,
  Value,
  ButtonContainer,
} from './styles';

const PaymentHistory = ({ open, onClose }) => {
  const [loading, setLoading] = useState(true);
  const [paymentHistoryData, setPaymentHistoryData] = useState([]);

  useEffect(() => {
    (async () => {
      if (!open) return;

      setLoading(true);

      await getPaymentHistory()
        .then(res => {
          const history = res.data;

          setPaymentHistoryData(history.items);

          if (history.total >= history.per_page) {
            toast.info(
              'Este é o seu histórico  parcial de pagamentos. Entre em contato com o suporte para ver todos os pagamentos.',
              { toastId: 'history-payment-toast', autoClose: false },
            );
          }
        })
        .catch(() => {
          toast.error(
            'Ocorreu um erro ao buscar seu histórico, tente novamente',
          );
          setLoading(false);
          setPaymentHistoryData([]);
          onClose();
        })
        .finally(() => {
          setLoading(false);
        });
    })();
  }, [open, onClose]);

  return (
    <Overlay open={open}>
      <Container open={open}>
        <Header>
          <img src={HistoryIcon} alt="Relógio voltando o tempo" />
          <h1>Histórico de pagamento</h1>
          <h2>Veja aqui todas as assinaturas e compras já feitas.</h2>
        </Header>

        <section>
          <ul>
            {loading ? <Spinner style={{ margin: '0 auto' }} /> : null}
            {paymentHistoryData.length && !loading
              ? paymentHistoryData.map(item => (
                  <li>
                    <BuyDate>
                      <span>Compra feita</span>
                      <p>{format(new Date(item.purchase_at), 'dd-MM-yyyy')}</p>
                    </BuyDate>

                    <Description>{item.product}</Description>

                    <Value>
                      {Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(item.value)}
                    </Value>
                  </li>
                ))
              : null}
          </ul>
        </section>

        <ButtonContainer>
          <Button
            title="FECHAR"
            onClick={() => {
              onClose();
              setPaymentHistoryData([]);
            }}
          />
        </ButtonContainer>
      </Container>
    </Overlay>
  );
};

export default PaymentHistory;
