import React, { useState, useEffect } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';
import { useHistory, useParams } from 'react-router-dom';

import playIcon from '../../../assets/icons/play.svg';
import { useExecutionCourse } from '../../../hooks/executionCourse';
import { getExamUser } from '../../../services/examService';

import { ExamItem, ExamsContainer } from './styles';

const Exams = ({ course, parentUuid, setOpenSidebar, setExamUserData }) => {
  const { setData, data } = useExecutionCourse();
  const history = useHistory();
  const { uuid } = useParams();
  const [examUser, setExamUser] = useState(null);
  const exam = course?.data?.exams[0];

  function goToExam(contentId) {
    if (contentId === uuid) {
      window.location.reload();
      return;
    }
    if (data.courseStep === 'finished') {
      history.push(`/curso/${contentId}/${parentUuid}/exam`);
      window.location.reload();
    }
    setData(state => {
      return {
        ...state,
        courseStep: 'exam',
        isLoading: true,
      };
    });
    setOpenSidebar(false);
    document.body.style.overflow = 'auto';
    history.push(`/curso/${contentId}/${parentUuid}/exam`);
  }

  const VerifyStatus = () => {
    if (!examUser?.finish_at) {
      return (
        <i>
          <BiEdit size={24} color="inherit" />
        </i>
      );
    }
    if (!examUser?.approved) {
      return (
        <i>
          <AiFillCloseCircle size={24} color="inherit" />
        </i>
      );
    }
    return <img src={playIcon} alt="Play" />;
  };

  useEffect(() => {
    const getExamUserData = async () => {
      const user = JSON.parse(localStorage.getItem('@HaluGamashi:user'));
      const response = await getExamUser(
        course?.data?.exams[0]?.exam_id,
        user?.user_id,
      );
      setExamUserData(response?.data[0])
      setExamUser(response?.data[0]);
      return null;
    };
    if (!examUser?.exam_user_id) getExamUserData();
  }, [course?.data?.exams, examUser, setExamUserData]);
  return (
    <ExamsContainer>
      <ul>
        <li>
          <ExamItem
            active={exam?.exam_id === uuid}
            onClick={() => goToExam(exam?.exam_id)}
          >
            <VerifyStatus />
            <h5>{exam?.title}</h5>
          </ExamItem>
        </li>
      </ul>
    </ExamsContainer>
  );
};

export default Exams;
