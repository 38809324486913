export const mappedErrors = [
  {
    error: 'Já existe uma compra em andamento para este plano',
    message: 'Já existe uma compra em andamento para este plano',
  },
  {
    error: 'Já existe uma compra em andamento para este curso',
    message: 'Já existe uma compra em andamento para este curso',
  },
  {
    error: 'Saldo insuficiente',
    message: 'Saldo insuficiente, cheque seu limite de crédito',
  },
  {
    error: 'Transação não autorizada',
    message:
      'Transação não autorizada, entre em contato com a operadora do seu cartão',
  },
  {
    error: 'Cartão com restrição',
    message: 'Cartão com restrição, entre em contato com seu banco',
  },
  {
    error: 'Código de segurança inválido',
    message: 'Código de segurança inválido, cheque os dados digitados',
  },
  {
    error: 'Cartão não foi desbloqueado pelo portador',
    message: 'Cartão não foi desbloqueado, efetue o desbloqueio do seu cartão',
  },
  {
    error: 'Código de segurança inválido',
    message: 'Código de segurança inválido, cheque os dados digitados',
  },
  {
    error: 'Usuário já comprou este conteúdo',
    message: 'Conteúdo já comprado, recarregue a página',
  },
  {
    error: 'Você já possui este plano',
    message: 'Você já possui este plano',
  },
  {
    error: 'Transação não permitida',
    message: 'Transação não permitida, contate o banco emissor do seu cartão',
  },
  {
    error: 'Erro de comunicação com o gateway',
    message: 'A operadora do cartão não está fora do ar, tente mais tarde',
  },
  {
    error: 'Ocorreu um erro ao verificar o status da sua compra',
    message:
      'Ocorreu um erro ao verificar o status da sua compra, entre em contato com o suporte',
  },
];
