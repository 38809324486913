import styled from 'styled-components';

const Text = styled.div`
  position: absolute;
  left: -200px;
  top: -41px;

  width: 191px;
  padding: 0.75rem;

  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.8s;

  display: none;
  opacity: 0;

  > span {
    display: block;

    font-size: 12px;
    line-height: 1.2;

    & > strong {
      display: block;
      margin-top: 5px;
      font-weight: 600;
    }
  }
`;

const WhatsAppIcon = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;

  &:hover ${Text} {
    opacity: 1;
    display: block;
  }
`;

const WhatsAppIconInner = styled.a`
  width: 50px;
  height: 50px;
  border: 2px solid #ffffff;
  background-color: #25d366;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px;
  margin: 0;
  border-radius: 25px 25px 0px 25px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  > span {
    display: none;
    margin-left: 10px;
    width: 170px;
    min-width: 170px;
  }
`;

export { WhatsAppIcon, WhatsAppIconInner, Text };
