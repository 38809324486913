/* eslint-disable no-new */
import Compressor from 'compressorjs';
import React, { useRef, useState } from 'react';
import { FiEdit2 } from 'react-icons/fi';

import { toast } from '../../../../../components/Toast/Toast';
import { useAuth } from '../../../../../hooks/auth';
import { FilesService } from '../../../../../services/fileService';
import { UserService } from '../../../../../services/userService';
import { theme } from '../../../../../styles/theme';
import { IconEdit, ImageCropperWrapper } from '../styles';

import ImageCrop from './CropImage';

const ImageCropper = ({ setEditing, isEditing }) => {
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [scaleValue, setScaleValue] = useState(1);
  const inputRef = useRef(null);

  const filesService = new FilesService();
  const userService = new UserService();
  const { storeUser, user } = useAuth();

  const handleEditAvatar = async file => {
    try {
      const formData = new FormData();
      const fileToUpload = file;

      formData.append('file', fileToUpload);

      let avatarCompressedImg;
      let responseUploadAvatar;

      new Compressor(fileToUpload, {
        quality: 0.1,
        success: async result => {
          const formDataImgCompressed = new FormData();
          formDataImgCompressed.append('file', result);
          avatarCompressedImg = await filesService.uploadAvatar(
            formDataImgCompressed,
          );
          responseUploadAvatar = await filesService.uploadAvatar(formData);
          await userService.updateUser({
            name: user.name,
            email: user.email,
            images: {
              avatar: responseUploadAvatar.data.reference,
              chat_avatar: avatarCompressedImg.data.reference,
            },
          });

          storeUser({
            ...user,
            images: { avatar: responseUploadAvatar.data.reference },
          });
          setEditing(false);
          setLoading(false);
          toast.success('Foto de perfil atualizada com sucesso.');
        },
        error: () => {
          toast.error(
            'Houve um erro ao atualizar sua foto de perfil, tente novamente.',
          );
        },
      });
    } catch {
      toast.error(
        'Houve um erro ao atualizar sua foto de perfil, tente novamente.',
      );
    }
  };

  const dataUrlToFile = async (dataUrl, fileName) => {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    const file = new File([blob], fileName, { type: 'image/png' });
    return file;
  };

  const profileImageChange = fileChangeEvent => {
    const file = fileChangeEvent?.target?.files[0];
    const { type } = file;
    if (type.endsWith('jpeg') || type.endsWith('png') || type.endsWith('jpg')) {
      setSelectedImage(file);
      setEditing(true);
    }
  };

  const onCrop = async () => {
    setLoading(true);

    const { name } = selectedImage;

    if (!inputRef) {
      toast.warn('Selecione uma imagem para continuar.');
      setLoading(false);
      return;
    }

    const url = inputRef?.current?.getImageScaledToCanvas().toDataURL();
    const file = await dataUrlToFile(url, name);
    await handleEditAvatar(file);
  };

  const onScaleChange = scaleValueEvent => {
    const scaleValueFromInput = parseFloat(scaleValueEvent.target.value);
    setScaleValue(scaleValueFromInput);
  };

  return (
    <>
      {!isEditing && (
        <>
          <IconEdit for="uploadAvatar">
            <FiEdit2 style={{ color: theme.colors.light }} />
          </IconEdit>
          <input
            id="uploadAvatar"
            type="file"
            accept="image/png, image/jpeg"
            hidden
            onChange={profileImageChange}
          />
        </>
      )}
      {selectedImage && isEditing && (
        <ImageCropperWrapper>
          <ImageCrop
            imageSrc={selectedImage}
            setEditorRef={inputRef}
            onCrop={onCrop}
            onScaleChange={onScaleChange}
            scaleValue={scaleValue}
            onCancel={setEditing}
            loading={loading}
          />
        </ImageCropperWrapper>
      )}
    </>
  );
};

export default ImageCropper;
