import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as EmailIcon } from '../../../../assets/icons/email.svg';
import { ReactComponent as UnlockedIcon } from '../../../../assets/icons/unlocked.svg';
import { Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';

import { Container, FormContainer, ErrorMessage } from './styles';

const WelcomeToIMA = ({ handleSubmit, errors, register }) => {
  return (
    <Container>
      <h2>Passo 1 de 2</h2>
      <h1>Bem vindo ao IMA</h1>

      <FormContainer>
        <span>Insira seus dados</span>

        <form onSubmit={handleSubmit}>
          <Input
            placeholder="Email"
            type="email"
            icon={<EmailIcon />}
            autoComplete={false}
            isInvalid={errors.email?.message}
            errorMessage={errors.email?.message}
            {...register('email')}
          />

          <Input
            placeholder="Senha"
            type="password"
            icon={<UnlockedIcon />}
            autoComplete={false}
            isInvalid={errors.password?.message}
            errorMessage={errors.password?.message}
            {...register('password')}
          />

          <Input
            placeholder="Confirme a senha"
            type="password"
            icon={<UnlockedIcon />}
            autoComplete={false}
            isInvalid={errors.passwordConfirmation?.message}
            errorMessage={errors.passwordConfirmation?.message}
            {...register('passwordConfirmation')}
          />

          <label htmlFor="terms">
            <input type="checkbox" id="terms" {...register('terms')} />
            <span>
              Li e aceito as{' '}
              <Link to="/politica-privacidade" target="_blank">
                Políticas de privacidade
              </Link>{' '}
              e{' '}
              <Link to="/termos-de-uso" target="_blank">
                Termos de Uso
              </Link>
            </span>
          </label>

          {errors.terms?.message ? (
            <ErrorMessage>{errors.terms.message}</ErrorMessage>
          ) : null}

          <Button
            type="submit"
            title="CADASTRAR"
            disabled={Object.keys(errors).length}
            containerStyle={{ marginTop: 32 }}
          />
        </form>
      </FormContainer>
    </Container>
  );
};

export { WelcomeToIMA };
