import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import aboutHaluImgMobile from '../../../../assets/images/about-halu-lp.png';
import aboutHaluImgDesktop from '../../../../assets/images/about-halu.png';
import useWindowSize from '../../../../hooks/useWindowSize';

import {
  Container,
  ContentContainer,
  HomeAboutHaluLeftContent,
  HomeAboutHaluRightContent,
} from './style';

const HomeAboutHalu = () => {
  const { windowSize } = useWindowSize();
  const history = useHistory();

  const handleGoToLogin = () => {
    history.push('/logar');
  };

  return (
    <div className="max-content">
      <Container>
        <div className="title hidden-desktop">
          <h2>Sobre Halu Gamashi</h2>
          <Link to="/sobre-halu-gamashi">SAIBA MAIS</Link>
        </div>

        <ContentContainer>
          <HomeAboutHaluRightContent>
            <div />
            <div />
            <img
              src={windowSize < 800 ? aboutHaluImgMobile : aboutHaluImgDesktop}
              alt="Sobre Halu Gamashi"
            />
          </HomeAboutHaluRightContent>

          <HomeAboutHaluLeftContent>
            <div className="title hidden-mobile">
              <h2>Sobre Halu Gamashi</h2>
              <Link to="/sobre-halu-gamashi">SAIBA MAIS</Link>
            </div>

            <p>
              Fundadora do IMA, Halu Gamashi é baiana de nascimento e uma cidadã
              do mundo por excelência. Terapeuta e Escritora há mais de 30 anos
              e criadora de Técnicas Corporais Sutis, ministra cursos,
              congressos e vivências sobre Anatomia do Corpo Sutil, Chakras,
              Campo Áurico, Astrosofia, Filosofia, Saúde e Comportamento Humano
              em diversas cidades brasileiras e europeias.
            </p>

            <p>
              Desenvolveu uma linha de produtos – Maberu – que tem auxiliado e
              melhorado a qualidade de vida de milhares de pessoas. Iniciada nas
              culturas ancestrais, relata em livros a ampliação da consciência
              cósmica e as aberturas espontâneas de seus chakras.
            </p>

            <button type="button" onClick={handleGoToLogin}>
              COMEÇAR MINHA JORNADA
            </button>
          </HomeAboutHaluLeftContent>
        </ContentContainer>
      </Container>
    </div>
  );
};

export default HomeAboutHalu;
