import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../Button';

import { Container, Content, CardText } from './styles';

const CardMyCoursesPage = ({ name, description, url }) => {
  const history = useHistory();

  function goTo() {
    history.push(url);
  }

  return (
    <Container>
      <Content>
        <span />
        <CardText>
          <h2> {name} </h2>
          <p>{description}</p>
        </CardText>
        <label>
          <Button
            title="VER MAIS"
            containerStyle={{
              maxWidth: '157px',
            }}
            onClick={goTo}
          />
        </label>
      </Content>
    </Container>
  );
};
export default CardMyCoursesPage;
