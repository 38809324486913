import { enc } from 'crypto-js';
import AES from 'crypto-js/aes';
import React, { createContext, useCallback, useState, useContext } from 'react';
import { toast } from 'react-toastify';

import {
  createPayment,
  createPaymentWithCardSaved,
  getPaymentProfile,
} from '../services/paymentsService';
import { purchasePlan } from '../services/plans';
import { UserService } from '../services/userService';
import { mappedErrors } from '../utils/paymentsMappedErrors';

import { useAuth } from './auth';

const PaymentModalContext = createContext({});

const PaymentModalProvider = ({ children }) => {
  const [modalData, setModalData] = useState({
    modalOpen: false,
    isLoading: false,
    paymentSuccess: false,
    product: {
      id: '',
      name: '',
      author: '',
      rating: { value: '', totalRatings: '' },
      price: '',
    },
    storedCard: null,
  });

  const { user, storeUser } = useAuth();

  const getStoredCard = useCallback(async () => {
    const data = await getPaymentProfile();
    setModalData(state => ({ ...state, storedCard: data }));
    return data;
  }, []);

  const openModal = useCallback(
    ({ name, author, type, rating, discount, price, id, info = {} }) => {
      getStoredCard();
      document.querySelector('body').style.overflow = 'hidden';

      setModalData(state => ({
        ...state,
        paymentSuccess: false,
        modalOpen: true,
        product: {
          name,
          type,
          author,
          rating,
          price,
          discount,
          id,
          info,
        },
      }));
    },
    [getStoredCard],
  );

  const closeModal = useCallback(() => {
    document.querySelector('body').style.overflow = 'auto';

    setModalData(state => ({
      modalOpen: false,
      isLoading: false,
      paymentSuccess: false,
      product: {
        name: '',
        author: '',
        rating: { value: 0, totalRating: 0 },
        price: 0,
      },
      storedCard: state.storedCard,
    }));
  }, []);

  const convertCardDataToRequestFormat = useCallback(data => {
    return {
      holder_name: data.name,
      registry_code: data.document,
      card_expiration: data.expirationDate,
      card_number: data.cardNumber.replaceAll(' ', ''),
      card_cvv: data.cvv,
    };
  }, []);

  const handleClickPayment = useCallback(
    async (
      rememberCard,
      cardData,
      contentId,
      token,
      coupon,
      installments = 1,
      hasSavedCard = false,
      course,
    ) => {
      setModalData(state => ({ ...state, isLoading: true }));
      try {
        if (cardData) {
          const dataToEncrypt = cardData;

          const encryptedData = AES.encrypt(
            JSON.stringify(convertCardDataToRequestFormat(dataToEncrypt)),
            `Bearer ${token}`,
          ).toString();

          const encodedData = enc.Base64.stringify(
            enc.Utf8.parse(encryptedData),
          );
          if (hasSavedCard) {
            await createPaymentWithCardSaved(
              contentId,
              coupon,
              installments,
              course,
            );
            const userService = new UserService();
            const response = await userService.getContentsPaid();
            storeUser({ ...user, contents_paid: response.data.data });

            setModalData(state => ({
              ...state,
              paymentSuccess: true,
            }));

            return;
          }

          await createPayment(
            rememberCard,
            encodedData,
            contentId,
            coupon,
            installments,
            course,
          );
          getStoredCard();
        } else {
          await createPaymentWithCardSaved(contentId, coupon, installments);
        }

        const userService = new UserService();
        const response = await userService.getContentsPaid();
        storeUser({ ...user, contents_paid: response.data.data });

        setModalData(state => ({
          ...state,
          paymentSuccess: true,
        }));
      } catch (err) {
        const parsedErrorMessage = mappedErrors.find(
          option => option.error === err?.response?.data?.message,
        );

        if (parsedErrorMessage) {
          toast.warning(parsedErrorMessage.message);
          return;
        }

        toast.error(
          'Ocorreu um erro ao tentar realizar sua compra, tente novamente.',
        );
      } finally {
        setModalData(state => ({ ...state, isLoading: false }));
      }
    },
    [convertCardDataToRequestFormat, storeUser, user, getStoredCard],
  );

  const handlePurchasePlan = useCallback(
    async (rememberCard, cardData, planId, token, installments = 1) => {
      setModalData(state => ({ ...state, isLoading: true }));
      let response = null;
      try {
        if (cardData) {
          const encryptedData = AES.encrypt(
            JSON.stringify(convertCardDataToRequestFormat(cardData)),
            `Bearer ${token}`,
          ).toString();

          const encodedData = enc.Base64.stringify(
            enc.Utf8.parse(encryptedData),
          );
          response = await purchasePlan(
            planId,
            encodedData,
            rememberCard,
            installments,
          );
          storeUser({ ...user, rememberCard });
          getStoredCard();
        } else {
          response = await purchasePlan(planId, null, false, installments);
        }

        setModalData(state => ({
          ...state,
          paymentSuccess: true,
        }));
        return response;
      } finally {
        setModalData(state => ({ ...state, isLoading: false }));
      }
    },
    [convertCardDataToRequestFormat, getStoredCard, storeUser, user],
  );

  const resetModalData = useCallback(() => {
    setModalData(state => ({
      modalOpen: false,
      isLoading: false,
      paymentSuccess: false,
      product: {
        id: '',
        name: '',
        author: '',
        rating: { value: '', totalRatings: '' },
        price: '',
      },
      storedCard: state.storedCard,
    }));
    closeModal();
  }, [closeModal]);

  const changeStatusLoading = useCallback(condition => {
    setModalData(state => ({
      ...state,
      isLoading: condition,
    }));
  }, []);

  const changePaymentSuccess = useCallback(condition => {
    setModalData(state => ({
      ...state,
      paymentSuccess: condition,
    }));
  }, []);

  return (
    <PaymentModalContext.Provider
      value={{
        openModal,
        closeModal,
        modalData,
        handleClickPayment,
        getStoredCard,
        handlePurchasePlan,
        resetModalData,
        changeStatusLoading,
        paymentSuccess: modalData.paymentSuccess,
        changePaymentSuccess,
      }}
    >
      {children}
    </PaymentModalContext.Provider>
  );
};

function usePaymentModal() {
  const context = useContext(PaymentModalContext);

  if (!context) {
    throw new Error(
      'usePaymentModal should be used within an PaymentModalProvider',
    );
  }

  return context;
}

export { PaymentModalProvider, usePaymentModal };
