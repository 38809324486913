import styled from 'styled-components';

import { theme } from '../../styles/theme';

export const ErrorMessage = styled.span``;

export const Container = styled.div`
  margin: 16px 0;
  width: 100%;

  ${ErrorMessage} {
    display: block;
    font-size: 0.8rem;
    color: ${theme.colors.error};
    text-align: start;
    width: 100%;
    margin: 8px 0 0 10px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 30px;
  border: 1px solid
    ${({ isInvalid }) =>
      isInvalid ? theme.colors.error : theme.colors.shapeSecondary};
  background-color: ${theme.colors.light};
  padding: 16px 12px;
  min-width: 270px;

  input {
    margin-left: 12px;
    width: 100%;
    height: 100%;

    font-size: 16px;
    font-weight: 400;

    &::placeholder {
      color: #adb5bd;
    }

    &:disabled {
      background-color: #ddd;
    }
  }
`;

export const TextArea = styled.textarea`
  margin-left: 12px;
  width: 100%;
  height: 100%;
  min-height: 50px;

  font-size: 16px;
  font-weight: 400;
  resize: none;
  overflow: hidden;

  &::placeholder {
    color: #adb5bd;
  }

  &:disabled {
    background-color: #ddd;
  }
`;
