import React, { useCallback, useEffect, useState } from 'react';

import { getLadingPageContent as getLandingPageContentService } from '../../services/HomeInfo';

import AscendHome from './components/AscendHome';
import HomeAbout from './components/HomeAbout';
import HomeAboutHalu from './components/HomeAboutHalu';
import HomeBanner from './components/HomeBanner';
import HomeFeatures from './components/HomeFeatures';
import HomeMasters from './components/HomeMasters';
import HomeNextLaunchs from './components/HomeNextLaunchs';
import HomePlans from './components/HomePlans';
import HomeSchedule from './components/HomeSchedule';
import { HomeContainer } from './style';

const Home = () => {
  const [landingPageContent, setLandingPageContent] = useState({});

  const getLandingPageContent = useCallback(async () => {
    const { data: homeInfoContents } = await getLandingPageContentService();
    const content = homeInfoContents[0];
    setLandingPageContent(content);
  }, []);

  useEffect(() => {
    getLandingPageContent();
  }, [getLandingPageContent]);

  return (
    <HomeContainer>
      <HomeBanner
        bannerUrl={landingPageContent?.info?.bannerUrl}
        mediaUrl={landingPageContent?.info?.mediaUrl}
      />

      <HomeFeatures />

      <HomeAbout mediaUrl={landingPageContent?.info?.mediaUrl} />

      <AscendHome />

      <HomeAboutHalu />

      <HomeSchedule />

      <HomeNextLaunchs />

      <HomePlans />

      <HomeMasters />
    </HomeContainer>
  );
};

export default Home;
