export function goToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

export function convertDuration(seconds) {
  const minutes = Math.floor(seconds / 60);
  const hours = (seconds / 3600).toFixed(2);

  if (minutes === 0 && hours === '0.00') return null;

  return minutes < 60 ? `${minutes} m` : `${hours} h`;
}
