import styled, { css } from 'styled-components';

import profileBanner from '../../assets/images/profile-banner.png';
import { theme } from '../../styles/theme';

export const Container = styled.div`
  background: ${theme.colors.shape};
`;

export const Header = styled.section`
  background: url(${profileBanner});
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 860px) {
    background-position: center;
  }
`;

export const ImageFilter = styled.small`
  background: linear-gradient(
    90deg,
    rgba(89, 74, 173, 0.83) -5.53%,
    rgba(159, 151, 201, 0.3818) 60.04%,
    rgba(47, 24, 156, 0.83) 131.32%
  );
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
  width: 100%;
  height: 100%;
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  padding: 152px 24px 24px 24px;
  position: relative;
  @media (max-width: 480px) {
    padding: 32px 10px;
  }
`;

export const Cards = styled.div`
  margin: 0 40px;
  @media (max-width: 480px) {
    margin: 0 16px;
  }
`;

export const ProfileCard = styled.section`
  background: ${theme.colors.light};
  padding: 32px 20px;
  margin-bottom: 32px;
  border-radius: 20px;
  transform: translateY(-100px);
  box-shadow: 0 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  position: relative;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  h3 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 8px;
  }

  h4 {
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 40px;
  }

  form {
    span {
      margin-top: 34px;
    }

    > div {
      display: flex;
      justify-content: flex-start;
      > div {
        margin: 8px;
      }
    }
  }

  @media (max-width: 480px) {
    padding: 0 0 8px 0;
    margin: 0 0 20px 0;
    min-width: 100%;

    h3 {
      padding: 16px 0 0 16px;
    }

    h4 {
      text-align: center;
    }

    form {
      margin: 0 0 50px 0;
    }
  }

  @media (max-width: 1350px) {
    form {
      > div {
        flex-direction: column;
        align-items: center;
      }
    }

    .profile-button {
      margin: 40px auto 0;
    }
  }
`;

export const SelectCustomStyle = {
  container: provided => ({
    ...provided,
    maxWidth: 270,
    width: '100%',
  }),
  control: provided => ({
    ...provided,
    maxWidth: 270,
    width: '100%',
    margin: '0 auto',
    borderRadius: 20,
    backgroundColor: '#F5F8FA',
    padding: '8px 12px',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
    display: 'flex',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  option: provided => ({
    ...provided,
    maxWidth: 270,
    borderRadius: 20,
  }),
  menuList: provided => ({
    ...provided,
    maxHeight: 130,
  }),
};

export const SelectCustomAddressStyle = {
  container: provided => ({
    ...provided,
    minWidth: 397,
    width: '100%',
  }),
  control: provided => ({
    ...provided,
    maxWidth: 397,
    width: '100%',
    borderRadius: 20,
    backgroundColor: '#F5F8FA',
    padding: '8px 12px',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
    display: 'flex',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  option: provided => ({
    ...provided,
    maxWidth: 397,
    borderRadius: 20,
  }),
  menuList: provided => ({
    ...provided,
    maxHeight: 130,
  }),
};

export const ErrorMessage = styled.span`
  display: block;
  font-size: 0.8rem;
  color: ${theme.colors.error};
  text-align: start;
  width: 100%;
  margin-top: 8px;
  margin-left: 10px;
`;

export const SelectContainerState = styled.div`
  max-width: 270px;
  width: 100%;
  margin-bottom: 16px;
  /* Border of ReactSelect */
  span + span + div {
    border: 1px solid
      ${({ error }) =>
        error ? theme.colors.error : theme.colors.shapeSecondary};
  }
`;

export const SelectContainer = styled.div`
  max-width: 270px;
  width: 100%;
  margin-bottom: 16px;
  /* Border of ReactSelect */
  span + span + div {
    border: 1px solid
      ${({ error }) =>
        error ? theme.colors.error : theme.colors.shapeSecondary};
  }
`;

export const Card = styled.section`
  background: ${theme.colors.light};
  border-radius: 20px;
  box-shadow: 0 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);

  padding: 32px 20px;
  margin-bottom: 32px;

  transform: translateY(-100px);
  position: relative;

  h3 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 8px;
  }

  h4 {
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 40px;
  }

  ${({ planCanceled }) =>
    planCanceled &&
    css`
  &#plan {
    padding-top 67px; 
  }
  `}
`;

export const PurchasesAndPlans = styled.div``;

export const PlanInfo = styled.div`
  display: flex;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const Plan = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 1.2rem;
    font-weight: 600;
  }

  h5 {
    font-size: 3rem;
    color: #594aad;
    font-weight: bold;
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 460px;
  }

  h5 + p {
    font-size: 3rem;
    font-weight: 500;
  }

  span {
    font-size: 0.9rem;
    color: #909599;
    font-weight: 500;
    margin-top: 8px;
  }

  ${({ planCanceled }) =>
    planCanceled &&
    css`
      span {
        color: ${theme.colors.warning};
        font-weight: 700;
      }
    `}

  @media (max-width: 1100px) {
    align-items: start;
  }

  @media (max-width: 430px) {
    h5 {
      font-size: 1.6rem;
    }

    p {
      font-size: 1.3rem;
    }
  }
`;

export const HorizontalDivider = styled.div`
  margin: 0 48px;
  height: 150px;
  border: 1px solid #cfd2d4;
  @media (max-width: 1100px) {
    width: 40%;
    height: 1px;
    margin: 16px 0;
  }
`;

export const PaymentInfo = styled.div`
  display: flex;
  flex-direction: column;
  p {
    color: #657787;
    margin-bottom: 10px;
    font-size: 1.1rem;
    font-weight: 500;
  }
  h5 {
    font-weight: 600;
    font-size: 1.2rem;
  }
`;

export const PaymentButtons = styled.div`
  display: flex;
  .spinner {
    display: flex;
    align-items: center;
    margin-left: 40px;
  }
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const ChangePasswordContainer = styled.div`
  display: flex;
  justify-content: ${({ alignCenter }) =>
    alignCenter ? 'space-around' : 'flex-start'};
  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;

export const ScoreStatus = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  h5 {
    flex: 1;
    font-size: 2rem;
    color: ${theme.colors.primary};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  svg {
    margin: 0 4px;
  }
`;

export const PointsInfo = styled.div`
  p {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  i {
    color: #676767;
  }
`;

export const LevelsContainer = styled.div`
  width: 40%;
  h2 {
    font-size: 2.4rem;
  }
  h3 {
    font-size: 1.6rem;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const LevelInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  gap: 8px;
  margin-top: 20px;

  h6 {
    font-size: 1.4rem;
  }

  > div {
    display: flex;

    svg {
      width: 20px;
      height: 20px;
      margin-bottom: 8px;
    }
  }

  @media (min-width: 600px) {
    flex-direction: row;

    h6 {
      font-size: 1.6rem;
    }

    > div {
      display: flex;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  @media (min-width: 800px) {
    flex-direction: column;

    h6 {
      font-size: 1.4rem;
    }

    > div {
      display: flex;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  @media (min-width: 800px) {
    flex-direction: column;

    h6 {
      font-size: 1.4rem;
    }

    > div {
      display: flex;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  @media (min-width: 1100px) {
    flex-direction: row;
  }
`;

export const PointsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const BadgeCanceledPlan = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  background: ${theme.colors.warning};
  padding: 8px;
  border-radius: 5px;

  span {
    color: ${theme.colors.light};
    font-weight: 700;
  }
`;

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1300px) {
  }
`;

export const AddressRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 850px;
`;
