import axiosApi from './api';

class UserService {
  constructor() {
    this.api = axiosApi;
    this.resource = 'users';
  }

  async updateUser(data) {
    return this.api.put(this.resource, data);
  }

  async updatePassword(currentPassword, newPassword) {
    return this.api.post(`${this.resource}/change-password`, {
      current_password: currentPassword,
      new_password: newPassword,
      new_password_confirm: newPassword,
    });
  }

  async getContentsPaid() {
    return this.api.get(`${this.resource}/contents-paid`);
  }

  async getContentsPurchaseIds() {
    return this.api.get(`${this.resource}/contents-id-purchase`);
  }

  async getPlansPurchaseIds() {
    return this.api.get(`${this.resource}/plans-id-purchase`);
  }

  async getUserData(token) {
    return this.api.get(`${this.resource}/me`, {
      headers: {
        ...(token && {
          authorization: `Bearer ${token}`,
        }),
      },
    });
  }

  async getUserLevel() {
    return this.api.get(`${this.resource}/level`);
  }

  async getUserCustomerAddress() {
    return this.api.get(`${this.resource}/personal`);
  }

  async updateUserCustomerAddress(data) {
    return this.api.put(`${this.resource}/customer`, data);
  }
}

export { UserService };
