import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  max-width: 340px;
  max-height: 440px;
  min-width: 300px;
  border-radius: 20px;
  position: relative;
`;
