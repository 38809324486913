import styled from 'styled-components';

export const Container = styled.div`
  width: 300px;
  min-width: 300px;
  height: 288px;
  display: flex;
  border-radius: 25px;
  margin: auto 12px;

  @media (max-width: 560px) {
    width: 100%;

    margin: 0 auto;
    padding: 0 24px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: unset;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  border-radius: 25px;
  @media (max-width: 480px) {
    padding: 0;
    margin: 0;
    position: relative;
  }

  img {
    border-radius: 25px 25px 0 0;
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    @media (max-width: 480px) {
      position: absolute;
    }
  }
`;

export const CardTop = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  padding-bottom: 5px;
  border-radius: 25px 25px 0 0;
  position: relative;
  @media (max-width: 480px) {
    padding: 0;
  }

  > p {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    width: 100%;
    text-align: center;
    letter-spacing: 0.05em;
    margin-top: 70px;
    height: 40px;
    @media (max-width: 480px) {
      padding: 0;
      margin-top: 90px;
    }
  }
`;

export const CardBottom = styled.div`
  background: #ffffff;
  height: 124px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  border-radius: 0 0px 25px 25px;
  @media (max-width: 480px) {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    padding-top: 40px;
    border-radius: 0 0 25px 25px;
  }

  > p {
    width: 100%;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: 0.05em;
    color: #25282b;
    @media (max-width: 480px) {
      padding-bottom: 36px;
      margin: 0;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;
