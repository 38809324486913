import React from 'react';

import { Container, ErrorMessage } from './styles';

export function TextArea({ containerStyle, errorMessage, isInvalid, ...rest }) {
  return (
    <div>
      <Container style={containerStyle} isInvalid={isInvalid}>
        <textarea {...rest} />
      </Container>
      {isInvalid ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </div>
  );
}
