import React from 'react';
// import { Carousel } from 'react-responsive-carousel';
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';

import 'react-multi-carousel/lib/styles.css';

import useMediaQuery from '../../hooks/useMediaQuery';

import { Container, CardInfoMobile } from './styles';

const CarouselResponsiveHomeLogged = ({
  children,
  className,
  link,
  linkText,
  title,
  textFallback = 'No momento não possuímos conteúdos para exibir aqui',
}) => {
  const isLargerThan1100 = useMediaQuery('(min-width: 1100px)');
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: { max: 699, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 40,
    },
  };
  return (
    <Container className={className}>
      <CardInfoMobile
        style={{ padding: isLargerThan1100 ? 'initial' : '' }}
        ClassName="hidden-desktop"
      >
        <h2>{title}</h2>
        {link ? <Link to={link}>{linkText}</Link> : null}
      </CardInfoMobile>

      <Carousel
        swipeable
        showDots
        draggable
        responsive={responsive}
        containerClass="carousel-container"
        partialVisbile
      >
        {children[0] ? children : <h5>{textFallback}</h5>}
      </Carousel>
    </Container>
  );
};

export default CarouselResponsiveHomeLogged;
