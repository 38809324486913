import styled, { css } from 'styled-components';

import { theme } from '../../../../styles/theme';

export const Container = styled.li`
  list-style: none;
  background-color: transparent;
  padding: 24px 32px;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: ${theme.colors.light};
  }

  ${({ isActive }) =>
    !isActive &&
    css`
      opacity: 0.7;
      background: inherit;

      &:hover {
        background-color: transparent;
      }

      > h3 {
        color: ${theme.colors.textSecondary};
      }
    `}
`;

export const Title = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;

  @media (max-width: 650px) {
    font-size: 0.9rem;
  }

  @media (max-width: 500px) {
    text-align: center;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 42px;

  img {
    margin-right: 9px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  @media (max-width: 650px) {
    margin-top: 21px;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const Username = styled.span`
  margin-right: 13px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Published = styled.span`
  font-size: 0.8rem;

  @media (max-width: 500px) {
    margin-top: 8px;
  }
`;

export const ConversationIcon = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 64px;
  right: 32px;
  color: ${theme.colors.textSecondary};
  font-weight: 600;

  svg {
    margin-right: 8px;
  }

  @media (max-width: 650px) {
    position: static;
    margin-top: 12px;

    svg {
      margin-left: auto;
    }
  }

  @media (max-width: 500px) {
    justify-content: center;

    svg {
      margin-left: unset;
    }
  }
`;

export const TrashIcon = styled.div`
  z-index: 2;
  cursor: pointer;

  position: absolute;
  bottom: 8px;
  right: 8px;

  display: flex;
  align-items: center;

  color: ${theme.colors.error};
  font-size: 0.9rem;

  &:hover {
    opacity: 0.4;
  }

  svg {
    margin-right: 10px;
  }

  @media (max-width: 650px) {
    position: static;
    margin-top: 12px;
  }

  @media (max-width: 500px) {
    justify-content: center;
  }
`;

export const ActiveStatusIcon = styled.div`
  z-index: 2;
  cursor: pointer;

  position: absolute;
  bottom: 8px;
  right: 96px;
  width: 80px;

  display: flex;
  align-items: center;

  color: ${({ isActive }) =>
    isActive ? theme.colors.warning : theme.colors.secondary};
  font-size: 0.9rem;

  &:hover {
    opacity: 0.4;
  }

  svg {
    margin-right: 10px;
  }

  @media (max-width: 650px) {
    position: static;
  }

  @media (max-width: 500px) {
    margin: 0 auto;
    margin-top: 12px;
  }
`;
