import axiosApi from './api';

class LivesService {
  constructor() {
    this.api = axiosApi;
    this.baseUrl = '/lives';
  }

  async getLive(liveId) {
    return this.api.get(`${this.baseUrl}/${liveId}`).then(res => res.data);
  }
}

export { LivesService };
