import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div``;

export const Overlay = styled.div`
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const Menu = styled.div`
  position: relative;
  top: 65px;
  right: 2%;
  left: auto;
  z-index: 1;

  margin-left: auto;
  min-width: 264px;
  max-width: 264px;
  height: max-content;

  background: #fff;
  box-shadow: 0 0 10px #0000001a;
  border-radius: 20px;

  user-select: none;

  @media (min-width: 1100px) {
    top: 87px;
    left: ${({ position }) => position}px;
    margin: 0;
  }
`;

export const MenuHead = styled.div`
  padding: 1rem 1rem 0.5rem;

  > p {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    color: #25282b !important;
  }
`;

export const MenuBody = styled.ul`
  &&& {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100px;
    max-height: 50vh;
    overflow: auto;
  }
`;

export const MenuEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-bottom: 0.8rem;
  cursor: default;

  > p {
    font-size: 0.875rem;
    padding: 0.8rem 0.5rem;
    color: #657787;
  }
`;

export const MenuItem = styled.li`
  &&& {
    width: 100%;

    display: flex;
    padding: 0.8rem 1rem;
    margin: 0;

    cursor: pointer;
    transition: all 0.2s ease;

    background-color: ${({ active }) => (active ? 'trasparent' : '#f9f9f9')};

    &:hover {
      background-color: ${({ active }) => (active ? '#efefef' : '#e0e0e0')};
    }

    .user-avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      position: relative;

      &:before {
        content: '';
        width: 6px;
        height: 6px;
        flex: 0 0 auto;
        background-color: ${({ active }) => (active ? '#2F189C' : '#ccc')};
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 0;
      }

      img,
      svg {
        width: 32px;
        height: 32px;
        object-fit: cover;
        border-radius: 100%;
        color: #ccc;
      }
    }

    .notification-body {
      display: flex;
      flex-direction: column;
      padding: 0 0.5rem;
      gap: 2px;

      time {
        font-size: 80%;
        color: #ccd5dd;
      }

      > p {
        font-size: 0.825rem;
        color: #657787;
      }
    }

    .title {
      color: #2f189c;
    }

    .description {
      color: #111212;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 2px;
    }

    .username {
      color: #2f189c;
    }
    .topic-name {
      color: #111212;
    }
  }
`;

export const MenuFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > button {
    padding: 0.5rem 0.8rem;

    color: #2f189c;
    font-size: 0.8rem;
    font-weight: 600;

    transition: all 0.2s ease;

    &:hover {
      background-color: #2f189c20;
      border-radius: 0 0 20px 20px;
    }

    &:disabled {
      visibility: hidden;
    }
  }
`;

export const ButtonIcon = styled.button`
  position: relative;

  > svg {
    width: 36px;
    height: 36px;
    color: #fff;
    cursor: pointer;
  }
`;

export const BadgetIcon = styled.div`
  position: absolute;
  top: -2px;
  right: 2px;
  width: 14px;
  height: 14px;

  padding-right: 1.5px;
  border-radius: 50%;

  background-color: red;
  bo-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  color: #fff;
  font-size: 11px;
  font-weight: 600;
  text-align: center;
`;

const shake = keyframes`
  0% {
    transform: translate(0, 0)
  }
  25% {
    transform: translate(-2px, 0px)
  }
  50% {
    transform: translate(2px, -0px);
  }
  75% {
    transform: translate(-2px, 0px);
  }
  100% {
    transform: translate(0, 0);
  }
`;

export const WrapperNotificationIcon = styled.div`
  ${({ showAnimation }) => css`
    ${showAnimation &&
    css`
      animation: ${shake} 0.5s infinite;

      > svg {
        animation: ${shake};
      }
    `}
  `}
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;

  > svg {
    width: 36px;
    height: 36px;
  }
`;
