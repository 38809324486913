import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import iconBack from '../../assets/icons/icon-back.svg';
import bannerAboutHaluGamashi from '../../assets/images/banner-about-halu-gamashi.png';
import NewFooterWithIconsLogged from '../../components/NewFooterWithIconsLogged';
import ResponsivePlayer from '../../components/ResponsivePlayer';
import { controlsToEnable } from '../../configs/SvPlayer';
import * as HomeInfoService from '../../services/HomeInfo';
import { goToTop } from '../../utils/helpers';

import {
  ContentHeader,
  AboutTextHaluGamashi,
  HeaderAbout,
  TextInfo,
  Video,
} from './styles';

const AboutHaluGamashi = () => {
  const [content, setContent] = useState({});

  useEffect(() => {
    HomeInfoService.getAboutHaluContent()
      .then(response => {
        setContent(response.data[0]);
      })
      .catch(() => {
        toast.error(
          'Ocorreu um erro ao buscar os conteúdos, recarregue a página.',
        );
      });
  }, []);

  goToTop();

  return (
    <>
      <HeaderAbout
        className="hidden-mobile"
        backgroundImage={bannerAboutHaluGamashi}
      >
        <ContentHeader>
          <div>
            <img src={iconBack} alt="Icone Voltar" />
            <Link to="/">Voltar</Link>
          </div>
          <h2 className="hidden-mobile">Sobre H. G.</h2>
          <h1>Sobre H. G.</h1>
        </ContentHeader>
      </HeaderAbout>

      <AboutTextHaluGamashi>
        <article>
          <h1>Conheça Halu Gamashi</h1>

          {content.info?.mediaUrl && (
            <Video>
              <ResponsivePlayer
                className="hidden-mobile"
                contentUrl={content.info.mediaUrl}
                controlsToEnable={controlsToEnable}
                id="playerdesktopSobreHalu"
              />
              <ResponsivePlayer
                className="hidden-desktop"
                contentUrl={content.info.mediaUrl}
                controlsToEnable={controlsToEnable}
                id="playerMobileSobreHalu"
              />
            </Video>
          )}
          <TextInfo dangerouslySetInnerHTML={{ __html: content.text }} />
        </article>
      </AboutTextHaluGamashi>
      <NewFooterWithIconsLogged />
    </>
  );
};
export default AboutHaluGamashi;
