import { addDays, isFuture } from 'date-fns';

function checkUserIsPaid(plans) {
  if (!plans) return true;

  const hasPlanPaid = plans.find(
    plan => plan.type !== 0 && isFuture(addDays(new Date(plan?.finish_at), 10)),
  );

  return !!hasPlanPaid;
}

export default checkUserIsPaid;
