import api from './api';

export function startContent(contentId) {
  return api.post(`/content-users/${contentId}/start`);
}

export function finishContent(contentId) {
  return api.post(`/content-users/${contentId}/finish`);
}

export function updateContentProgress(contentId, progress) {
  return api.patch(`/content-users/${contentId}/progress`, { progress });
}
