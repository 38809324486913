export const mappedErrors = [
  {
    error: 'Erro interno: Attempt limit exceeded, please try after some time..',
    message: 'Limite máximo de tentativas alcançado, tente mais tarde...',
  },
  {
    error:
      'Erro interno: User cannot be confirmed. Current status is CONFIRMED.',
    message: 'Este usuário já foi confirmado.',
  },
  {
    error: 'Erro interno: Código de validação não corresponde ao enviado.',
    message: 'Código de validação não corresponde ao enviado.',
  },
  {
    error: 'Erro interno: Código de validação informado expirou.',
    message: 'Código de validação informado expirou.',
  },
];
