import styled from 'styled-components';

const UnloggedFooterContainer = styled.div`
  background: linear-gradient(rgb(89, 74, 173) 0%, rgb(47, 24, 156) 100%);
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
  padding: 16px 0;
  color: white;
`;

const UnloggedFooterUpperContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
`;

const UnloggedFooterLowerContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UnloggedFooterCopyright = styled.div`
  font-size: 1.1em;
  font-weight: 500;
`;

const UnloggedFooterContacts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  div,
  a {
    display: flex;
    align-items: flex-start;
    gap: 8px;

    text-decoration: none;
    color: white;
  }
`;

const UnloggedFooterBannerContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 80%;
    min-width: 360px;
  }
`;

const UnloggedFooterSocialMedias = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  div,
  a {
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: none;
    color: white;

    span {
      display: flex;
      align-items: center;
    }
  }
`;

const UnloggedFooterDivider = styled.div`
  border: solid 1px rgba(255, 255, 255, 0.3);
  height: 0;
`;

export {
  UnloggedFooterContainer,
  UnloggedFooterUpperContent,
  UnloggedFooterSocialMedias,
  UnloggedFooterBannerContainer,
  UnloggedFooterContacts,
  UnloggedFooterCopyright,
  UnloggedFooterLowerContent,
  UnloggedFooterDivider,
};
