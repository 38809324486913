import { Link } from 'react-router-dom';
import styled from 'styled-components';

const UnloggedHeaderContainer = styled.div`
  background: rgba(0, 0, 0, 0);
  padding: 20px;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  transition: background 0.3s ease-in-out;

  &.scrolled {
    background: rgba(0, 0, 0, 0.6);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }
`;

const UnloggedHeaderContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 180px;
    height: 40px;
  }

  @media (min-width: 800px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const UnloggedLogoContainer = styled(Link)``;

const UnloggedSideButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 16px;

  button,
  a {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px 32px;
    background: var(--default-blue);
    color: white;
    height: 56px;
    border-radius: 28px;
    font-weight: 600;
    font-size: 1.1em;
    text-decoration: none;

    width: 100%;c
    max-width: 300px;

    &.transparent {
      background: transparent;
    }
  }

  @media (min-width: 800px) {
    button,
    a {
      width: auto;
    }

    button {
      order: 2;
    }

    flex-direction: row;
  }
`;

export {
  UnloggedHeaderContainer,
  UnloggedLogoContainer,
  UnloggedSideButtons,
  UnloggedHeaderContent,
};
