import { yupResolver } from '@hookform/resolvers/yup';
import { format, isToday } from 'date-fns';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineLike, AiFillLike } from 'react-icons/ai';
import { BiChevronUp, BiChevronDown } from 'react-icons/bi';
import { BsReplyFill } from 'react-icons/bs';
import { CgBlock, CgUnblock } from 'react-icons/cg';
import { FaTrash } from 'react-icons/fa';
import { MdAddAPhoto } from 'react-icons/md';
import { useInView } from 'react-intersection-observer';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import * as yup from 'yup';

import AvatarFallback from '../../../../../assets/images/avatar-fallback.png';
import { Button } from '../../../../../components/Button';
import { Input } from '../../../../../components/Input';
import Spinner from '../../../../../components/Spinner';
import { useAuth } from '../../../../../hooks/auth';
import { FilesService } from '../../../../../services/fileService';
import {
  sendTopicAnswer,
  sendLike,
  deleteLike,
  deleteAnswer,
  getAnswer,
  blockUser,
  unblockUser,
} from '../../../../../services/forum';
import { theme } from '../../../../../styles/theme';

import ListCommentItem from './components/ListCommentItem';
import {
  Container,
  Answer,
  UserInfo,
  UserName,
  Published,
  ActionIcons,
  AnswerContainer,
  MoreAnswers,
  CommentsList,
  TrashIcon,
  GamificationBadge,
} from './styles';

const schema = yup.object().shape({
  text: yup.string().required('Escreva uma resposta para enviar!'),
});

const ListDiscussionItem = ({ data, onDeleteAnswer }) => {
  const { user } = useAuth();

  const [blockedUser, setBlockedUser] = useState(
    Boolean(data.forum_user_blocked),
  );
  const [like, setLike] = useState(
    data?.likes.find(liked => liked.user_id === user.user_id),
  );
  const [answerContainerVisible, setVisibleAnswerContainer] = useState(false);
  const [answersVisible, setAnswersVisible] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [media, setMedia] = useState('');

  const { ref, inView } = useInView({ delay: 500 });
  const inputFileRef = useRef();
  const filesService = new FilesService();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const toggleAnswerContainer = () => {
    setVisibleAnswerContainer(state => !state);
  };

  const handleToggleLike = async () => {
    if (like) {
      setLike(null);
      return deleteLike(like?.like_id);
    }

    return sendLike(data?.topic_answer_id).then(res => {
      setLike(res);
    });
  };

  const handleAnswer = async formData => {
    const body = { ...formData };

    if (media) {
      Object.assign(body, { media });
    }

    try {
      const answer = await sendTopicAnswer(
        { topic_answer_answered_id: data?.topic_answer_id },
        body,
      );
      setAnswers(state => [
        ...state,
        {
          ...answer,
          user,
        },
      ]);
      setVisibleAnswerContainer();
      toast.success('Comentário enviado');
      reset();
    } catch (err) {
      if (err?.response?.status === 403) {
        toast.warn(err?.response?.data?.message);
        return;
      }

      toast.error('Ocorreu um erro ao enviar sua resposta');
    }
  };

  const handleToggleCommentsVisible = () => {
    setAnswersVisible(state => !state);
  };

  const fetchDeleteAnswer = async () => {
    try {
      await deleteAnswer(data?.topic_answer_id);

      toast.success('Resposta excluída com sucesso');
      onDeleteAnswer(data?.topic_answer_id);
    } catch {
      toast.error('Ocorreu um erro ao tentar deletar essa resposta');
    }
  };

  const handleDeleteAnswer = async event => {
    event.stopPropagation();

    const result = await Swal.fire({
      icon: 'warning',
      iconColor: theme.colors.warning,
      title: 'Deseja confirmar a exclusão desta resposta?',
      text: 'Os comentários a essa resposta serão perdidos e essa ação não poderá ser desfeita',
      showCancelButton: true,
      confirmButtonColor: theme.colors.warning,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    });

    if (!result.isConfirmed) return;

    await fetchDeleteAnswer();
  };

  const onDeleteAnswerAnswered = commentId => {
    setAnswers(state =>
      state.filter(comment => comment.topic_answer_id !== commentId),
    );
  };

  const itemNotCreatedByLoggedUser = useMemo(
    () => data?.user_id !== user.user_id,
    [data?.user_id, user],
  );

  // TODO change to state?
  const hasComments = !!answers.length;

  const addNewAnswer = answer => {
    setAnswers(state => [
      ...state,
      {
        ...answer,
        user,
      },
    ]);
  };

  const handleUploadMedia = async event => {
    const file = event.currentTarget.files[0];

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await filesService.uploadFile(formData);
      setMedia(response?.data?.reference);
      toast.info('Imagem anexada a resposta');
    } catch {
      toast.warning('Ocorreu um erro ao anexar a imagem, selecione novamente');
    }
  };

  const fetchBlockUser = async () => {
    try {
      await blockUser(data.user_id);
      toast.info('Usuário bloqueado');
      setBlockedUser(true);
    } catch {
      toast.error('Ocorreu um erro ao tentar bloquear este usuário');
    }
  };

  const handleBlockUser = async event => {
    event.stopPropagation();

    const result = await Swal.fire({
      icon: 'question',
      title: 'Deseja confirmar o bloqueio deste usuário?',
      text: 'O mesmo não poderá mais responder nenhum tópico',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    });

    if (!result.isConfirmed) return;

    await fetchBlockUser();
  };

  const fetchUnblockUser = async () => {
    try {
      await unblockUser(data.user_id);
      toast.info('Usuário desbloqueado');
      setBlockedUser(false);
    } catch {
      toast.error('Ocorreu um erro ao tentar desbloquear este usuário');
    }
  };

  const handleUnblockUser = async event => {
    event.stopPropagation();

    const result = await Swal.fire({
      icon: 'question',
      title: 'Deseja confirmar o desbloqueio deste usuário?',
      text: 'O mesmo poderá voltar a responder tópicos',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    });

    if (!result.isConfirmed) return;

    await fetchUnblockUser();
  };

  const isModerator = user.roles.some(role => role === 'Moderator');

  useEffect(() => {
    if (!inView || hasComments) return;

    async function fetchAnswerAnswers() {
      const response = await getAnswer(data.topic_answer_id);
      setAnswers(response.answers);
    }

    fetchAnswerAnswers();
  }, [inView, hasComments, data.topic_answer_id]);

  useEffect(() => {
    return () => {
      setVisibleAnswerContainer(false);
      setAnswersVisible(false);
    };
  }, []);

  return (
    <Container ref={ref}>
      {data?.media && (
        <img src={data?.media} alt="Imagem enviada pelo usuário da resposta" />
      )}

      <Answer>{data.text}</Answer>

      <UserInfo>
        <img src={data.user?.images?.avatar || AvatarFallback} alt="Avatar" />
        <UserName>
          {itemNotCreatedByLoggedUser ? data.user.name : 'Eu'}
        </UserName>
        <GamificationBadge>{data.user?.level?.name}</GamificationBadge>
        <Published>
          {isToday(new Date(data.created_at))
            ? `às ${format(new Date(data.created_at), 'HH:mm')}`
            : `em ${format(new Date(data.created_at), 'dd/MM/yy')}`}
        </Published>
      </UserInfo>

      <ActionIcons>
        <span
          role="button"
          tabIndex="0"
          onKeyPress={toggleAnswerContainer}
          onClick={toggleAnswerContainer}
        >
          <span>
            <BsReplyFill class="invert-orientation" />
            Responder
          </span>
        </span>

        {itemNotCreatedByLoggedUser && (
          <span
            role="button"
            tabIndex="0"
            onKeyPress={handleToggleLike}
            onClick={handleToggleLike}
          >
            <span>
              {like ? <AiFillLike /> : <AiOutlineLike />}
              Curtir
            </span>
          </span>
        )}

        {(user.is_admin || isModerator) && (
          <span
            role="button"
            tabIndex="0"
            onKeyPress={blockedUser ? handleUnblockUser : handleBlockUser}
            onClick={blockedUser ? handleUnblockUser : handleBlockUser}
          >
            {blockedUser ? <CgUnblock /> : <CgBlock />}
            {blockedUser ? 'Desbloquear usuário' : 'Bloquear usuário'}
          </span>
        )}

        {(user.is_admin || isModerator || data.user_id === user.user_id) && (
          <TrashIcon
            role="button"
            tabIndex="0"
            onKeyPress={handleDeleteAnswer}
            onClick={handleDeleteAnswer}
          >
            <FaTrash />
            Excluir
          </TrashIcon>
        )}
      </ActionIcons>

      {answerContainerVisible && (
        <AnswerContainer onSubmit={handleSubmit(handleAnswer)}>
          <img src={user?.images?.avatar || AvatarFallback} alt="Avatar" />

          <MdAddAPhoto
            onClick={() => {
              inputFileRef?.current?.click();
            }}
          />

          <Input
            wrapperClassName="input"
            containerStyle={{
              padding: '12px 50px 12px 16px',
            }}
            placeholder="Escreva aqui seu comentário..."
            isInvalid={errors.text?.message}
            errorMessage={errors.text?.message}
            icon={MdAddAPhoto}
            isTextArea
            {...register('text')}
          />

          <input
            ref={inputFileRef}
            type="file"
            name="media"
            id="media"
            accept="image/png, image/jpeg, image/jpg"
            onChange={handleUploadMedia}
          />

          {isSubmitting && (
            <div className="spinner">
              <Spinner />
            </div>
          )}

          {!isSubmitting && (
            <Button
              title="ENVIAR"
              type="submit"
              containerStyle={{
                marginLeft: 19,
                maxWidth: 150,
                minWidth: 150,
              }}
            />
          )}
        </AnswerContainer>
      )}

      {answersVisible && (
        <CommentsList>
          {answers.map(answer => (
            <ListCommentItem
              key={answer.answer_id}
              data={answer}
              addNewAnswer={addNewAnswer}
              onDeleteAnswer={onDeleteAnswerAnswered}
              topic_answer_id={data?.topic_answer_id}
            />
          ))}
        </CommentsList>
      )}

      {hasComments && (
        <MoreAnswers>
          {answersVisible ? <BiChevronUp /> : <BiChevronDown />}

          <span
            onClick={handleToggleCommentsVisible}
            onKeyPress={handleToggleCommentsVisible}
            role="button"
            tabIndex="0"
          >
            Ver respostas
          </span>
        </MoreAnswers>
      )}
    </Container>
  );
};

export default ListDiscussionItem;
