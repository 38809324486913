import { yupResolver } from '@hookform/resolvers/yup';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { format } from 'date-fns';
import DOMPurify from 'dompurify';
import { EditorState } from 'draft-js';
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import backArrow from '../../../assets/icons/icon-back-breadcrumb-mobile.png';
import AvatarFallback from '../../../assets/images/avatar-fallback.png';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { Button } from '../../../components/Button';
import { Input } from '../../../components/Input';
import Spinner from '../../../components/Spinner';
import { useAuth } from '../../../hooks/auth';
import { sendTopic } from '../../../services/forum';

import WYSIWYGEditor from './components/WYSIWYGEditor';
import {
  Container,
  Header,
  BreadcrumbMobile,
  Overlay,
  Content,
  UserInfo,
  Form,
  FormHeader,
  UserAvatar,
  ErrorMessage,
  Preview,
  PreviewRichText,
  UserName,
  GamificationBadge,
  Published,
  PreviewContainer,
  TopicLabel,
} from './styles';

const schema = yup.object().shape({
  title: yup
    .string()
    .required('Este campo é obrigatório')
    .max(100, 'Título muito grande máximo 100 caracteres'),
  description: yup
    .string()
    .required('Este campo é obrigatório')
    .min(19, 'Digite pelo menos 10 caracteres'),
});

const CreateTopic = () => {
  const [loading, setLoading] = useState(false);
  const [textPreview, setTextPreview] = useState('');

  const { state } = useLocation();
  const history = useHistory();
  const { user } = useAuth();
  const handleSaveTopic = async data => {
    setLoading(true);
    try {
      const newTopic = await sendTopic({
        ...data,
        section_id: state.sectionId,
      });
      toast.success('Tópico criado com sucesso!');
      history.push(`/secao/topico/${newTopic.topic_id}`);
    } catch {
      toast.error('Ocorreu um erro ao tentar salvar, tente novamente.');
      setLoading(false);
    }
  };

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <Container>
      <Header>
        <Overlay>
          <Breadcrumbs
            className="hidden-mobile"
            style={{ paddingLeft: '30px', paddingBottom: '10px' }}
            links={[{ name: 'Fórum', url: '/forum' }, { name: 'Tópico' }]}
          />
          <BreadcrumbMobile className="hidden-desktop">
            <Link to="/home">
              <img src={backArrow} alt="Voltar" />
              <p>Início</p>
            </Link>
          </BreadcrumbMobile>
          <h1>
            Tópico
            <span>Tópico</span>
          </h1>
        </Overlay>
      </Header>

      <Content>
        <section>
          <h2>Criar Tópicos</h2>
        </section>

        <TopicLabel>
          <span>Tópico</span>
        </TopicLabel>

        <Form onSubmit={handleSubmit(handleSaveTopic)}>
          <FormHeader>
            <UserAvatar>
              <img src={user?.images?.avatar || AvatarFallback} alt="Avatar" />
            </UserAvatar>
            <fieldset>
              <Input
                containerStyle={{
                  borderRadius: 8,
                  maxWidth: 900,
                  margin: '0 auto',
                }}
                type="text"
                placeholder="Escreva aqui título do tópico"
                isInvalid={errors.title?.message}
                errorMessage={errors.title?.message}
                {...register('title')}
              />
            </fieldset>

            {loading && (
              <div className="spinner">
                <Spinner />
              </div>
            )}

            {!loading && (
              <button className="btn" type="submit">
                Enviar
              </button>
            )}
          </FormHeader>

          <Controller
            name="description"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <WYSIWYGEditor setTextPreview={setTextPreview} {...field} />
            )}
          />
          {errors.description?.message && (
            <ErrorMessage>{errors.description?.message}</ErrorMessage>
          )}
        </Form>

        <Preview>
          <h3>Resultado:</h3>

          <PreviewContainer>
            <section>
              <h2>{watch().title || 'Escreva aqui título do tópico'}</h2>
              <Button
                title="Responder Tópico"
                type="button"
                style={{
                  cursor: 'default',
                }}
                containerStyle={{
                  cursor: 'default',
                  width: 250,
                }}
              />
            </section>

            <UserInfo>
              <img src={user?.images?.avatar || AvatarFallback} alt="Avatar" />
              <UserName>{user?.name}</UserName>
              <GamificationBadge>{user?.level?.name}</GamificationBadge>
              <Published>
                Iniciado em {format(new Date(), 'dd/MM/yyyy')}
              </Published>
            </UserInfo>

            <PreviewRichText
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(textPreview || ''),
              }}
            />
          </PreviewContainer>
        </Preview>
      </Content>
    </Container>
  );
};

export default CreateTopic;
