import React from 'react';

import LogoLargeImg from '../../assets/logo-footer-png.png';

import { Container } from './styles';

const FooterLoginAndRegister = () => {
  return (
    <Container>
      <img
        src={LogoLargeImg}
        alt="Instituto dos Mistérios Ascensionais com Halu Gamashi"
      />
      <h6>
        &copy; Copyright {new Date().getFullYear()} - Todos direitos reservados.
      </h6>
    </Container>
  );
};

export default FooterLoginAndRegister;
