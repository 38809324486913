import styled, { css } from 'styled-components';

const MasterCardContainer = styled.div`
  width: 300px;
  min-height: 340px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 8px;
`;

const MasterCardTopContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 50%;
  color: white;
  font-weight: 500;
  font-size: 1.2em;

  ${props =>
    css`
      background: linear-gradient(
          90deg,
          rgb(0, 0, 0, 0.1) 0%,
          rgb(0, 0, 0, 0.2) 100%
        ),
        url(${props.background});
      background-size: cover;
      background-position: center;
    `}

  p {
    text-align: center;
  }
`;

const MasterCardBottomContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 50%;
  background: #fff;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  max-height: 296px;

  p {
    font-size: 0.95em;
    text-align: center;
    padding: 0 24px;
  }
`;

export { MasterCardContainer, MasterCardTopContent, MasterCardBottomContent };
