import styled from 'styled-components';

import { theme } from '../../styles/theme';

export const Overlay = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};

  &:before {
    content: '';
    display: block;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
  }
`;

export const Container = styled.div`
  padding: 32px;
  background: ${theme.colors.light};
  border-radius: 20px;
  max-width: 500px;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    margin-top: 26px;
    font-size: 2rem;
    color: ${theme.colors.primary};
    font-weight: bold;
  }

  h2 {
    color: ${theme.colors.textSecondary};
    font-size: 1.8rem;
    margin-bottom: 40px;
  }

  h3 {
    color: ${theme.colors.primary};
    font-weight: bold;
    font-size: 2.5rem;
    margin-bottom: 40px;
  }

  hr {
    margin-bottom: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    width: 100%;
  }
`;
