import React, { createContext, useCallback, useState, useContext } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as ContentService from '../services/Content';
import {
  finishContent,
  startContent,
  getById,
  getModuleByLessonId,
} from '../services/Content';

import { useAuth } from './auth';

const ExecutionCourseContext = createContext({});

const ExecutionCourseProvider = ({ children }) => {
  const { uuid, parentUuid } = useParams();
  const [data, setData] = useState({
    course: null,
    modules: [],
    lessons: [],
    courseStep: 'inProgress',
    currentLesson: {},
    nextLesson: {},
    isLoading: false,
  });

  const [updateModules, setUpdateModules] = useState(false);

  const { user } = useAuth();
  const location = useLocation();
  const history = useHistory();

  const resetExecutionData = useCallback(() => {
    setData({
      course: {},
      modules: [],
      lessons: [],
      courseStep: 'inProgress',
      hasReachedLevelPoints: false,
      isLoading: false,
    });
  }, []);

  const getModulesWithLock = useCallback(course => {
    if (!course) return {};

    if (!course?.info?.lockModules) return course.children;

    const modulesWithLock = course.children.map((module, index) => {
      const updatedModule = { ...module, isLocked: true };

      if (
        updatedModule?.content_user &&
        updatedModule?.content_user?.start_at
      ) {
        updatedModule.isLocked = false;
      }

      if (index === 0) {
        updatedModule.isLocked = false;
      }

      return updatedModule;
    });

    return modulesWithLock;
  }, []);

  const getSidebarModulesWithLock = useCallback(course => {
    if (!course) return {};

    if (!course?.info?.lockModules) return course.children;

    const modulesWithLock = course.children.map((module, index, modules) => {
      const updatedModule = { ...module, isLocked: true };

      if (
        updatedModule?.content_user &&
        updatedModule?.content_user?.start_at
      ) {
        updatedModule.isLocked = false;
      }

      const prevModule = modules[index - 1];

      if (prevModule && prevModule?.content_user && prevModule?.children) {
        const allLessonsWatched = prevModule?.children?.every(
          lesson =>
            lesson?.content_user?.start_at && lesson?.content_user?.finish_at,
        );

        updatedModule.isLocked = !allLessonsWatched;
      }

      if (index === 0) {
        updatedModule.isLocked = false;
      }

      return updatedModule;
    });

    return modulesWithLock;
  }, []);

  const storeCourseData = useCallback(
    async (course, redirectToLastWatchedLesson = null) => {
      try {
        const modulesUpdated = getModulesWithLock(course?.data);

        const lessons = modulesUpdated
          ?.map(module => module?.children?.map(lesson => lesson))
          .flat();

        const lastWatchedLesson = lessons.find(
          lesson =>
            (lesson?.content_user &&
              lesson?.content_user?.start_at &&
              !lesson?.content_user.finish_at) ||
            !lesson?.content_user,
        );
        const firstCourseLesson = await ContentService.getLessonById(
          course?.data.content_id,
          lessons[0]?.content_id,
        );

        const lessonUrlId = location.pathname.includes('exam')
          ? course?.data.children[0].children[0].content_id
          : location.pathname.substring(7, 43);

        const lessonInUrl = await ContentService.getLessonById(
          course?.data.content_id,
          lessonUrlId,
        );
        if (!course?.data?.content_user?.start_at) {
          ContentService.startContent(course?.data?.content_id);
        }

        setData(state => ({
          ...state,
          course: { ...course, children: modulesUpdated },
          modules: modulesUpdated,
          lessons,
          courseStep: location.pathname.includes('exam')
            ? 'exam'
            : 'inProgress',
          currentLesson:
            lessonInUrl.data ||
            (redirectToLastWatchedLesson
              ? lastWatchedLesson || firstCourseLesson?.data
              : lessonInUrl?.data || firstCourseLesson?.data),
          nextLesson: lastWatchedLesson || firstCourseLesson?.data,
        }));
      } catch (err) {
        if (err?.response?.status === 402) {
          toast.warn('Você não possui acesso a este conteúdo.');
          setTimeout(() => {
            history.goBack();
          }, 2000);
          return;
        }

        toast.warn(
          'Ocorreu um erro ao tentar acessar este conteúdo, tente mais tarde.',
        );
        history.goBack();
      }
    },
    [getModulesWithLock, history, location.pathname],
  );

  const navigateToNextContent = async (
    currentLessonId,
    callBack,
    targetLessonId,
    toastToLesson = true,
  ) => {
    // find current module by lesson id
    const module = data?.modules.find(el =>
      el.children.find(
        lesson => lesson.content_id === currentLessonId || targetLessonId,
      ),
    );

    if (!module) return;

    const moduleLessons = module?.children;
    const currentModuleIndex = data?.modules.findIndex(
      el => el.content_id === module.content_id,
    );

    let currentLessonIndex = moduleLessons.findIndex(
      lesson => lesson.content_id === currentLessonId || targetLessonId,
    );

    if (targetLessonId) {
      currentLessonIndex = currentLessonIndex - 1 ? currentLessonIndex : 0;
    }

    getById(module.content_id).then(res => {
      const allPrevModuleLessonsComplete = res.data.data.children?.every(
        moduleLesson =>
          moduleLesson?.content_user?.start_at &&
          moduleLesson?.content_user?.finish_at,
      );

      if (allPrevModuleLessonsComplete) finishContent(module.content_id);
    });

    if (currentLessonIndex < 0) return;

    let nextLesson = '';

    // if current lesson IS THE LAST in this module and the module is NOT the last in course
    if (
      currentLessonIndex + 1 === moduleLessons.length &&
      currentModuleIndex + 1 !== data?.modules.length
    ) {
      nextLesson = data?.modules[currentModuleIndex + 1].children[0].content_id;
    }

    // if current lesson IS THE LAST in this module and the module IS THE LAST in course
    if (
      currentLessonIndex + 1 === moduleLessons.length &&
      currentModuleIndex + 1 === data?.modules.length
    ) {
      const hasExam = !!(data?.course?.data?.exams?.length > 0);

      await finishContent(module.content_id);

      const response = await getById(data.course.data.content_id);

      const { data: courseUpdated } = response.data;

      const courseUpdatedLessons = courseUpdated.children
        ?.map(moduleUpdated => moduleUpdated?.children)
        ?.map(lessonUpdated => lessonUpdated)
        ?.flat();

      const allLessonsComplete = courseUpdatedLessons?.every(
        courseModule =>
          courseModule?.content_user?.start_at &&
          courseModule?.content_user?.finish_at,
      );

      const lastLessonDefined = courseUpdatedLessons.find(
        lesson => lesson?.info?.last_lesson,
      );

      if (!allLessonsComplete) {
        toast.warn('Termine todos as aulas antes de encerrar o curso.');
        setData(state => ({
          ...state,
          isLoading: false,
        }));
        return;
      }
      if (!lastLessonDefined) {
        toast.info('Curso ainda nao esta completo! Aguarde por novas aulas.');
        setData(state => ({
          ...state,
          isLoading: false,
        }));
        return;
      }

      if (!hasExam) await finishContent(data?.course?.data?.content_id);

      toast(
        hasExam ? 'Responda agora algumas perguntas.' : 'Curso finalizado!',
        {
          type: hasExam ? 'info' : 'success',
          pauseOnFocusLoss: false,
          pauseOnHover: false,
        },
      );

      const hasReachedLevelPoints = (() => {
        let coursePointsConceded =
          data?.course?.data?.info?.gamification_points || 0;

        data?.course.data.children.forEach(element => {
          coursePointsConceded += element?.info?.gamification_points || 0;

          element.children.forEach(el => {
            coursePointsConceded += el?.info?.gamification_points || 0;
          });
        });

        const remainingPoints = user?.level?.range.end - user?.points;
        return coursePointsConceded > remainingPoints;
      })();

      setTimeout(() => {
        if (hasExam) {
          setData(state => ({
            ...state,
            courseStep: 'exam',
            isLoading: true,
          }));
          history.push(
            `/curso/${data?.course?.data?.exams[0]?.exam_id}/${data?.course?.data?.exams[0]?.content_id}/exam`,
          );
        } else {
          setData(state => ({
            ...state,
            course: response.data, // courseUpdated
            courseStep: 'finished',
            hasReachedLevelPoints,
            isLoading: false,
          }));
          callBack(currentLessonId, data?.course.data.content_id);
        }
      }, 1000);

      return;
    }

    // if current lesson is NOT the last in this module and the module is NOT the last in course
    if (
      currentLessonIndex + 1 !== moduleLessons.length &&
      currentModuleIndex + 1 !== data?.modules.length
    ) {
      nextLesson = moduleLessons[currentLessonIndex + 1].content_id;
    }

    // if current lesson is NOT the last in this module and the module is the last in course
    if (
      currentLessonIndex + 1 !== moduleLessons.length &&
      currentModuleIndex + 1 === data?.modules.length
    ) {
      nextLesson = moduleLessons[currentLessonIndex + 1].content_id;
    }

    if (toastToLesson) {
      toast.info('Próxima aula em 3 segundos', {
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        toastId: 'nextLesson',
      });
    }

    setTimeout(() => {
      callBack(nextLesson, data?.course.data.content_id);
    }, 4000);
  };

  const changeCourseStep = useCallback(courseStep => {
    setData(state => ({
      ...state,
      courseStep,
    }));
  }, []);

  const changeCurrentLesson = useCallback(async lesson => {
    setData(state => ({
      ...state,
      currentLesson: lesson,
    }));

    if (!lesson?.content_id) return;

    const { data: currentModule } = await getModuleByLessonId(
      lesson.content_id,
    );

    if (currentModule && !currentModule?.content_user?.start_at) {
      startContent(currentModule.content_id);
    }
  }, []);

  const finishExamToNextContent = async () => {
    const ContentData = await getById(data.course.data.content_id);
    const allLessonsComplete = data?.lessons?.every(
      module =>
        module?.content_user?.start_at && module?.content_user?.finish_at,
    );

    const lastLessonDefined = data?.lessons?.find(
      lesson => lesson?.info?.last_lesson,
    );

    if (!allLessonsComplete) {
      toast.warn('Termine todos as aulas antes de encerrar o curso.');
      setData(state => ({
        ...state,
        isLoading: false,
      }));
      return;
    }
    if (!lastLessonDefined) {
      toast.info('Curso ainda nao esta completo! Aguarde por novas aulas.');
      setData(state => ({
        ...state,
        isLoading: false,
      }));
      return;
    }

    const response = await finishContent(data?.course?.data?.content_id);
    if (response?.data?.finish_at) {
      toast('Curso finalizado!', {
        type: 'success',
        pauseOnFocusLoss: false,
        pauseOnHover: false,
      });
    }

    const hasReachedLevelPoints = (() => {
      let coursePointsConceded =
        data?.course?.data?.info?.gamification_points || 0;

      data?.course.data.children.forEach(element => {
        coursePointsConceded += element?.info?.gamification_points || 0;

        element.children.forEach(el => {
          coursePointsConceded += el?.info?.gamification_points || 0;
        });
      });

      const remainingPoints = user?.level?.range.end - user?.points;
      return coursePointsConceded > remainingPoints;
    })();

    setData(state => ({
      ...state,
      course: ContentData.data,
      courseStep: 'finished',
      hasReachedLevelPoints,
      // isLoading: false,
    }));
  };

  return (
    <ExecutionCourseContext.Provider
      value={{
        data,
        setData,
        storeCourseData,
        navigateToNextContent,
        changeCourseStep,
        resetExecutionData,
        changeCurrentLesson,
        getModulesWithLock,
        getSidebarModulesWithLock,
        finishExamToNextContent,
        updateModules,
        setUpdateModules,
      }}
    >
      {children}
    </ExecutionCourseContext.Provider>
  );
};

function useExecutionCourse() {
  const context = useContext(ExecutionCourseContext);

  if (!context) {
    throw new Error('useExecutionCourse should be used within an AuthProvider');
  }

  return context;
}

export { ExecutionCourseProvider, useExecutionCourse };
