import { format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import backArrow from '../../assets/icons/icon-back-breadcrumb-mobile.png';
import Breadcrumbs from '../../components/Breadcrumbs';
import { Button } from '../../components/Button';
import Spinner from '../../components/Spinner';
import {
  getHistoryNotifications,
  getNotifications,
} from '../../services/notification';

import {
  Container,
  Content,
  Header,
  Overlay,
  BreadcrumbMobile,
  Item,
} from './styles';

const HistoryNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [notificationsForum, setNotificationsForum] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const userStorage = localStorage.getItem('@HaluGamashi:user');
  const parsedUserObject = JSON.parse(userStorage);

  const contentsPaidId = parsedUserObject.contents_paid.map(
    content => content.content_id,
  );

  const plansPaidId = parsedUserObject.plans_paid.map(plan => plan.plan_id);

  useEffect(() => {
    setLoading(true);
    async function getNotificationsAdmin() {
      const response = await getHistoryNotifications(
        plansPaidId,
        contentsPaidId,
      );

      setNotifications(response.data);
      setLoading(false);
    }
    getNotificationsAdmin();
  }, []);

  useEffect(() => {
    setLoading(true);
    async function getNotificationForumList() {
      const response = await getNotifications({ with_seen: true });
      setNotificationsForum(response.data);
      setLoading(false);
    }

    getNotificationForumList();
  }, []);

  function formatDate(dataString) {
    const data = new Date(dataString);
    const dataFormatada = format(data, "dd/MM/yyyy 'às' HH:mm");
    return dataFormatada;
  }

  function limitString(inputString, maxLength) {
    if (inputString?.length <= maxLength) {
      return inputString;
    }
    return `${inputString?.slice(0, maxLength - 3)}...`;
  }

  const combinedNotifications = [...notificationsForum, ...notifications];

  combinedNotifications.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  const handleClickItemForum = notification => {
    const { topic } = notification;

    history.push(`/secao/topico/${topic.topic_id}`, {
      notification,
    });
  };

  const handleClickItemNotification = notificationData => {
    history.push(`/notificacao/${notificationData.notification_id}`, {
      notificationData,
    });
  };
  return (
    <Container>
      <Header>
        <Overlay>
          <Breadcrumbs
            className="hidden-mobile"
            style={{ paddingLeft: '30px', paddingBottom: '10px' }}
            links={[{ name: 'Histórico de Notificações' }]}
          />
          <BreadcrumbMobile className="hidden-desktop">
            <Link to="/home">
              <img src={backArrow} alt="Voltar" />
              <p>Início</p>
            </Link>
          </BreadcrumbMobile>
          <h1>
            Histórico de Notificações
            <span>Histórico de Notificações</span>
          </h1>
        </Overlay>
      </Header>

      <Content>
        {loading ? (
          <div className="spinner">
            <Spinner color="#594AAD" />
          </div>
        ) : (
          combinedNotifications &&
          combinedNotifications.map(notification => (
            <Item key={notification.id}>
              <div>
                <h2>
                  {notification?.topic?.title
                    ? notification?.topic?.title
                    : notification.title}
                </h2>

                <span>{formatDate(notification.created_at)}</span>
              </div>
              <p>
                {notification.user
                  ? notification.user.name +
                    (notification.type === 'like'
                      ? ' curtiu seu comentário no tópico'
                      : ' respondeu seu comentário no tópico')
                  : limitString(notification.description, 200)}
              </p>
              <div className="wrapper-button">
                <Button
                  title="Ver Detalhes"
                  onClick={
                    notification?.topic?.title
                      ? () => handleClickItemForum(notification)
                      : () => handleClickItemNotification(notification)
                  }
                />
              </div>
            </Item>
          ))
        )}
      </Content>
    </Container>
  );
};

export default HistoryNotifications;
