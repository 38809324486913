import React from 'react';
import { Link } from 'react-router-dom';

import nextArrow from '../../assets/icons/next-arrow.svg';

import { Container, Content } from './styles';

const Breadcrumbs = ({ links, style, className }) => {
  return (
    <Container style={style} className={className}>
      <span>
        <Link to="/home">Início</Link>
      </span>
      {links.map(link => {
        return (
          <Content key={link.name}>
            <img className="breadcrumb-image" src={nextArrow} alt="" />
            <span>
              {link.url ? <Link to={link.url}>{link.name}</Link> : link.name}
            </span>
          </Content>
        );
      })}
    </Container>
  );
};
export default Breadcrumbs;
