import styled from 'styled-components';

import { theme } from '../../styles/theme';

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 46px 0;
  margin-top: auto;

  img {
    margin-bottom: 24px;
  }

  h6 {
    color: ${theme.colors.textLight};
    font-size: 1rem;
    font-weight: 500;
  }

  @media (max-width: 480px) {
    padding-bottom: 60px;

    img {
      max-width: 264px;
      width: 100%;
    }

    h6 {
      font-size: 0.7rem;
      padding-bottom: 20px;
      margin: 0;
    }
  }
`;
