import React, { useEffect, useState } from 'react';

import { ReactComponent as ChakraIcon } from '../../assets/icons/next-level-icon.svg';
import { useAuth } from '../../hooks/auth';
import { getLevels } from '../../services/gamification';
import { Button } from '../Button';

import { Overlay, Container } from './styles';

const ModalAscendLevel = () => {
  const [open, setOpen] = useState(true);
  const [nextLevelToAscend, setNextLevelToAscend] = useState('');

  const { user } = useAuth();

  useEffect(() => {
    const getNextLevel = async () => {
      const response = await getLevels();

      const levelsOrderedByStartPoints = response.data.sort((a, b) =>
        a.range.start < b.range.start ? -1 : 1,
      );

      const nextLevelIndex =
        levelsOrderedByStartPoints.findIndex(
          level => level.level_id === user.level_id,
        ) + 1;

      const nextLevel = levelsOrderedByStartPoints[nextLevelIndex];

      setNextLevelToAscend(nextLevel?.name);
    };

    getNextLevel();
  }, [user]);

  return (
    <Overlay open={open}>
      <Container>
        <ChakraIcon />
        <h1>Parabéns</h1>

        <h2>você ascendeu ao próximo nível!</h2>

        <h3>{nextLevelToAscend}</h3>

        <hr />

        <Button title="CONTINUAR" onClick={() => setOpen(false)} />
      </Container>
    </Overlay>
  );
};

export default ModalAscendLevel;
