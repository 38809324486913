import React from 'react';
import { useHistory } from 'react-router-dom';

import LogoImg from '../../../../assets/icons/logo-small.svg';

import { Container, Box } from './styles';

const HeaderConfirmRegister = () => {
  const history = useHistory();

  function handleGoToLogin() {
    history.push('/logar');
  }

  function handleGoToLadingPage() {
    history.push('/');
  }

  return (
    <Container>
      <Box>
        <img
          src={LogoImg}
          alt="IMA Logo"
          onClick={handleGoToLadingPage}
          onKeyPress={handleGoToLadingPage}
          aria-hidden="true"
        />
      </Box>

      <button onClick={handleGoToLogin}>Já sou assinante</button>
    </Container>
  );
};

export { HeaderConfirmRegister };
