import React, { useEffect } from 'react';
import styled from 'styled-components';

import { usePaymentModal } from '../../../../hooks/paymentModal';
import { theme } from '../../../../styles/theme';
import { Button } from '../../../Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 48px;
  text-align: center;

  h1 {
    font-size: 2rem;
    color: ${theme.colors.secondary};
    margin-bottom: 8px;
  }

  h2 {
    font-size: 1.7rem;
    color: ${theme.colors.textSecondary};
    margin-bottom: 55px;
  }
`;

const PaymentConfirmed = () => {
  const [counter, setCounter] = React.useState(10);

  const { resetModalData } = usePaymentModal();

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <Container>
      <h1>Parabéns, sua compra foi confirmada! </h1>

      {counter === 0 && (
        <h2>Aproveite todo conteúdo e continue na busca da ascensão!</h2>
      )}

      {counter !== 0 && (
        <h2>Aguarde! Estamos liberando seu acesso ao curso {counter}...</h2>
      )}

      {counter === 0 && (
        <Button
          title="OK"
          onClick={resetModalData}
          containerStyle={{
            maxWidth: '50%',
            marginTop: 32,
          }}
        />
      )}
    </Container>
  );
};

export default PaymentConfirmed;
