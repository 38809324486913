import { yupResolver } from '@hookform/resolvers/yup';
import React, { useReducer } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { RegisterLayoutRoutes } from '../../routes/RegisterLayoutRoutes';
import { firstAccessImportedUser, resendCode } from '../../services/Auth';
import {
  regexPasswordPhrase,
  regexPasswordValidation,
} from '../../utils/regexPasswordValidation';

import { HeaderConfirmRegister } from './components/HeaderConfirmRegister';
import { SecurityCode } from './components/SecurityCode';
import ThanksRegister from './components/ThanksRegister';
import { WelcomeToIMA } from './components/WelcomeToIMA';
import { mappedErrors } from './utils/errorMessages';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Digite um email válido')
    .required('Email é obrigatório'),
  password: yup
    .string()
    .required('Senha é obrigatória')
    .matches(regexPasswordValidation, regexPasswordPhrase),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Senhas não conferem'),
  terms: yup
    .boolean()
    .required('Leia e aceite para continuar')
    .oneOf([true], 'Leia e aceite para continuar'),
});

const STEPS = {
  INSERT_DATA: 'INSERT_DATA',
  SECURITY_CODE: 'SECURITY_CODE',
  SEND_DATA: 'SEND_DATA',
  DATA_SEND_SUCCESS: 'DATA_SEND_SUCCESS',
  DATA_SEND_FAIL: 'DATA_SEND_FAIL',
  EMAIL_DELAY: 'EMAIL_DELAY',
  ADD_CODE: 'ADD_CODE',
};

const reducer = (state, action) => {
  switch (action.type) {
    case STEPS.INSERT_DATA:
      return {
        data: action.data,
        step: 'SECURITY_CODE',
        loading: false,
      };

    case STEPS.SEND_DATA:
      return {
        ...state,
        loading: true,
      };

    case STEPS.DATA_SEND_SUCCESS:
      return {
        data: null,
        step: 'DATA_SEND_SUCCESS',
        loading: false,
      };

    case STEPS.DATA_SEND_FAIL:
      return {
        ...state,
        loading: false,
      };

    case STEPS.EMAIL_DELAY:
      return {
        ...state,
        emailDelay: action.emailDelay,
      };

    case STEPS.ADD_CODE:
      return {
        ...state,
        data: { ...state.data, code: action.code },
      };

    default:
      throw Error(`No switch case mapped action type for, ${action.type}`);
  }
};

const ConfirmRegister = () => {
  const [state, dispatch] = useReducer(reducer, {
    step: 'INSERT_DATA',
    data: null,
    loading: false,
    emailDelay: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const handleInsertData = data => {
    dispatch({ type: 'INSERT_DATA', data });
  };

  const handleCheckCode = async () => {
    dispatch({ type: 'SEND_DATA' });

    try {
      const data = {
        email: state.data.email,
        new_password: state.data.password,
        new_password_confirm: state.data.passwordConfirmation,
        code: state.data.code,
      };

      await firstAccessImportedUser(data);
      dispatch({ type: 'DATA_SEND_SUCCESS' });
    } catch (err) {
      const parsedErrorMessage = mappedErrors.find(
        option => option.error === err.response.data.message,
      );

      if (parsedErrorMessage) {
        toast.error(parsedErrorMessage.message);
        return;
      }

      toast.error('Ocorreu um erro, por favor tente mais tarde.');
    } finally {
      dispatch({ type: 'DATA_SEND_FAIL' });
    }
  };

  const handleResendCode = async () => {
    dispatch({ type: 'EMAIL_DELAY', emailDelay: true });

    try {
      await resendCode(state.data.email);
    } catch {
      toast.error('Ocorreu um erro, por favor tente mais tarde.');
    }
  };

  return (
    <RegisterLayoutRoutes>
      <HeaderConfirmRegister />

      {state.step === 'INSERT_DATA' && (
        <WelcomeToIMA
          handleSubmit={handleSubmit(handleInsertData)}
          errors={errors}
          register={register}
        />
      )}

      {state.step === 'SECURITY_CODE' && (
        <SecurityCode
          loading={state.loading}
          email={state.data.email}
          setCode={code => dispatch({ type: 'ADD_CODE', code })}
          sendRequestToConfirm={handleCheckCode}
          resendCode={handleResendCode}
          emailDelay={state.emailDelay}
          setEmailDelay={() =>
            dispatch({ type: 'EMAIL_DELAY', emailDelay: false })
          }
        />
      )}

      {state.step === 'DATA_SEND_SUCCESS' && <ThanksRegister />}
    </RegisterLayoutRoutes>
  );
};

export default ConfirmRegister;
