import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.8) 0 0 10px;
  -moz-box-shadow: rgba(0, 0, 0, 0.8) 0 0 10px;
  background-color: white;

  position: relative;

  @media (max-width: 870px) {
    flex-direction: column;
  }
`;

export const ImageCourseContainer = styled.div`
  > img {
    border-radius: 25px 0 0 25px;
    height: 100%;
    width: 100%;

    object-fit: cover;

    @media (max-width: 870px) {
      border-radius: 25px 25px 0 0;
    }
  }

  @media (min-width: 870px) {
    max-width: 50%;

    > img {
      border-radius: 25px 0 0 25px;
    }
  }
`;

export const InfoCourseContainer = styled.div`
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  padding: 24px 16px;

  h2 {
    font-weight: bold;
    font-size: 1.2rem;
    letter-spacing: 0.05em;
    color: ${({ theme }) => theme.colors.text};
    margin-top: 8px;
  }

  > span {
    align-items: center;
    font-size: 0.9rem;
    padding: 12px 0;
  }

  img {
    padding-right: 6px;
  }

  @media (min-width: 870px) {
    min-width: 50%;
  }
`;

export const AuthorAndRateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  p {
    font-weight: 600;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.textSecondary};
    font-size: 0.8rem;

    h3 {
      display: inline;
      color: ${({ theme }) => theme.colors.primary};
      font-size: 1rem;
    }
  }
`;

export const RateContainer = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 600px) {
    margin-right: auto;
    margin-left: 28px;
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 25px;
    width: 30px;
  }

  span {
    display: flex;
  }

  p {
    color: #25282b;
    font-weight: 500;
    font-size: 16px;
    padding-left: 4px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;

  width: 100%;
  margin-top: 16px;

  @media (min-width: 600px) {
    justify-content: flex-start;

    ${Info}:nth-child(2) {
      margin-left: 32px;
    }
  }
`;

export const DateContainer = styled.div`
  margin-top: 10px;
  > span {
    font-weight: bold;
    padding-right: 5px;
  }
`;

export const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: #594aad;
  border-radius: 20px;

  height: 24px;

  font-weight: 600;
  font-size: 0.8rem;
  color: #ffffff;

  margin-right: 16px;
  padding: 15px;

  @media (min-width: 1024px) {
    font-weight: 600;
    width: 104px;
  }
`;

export const BadgeDiscount = styled.div`
  background: #4cad4a;
  border-radius: 20px;
  height: 24px;
  font-weight: 600;
  font-size: 10px;
  color: #ffffff;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 16px;
  padding: 0 20px;

  @media (min-width: 1024px) {
    max-width: 100%;
    max-height: 100%;
    font-weight: 600;
    font-size: 10px;
    padding: 15px;
  }
`;

export const CourseRating = styled.span`
  color: ${({ theme }) => theme.colors.warning};
`;

export const CourseTotalRatings = styled.span`
  padding-top: 0 !important;
  color: ${({ theme }) => theme.colors.textSecondary};
`;

export const FireImage = styled.span``;

export const BadgesBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  > div {
    margin: 4px 0;
  }

  @media (min-width: 600px) {
    flex-direction: row;
    align-items: center;

    > div {
      margin: 0 4px;
    }
  }
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BadgePaymentStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: #f7b228;
  border-radius: 20px;

  height: 24px;

  font-weight: 600;
  font-size: 0.7rem;
  color: #ffffff;

  margin-right: 16px;
  padding: 12px;

  @media (min-width: 400px) {
    font-size: 0.8rem;
  }

  @media (min-width: 600px) {
    font-size: 0.8rem;
    padding: 15px;
  }
`;
