import { format, isFuture, getDay, getHours } from 'date-fns';

import { getMostRecentPlan } from './getMostRecentPlan';

const numberToDays = {
  0: 'Domingo',
  1: 'Segunda',
  2: 'Terça',
  3: 'Quarta',
  4: 'Quinta',
  5: 'Sexta',
  6: 'Sábado',
};

function adapterContentToCardCourse(content, user) {
  const { finalPrice, originalPrice, discount } = calcPrice(
    content?.info,
    user,
  );

  return {
    id: content?.content_id,
    name: content?.name,
    courseLiked: !!content?.content_user?.like,
    courseImg: content?.images?.thumbnail_url,
    bannerImg: content?.images?.banner_url,
    rating: {
      totalRatings: content?.content_review?.total_reviews || 0,
      value: content?.content_review?.average.toFixed(1) || 0,
      initialRating: content?.content_user?.review_stars || 0,
    },
    title: content?.name,
    courseProgress:
      content?.content_user?.start_at && !content?.content_user?.finish_at,
    courseFinished:
      content?.content_user?.finish_at ||
      content?.content_user?.progress === 100,
    progress: content?.content_user?.progress,
    // exists only one position in this array
    author: content?.authors ? content?.authors?.[0]?.name : '',
    date: isFuture(new Date(content?.info?.publish_date))
      ? `${
          numberToDays[getDay(new Date(content?.info?.publish_date))]
        }, ${format(
          new Date(content?.info?.publish_date),
          'dd/MM',
        )} ás ${getHours(new Date(content?.info?.publish_date))}h`
      : 'Já disponível',
    duration: content?.duration,
    buttonText: null,
    showRater: null,
    // exists only one position in this array
    tag: content?.tags ? content?.tags?.[0]?.title : '',
    price: finalPrice,
    discount,
    originalPrice,
    type: content?.type,
    buttonIcon: undefined,
    isFree: content?.info?.is_free,
    onlySubscribers: content?.info?.only_subscribers,
    redirectToSubscribePage: false,
    isSell: content?.info?.to_sell,
  };
}

const calcPrice = (info, user) => {
  const activePlan = getMostRecentPlan(user?.plans_paid);

  if (activePlan && activePlan?.plan?.discount) {
    const parsedDiscount = Number(activePlan.plan.discount);

    if (parsedDiscount > 0 && info?.price) {
      const finalPrice = info.price - info?.price * (parsedDiscount / 100);

      return {
        finalPrice: Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(finalPrice),

        originalPrice: Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(info?.price),
        discount: info?.price * (parsedDiscount / 100),
      };
    }
  }

  if (!info?.price) return { finalPrice: null, originalPrice: null };

  return {
    finalPrice: Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(info?.price),
    originalPrice: null,
  };
};

export default adapterContentToCardCourse;
