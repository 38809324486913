import { pdf } from '@react-pdf/renderer';
import axios from 'axios';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { AiOutlineFile } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import iconCertificates from '../../assets/icons/icon-certificates.png';
import iconDownloadCertificates from '../../assets/icons/icon-download-certificates.svg';
import leftLiveMobile from '../../assets/icons/left-live-mobile.svg';
import rightLiveMobile from '../../assets/icons/rigth-live-mobile.svg';
import { controlsToEnable } from '../../configs/SvPlayer';
import { useAuth } from '../../hooks/auth';
import { useExecutionCourse } from '../../hooks/executionCourse';
import * as CertificateService from '../../services/Certificates';
import {
  finishContent as finishContentService,
  updateContentProgress,
  startContent as startContentService,
} from '../../services/Content';
import checkUserIsPaid from '../../utils/checkUserIsFree';
import BoxLiveDetail from '../BoxLiveDetail';
import { Button } from '../Button';
import { Certificates } from '../CardCourseFinalized/styles';
import Certificate from '../Certificate';
import ResponsivePlayer from '../ResponsivePlayer';

import {
  Container,
  Content,
  ContentBottom,
  ExtraMaterial,
  ModulesCourseMobile,
  InfoLeftLiveMobile,
  InfoRightLiveMobile,
  VideoComponent,
  AvailableSoon,
  TextInfo,
  OverviewAndSupportMaterial,
} from './styles';

const FIRST_LESSON = 0;

const CourseContent = ({ data, course }) => {
  const { parentUuid } = useParams();
  const [tab1, setTab1] = useState(true);
  const [tab2, setTab2] = useState(false);
  const [tab3, setTab3] = useState(false);
  const [alreadySendFinishRequest, setAlreadySendFinishRequest] =
    useState(false);
  const [progress, setProgress] = useState(0);
  const [module, setModule] = useState({});
  const [lastModuleAndLastLesson, setIsLastModuleAndLesson] = useState(false);

  const [lastWatchedTime, setLastWatchedTime] = useState(0);
  const history = useHistory();
  const [allowedAccess, setAllowedAccess] = useState(false);

  const cancelToken = axios.CancelToken;
  const cancelTokenFinishContent = cancelToken.source();

  function showTab1() {
    setTab1(true);
    setTab2(false);
    setTab3(false);
  }

  function showTab2() {
    setTab1(false);
    setTab3(false);
    setTab2(true);
  }
  function showTab3() {
    setTab1(false);
    setTab2(false);
    setTab3(true);
  }

  function handleDownloadMaterial(url) {
    window.open(url, '_blank').focus();
  }

  function handleFindCertificate(doDownload = false) {
    CertificateService.findCertificate(course?.data?.content_id)
      .then(response => {
        generateCertificate(response.data, doDownload);
      })
      .catch(() => {
        toast.error(
          'Ocorreu um erro ao gerar seu certificado, tente mais tarde',
        );
      });
  }

  async function generateCertificate(certificate, download) {
    if (download) {
      const cert = await pdf(<Certificate data={certificate} />).toBlob();
      if (cert) {
        const url = window.URL.createObjectURL(cert);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'certificado.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }
    } else {
      history.push(`/certificado/${certificate.certificate_id}`);
    }
  }

  const resumeTime = useMemo(() => {
    if (!data) {
      return false;
    }

    if (data?.content_user) {
      setLastWatchedTime(Number(data?.content_user.content_view));
      return Number(data?.content_user.content_view);
    }

    return false;
  }, [data]);

  function hasReachedCompletionTime(duration, currentTime) {
    const totalDurationInSecs = duration / 1000;
    const completionRate = 0.9;

    const completionTime = totalDurationInSecs * completionRate;
    return currentTime >= completionTime && !alreadySendFinishRequest;
  }

  const finishContent = useCallback(async () => {
    await finishContentService(data?.content_id, cancelTokenFinishContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const {
    navigateToNextContent,
    start,
    data: courseData,
    setUpdateModules,
  } = useExecutionCourse();

  const lastLessonDefined = courseData?.lessons?.find(
    lesson => lesson?.info?.last_lesson,
  );

  function goToNextContent(nextLesson, contentId) {
    window.navigator.vibrate(500);
    history.push(`/curso/${nextLesson}/${contentId}`);
  }

  function updateWatchTime(player) {
    const { event, eventParam } = player;

    if (event === 'onProgress') {
      setProgress(eventParam);
    }
    return null;
  }

  async function startContent() {
    await startContentService(data?.content_id);
  }

  async function getEventListeners(player) {
    if (data && data?.content_id) {
      switch (player.event) {
        case 'onProgress':
          if (hasReachedCompletionTime(player.duration, player.eventParam)) {
            setAlreadySendFinishRequest(true);
          }

          updateWatchTime(player);
          break;

        case 'onStart':
          startContent();
          break;

        case 'onFinish':
          await finishContent();
          navigateToNextContent(data?.content_id, goToNextContent);
          break;

        default:
          break;
      }
    }
  }

  const statusWaitingRecordedContent = ['STOPPED', 'STOPPING', 'FINISHED'];

  async function handleNavigateToLiveChat() {
    startContentService(data?.content_id);
    finishContent();
    history.push({
      pathname: `/live-chat/${data?.live.live_id}`,
      state: {
        prevPath: `${history.location.pathname}`,
      },
    });
  }

  function getIndexCurrentModule() {
    return course?.data?.children?.findIndex(
      item => item.content_id === module?.content_id,
    );
  }

  function handleGoToPreviousContent() {
    const currentLessonIndex = module?.children?.findIndex(
      item => item.content_id === data?.content_id,
    );

    if (currentLessonIndex > 0) {
      const previousLesson = module?.children[currentLessonIndex - 1];

      if (!previousLesson) {
        toast.warn('Não foi possível encontrar o conteúdo anterior.');
        return;
      }

      history.push(
        `/curso/${previousLesson?.content_id}/${course?.data?.content_id}`,
      );
      return;
    }
    const indexCurrentModule = getIndexCurrentModule();

    if (indexCurrentModule === 0) {
      toast.warn('Não há mais conteúdos anteriores a este!');
      return;
    }

    const previousModule = course.children[indexCurrentModule - 1];

    const lastModuleLesson =
      previousModule?.children[previousModule?.children?.length - 1];

    if (!lastModuleLesson) {
      toast.warn('Não foi possível encontrar o conteúdo anterior.');
      return;
    }

    history.push(
      `/curso/${lastModuleLesson?.content_id}/${course?.data?.content_id}`,
    );
  }

  function handleGoToNextContent() {
    const currentLessonIndex = module?.children?.findIndex(
      item => item.content_id === data?.content_id,
    );

    const moduleLength = module?.children.length - 1;
    if (currentLessonIndex < moduleLength) {
      const nextLesson = module?.children[currentLessonIndex + 1];

      if (!nextLesson) {
        toast.warn('Não foi possível encontrar o conteúdo posterior.');
        return;
      }

      history.push(
        `/curso/${nextLesson?.content_id}/${course?.data?.content_id}`,
      );
      return;
    }

    const indexCurrentModule = getIndexCurrentModule();
    const nextModule = course.children[indexCurrentModule + 1];

    if (!nextModule) {
      toast.warn('Não há mais conteúdos posteriores a este!');
      return;
    }

    const nextModuleLesson = nextModule?.children[FIRST_LESSON];

    if (!nextModuleLesson) {
      toast.warn('Não foi possível encontrar o conteúdo posterior.');
      return;
    }

    history.push(
      `/curso/${nextModuleLesson?.content_id}/${course?.data?.content_id}`,
    );
  }

  const getCurrentModule = useCallback(() => {
    if (!course) return;
    const moduleFound = course?.data?.children?.find(courseModule =>
      courseModule?.children?.find(
        lesson => lesson?.content_id === data?.content_id,
      ),
    );

    const moduleIndexFound = course?.data?.children?.findIndex(courseModule =>
      courseModule?.children?.find(
        lesson => lesson?.content_id === data?.content_id,
      ),
    );

    const lessonIndexFound = moduleFound?.children?.findIndex(
      lesson => lesson?.content_id === data?.content_id,
    );

    setIsLastModuleAndLesson(
      moduleIndexFound + 1 === course?.data?.children?.length &&
        lessonIndexFound + 1 === moduleFound?.children?.length,
    );

    if (moduleFound) setModule(moduleFound);
  }, [course, data?.content_id]);

  function handleFinishCourse() {
    navigateToNextContent(data?.content_id, goToNextContent);
  }

  useEffect(() => {
    async function updateProgress(time) {
      await updateContentProgress(data?.content_id, time);
    }

    if (progress >= lastWatchedTime + 60) {
      setLastWatchedTime(progress);
      updateProgress(progress);
      return;
    }

    if (progress < lastWatchedTime) {
      if (progress > 1) {
        setLastWatchedTime(progress);
        updateProgress(progress);
        return;
      }
      setLastWatchedTime(0);
      updateProgress(null);
    }
  }, [progress, lastWatchedTime, data?.content_id, data?.finish_at]);

  const { user } = useAuth();

  const verifyAccess = useCallback(() => {
    const userIsPaid = checkUserIsPaid(user?.plans_paid);
    const userCoursesIdsPaids = user.contents_paid.map(item => {
      return item.content_id;
    });
    const userHasPayedThisContent = userCoursesIdsPaids.includes(
      course.data.content_id,
    );

    if (
      course.data.info.only_subscribers &&
      !userIsPaid &&
      !userHasPayedThisContent
    ) {
      toast.warn('Você não possui acesso a este conteúdo.');
      setTimeout(() => {
        history.push(`/curso-detalhe/${parentUuid}`);
      }, 2000);
      setAllowedAccess(false);
    } else {
      setAllowedAccess(true);
    }
  }, [
    course.data.content_id,
    course.data.info.only_subscribers,
    history,
    parentUuid,
    user.contents_paid,
    user?.plans_paid,
  ]);

  useEffect(() => {
    const handleStartAndFinishContent = async () => {
      if (data?.reference === null) {
        await startContent();
        await finishContent();
        await updateContentProgress(data?.content_id, 1);
        setUpdateModules(true);
      }
    };

    handleStartAndFinishContent();

    return () => {
      setUpdateModules(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCurrentModule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data?.courseStep]);

  useEffect(() => {
    verifyAccess();
  }, [verifyAccess]);

  if (allowedAccess)
    return (
      <Container>
        <Content>
          <h1 className="hidden-mobile">{data?.name}</h1>
          <VideoComponent>
            {data?.reference && (
              <ResponsivePlayer
                contentUrl={data?.reference}
                controlsToEnable={controlsToEnable}
                id="playerCourseFlow"
                resume={resumeTime}
                getEventListeners={getEventListeners}
              />
            )}
          </VideoComponent>

          {data?.live &&
          !data?.reference &&
          !statusWaitingRecordedContent.includes(data?.live?.status) ? (
            <BoxLiveDetail onClick={handleNavigateToLiveChat} item={data} />
          ) : null}

          {!data?.reference &&
          statusWaitingRecordedContent.includes(data?.live?.status) ? (
            <AvailableSoon>
              <h1>Disponível em breve</h1>
            </AvailableSoon>
          ) : null}

          {data?.live?.status === 'ACTIVE' ? (
            <Button
              className=""
              containerStyle={{ maxWidth: '200px', marginBottom: 48 }}
              buttonStyle={{ textTransform: 'uppercase' }}
              title="assistir a live agora"
              onClick={handleNavigateToLiveChat}
            />
          ) : null}

          <ModulesCourseMobile className="hidden-desktop">
            <h4>{module.name}</h4>
            <h1>{data?.name}</h1>

            <span>
              <InfoLeftLiveMobile
                aria-label="previous"
                onClick={handleGoToPreviousContent}
              >
                <img src={leftLiveMobile} alt="Ícone de conteúdo anterior" />
                <p>Anterior</p>
              </InfoLeftLiveMobile>

              <InfoRightLiveMobile
                aria-label="next"
                onClick={handleGoToNextContent}
              >
                <p>Próximo</p>
                <img src={rightLiveMobile} alt="Ícone de próximo conteúdo" />
              </InfoRightLiveMobile>
            </span>
          </ModulesCourseMobile>

          <ContentBottom>
            <OverviewAndSupportMaterial>
              <li
                className={tab1 ? 'active' : ''}
                onClick={showTab1}
                onKeyPress={showTab1}
                aria-hidden="true"
              >
                Visão Geral
              </li>

              <li
                className={tab2 ? 'active' : ''}
                onClick={showTab2}
                onKeyPress={showTab2}
                aria-hidden="true"
              >
                Material de Apoio
              </li>
              {course?.data?.content_user?.finish_at && (
                <li
                  className={tab3 ? 'active' : ''}
                  onClick={showTab3}
                  onKeyPress={showTab3}
                  aria-hidden="true"
                >
                  Certificado
                </li>
              )}
            </OverviewAndSupportMaterial>
            {tab1 && (
              <TextInfo
                dangerouslySetInnerHTML={{ __html: data?.description }}
              />
            )}
            {tab2 && data?.extra_materials ? (
              <ul>
                {data?.extra_materials.map(item => (
                  <ExtraMaterial
                    onClick={() => handleDownloadMaterial(item.reference)}
                  >
                    <AiOutlineFile />
                    <span>{item.title.slice(21)}</span>
                    <FiDownload />
                  </ExtraMaterial>
                ))}
              </ul>
            ) : null}
            {tab3 && course?.data?.content_user?.finish_at && (
              <ul className="box-certificates">
                <Certificates>
                  <Button
                    className="hidden-mobile"
                    title="Visualizar certificado "
                    icon={data?.buttonIcon}
                    containerStyle={{
                      marginBottom: '14px',
                      background: '#2F189C',
                    }}
                    onClick={() => handleFindCertificate()}
                    img={iconCertificates}
                  />
                  <Button
                    className="hidden-mobile"
                    title="Baixar certificado"
                    icon={data?.buttonIcon}
                    containerStyle={{
                      marginBottom: '14px',
                      background: '#2F189C',
                    }}
                    onClick={() => handleFindCertificate(true)}
                    img={iconDownloadCertificates}
                  />

                  <Button
                    className="hidden-desktop"
                    title="Visualizar certificado "
                    icon={data?.buttonIcon}
                    containerStyle={{
                      marginBottom: '14px',
                      background: '#2F189C',
                    }}
                    onClick={() => handleFindCertificate()}
                    img={iconCertificates}
                  />
                  <Button
                    className="hidden-desktop"
                    title="Baixar certificado"
                    icon={data?.buttonIcon}
                    containerStyle={{
                      marginBottom: '14px',
                      background: '#2F189C',
                      alignSelf: 'center',
                      maxWidth: '100%',
                      minWidth: 'unset',
                    }}
                    onClick={() => handleFindCertificate(true)}
                    img={iconDownloadCertificates}
                  />
                </Certificates>
              </ul>
            )}
          </ContentBottom>

          {!data?.reference && lastModuleAndLastLesson && lastLessonDefined && (
            <Button
              containerStyle={{ maxWidth: '200px', marginBottom: 48 }}
              buttonStyle={{ textTransform: 'uppercase' }}
              title="Encerrar curso"
              onClick={handleFinishCourse}
            />
          )}
        </Content>
      </Container>
    );
  return null;
};

export default CourseContent;
