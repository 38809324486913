import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import CircleImg from '../../assets/icons/circle.svg';
import backArrow from '../../assets/icons/icon-back.svg';
import bannerCourseDetailId from '../../assets/images/banner-course-detail-id.png';
import AccordionHalu from '../../components/AccordionHalu';
import Breadcrumbs from '../../components/Breadcrumbs';
import CardCourse from '../../components/CardCourse';
import CardCourseInfo from '../../components/CardCourseInfo';
import { SpinnerWrapper } from '../../components/CardCourseInfo/styles';
import CardCourseSkeleton from '../../components/CardCourseSkeleton';
import Divider from '../../components/Divider';
import HorizontalScroll from '../../components/HorizontalScroll';
import ResponsivePlayer from '../../components/ResponsivePlayer';
import Spinner from '../../components/Spinner';
import { controlsToEnable } from '../../configs/SvPlayer';
import useCardCourse from '../../hooks/cardCourse';
import { usePaymentModal } from '../../hooks/paymentModal';
import * as ContentService from '../../services/Content';
import { ContentsService } from '../../services/contentsService';
import * as ExamService from '../../services/examService';
import { UserService } from '../../services/userService';
import { goToTop } from '../../utils/helpers';

import {
  Container,
  AboutCourse,
  AboutCourseInfo,
  VideoComponent,
  Background,
  ButtonBack,
} from './styles';

const CoursesDetail = () => {
  const [loading, setLoading] = useState(true);
  const [examUser, setExamUser] = useState();
  const [courseInfo, setCourseInfo] = useState({});
  const [coursePrerequisites, setCoursePrerequisites] = useState(null);
  const { uuid } = useParams();
  const { paymentSuccess } = usePaymentModal();
  const history = useHistory();
  const cardCourseHook = useCardCourse();
  const [recommendedContents, setRecommendedContents] = useState([]);
  const [userCoursesIds, setUserCoursesIds] = useState([]);
  const courseProgress =
    courseInfo?.content_user?.start_at && !courseInfo?.content_user?.finish_at;

  goToTop();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const userService = new UserService();

      await userService
        .getContentsPurchaseIds()
        .then(res => setUserCoursesIds(res.data));

      ContentService.getByIdDetails(uuid)
        .then(async response => {
          const contentWithChildrenInOrder = response.data.data;

          contentWithChildrenInOrder.children =
            contentWithChildrenInOrder.children.sort((firstEl, secondEl) => {
              if (firstEl.position > secondEl.position) {
                return 1;
              }

              return -1;
            });
          if (contentWithChildrenInOrder?.exams) {
            const user = JSON.parse(localStorage.getItem('@HaluGamashi:user'));
            const responseExamUser = await ExamService?.getExamUser(
              contentWithChildrenInOrder?.exams[0]?.exam_id,
              user?.user_id,
            );
            setExamUser(responseExamUser);
          }
          setCourseInfo(contentWithChildrenInOrder);
          setCoursePrerequisites(response.data.prerequisites);
          setLoading(false);
        })
        .catch(() => {
          toast.error(
            'Ocorreu um erro ao buscar os conteúdos, recarregue a página.',
          );
        });
    })();
  }, [paymentSuccess, uuid]);

  useEffect(() => {
    (async () => {
      try {
        const contentsService = new ContentsService();
        const response = await contentsService.searchRecommendedContents();
        setRecommendedContents(
          response.data.map(item =>
            cardCourseHook.getCardCourseData(item, userCoursesIds),
          ),
        );
      } catch {
        toast.error(
          'Ocorreu um erro ao buscar os conteúdos recomendados, recarregue a página.',
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleGoToLogin() {
    history.push('/cursos');
  }

  return (
    <Container>
      <Background>
        <img
          src={courseInfo.images?.banner_url || bannerCourseDetailId}
          alt=""
        />
        <Breadcrumbs
          className="hidden-mobile"
          links={[
            { name: 'Cursos', url: '/cursos' },
            { name: courseInfo.name, url: null },
          ]}
        />
        <ButtonBack onClick={handleGoToLogin} className="hidden-desktop">
          <img src={backArrow} alt="Voltar" />
          Voltar
        </ButtonBack>
        <CardCourseInfo
          data={courseInfo}
          reloadData={paymentSuccess}
          coursePrerequisites={coursePrerequisites}
        />
      </Background>

      <AboutCourse>
        {coursePrerequisites && !coursePrerequisites?.availableToConsume && (
          <h2>
            Para acessar este curso é necessário assistir ao menos{' '}
            {coursePrerequisites.requiredPercentage}% de <br />
            <u>{coursePrerequisites.name}</u>
          </h2>
        )}

        <VideoComponent>
          {loading && (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          )}

          {courseInfo?.reference && !loading && (
            <>
              <ResponsivePlayer
                className="hidden-mobile"
                contentUrl={courseInfo.reference}
                controlsToEnable={controlsToEnable}
                id="playerHome2desktop"
                styleVideo={{
                  height: '427px',
                  minWidth: '100%',
                }}
              />
              <ResponsivePlayer
                className="hidden-desktop"
                contentUrl={courseInfo.reference}
                controlsToEnable={controlsToEnable}
                id="playerHome2Mobile"
                styles={{
                  height: '270px',
                  maxWidth: '100%',
                }}
              />
            </>
          )}
        </VideoComponent>

        <AboutCourseInfo>
          <h3>Sobre</h3>
          <div dangerouslySetInnerHTML={{ __html: courseInfo.description }} />
        </AboutCourseInfo>

        <Divider />

        <h3>CONTEÚDO DO CURSO</h3>

        {courseInfo.children
          ? courseInfo.children.map((courseModule, index) => (
              <AccordionHalu
                moduleIndex={index}
                inProgress={courseProgress}
                parentUuid={uuid}
                title={courseModule.name}
                lessons={courseModule.children}
                course={courseInfo}
                userCoursesIds={userCoursesIds}
              />
            ))
          : null}
        {courseInfo.exams
          ? courseInfo.exams.map((exam, index) => (
              <AccordionHalu
                moduleIndex={index}
                inProgress={courseProgress}
                parentUuid={uuid}
                title="Questionários"
                lessons={courseInfo.exams}
                course={courseInfo}
                userCoursesIds={userCoursesIds}
                examUser={examUser.data[0]}
                examMode
              />
            ))
          : null}
      </AboutCourse>

      <Divider />

      <HorizontalScroll
        icon={CircleImg}
        title="Recomendados"
        subtitle="Veja os conteúdos recomendados"
        totalItems={recommendedContents.length}
      >
        {recommendedContents.length
          ? recommendedContents.map(item => (
              <div style={{ marginLeft: 24, marginRight: 24 }}>
                <CardCourse data={item} />
              </div>
            ))
          : [...Array(6).keys()].map(item => <CardCourseSkeleton key={item} />)}
      </HorizontalScroll>
    </Container>
  );
};

export default CoursesDetail;
