/* eslint-disable no-nested-ternary */
import React from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { Container, InputWrapper, ErrorMessage, TextArea } from './styles';

export function Input({
  containerId,
  icon,
  wrapperClassName,
  containerStyle,
  wrapperStyle,
  errorMessage,
  isInvalid,
  name,
  control,
  ref,
  isTextArea,
  ...rest
}) {
  return (
    <Container
      id={containerId}
      className={wrapperClassName}
      style={wrapperStyle}
    >
      <InputWrapper style={containerStyle} isInvalid={isInvalid}>
        {icon ? <>{icon}</> : null}

        {isTextArea ? (
          <TextArea name={name} {...rest} ref={ref} />
        ) : control ? (
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value } }) => (
              <InputMask onChange={onChange} value={value} {...rest} />
            )}
          />
        ) : (
          <InputMask name={name} {...rest} ref={ref} />
        )}
      </InputWrapper>
      {isInvalid ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </Container>
  );
}
