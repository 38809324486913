import styled from 'styled-components';

import BackgroundImg from '../../../assets/images/login-bg.png';
import { theme } from '../../../styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: url(${BackgroundImg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;

  h1,
  h2 {
    color: ${theme.colors.textLight};
    text-align: center;
  }

  @media (max-width: 900px) {
    height: 100vh;
  }
`;

export const ThanksContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.colors.light};
  border-radius: 20px;
  text-align: center;
  padding: 24px;
  max-width: 445px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 480px) {
    padding-top: 60px;
    margin-top: 60px;
    width: 300px;
  }

  h1 {
    font-size: 2rem;
    color: ${theme.colors.text};
    font-weight: bold;
    margin: 16px 0px;

    @media (max-width: 470px) {
      width: 100%;
      font-size: 23px;
      line-height: 32px;
    }
  }

  h2 {
    font-size: 1.2rem;
    color: ${theme.colors.textSecondary};
    margin-bottom: 40px;

    @media (max-width: 480px) {
      max-width: 260px;
      font-size: 1rem;
    }
  }

  @media (max-width: 470px) {
    padding: 12px;

    img {
      width: 90px;
      height: 90px;
    }
  }
`;
