import styled from 'styled-components';

import { theme } from '../../../../styles/theme';

export const Overlay = styled.div`
  &:before {
    content: '';
    display: ${({ open }) => (open ? 'block' : 'none')};
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 400;
  }
`;

export const CouponSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin: 16px 0;

  div {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 8px;
  }

  h5 {
    font-weight: bold;
    font-size: 18px;
    color: var(--default-blue);
  }

  input {
    text-transform: uppercase;
  }

  button {
    padding: 16px;
    font-weight: bold;
    border-radius: 20px;
    text-transform: uppercase;
  }

  button#apply-coupon {
    color: #fff;
    background-color: var(--default-blue);
    border: 1px solid var(--default-blue);
    /* border-radius: 0 8px 8px 0; */
  }

  span#error {
    font-size: 0.8rem;
    width: 100%;
    color: ${theme.colors.error};
    text-align: left;
    margin-left: 20px;
  }
`;

export const AppliedCoupon = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #f3f9fb;
  padding: 16px 32px;

  div {
    display: flex;
    flex-direction: column;
  }

  #coupon-info {
    h5 {
      font-size: 16px;
      font-weight: bold;
    }
    span {
      color: gray;
    }
  }

  #final-price {
    h5 {
      color: gray;
    }
    span {
      font-size: 16px;
      font-weight: bold;
    }
  }
`;

export const Container = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  flex-direction: column;
  background: ${theme.colors.light};
  padding: 50px 0 40px 0;
  max-width: 90%;
  min-width: 680px;
  z-index: 500;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  overflow: auto;
  max-height: 90vh;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-bottom: 5px solid ${theme.colors.light};
  border-top: 5px solid ${theme.colors.light};

  > svg {
    align-self: center;
    width: 100%;
  }

  h1 {
    align-self: center;
    text-align: center;
    font-size: 2rem;
    color: ${theme.colors.secondary};
    margin: 50px 0 20px;
  }
  small {
    color: rgb(144, 149, 153);
    padding: 0 20px;
    text-align: center;
    max-width: 400px;
    margin: 0 auto 50px;
    display: block;
    font-weight: 600;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.secondary};
    border: 4px solid transparent;
    border-radius: 8px;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    background: ${theme.colors.shapeSecondary};
    border-radius: 10px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  h6 {
    margin: 40px auto;
    text-align: center;
    font-size: 1.3rem;
    color: ${theme.colors.secondary};
  }
  @media (min-width: 2300px) {
    min-width: 900px;
  }
  @media screen and (min-width: 1100px) {
    padding: 50px 20px 40px;
  }
  @media (max-width: 690px) {
    min-width: auto;
  }
`;

export const PurchaseDetails = styled.div`
  background: ${theme.colors.shape};
  padding: 25px 35px;
  display: flex;
  justify-content: space-around;
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 70%;
  padding-right: 24px;

  h2 {
    font-size: 1.2rem;
    margin-bottom: 16px;
  }

  @media (max-width: 690px) {
    h2 {
      font-size: 0.9rem;
    }

    p {
      font-size: 0.8rem;
    }
  }
`;

export const ProductInfoFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    color: ${theme.colors.textSecondary};
    font-weight: 500;

    > span {
      margin-left: 8px;
      color: ${theme.colors.secondary};
    }

    svg ~ span {
      margin-left: 0;
      color: ${theme.colors.warning};
    }
  }

  p ~ p {
    svg {
      width: 16px;
      height: 16px;
    }

    font-size: 0.9rem;
  }

  @media (max-width: 690px) {
    p {
      font-size: 0.9rem;
    }

    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ProductPrice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 24px;

  p {
    font-size: 0.9rem;
    color: ${theme.colors.textSecondary};
  }

  h4 {
    font-size: 2rem;
  }

  @media (max-width: 690px) {
    h4 {
      font-size: 1rem;
    }
  }
`;

export const PaymentForm = styled.div`
  h2 {
    align-self: center;
    text-align: center;
    font-size: 1.2rem;
    color: ${theme.colors.textSecondary};
  }

  h5 {
    margin-top: 50px;
    margin-left: 40px;
    margin-bottom: 32px;
    text-align: ${storedCard => (storedCard ? 'left' : 'center')};
    font-size: 1.3rem;
    color: ${theme.colors.secondary};
    display: flex;
    align-items: center;
    justify-content: ${storedCard => (storedCard ? 'flex-start' : 'center')};

    svg {
      display: ${({ storedCard }) => (storedCard ? 'block' : 'none')};
      margin-right: 20px;
      cursor: pointer;
    }
  }
`;

export const FormInputs = styled.div`
  padding: 0 20px 36px;

  label {
    cursor: pointer;
  }

  input[type='checkbox'] {
    cursor: pointer;
    margin-right: 8px;
  }
`;

export const RowInputs = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 26px;
`;

export const FormButtons = styled.div`
  display: flex;
  justify-content: space-around;
  border-top: 1px solid ${theme.colors.shapeSecondary};
  padding-top: 30px;

  @media (max-width: 690px) {
    flex-direction: column;
    align-items: center;
    border-top: none;

    div:first-of-type {
      display: none;
    }
  }
`;

export const SavedCardsList = styled.ul`
  list-style: none;
`;

export const SavedCard = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 18px 40px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
`;

export const CardInfo = styled.div`
  margin-left: 20px;

  span {
    font-weight: bold;
    font-size: 0.8rem;
  }

  p {
    color: ${theme.colors.textSecondary};

    span {
      color: ${theme.colors.text};
      font-size: 0.9rem;
    }
  }

  span ~ p {
    margin-top: 8px;
  }
`;

export const CloseButtonMobile = styled.div`
  position: absolute;
  top: 22px;
  right: 16px;
`;

export const SelectFormOfPayment = styled.div`
  display: flex;
  justify-content: end;
  padding: 30px 10px 50px 10px;

  input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: 25px;
    height: 25px;
    padding: 3px;
    background-clip: content-box;
    border: 2px solid #25282b;
    background-color: #e7e6e7;
    border-radius: 50%;
    @media (min-width: 300px) {
      width: 20px;
      height: 20px;
    }
  }

  input[type='radio']:checked {
    background-color: #594aad;
    border: 2px solid #594aad;
  }

  label {
    color: #25282b;
    font-size: 1rem;
    font-weight: 600;
    align-self: center;
    padding: 0 50px 0 8px;
  }

  @media (max-width: 620px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    label {
      font-size: 0.8rem;
      padding: 0 10px 0 8px;
    }
  }

  @media (max-width: 700px) {
    label {
      font-size: 1rem;
    }
  }
`;

export const ViewBilletCamp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: 48px;
  padding-bottom: 40px;

  > svg {
    color: ${theme.colors.primary};
    width: 60px;
    height: 60px;
    margin: 0 auto;
    margin-bottom: 24px;
    align-self: center;
  }

  h5 {
    font-weight: 600;
    font-size: 24px;
    color: #2f189c;
    padding-bottom: 32px;
  }

  p {
    font-size: 18px;
    color: #25282b;
    font-weight: 500;
    padding-bottom: 30px;
    text-align: center;
  }

  h4 {
    font-weight: 700;
    font-size: 18px;
    color: #25282b;
    padding-top: 32px;
    padding-bottom: 16px;
    align-self: flex-start;
    padding-left: 50px;
  }

  span {
  }
`;

export const TicketNumberCamp = styled.div`
  display: flex;
  border-radius: 62px;
  background: #594aad;
  width: 90%;
  height: 64px;
  align-items: center;

  span,
  a {
    text-align: center;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    max-width: 100%;
  }

  img {
    cursor: pointer;
  }
`;

export const ViewBillet = styled.div`
  display: flex;
  border-radius: 62px;
  background: #2f189c;
  height: 64px;
  align-items: center;
  margin-bottom: 40px;
  padding: 0 100px;
  cursor: pointer;

  a {
    text-align: center;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    max-width: 100%;
  }

  img {
    padding-right: 12px;
  }
`;
export const ViewBilletPaypal = styled.div`
  display: flex;
  border-radius: 62px;
  background: #2f189c;
  height: 64px;
  align-items: center;
  margin-bottom: 40px;
  padding: 0 100px;
  cursor: pointer;

  a {
    text-align: center;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    max-width: 100%;
  }

  img {
    padding-right: 12px;
    max-height: 50px;
  }
`;

export const SelectContainer = styled.div`
  width: 100%;
  z-index: 200;

  /* Border of ReactSelect */

  span + span + div {
    border: 1px solid
      ${({ error }) =>
        error ? theme.colors.error : theme.colors.shapeSecondary};
  }
`;

export const SelectCustomStyle = {
  container: provided => ({
    ...provided,
    width: '100%',
  }),
  control: provided => ({
    ...provided,
    width: '100%',
    borderRadius: 20,
    padding: '8px 12px',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
    display: 'flex',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  option: provided => ({
    ...provided,
    borderRadius: 20,
  }),
  menu: provided => ({
    ...provided,
    paddingBottom: 25,
  }),
  menuList: provided => ({
    ...provided,
    paddingBottom: 20,
    paddingTop: 20,
  }),
};

export const ErrorMessage = styled.span`
  display: block;
  font-size: 0.8rem;
  color: ${theme.colors.error};
  text-align: start;
  width: 100%;
  margin-top: 8px;
  margin-left: 10px;
`;

export const SelectFormOfPaymentOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;

  @media (max-width: 620px) {
    margin: 4px 0;
  }
`;

export const PixContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;

  p {
    text-align: center;
    font-size: 0.9rem;
    margin-bottom: 24px;
  }

  iframe {
    width: 100%;
    height: 200px;
    margin-bottom: 24px;
  }

  h5 {
    font-weight: 600;
    font-size: 24px;
    color: #2f189c;
    padding-bottom: 24px;
  }

  img {
    width: 100%;
    max-width: 120px;
    margin-bottom: 32px;
  }
`;

export const PixCopyAndPasteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  margin-bottom: 32px;

  p {
    font-size: 0.9rem;
    font-weight: 600;
  }

  p + p {
    text-transform: uppercase;
  }

  a {
    color: var(--default-blue);
    font-size: 0.9rem;
    font-weight: 500;
    text-decoration: none;
    font-style: italic;
  }

  .spinner {
    margin-top: 16px;
  }
`;

export const PixCopyAndPaste = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  min-width: 200px;
  max-width: 400px;
  margin-bottom: 24px;

  > p {
    font-size: 0.9rem;
    color: ${theme.colors.primary};
    font-weight: 600;
    margin-top: 32px;
    margin-bottom: 16px;
    text-align: center;
  }

  > textarea {
    background-color: ${theme.colors.textDisabled};
    color: ${theme.colors.text};
    padding: 8px;
    border-radius: 4px;

    text-align: center;

    height: 140px;
    width: 100%;
    max-width: 260px;
    resize: none;
  }

  > button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 8px 16px;
    background: var(--default-blue);
    border-radius: 24px;
    width: 100%;
    max-width: 140px;
    margin-top: 16px;

    font-size: 0.8em;
    font-weight: 600;
    color: white;
  }
`;

export const ContainerDebitCreditCard = styled.div`
  background-color: #ffcc00;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 10px 10px;
`;

export const LabelDebitCreditCard = styled.p`
  font-size: 16px;
`;
