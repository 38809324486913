import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import CardPlan from '../../../../components/CardPlan';
import { listPlans } from '../../../../services/plans';
import { planConverter } from '../../../../utils/planConverter';

import { HomePlansContainer, NoPlansFound } from './style';

const HomePlans = () => {
  const history = useHistory();
  const [plans, setPlans] = useState([]);

  const getPlans = useCallback(async () => {
    const localPlansWithoutFilter = await listPlans();
    const localPlans = localPlansWithoutFilter.filter(
      plan => plan.is_active === true,
    );
    setPlans(localPlans);
  }, []);

  const handleGoToRegister = useCallback(() => {
    history.push('/registro');
  }, [history]);

  const monthlyPlans = useMemo(() => {
    return plans
      .filter(plan => plan.period_in_days === 30)
      .sort((a, b) => (a.info.type > b.info.type ? 1 : -1));
  }, [plans]);

  const quarterlyPlans = useMemo(() => {
    return plans
      .filter(plan => plan.period_in_days === 90)
      .sort((a, b) => (a.info.type > b.info.type ? 1 : -1));
  }, [plans]);

  const semiannualPlans = useMemo(() => {
    return plans
      .filter(plan => plan.period_in_days === 180)
      .sort((a, b) => (a.info.type > b.info.type ? 1 : -1));
  }, [plans]);

  const yearlyPlans = useMemo(() => {
    return plans
      .filter(plan => plan.period_in_days === 365)
      .sort((a, b) => (a.info.type > b.info.type ? 1 : -1));
  }, [plans]);

  useEffect(() => {
    getPlans();
  }, [getPlans]);

  return (
    <HomePlansContainer className="max-content">
      <h2>Nossos Planos de Assinatura</h2>

      <Tabs>
        <TabList>
          <Tab>Mensal</Tab>
          <Tab>Trimestral</Tab>
          <Tab>Semestral</Tab>
          <Tab>Anual</Tab>
        </TabList>

        <TabPanel>
          {monthlyPlans?.length ? (
            monthlyPlans.map(p => (
              <CardPlan
                key={p.plan_id}
                data={planConverter(p)}
                onSelect={handleGoToRegister}
              />
            ))
          ) : (
            <NoPlansFound>Não temos planos para essa opção</NoPlansFound>
          )}
        </TabPanel>

        <TabPanel>
          {quarterlyPlans?.length ? (
            quarterlyPlans.map(p => (
              <CardPlan
                key={p.plan_id}
                data={planConverter(p)}
                onSelect={handleGoToRegister}
              />
            ))
          ) : (
            <NoPlansFound>Não temos planos para essa opção</NoPlansFound>
          )}
        </TabPanel>

        <TabPanel>
          {semiannualPlans?.length ? (
            semiannualPlans.map(p => (
              <CardPlan
                key={p.plan_id}
                data={planConverter(p)}
                onSelect={handleGoToRegister}
              />
            ))
          ) : (
            <NoPlansFound>Não temos planos para essa opção</NoPlansFound>
          )}
        </TabPanel>

        <TabPanel>
          {yearlyPlans?.length ? (
            yearlyPlans.map(p => (
              <CardPlan
                key={p.plan_id}
                data={planConverter(p)}
                onSelect={handleGoToRegister}
              />
            ))
          ) : (
            <NoPlansFound>Não temos planos para essa opção</NoPlansFound>
          )}
        </TabPanel>
      </Tabs>
    </HomePlansContainer>
  );
};

export default HomePlans;
