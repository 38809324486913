import axiosApi from './api';

const ContentsServiceConstants = {
  LIMIT: 12,
};

class ContentsService {
  constructor() {
    this.api = axiosApi;
  }

  _filterOnlyAvailableToSell(contents) {
    const onlyContentsAvailableToSell = contents.filter(content => {
      if (content.info.is_free) {
        return content;
      }

      if (content?.info?.to_sell) {
        return content;
      }

      return content;
    });

    return onlyContentsAvailableToSell;
  }

  async searchContentsByName(name) {
    return this.api
      .get(`/contents/?name=${name}`, {
        params: {
          is_active: true,
        },
      })
      .then(res => res.data);
  }

  async searchHighlightedContents() {
    return this.api
      .get('/contents', {
        params: {
          is_active: true,
          flag: ['course', 'retreat'],
          is_highlight: true,
          type: ['curso', 'on-demand', 'retiro'],
          relations: [
            'reviews',
            'content_user',
            // 'progress',
            'authors',
            'tags',
            'categories',
          ],
          limit: ContentsServiceConstants.LIMIT,
        },
      })
      .then(res => {
        const onlyContentsAvailableToSell = this._filterOnlyAvailableToSell(
          res.data.data,
        );

        return {
          ...res.data,
          data: onlyContentsAvailableToSell,
        };
      });
  }

  async searchMostWatchedContents() {
    return this.api
      .get('/contents', {
        params: {
          is_active: true,
          flag: ['course'],
          type: ['curso', 'on-demand'],
          relations: [
            'reviews',
            'content_user',
            // 'progress',
            'authors',
            'tags',
            'categories',
          ],
          order_by: 'most_viewed',
          limit: ContentsServiceConstants.LIMIT,
        },
      })
      .then(res => {
        const onlyContentsAvailableToSell = this._filterOnlyAvailableToSell(
          res.data.data,
        );

        return {
          ...res.data,
          data: onlyContentsAvailableToSell,
        };
      });
  }

  async searchRecommendedContents() {
    return this.api
      .get('/contents', {
        params: {
          flag: ['course', 'retreat'],
          type: ['curso', 'on-demand', 'retiro'],
          is_recommended: true,
          relations: [
            'reviews',
            'content_user',
            // 'progress',
            'authors',
            'tags',
            'categories',
          ],
          order_by: 'most_viewed',
          limit: ContentsServiceConstants.LIMIT,
          is_active: true,
        },
      })
      .then(res => {
        const onlyContentsAvailableToSell = this._filterOnlyAvailableToSell(
          res.data.data,
        );

        return {
          ...res.data,
          data: onlyContentsAvailableToSell,
        };
      });
  }

  async searchInProgressContentsHomeLogged() {
    return this.api
      .get('/contents', {
        params: {
          cache_name: 'in_progress_contents_home_logged',
          flag: ['course', 'retreat'],
          type: ['curso', 'on-demand', 'retiro'],
          relations: [
            'reviews',
            'content_user',
            // 'progress',
            'authors',
            'tags',
            'categories',
          ],
          in_progress: true,
          limit: 15,
          is_active: true,
        },
      })
      .then(res => res.data);
  }

  async likeContent(id, like) {
    return this.api.patch(`content-users/${id}/like`, {
      like,
    });
  }

  async reviewContent(id, value, description) {
    return this.api.patch(`/content-users/${id}/review`, {
      review_stars: value,
      review_description: description || ' ',
    });
  }

  async listContentsWithFilter(filterSelected, offset = 1) {
    return this.api
      .get('/contents', {
        params: {
          cache_name: 'list_contents_with_filter',
          is_active: true,
          ...filterSelected,
          limit: ContentsServiceConstants.LIMIT,
          offset,
        },
      })
      .then(res => {
        const onlyContentsAvailableToSell = this._filterOnlyAvailableToSell(
          res.data.data,
        );

        return {
          ...res.data,
          data: onlyContentsAvailableToSell,
        };
      });
  }

  async searchContentsWithFilter(filterSelected, offset = 1) {
    return this.api
      .get('/contents', {
        params: {
          is_active: true,
          ...filterSelected,
          limit: ContentsServiceConstants.LIMIT,
          offset,
        },
      })
      .then(res => {
        const onlyContentsAvailableToSell = this._filterOnlyAvailableToSell(
          res.data.data,
        );

        return {
          ...res.data,
          data: onlyContentsAvailableToSell,
        };
      });
  }

  async searchContents(filter, offset, limit) {
    const params = {
      ...filter,
      limit: limit || ContentsServiceConstants.LIMIT,
    };

    if (offset) params.offset = offset;

    return this.api.get('/contents/search', { params }).then(res => {
      const onlyContentsAvailableToSell = this._filterOnlyAvailableToSell(
        res.data.data.data,
      );

      return {
        ...res.data,
        data: onlyContentsAvailableToSell,
      };
    });
  }

  async searchContentsInProgress(filter, offset) {
    const params = {
      ...filter,
      limit: ContentsServiceConstants.LIMIT,
      in_progress: true,
    };

    if (offset) params.offset = offset;

    return this.api.get('/contents/search', { params }).then(res => res.data);
  }

  async searchContentsFinished(filter, offset) {
    const params = {
      ...filter,
      limit: ContentsServiceConstants.LIMIT,
      in_progress: true,
      is_finished: true,
    };

    if (offset) params.offset = offset;

    return this.api.get('/contents/search', { params }).then(res => res.data);
  }
}

export { ContentsService, ContentsServiceConstants };
