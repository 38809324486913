import api from './api';

export async function sendEventRdStation(data, token) {
  return api.post(`/rd/events`, data, {
    headers: {
      ...(token && {
        authorization: `Bearer ${token}`,
      }),
    },
  });
}
