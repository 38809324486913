import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
  display: contents;

  > #divider {
    margin: 72px 0 !important;
  }

  @media (max-width: 480px) {
    width: 100%;
    min-height: 100%;
    align-items: center;
  }
`;

export const Background = styled.div`
  max-width: 1360px;
  width: 100%;
  height: auto;

  padding: 120px 24px 40px;
  margin: 0 auto;

  > img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 400px;
    object-fit: cover;

    @media (max-width: 870px) {
      height: 350px;
    }

    @media (max-width: 480px) {
      height: 250px;
    }
  }

  @media (max-width: 1024px) {
    padding: 24px 24px 40px;
  }

  @media (max-width: 480px) {
    width: 100%;

    padding: 48px 24px 0;

    display: flex;
    flex-direction: column;

    > img {
      height: 250px;
    }
  }
`;

export const ButtonBack = styled.button`
  display: flex;
  color: #ffffff;
  font-size: 16px;
  align-items: flex-start;
  margin: 90px 0 30px 16px;

  img {
    padding-right: 24px;
    margin-top: 2px;
  }
`;

export const AboutCourse = styled.div`
  max-width: 890px;
  width: 100%;

  display: flex;
  flex-direction: column;

  padding: 0 24px;
  margin: 0 auto;

  h2 {
    margin: 40px 0;
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
  }

  @media (max-width: 870px) {
    padding-top: 20px;
  }

  @media (max-width: 480px) {
    margin: 40px 0 0 0;
  }

  > h3 {
    padding: 0 16px 24px 10px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
`;

export const AboutCourseInfo = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    padding: 0 16px;
    margin: 0;
  }

  @media (max-width: 480px) {
    max-width: 100%;
    padding: 0 16px;
    margin: 32px 0 0 0;
  }

  h3 {
    margin-bottom: 24px;
    font-size: 20px;
    align-items: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: #25282b;
  }

  > div {
    p {
      span {
        background: transparent !important;
      }
    }
  }
`;

export const VideoComponent = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  padding: 12px 0;
  border-radius: 8px;
  overflow: hidden;
  iframe {
    width: 100%;
    height: 100%;
  }
`;
