import React from 'react';
import { FaRegAddressCard } from 'react-icons/fa';
import { components } from 'react-select';

const AddressContainerIcon = ({ children, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && <FaRegAddressCard />}
        {children}
      </components.ValueContainer>
    )
  );
};

export default AddressContainerIcon;
