import React, { useState, useCallback, useEffect } from 'react';
import { AiFillCloseCircle, AiFillCheckCircle } from 'react-icons/ai';
import { toast } from 'react-toastify';

import { useExecutionCourse } from '../../hooks/executionCourse';
import {
  finishExam,
  getExam,
  answerQuestions,
  startExam,
  getExamUser,
} from '../../services/examService';
import { Button } from '../Button';
import Spinner from '../Spinner';

import {
  Container,
  ButtonStartContainer,
  Questions,
  DisapprovedBox,
  ApprovedBox,
} from './styles';

const Exam = ({ exam }) => {
  const [openExam, setOpenExam] = useState(false);
  const [localExamUser, setExamUser] = useState({});
  const [questions, setQuestions] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { finishExamToNextContent } = useExecutionCourse();
  const [finalStatus, setFinalStatus] = useState(null); // disapproved ou approved
  const percent = exam?.average_in_percent;
  const [rightTruePercent, setRightTruePercent] = useState();
  const [disabledQuestions, setDisabledQuestions] = useState(false);

  function countRightTrue(objects) {
    const total = objects.length;
    const rightTrue = objects.filter(
      object => object.data.right === true,
    ).length;
    const result = (rightTrue * 100) / total;
    setRightTruePercent(Math.round(result));
    return result;
  }

  function tryAgain() {
    setFinalStatus('disapproved');
    setOpenExam(false);
    setSelectedOption({});
    setFinalStatus(null);
    setRightTruePercent(null);
    handleInitExam();
    setDisabledQuestions(false);
  }

  function changeStatusOption(questionsList) {
    questionsList.forEach(item => {
      selectedOption[item?.exam_question_id].status =
        item?.right === true ? 'correct' : 'incorrect';
    });
  }

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const questionsList = questions.map(question => {
      return question.exam_question_id;
    });
    if (questionsList.every(question => selectedOption[question])) {
      try {
        const listResultQuestions = [];
        const status = await Promise.all(
          questionsList.map(async item => {
            const response = await answerQuestions(
              selectedOption[item].questionId,
              {
                exam_question_alternative_id: selectedOption[item].optionId,
                exam_user_id: localExamUser.exam_user_id,
              },
            );
            listResultQuestions.push(response.data);
            return response;
          }),
        );
        changeStatusOption(listResultQuestions);
        toast.success('Respostas enviadas com sucesso!');
        if (countRightTrue(status) >= percent) {
          setFinalStatus('approved');
          await finishExam(localExamUser.exam_user_id);
        } else {
          setFinalStatus('disapproved');
        }

        setDisabledQuestions(true);
      } catch (error) {
        if (error.response.status === 404) {
          toast.info(error.response.data.message);
          return;
        }
        toast.error(
          'Ocorreu um erro ao tentar iniciar a prova, tente novamente.',
        );
      }
    } else {
      toast.warn('Todas as perguntas devem ser respondidas antes do envio!');
    }
    setIsSubmitting(false);
  };

  const handleInitExam = async () => {
    try {
      setOpenExam(true);
      if (localExamUser?.exam_user_id) return;
      if (exam?.exam_id) {
        const user = JSON.parse(localStorage.getItem('@HaluGamashi:user'));
        const response = await getExamUser(exam?.exam_id, user?.user_id);
        if (response?.data[0]?.finish_at) {
          setFinalStatus('approved');
          const finalResultOptions = {};
          response?.data[0]?.exam_question_users.forEach(question => {
            const elemento = document?.getElementById(
              question.exam_question_alternative_id,
            );
            elemento.click();
            finalResultOptions[question?.exam_question_id] = {
              questionId: question?.exam_question_id,
              optionId: question?.exam_question_alternative_id,
              status: question?.right === true ? 'correct' : 'incorrect',
            };
          });
          setSelectedOption(finalResultOptions);
          setDisabledQuestions(true);
        }
        if (!response?.data[0]?.start_at) {
          const newExamUser = await startExam(exam.exam_id);
          if (newExamUser?.exam_user_id) {
            setExamUser(newExamUser);
          }
        } else {
          setExamUser(response.data[0]);
        }
      }
    } catch (error) {
      if (error.response.status === 404) {
        toast.info(error.response.data.message);
        return;
      }
      toast.error(
        'Ocorreu um erro ao tentar iniciar a prova, tente novamente.',
      );
    }
  };
  const handleOptionChange = (questionId, optionId) => {
    setSelectedOption({
      ...selectedOption,
      [questionId]: { questionId, optionId, status: 'pending' },
    });
  };

  const getQuestions = useCallback(async () => {
    if (exam) {
      await getExam(exam.exam_id)
        .then(response => {
          const questionsOrdered = response.data.exam_questions
            .map(item => {
              const alternativesOrdered = item.exam_question_alternatives.sort(
                (current, next) => (current.position > next.position ? 1 : -1),
              );

              const itemWithAlternativesOrdered = Object.assign(item, {
                exam_question_alternatives: alternativesOrdered,
              });

              return itemWithAlternativesOrdered;
            })
            .sort((current, next) =>
              current.position > next.position ? 1 : -1,
            );

          setQuestions(questionsOrdered);
        })
        .catch(() => {
          toast.error(
            'Ocorreu um erro ao buscar as questões, tente novamente.',
          );
        });
    }
  }, [exam]);

  useEffect(() => {
    getQuestions();
  }, [getQuestions]);

  const CurrentButton = () => {
    if (finalStatus === 'disapproved') {
      return (
        <Button
          title="TENTAR NOVAMENTE"
          onClick={() => tryAgain()}
          containerStyle={{
            maxWidth: 400,
          }}
        />
      );
    }
    if (finalStatus === 'approved') {
      return (
        <Button
          title="CONCLUIR"
          onClick={() => finishExamToNextContent()}
          containerStyle={{
            maxWidth: 400,
          }}
        />
      );
    }
    return (
      <Button
        disabled={disabledQuestions}
        title="ENVIAR"
        onClick={() => handleSubmit()}
        containerStyle={{
          maxWidth: 400,
        }}
      />
    );
  };

  function checkStatusAlternative(alternative, question) {
    return (
      alternative?.exam_question_alternative_id ===
        selectedOption[question?.exam_question_id]?.optionId &&
      selectedOption[question?.exam_question_id]?.status
    );
  }

  return (
    <Container>
      <h1>{exam?.title}</h1>
      {finalStatus === 'disapproved' && (
        <DisapprovedBox>
          <p>
            <AiFillCloseCircle size={20} color="inherit" /> Infelizmente, você
            não atingiu a pontuação mínima necessária.
            <br /> Você obteve {rightTruePercent}%, mas precisava ter no mínimo{' '}
            {percent}%.
            <br />
            Tente novamente.
          </p>
        </DisapprovedBox>
      )}
      {finalStatus === 'approved' && (
        <ApprovedBox>
          <p>
            <AiFillCheckCircle size={20} color="#00875A" />{' '}
            <span>Finalizado!</span> Veja suas respostas abaixo.
          </p>
        </ApprovedBox>
      )}
      {!openExam && (
        <ButtonStartContainer>
          <Button
            title="INICIAR"
            onClick={handleInitExam}
            containerStyle={{
              width: 200,
              minWidth: 200,
            }}
          />
        </ButtonStartContainer>
      )}
      {openExam && (
        <>
          <Questions>
            {questions.map((question, index) => (
              <div key={question.exam_question_id}>
                <p>
                  {index + 1} - {question.description}
                </p>

                <ul>
                  {question.exam_question_alternatives.map(alternative => (
                    <li
                      key={alternative.exam_question_alternative_id}
                      className={
                        finalStatus === 'approved' &&
                        checkStatusAlternative(alternative, question)
                      }
                    >
                      <label htmlFor={alternative.exam_question_alternative_id}>
                        <input
                          type="radio"
                          id={alternative.exam_question_alternative_id}
                          value={alternative.exam_question_alternative_id}
                          checked={
                            selectedOption[question.exam_question_id]
                              ?.optionId ===
                            alternative.exam_question_alternative_id
                          }
                          disabled={disabledQuestions}
                          onChange={() => {
                            handleOptionChange(
                              question.exam_question_id,
                              alternative.exam_question_alternative_id,
                            );
                          }}
                        />
                        {alternative.description}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </Questions>
          {isSubmitting ? <Spinner /> : <CurrentButton />}
        </>
      )}
    </Container>
  );
};

export { Exam };
