import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import myCoursesMobile from '../../assets/images/my-course-mobile.png';
import myCourses from '../../assets/images/my-courses.jpg';
import Breadcrumbs from '../../components/Breadcrumbs';
import { getTermsContent } from '../../services/HomeInfo';
import { goToTop } from '../../utils/helpers';

import { Content, Background, ImageFilter } from './styles';

const PrivacyPolicy = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await getTermsContent();
      setContent(response.data[0]);
    })();
  }, []);

  goToTop();

  return (
    <>
      <Background>
        <ImageFilter />
        <img className="hidden-mobile" src={myCourses} alt="HaluGamashi" />
        <img
          className="hidden-desktop"
          src={myCoursesMobile}
          alt="HaluGamashi"
        />
        <Breadcrumbs
          style={{ paddingLeft: '40px' }}
          links={[{ name: 'Politica de privacidade', url: '/courses' }]}
        />
        <h2 className="hidden-mobile">Politica de privacidade</h2>
        <h1 className="hidden-mobile">Politica de privacidade</h1>
      </Background>
      <Content>
        {content ? (
          <div
            dangerouslySetInnerHTML={{ __html: content.info.privacyPolicy }}
          />
        ) : (
          <>
            {[...Array(2).keys()].map(() => (
              <>
                <Skeleton
                  count={1}
                  style={{ marginTop: 10 }}
                  width="100%"
                  height={15}
                />
                <Skeleton count={3} width="100%" height={15} />
                <Skeleton count={3} width="80%" height={15} />
                <Skeleton count={2} width="70%" height={15} />

                <Skeleton
                  count={1}
                  style={{ marginTop: 10 }}
                  width="100%"
                  height={15}
                />
                <Skeleton count={3} width="100%" height={15} />
                <Skeleton count={3} width="80%" height={15} />
                <Skeleton count={2} width="70%" height={15} />
              </>
            ))}
          </>
        )}
      </Content>
    </>
  );
};

export default PrivacyPolicy;
