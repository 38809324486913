import styled from 'styled-components';

import { theme } from '../../styles/theme';

export const ContainerFooter = styled.div`
  width: 100%;
  min-height: 175px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;
  padding: 40px 40px;
  background: linear-gradient(
    180deg,
    ${theme.colors.primary} 0%,
    ${theme.colors.secondary} 100%
  );
  @media (max-width: 480px) {
    padding: 0;
    margin: 0;
    min-height: 200px;
  }

  hr {
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
    margin-top: 45px;
  }
`;

export const ContentFooterTop = styled.div`
  display: flex;
  width: 100%;
`;

export const LeftFooter = styled.div`
  img {
    width: 100%;
    @media (max-width: 480px) {
      padding: 24px 48px 0 48px;
      max-width: 100%;
      height: 100%;
    }
  }
`;

export const RightFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  min-height: 100%;
  padding: 5px 0;
  gap: 8px;
  flex: 1;

  a {
    font-size: 20px;
    color: #ffffff;
    text-align: end;
    text-decoration: none;
  }
`;

export const ContentFooterBottom = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  margin-top: 45px;
  color: #ffffff;
  @media (max-width: 480px) {
    padding: 30px 16px 76px 16px;
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
  }
`;
