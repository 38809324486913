import React from 'react';
import ReactRating from 'react-rating';

import { ReactComponent as StarFilledImg } from '../../assets/icons/star.svg';
import { ReactComponent as UnFilledStarImg } from '../../assets/icons/un-filled-star.svg';

import { Container } from './styles';

const Rater = ({
  changeRating = () => {},
  initialRating,
  title,
  starsWidth = 24,
  starsHeight = 24,
}) => {
  return (
    <Container>
      <span>{title}:</span>
      <ReactRating
        initialRating={initialRating}
        emptySymbol={
          <UnFilledStarImg width={starsWidth} height={starsHeight} />
        }
        fullSymbol={<StarFilledImg width={starsWidth} height={starsHeight} />}
        onChange={value => changeRating(value)}
      />
    </Container>
  );
};

export default Rater;
