import styled from 'styled-components';

const HomeFeaturesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 16px;
  flex-wrap: wrap;
  padding-top: 126px;
  padding-bottom: 63px;

  div.feature-card {
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 2.4em;
    color: var(--default-font-black);
    text-align: center;
  }

  .carousel {
    position: relative;
    width: 100%;
    height: 266px;

    .carousel__slide {
      min-width: 300px;
    }

    .carousel__back-button,
    .carousel__next-button {
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 8px;
      background: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 50%;
    }

    .carousel__back-button {
      transform: translate(0%, -50%);
      left: 0;
    }

    .carousel__next-button {
      transform: translate(0%, -50%);
      right: 0;
    }

    .carousel__dot-group {
      position: absolute;
      bottom: -40px;
      left: 0;
      display: flex;
      justify-content: center;
      gap: 8px;
      width: 100%;

      .carousel__dot {
        width: 32px;
        height: 6px;
        background: #909599;

        &.carousel__dot--selected {
          background: var(--default-blue);
        }
      }
    }
  }
`;

export { HomeFeaturesContainer };
