import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-bottom: 25px;
  z-index: 2;

  @media (max-width: 400px) {
    display: flex;
    align-items: center;
  }

  span {
    a {
      text-decoration: none;
      color: #ffffff;
      font-weight: 600;
      font-size: 16px;
      @media (max-width: 400px) {
        font-size: 16px;
      }
    }
  }

  div {
    display: flex;
    align-items: center;
  }

  div > img {
    padding: 5px 24px 0 24px;
    @media (max-width: 400px) {
      padding: 5px 5px 0 5px;
    }
  }

  span {
    font-weight: 400;
    text-shadow: 0 2px 4px rgba(24, 39, 75, 0.12),
      0 4px 4px rgba(24, 39, 75, 0.08);
  }

  span > a {
    color: #ffffff;
    font-weight: 700;
    text-decoration: none;
  }
`;

export const Content = styled.div`
  display: flex;
  position: relative;
  bottom: 2px;
  @media (max-width: 400px) {
    padding-top: 5px;
  }
  img {
    padding: 0 20px;
    @media (max-width: 400px) {
      padding: 0 30px 0 0;
      margin-right: 10px;
    }
  }

  span {
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;

    a {
      text-decoration: none;
      @media (max-width: 400px) {
        font-size: 16px;
      }
    }
  }
`;
