import React, { useEffect, useState } from 'react';
import { BiChevronRight, BiChevronLeft } from 'react-icons/bi';
import { FiSearch } from 'react-icons/fi';
import ReactPaginate from 'react-paginate';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import backArrow from '../../assets/icons/icon-back-breadcrumb-mobile.png';
import Breadcrumbs from '../../components/Breadcrumbs';
import Spinner from '../../components/Spinner';
import {
  getAllSections,
  getSectionsByName,
  SECTIONS_FORUM_LIMIT,
} from '../../services/forum';
import debounce from '../../utils/debounce';
import getPageCount from '../../utils/getPageCount';

import { SectionCard } from './components/SectionCard';
import {
  Container,
  Header,
  BreadcrumbMobile,
  Overlay,
  Content,
  List,
  ListHeader,
  ListHeaderOptions,
  SearchTopicsInput,
  Pagination,
} from './styles';

const Forum = () => {
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(true);

  const [pageCount, setPageCount] = useState(1);
  const [offset, setOffset] = useState(0);

  const history = useHistory();

  const handleGoToSectionTopics = sectionId => {
    history.push(`secao/${sectionId}`);
  };

  const fetchSectionsByName = async name => {
    try {
      setLoading(true);
      const response = await getSectionsByName(name, offset);

      setSections(response.sections);

      setPageCount(getPageCount(response.total, SECTIONS_FORUM_LIMIT));
    } catch {
      toast.error('Ocorreu um erro ao buscar as seções com este título.');
    } finally {
      setLoading(false);
    }
  };

  const handleSearchByTitle = debounce(event => {
    fetchSectionsByName(event.target.value);
  }, 500);

  const onDeleteSection = section_id => {
    setSections(state =>
      state.filter(section => section.section_id !== section_id),
    );
  };

  useEffect(() => {
    async function fetchSections() {
      setLoading(true);
      try {
        const response = await getAllSections(offset);

        setSections(response.sections);

        setPageCount(getPageCount(response.total, SECTIONS_FORUM_LIMIT));
      } catch {
        toast.error('Ocorreu um erro ao buscar as seções, tente mais tarde.');
      } finally {
        setLoading(false);
      }
    }

    fetchSections();
  }, [offset]);

  return (
    <Container>
      <Header>
        <Overlay>
          <Breadcrumbs
            className="hidden-mobile"
            style={{ paddingLeft: '30px', paddingBottom: '10px' }}
            links={[{ name: 'Fórum' }]}
          />
          <BreadcrumbMobile className="hidden-desktop">
            <Link to="/home">
              <img src={backArrow} alt="Voltar" />
              <p>Início</p>
            </Link>
          </BreadcrumbMobile>
          <h1>
            Fórum
            <span>Fórum</span>
          </h1>
        </Overlay>
      </Header>

      <Content>
        <section>
          <h2>
            Quer bater um papo sobre o curso ou tirar dúvidas com os colegas?
          </h2>
        </section>

        <List>
          <ListHeader>
            <ListHeaderOptions>
              <span>Seções</span>
            </ListHeaderOptions>

            <SearchTopicsInput>
              <FiSearch />
              <input
                type="text"
                placeholder="Digite aqui o que você procura"
                onChange={handleSearchByTitle}
              />
            </SearchTopicsInput>
          </ListHeader>

          {loading && (
            <div className="spinner">
              <Spinner />
            </div>
          )}

          {!loading && (
            <ul>
              {sections.map(section => (
                <SectionCard
                  onDeleteTopic={onDeleteSection}
                  onClick={() => handleGoToSectionTopics(section.section_id)}
                  data={section}
                />
              ))}
            </ul>
          )}
        </List>
      </Content>

      <Pagination>
        <ReactPaginate
          initialPage={0}
          forcePage={offset / SECTIONS_FORUM_LIMIT}
          previousLabel={<BiChevronLeft />}
          nextLabel={<BiChevronRight />}
          nextClassName="nextButton"
          previousClassName="previousButton"
          previousLinkClassName="previousContainerButton"
          nextLinkClassName="nextContainerButton"
          containerClassName="pagination"
          disabledClassName="disabledButtons"
          activeLinkClassName="activeLink"
          pageClassName="page"
          breakClassName="page"
          breakLabel="..."
          pageCount={pageCount}
          marginPagesDisplayed={1}
          onPageChange={page =>
            setOffset(
              (page.selected + 1) * SECTIONS_FORUM_LIMIT - SECTIONS_FORUM_LIMIT,
            )
          }
        />
      </Pagination>
    </Container>
  );
};

export default Forum;
