import styled from 'styled-components';

import { theme } from '../../../../styles/theme';

export const Container = styled.div`
  background: ${theme.colors.light};
  padding: 32px 24px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px 20px 00;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: fit-content;
  width: 100%;
  position: absolute;

  & > svg {
    font-size: 1rem;
    fill: ${theme.colors.secondary};
    position: absolute;
    top: 32px;
    right: 24px;
    cursor: pointer;
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-bottom: 20px;
  }

  h1 {
    margin-top: 20px;
    margin-bottom: 8px;
    color: ${theme.colors.secondary};
    font-weight: 700;
    font-size: 1.5rem;
  }

  h2 {
    margin-top: 8px;
    color: ${theme.colors.textSecondary};
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
  }

  hr {
    height: 1px;
    width: 100%;
    margin: 32px 0;
    background: rgba(0, 0, 0, 0.3);
  }

  h3 {
    margin-top: 8px;
    margin-bottom: ${({ step }) => (step !== 2 ? '50px' : '8px')};
    color: ${theme.colors.text};
    font-weight: 400;
    font-size: 1.2rem;
    text-align: center;
  }
`;

export const Step = styled.span`
  font-weight: 600;
  color: ${theme.colors.textDisabled};
  position: absolute;
  top: 32px;
  left: 24px;
`;

export const SpinnerWrapper = styled.div`
  margin-top: ${({ distance }) => `${distance}px`};
`;
