import { yupResolver } from '@hookform/resolvers/yup';
import { format, isToday } from 'date-fns';
import React, { useRef, useState, useLayoutEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineLike, AiFillLike } from 'react-icons/ai';
import { BsReplyFill } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import * as yup from 'yup';

import AvatarFallback from '../../../../../../../assets/images/avatar-fallback.png';
import { Button } from '../../../../../../../components/Button';
import { Input } from '../../../../../../../components/Input';
import Spinner from '../../../../../../../components/Spinner';
import { useAuth } from '../../../../../../../hooks/auth';
import {
  sendTopicAnswer,
  sendLike,
  deleteAnswer,
  deleteLike,
} from '../../../../../../../services/forum';
import { theme } from '../../../../../../../styles/theme';

import {
  Container,
  Answer,
  UserInfo,
  UserName,
  Published,
  ActionIcons,
  AnswerContainer,
  TrashIcon,
  GamificationBadge,
} from './styles';

const schema = yup.object().shape({
  text: yup.string().required('Escreva uma resposta para enviar!'),
});

const ListCommentItem = ({
  topic_answer_id,
  data,
  addNewAnswer,
  onDeleteAnswer,
}) => {
  const { user } = useAuth();

  const [like, setLike] = useState(
    data?.likes?.find(liked => liked.user_id === user.user_id),
  );
  const [answerContainerVisible, setVisibleAnswerContainer] = useState(false);

  const answerContainerRef = useRef();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      text: data.user.name,
    },
  });
  const toggleAnswerContainer = () => {
    setVisibleAnswerContainer(state => !state);
  };

  const handleToggleLike = async () => {
    if (like) {
      setLike(null);
      return deleteLike(like?.like_id);
    }

    return sendLike(data?.topic_answer_id).then(res => {
      setLike(res);
    });
  };

  const handleAnswer = async formData => {
    try {
      const newAnswer = await sendTopicAnswer(
        { topic_answer_answered_id: topic_answer_id },
        formData,
      );
      addNewAnswer(newAnswer);
      toggleAnswerContainer();
      toast.success('Resposta enviada');
    } catch (err) {
      if (err?.response?.status === 403) {
        toast.warn(err?.response?.data?.message);
        return;
      }

      toast.error('Ocorreu um erro ao enviar sua resposta');
    }
  };

  const fetchDeleteAnswer = async () => {
    try {
      await deleteAnswer(data.topic_answer_id);

      toast.success('Comentário excluído com sucesso');
      onDeleteAnswer(data.topic_answer_id);
    } catch {
      toast.error('Ocorreu um erro ao tentar deletar esse comentário');
    }
  };

  const handleAdminDeleteComment = async event => {
    event.stopPropagation();

    const result = await Swal.fire({
      icon: 'warning',
      iconColor: theme.colors.warning,
      title: 'Deseja confirmar a exclusão deste comentário?',
      text: 'Essa ação não poderá ser desfeita.',
      showCancelButton: true,
      confirmButtonColor: theme.colors.warning,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    });

    if (!result.isConfirmed) return;

    await fetchDeleteAnswer();
  };

  const itemNotCreatedByLoggedUser = data.user_id !== user.user_id;

  const isModerator = user.roles.some(role => role === 'Moderator');

  useLayoutEffect(() => {
    if (!answerContainerVisible) return;

    const ulElement = answerContainerRef?.current?.parentNode?.parentNode;
    const liElement = answerContainerRef?.current?.parentNode;

    ulElement.scrollTo({ top: liElement.offsetTop, behavior: 'smooth' });
  }, [answerContainerVisible]);

  return (
    <Container>
      {data?.media && (
        <img src={data?.media} alt="Imagem enviada pelo usuário da resposta" />
      )}

      <Answer>{data.text}</Answer>

      <UserInfo>
        <img src={data.user?.images?.avatar || AvatarFallback} alt="Avatar" />
        <UserName>
          {itemNotCreatedByLoggedUser ? data.user.name : 'Eu'}
        </UserName>
        <GamificationBadge>{data.user?.level?.name}</GamificationBadge>
        <Published>
          {isToday(new Date(data.created_at))
            ? `às ${format(new Date(data.created_at), 'HH:mm')}`
            : `em ${format(new Date(data.created_at), 'dd/MM/yy')}`}
        </Published>
      </UserInfo>

      <ActionIcons>
        <span
          role="button"
          tabIndex="0"
          onKeyPress={toggleAnswerContainer}
          onClick={toggleAnswerContainer}
        >
          <BsReplyFill class="invert-orientation" /> Citar
        </span>

        {itemNotCreatedByLoggedUser && (
          <span
            role="button"
            tabIndex="0"
            onKeyPress={handleToggleLike}
            onClick={handleToggleLike}
          >
            <span>
              {like ? <AiFillLike /> : <AiOutlineLike />}
              Curtir
            </span>
          </span>
        )}

        {(user.is_admin || isModerator || data.user_id === user.user_id) && (
          <TrashIcon
            role="button"
            tabIndex="0"
            onKeyPress={handleAdminDeleteComment}
            onClick={handleAdminDeleteComment}
          >
            <FaTrash />
            Excluir
          </TrashIcon>
        )}
      </ActionIcons>

      {answerContainerVisible && (
        <AnswerContainer
          ref={answerContainerRef}
          onSubmit={handleSubmit(handleAnswer)}
        >
          <img src={user?.images?.avatar || AvatarFallback} alt="Avatar" />

          <Input
            wrapperClassName="input"
            containerStyle={{
              padding: '12px 16px',
            }}
            placeholder="Escreva aqui seu comentário..."
            isInvalid={errors.text?.message}
            errorMessage={errors.text?.message}
            {...register('text')}
            value={watch().text}
          />

          {isSubmitting && (
            <div className="spinner">
              <Spinner />
            </div>
          )}

          {!isSubmitting && (
            <Button
              title="ENVIAR"
              type="submit"
              containerStyle={{
                marginLeft: 19,
                maxWidth: 150,
                minWidth: 150,
              }}
            />
          )}
        </AnswerContainer>
      )}
    </Container>
  );
};

export default ListCommentItem;
