import { validate } from 'gerador-validador-cpf';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import 'animate.css';
import Swal from 'sweetalert2';

import PaymentModal from './components/CardCourseInfo/components/PaymentModal';
import { ToastComponent } from './components/Toast/Toast';
import WhatsApp from './components/WhatsApp';
import WindowSizeContextProvider from './components/WindowSizeContextProvider';
import AppProvider from './hooks';
import Routes from './routes';
import { getUserPayments } from './services/plans';
import { UserService } from './services/userService';
import GlobalStyle from './styles/global';
import { theme } from './styles/theme';

const App = () => {
  const history = useHistory();
  const unloggedPaths = useMemo(() => ['/', '/logar', '/registrar'], []);

  const userHasToUpdateCustomer = useCallback(async () => {
    const userStorage = localStorage.getItem('@HaluGamashi:user');
    const parsedUserObject = JSON.parse(userStorage);

    if (!userStorage) return;
    if (parsedUserObject.document !== null) return;

    const userService = new UserService();

    const { data } = await getUserPayments();
    const transactionRequireDocument = data.some(
      transaction =>
        transaction.payment_type === 'credit_card' &&
        transaction.status === 'paid',
    );

    if (!transactionRequireDocument) return;

    Swal.fire({
      title: 'Um momento, por favor!',
      text: 'Antes de continuar utilizando o IMA, precisamos atualizar o número do seu CPF',
      icon: 'warning',
      confirmButtonText: 'Pronto!',
      cancelButtonText: 'Não possuo CPF!',
      showCancelButton: true,

      allowEscapeKey: false,
      allowOutsideClick: () => {
        const popup = Swal.getPopup();
        popup.classList.remove('swal2-show');
        setTimeout(() => {
          popup.classList.add('animate__animated', 'animate__headShake');
        });
        setTimeout(() => {
          popup.classList.remove('animate__animated', 'animate__headShake');
        }, 500);
        return false;
      },

      input: 'text',
      inputLabel: 'Informe o seu CPF',
      inputPlaceholder: 'Ex: 000.000.000-00',

      showLoaderOnConfirm: true,
      preConfirm: async document => {
        const formattedDocument = document.replace(/[^0-9]/g, '');

        if (!validate(formattedDocument)) {
          return Swal.showValidationMessage('CPF inválido');
        }

        return userService.updateUser({
          name: parsedUserObject.name,
          document: formattedDocument,
        });
      },
    })
      .then(result => {
        if (result.isConfirmed) {
          localStorage.setItem(
            '@HaluGamashi:user',
            JSON.stringify(result.value.data),
          );

          Swal.fire({
            title: 'Obrigado!',
            text: 'Continue utilizando o IMA.',
            icon: 'success',
          });
        }

        if (result.isDismissed) {
          Swal.fire({
            title: 'Desculpe o inconveniente!',
            text: 'Continue utilizando o IMA.',
            icon: 'success',
          });

          userService
            .updateUser({
              name: parsedUserObject.name,
              document: ' ',
            })
            .then(res => {
              localStorage.setItem(
                '@HaluGamashi:user',
                JSON.stringify(res.data),
              );
            });
        }
      })
      .catch(() => {
        Swal.fire({
          title: 'Sistema indisponível',
          text: 'Existe uma indisponibilidade no sistema, tentaremos novamente mais tarde.',
          icon: 'error',
        });
      });
  }, []);

  useEffect(() => {
    history.listen(location => {
      if (unloggedPaths.includes(location.pathname)) return;

      userHasToUpdateCustomer();
    });
  }, [userHasToUpdateCustomer, history, unloggedPaths]);

  useEffect(() => {
    if (unloggedPaths.includes(window.location.pathname)) return;

    userHasToUpdateCustomer();
  }, [userHasToUpdateCustomer, unloggedPaths]);

  return (
    <WindowSizeContextProvider>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <Routes />
          <GlobalStyle />
          <PaymentModal />
        </AppProvider>
        <ToastComponent />
        <WhatsApp />
      </ThemeProvider>
    </WindowSizeContextProvider>
  );
};

export default App;
