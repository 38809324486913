import styled from 'styled-components';

const ResponsivePlayerContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding: -2px !important;
  display: flex;

  & [id*='responsive-player_'] {
    padding: 0 !important;
    margin: 0 !important;
    flex: 1;
    overflow: hidden;
    border-radius: 8px;
    max-width: 1200px;
  }
`;

export { ResponsivePlayerContainer };
