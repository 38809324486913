import styled, { css } from 'styled-components';

const HomeBannerContainer = styled.div`
  ${props =>
    css`
      background: url(${props.background});
      background-size: cover;
      background-position: center;
    `}
  position: relative;
`;

export const ImageFilter = styled.small`
  background: linear-gradient(
    90deg,
    rgba(89, 74, 173, 0.83) -5.53%,
    rgba(159, 151, 201, 0.3818) 60.04%,
    rgba(47, 24, 156, 0.83) 131.32%
  );
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;


const HomeBannerContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  color: white;

  padding: 0 32px 48px 32px;
  padding-top: 240px;

  @media (min-width: 840px) {
    padding-top: 144px;
  }
`;

const HomeBannerLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  flex: 1;

  @media (max-width: 768px) {
    gap: 16px;
  }

  h2 {
    font-size: 2em;
    text-align: center;
  }

  p {
    font-size: 1.6em;
    font-weight: 500;
    text-shadow: rgb(24 39 75 / 12%) 0px 2px 4px, rgb(24 39 75 / 8%) 0px 4px 4px;
    text-align: center;
  }

  > button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 8px 32px;
    background: var(--default-blue);
    border-radius: 24px;
    height: 48px;
    max-width: 400px;

    font-size: 0.9em;
    font-weight: 600;
    color: white;
  }
`;

const HomeBannerRightContent = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 500px;
`;

export {
  HomeBannerRightContent,
  HomeBannerLeftContent,
  HomeBannerContainer,
  HomeBannerContent,
};
