import styled from 'styled-components';

import { theme } from '../../styles/theme';

export const Overlay = styled.div`
  &:before {
    content: '';
    display: ${({ open }) => (open ? 'block' : 'none')};
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
  }
`;

export const Container = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  background: ${theme.colors.light};
  max-width: 80%;
  z-index: 210;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ul {
    max-height: 275px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    > span {
      align-self: center;
    }

    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      background: #e1e3e6;
    }
    ::-webkit-scrollbar-thumb {
      background: #2f189c;
      border-radius: 10px;
    }

    li:nth-child(odd) {
      background: #f5f8fa;
    }

    li {
      padding: 26px 48px;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }

  @media (max-width: 500px) {
    ul {
      li {
        padding: 16px;
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 48px 48px;
  z-index: 210;

  h1 {
    font-size: 2rem;
    font-weight: bold;
    color: ${theme.colors.secondary};
    text-align: center;
    margin: 8px;
  }

  h2 {
    font-size: 1.2rem;
    font-weight: 500;
    color: ${theme.colors.textSecondary};
    text-align: center;
    margin: 8px;
  }

  @media (max-width: 1100px) {
    padding: 16px;

    h1 {
      font-size: 1.2rem;
    }

    h2 {
      font-size: 1rem;
    }
  }
`;

export const BuyDate = styled.div`
  span {
    font-size: 0.8rem;
    color: #909599;
    font-weight: 500;
  }

  p {
    color: ${theme.colors.text};
    margin-top: 2px;
    font-weight: 500;
  }

  @media (max-width: 1100px) {
    span {
      font-size: 0.6rem;
    }

    p {
      font-size: 0.8rem;
    }
  }
`;

export const Description = styled.p`
  flex: 1;
  text-align: start;
  font-size: 1.2rem;
  color: ${theme.colors.text};
  font-weight: 500;
  white-space: nowrap;
  max-height: 40px;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1100px) {
    margin: 0 16px;
    font-size: 0.9rem;
    margin-bottom: 0;
  }
`;

export const Value = styled.span`
  font-weight: 500;

  @media (max-width: 1100px) {
    font-size: 0.9rem;
  }
`;

export const ButtonContainer = styled.div`
  padding: 40px 44px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  @media (max-width: 1100px) {
    padding: 32px;
  }

  @media (max-width: 500px) {
    padding: 20px;
  }
`;
