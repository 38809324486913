import styled from 'styled-components';

import inProgressBanner from '../../assets/images/in-progress-banner.png';
import { theme } from '../../styles/theme';

export const Container = styled.div``;

export const Header = styled.section`
  background: url(${inProgressBanner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  object-fit: contain;
  @media (max-width: 480px) {
    margin-bottom: 30px;
  }

  h1 {
    padding-left: 63px;
    font-size: 7rem;
    color: ${theme.colors.textLight};
    position: relative;
    z-index: 1;

    span {
      color: ${theme.colors.text};
      position: absolute;
      font-size: 8rem;
      opacity: 0.2;
      bottom: 10px;
      left: 24px;
      z-index: -1;
    }
  }

  @media (max-width: 1100px) {
    h1 {
      font-size: 2rem;
      z-index: 0;

      span {
        font-size: 5rem;
      }
    }
  }

  @media (max-width: 700px) {
    h1 {
      font-size: 2rem;

      span {
        font-size: 3rem;
      }
    }
  }
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  padding: 192px 24px 24px 24px;
  background: linear-gradient(
    90deg,
    rgba(89, 74, 173, 0.83) -5.53%,
    rgba(159, 151, 201, 0.3818) 60.04%,
    rgba(47, 24, 156, 0.83) 131.32%
  );

  @media (max-width: 650px) {
    padding: 80px 24px 24px 24px;
  }

  @media (max-width: 480px) {
    h1 {
      font-weight: bold;
      font-size: 40px;
      padding: 0;
    }

    span {
      font-size: 36px;
    }
  }
`;

export const BreadcrumbMobile = styled.span`
  display: flex;
  margin-bottom: 20px;

  a {
    display: flex;
    text-decoration: unset;

    img {
      padding-top: 2px;
      margin-right: 24px;
    }

    p {
      color: #fff;
      font-weight: 600;
      font-size: 16px;
    }
  }
`;

export const Content = styled.main`
  align-self: center;
  padding: 64px 187px;

  p {
    padding-top: 10px;
  }
  section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 2.1rem;
      font-weight: 400;
      color: ${theme.colors.textSecondary};
      margin-right: 25px;
    }
  }

  @media (max-width: 1100px) {
    section {
      h2 {
        font-size: 1.5rem;
      }
    }
  }

  @media (max-width: 900px) {
    padding: 32px 93px;

    section {
      h2 {
        font-size: 1rem;
      }
    }
  }

  @media (max-width: 650px) {
    section {
      flex-direction: column;

      h2 {
        margin-bottom: 16px;
        text-align: center;
        margin-right: 0;
      }
    }
  }

  @media (max-width: 500px) {
    padding: 16px 25px;
  }
`;
