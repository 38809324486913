import { isPast } from 'date-fns';
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../services/api';
import { AuthService } from '../services/authService';
import { UserService } from '../services/userService';
import { parseJwt } from '../utils/parseJwt';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const history = useHistory();

  const [data, setData] = useState({});

  const login = useCallback(async ({ email, password, keepLoggedIn }) => {
    const userService = new UserService();
    const authService = new AuthService();

    const response = await authService.login({ email, password, keepLoggedIn });
    const { api_token } = response;

    api.defaults.headers.authorization = `Bearer ${response.api_token}`;

    const { data: user } = await userService.getUserData();

    localStorage.setItem('@HaluGamashi:token', api_token);
    localStorage.setItem('@HaluGamashi:user', JSON.stringify(user));

    setData({ token: api_token, user });
  }, []);

  const socialLogin = useCallback(async ({ accessToken, tokenId }) => {
    const authService = new AuthService();

    const response = await authService.socialLogin({
      accessToken,
      tokenId,
    });

    const { token, user } = response.data;

    localStorage.setItem('@HaluGamashi:token', token);
    localStorage.setItem('@HaluGamashi:user', JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token}`;
    setData({ token, user });
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('@HaluGamashi:token');
    localStorage.removeItem('@HaluGamashi:user');
    api.defaults.headers.authorization = `Bearer ${process.env.REACT_APP_UNLOGGED_TOKEN}`;
    setData({});
  }, []);

  const storeUser = useCallback((user, token) => {
    if (token) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      localStorage.setItem('@HaluGamashi:token', token);
      setData(state => ({ ...state, token }));
    }
    if (user) {
      localStorage.setItem('@HaluGamashi:user', JSON.stringify(user));
      setData(state => ({ ...state, user }));
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('@HaluGamashi:token');
    const user = localStorage.getItem('@HaluGamashi:user');

    if (!token) return;

    const jwtData = parseJwt(token);

    const expirationDate = new Date(jwtData.exp * 1000);

    if (
      isPast(expirationDate) &&
      window.location.pathname !== '/logar' &&
      window.location.pathname !== '/registro' &&
      window.location.pathname !== '/confirmar-cadastro' &&
      window.location.pathname !== '/'
    ) {
      toast.warning('Sua sessão expirou, faça login novamente.');
      history.push('/logar');
      api.defaults.headers.authorization = `Bearer ${process.env.REACT_APP_UNLOGGED_TOKEN}`;
      return;
    }

    if (user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      setData({ token, user: JSON.parse(user) });
    }
  }, [history]);

  return (
    <AuthContext.Provider
      value={{
        token: data.token,
        user: data.user,
        login,
        logout,
        socialLogin,
        storeUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth should be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
