import styled from 'styled-components';

import { theme } from '../../styles/theme';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0 16px;

  .spinner {
    margin: 0 auto;
    margin-top: 32px;
  }

  h1,
  h2 {
    color: ${theme.colors.textLight};
    text-align: center;
  }

  h1 {
    margin-bottom: 40px;
    margin-top: 8px;
    padding: 0 24px;
    font-size: 2.4rem;
  }

  h2 {
    margin-bottom: 8px;
    margin-top: 40px;
    font-weight: normal;
  }

  h6 {
    margin: 40px auto;
    text-align: center;
    font-size: 1.3rem;
    color: ${theme.colors.secondary};
  }

  @media (max-width: 900px) {
    height: auto;
  }

  @media (max-width: 480px) {
    h1 {
      font-weight: 600;
      font-size: 32px;
    }

    h2 {
      font-size: 16px;
    }
  }
`;

export const PaymentFormContainer = styled.div`
  padding: 32px 0;
  background: ${theme.colors.light};
  border-radius: 20px;
  max-width: 750px;
`;

export const PaymentFormHeader = styled.div`
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid ${theme.colors.shapeSecondary};
  padding: 24px;
  padding-bottom: 32px;
`;

export const PaymentFormInfo = styled.div`
  p {
    font-weight: 500;
  }

  h3 {
    margin: 0;
    padding: 0;
    color: ${theme.colors.secondary};
    font-size: 2rem;
  }
`;

export const PaymentFormPrice = styled.div`
  margin-left: 32px;

  p {
    color: ${theme.colors.textSecondary};
  }

  h3 {
    font-size: 2rem;
    font-weight: 500;
  }
`;

export const PaymentForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0 24px;

  h4 {
    color: ${theme.colors.secondary};
    font-size: 1.5rem;
    font-weight: 600;
    margin: 32px 0 24px;
    text-align: center;
  }

  div.spinner {
    display: flex;
    justify-content: center;
  }

  > svg {
    color: ${theme.colors.primary};
    width: 40px;
    height: 40px;
    margin: 0 auto;
    margin-bottom: 24px;
    align-self: center;
  }

  > span {
    text-align: center;
    margin-bottom: 24px;
  }

  div {
    svg {
      color: rgb(144, 149, 153);
    }
  }
`;

export const RowInputs = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 26px;
`;

export const SelectContainer = styled.div`
  width: 100%;
  z-index: 200;

  /* Border of ReactSelect */
  span + span + div {
    border: 1px solid
      ${({ error }) =>
        error ? theme.colors.error : theme.colors.shapeSecondary};
  }
`;

export const SelectCustomStyle = {
  container: provided => ({
    ...provided,
    width: '100%',
  }),
  control: provided => ({
    ...provided,
    width: '100%',
    borderRadius: 20,
    padding: '8px 12px',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
    display: 'flex',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  option: provided => ({
    ...provided,
    maxWidth: 397,
    borderRadius: 20,
  }),
};

export const ErrorMessage = styled.span`
  display: block;
  font-size: 0.8rem;
  color: ${theme.colors.error};
  text-align: start;
  width: 100%;
  margin-top: 8px;
  margin-left: 10px;
`;

export const SelectFormOfPayment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 540px) {
    min-width: 600px;
    flex-direction: row;
    justify-content: space-around;
  }
`;

export const SelectFormOption = styled.div`
  display: flex;
  width: 200px;
  margin: 8px 0;

  input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    display: inline-block;

    width: 25px;
    height: 25px;
    padding: 3px;

    background-clip: content-box;
    border: 2px solid #25282b;
    background-color: #e7e6e7;
    border-radius: 50%;

    cursor: pointer;
  }

  input[type='radio']:checked {
    background-color: #594aad;
    border: 2px solid #594aad;
  }

  label {
    align-self: center;
    margin-left: 8px;

    color: #25282b;
    font-size: 16px;
    font-weight: 600;
  }

  @media (min-width: 540px) {
    width: max-content;
  }
`;

export const TicketNumberCamp = styled.div`
  display: flex;
  border-radius: 62px;
  background: #594aad;
  width: 90%;
  height: 64px;
  align-items: center;
  margin-bottom: 40px;

  span,
  a {
    text-align: center;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    max-width: 100%;
  }

  img {
    cursor: pointer;
  }
`;

export const ViewBillet = styled.div`
  display: flex;
  border-radius: 62px;
  background: #2f189c;
  height: 64px;
  align-items: center;
  margin-bottom: 40px;
  padding: 0 100px;
  cursor: pointer;

  a {
    text-align: center;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    max-width: 100%;
  }

  img {
    padding-right: 12px;
  }
`;

export const ViewPayPalCamp = styled.div`
  display: flex;
  flex-direction: column;
  height: 64px;
  align-items: center;
  padding: 0 100px;
  cursor: pointer;
  margin-bottom: 40px;

  h5 {
    margin-bottom: 20px;
    font-size: 1rem;
  }

  a {
    border-radius: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    text-align: center;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    max-width: 100%;
    background: #2f189c;
    min-width: 400px;
  }

  img {
    padding-right: 12px;
    height: 30px;
  }
`;

export const PixContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;

  p {
    text-align: center;
    font-size: 0.9rem;
    margin-bottom: 24px;
  }

  iframe {
    width: 100%;
    height: 200px;
    margin-bottom: 24px;
  }

  h5 {
    font-weight: 600;
    font-size: 24px;
    color: #2f189c;
    padding-bottom: 24px;
  }

  img {
    width: 100%;
    max-width: 120px;
  }
`;

export const PixCopyAndPasteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  margin-bottom: 32px;

  p {
    font-size: 0.9rem;
    font-weight: 600;
  }

  p + p {
    text-transform: uppercase;
  }

  a {
    color: var(--default-blue);
    font-size: 0.9rem;
    font-weight: 500;
    text-decoration: none;
    font-style: italic;
  }

  .spinner {
    margin-top: 16px;
  }
`;

export const PixCopyAndPaste = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  min-width: 200px;
  max-width: 400px;
  margin-bottom: 24px;

  > p {
    font-size: 0.9rem;
    color ${theme.colors.primary};
    font-weight: 600;
    margin-top: 32px;
    margin-bottom: 16px;
    text-align: center;
  }

  > textarea {
    background-color ${theme.colors.textDisabled};
    color ${theme.colors.text};
    padding: 8px;
    border-radius: 4px;

    text-align: center;

    height: 140px;
    width: 100%;
    max-width: 260px;
    resize: none;
  }

  > button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 8px 16px;
    background: var(--default-blue);
    border-radius: 24px;
    width: 100%;
    max-width: 140px;
    margin-top: 16px;

    font-size: 0.8em;
    font-weight: 600;
    color: white;
  }
`;

export const FormInputs = styled.div`
  padding: 0 20px 36px;

  label {
    cursor: pointer;
  }

  input[type='checkbox'] {
    cursor: pointer;
    margin-right: 8px;
  }
`;

export const ContainerDebitCreditCard = styled.div`
  background-color: #ffcc00;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 10px 10px;
`;

export const LabelDebitCreditCard = styled.p`
  font-size: 16px;
`;
