import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 25px;
  margin: 20px;
  background: #ffffff;
  @media screen and (min-width: 701px){
    width: 600px;
    height: 300px;
  }
  @media (max-width: 700px) {
    min-width: 100%;
    max-width: 100%;
    margin: 30px 0 30px 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: 100%;
  position: relative;

  > span {
    background-color: #2f189c;
    color: #2f189c;
    height: 12px;
    width: 100%;
    border-radius: 20px 20px 0 0;
  }

  label {
    margin: 0 0 24px 24px;
    @media (max-width: 480px) {
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0 24px 0;
    }
  }
`;

export const CardText = styled.div`
  width: 100%;
  flex: 1;
  position: relative;
  padding: 10px 20px 8px 24px;
  background: #FFFFFF;
  border-radius: 0 0 6px 6px;
  
  h2 {
    font-weight: bold;
    font-size: 32px;
    align-items: center;
    color: #25282B;
    padding-top: 20px;
     }

  > p {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #25282b;
    padding: 18px;
      }
}
`;
