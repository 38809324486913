import React, { useState, useEffect } from 'react';

import { Button } from '../../../../components/Button';
import { CodeVerificationInput } from '../../../../components/CodeVerificationInput';
import Spinner from '../../../../components/Spinner';

import { Container, SecurityCodeContainer } from './styles';

const SecurityCode = ({
  sendRequestToConfirm,
  email,
  loading,
  emailDelay,
  setEmailDelay,
  resendCode,
  setCode,
}) => {
  const [counter, setCounter] = useState(60);

  useEffect(() => {
    const timer =
      counter > 0 &&
      emailDelay &&
      setInterval(() => setCounter(counter - 1), 1000);

    if (counter === 0) {
      setCounter(10);
      setEmailDelay(false);
    }

    return () => clearInterval(timer);
  }, [counter, emailDelay, setEmailDelay]);

  return (
    <Container>
      <h3>Código de segurança</h3>
      <p>
        Digite o código de segurança enviado para{' '}
        <b>{email || 'seu email informado anteriormente'}</b>.
      </p>
      <p>Seu código possui 6 dígitos.</p>

      <form>
        <SecurityCodeContainer>
          <CodeVerificationInput onChange={value => setCode(value)} />
        </SecurityCodeContainer>

        {counter !== 0 && emailDelay && (
          <p className="counter-message">
            Ainda não recebeu o email? Aguarde
            <span
              style={{
                color: '#2F189C',
                fontWeight: '600',
                paddingLeft: '5px',
              }}
            >
              {counter}
            </span>
          </p>
        )}

        {counter !== 0 && !emailDelay && (
          <button
            type="button"
            aria-hidden="true"
            onClick={resendCode}
            className="resend-button"
            disabled={emailDelay}
          >
            Reenviar código por email
          </button>
        )}

        {loading ? (
          <div className="spinner">
            <Spinner />
          </div>
        ) : (
          <Button
            title="CONTINUAR"
            type="button"
            onClick={sendRequestToConfirm}
            containerStyle={{
              margin: '0 auto',
            }}
          />
        )}
      </form>
    </Container>
  );
};

export { SecurityCode };
