import React, { useState } from 'react';
import { AiOutlinePlusCircle, AiFillMinusCircle } from 'react-icons/ai';

import { Container, Content, Question, Answer } from './style';

const FAQItem = ({ id, question, answer }) => {
  const [collapse, setCollapse] = useState(false);

  const changeCollapse = () => {
    setCollapse(!collapse);
  };

  return (
    <Container key={id}>
      {collapse ? (
        <AiFillMinusCircle onClick={changeCollapse} size={26} color="#2F189C" />
      ) : (
        <AiOutlinePlusCircle
          onClick={changeCollapse}
          size={26}
          color="#2F189C"
        />
      )}
      <Content>
        <Question>{question}</Question>
        {collapse ? <Answer>{answer}</Answer> : <></>}
      </Content>
    </Container>
  );
};

export default FAQItem;
