/* eslint-disable no-param-reassign */
import { differenceInDays, isPast } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ReactComponent as ChevronArrowLeft } from '../../assets/icons/arrow-left.svg';
import { ReactComponent as ChevronArrowRight } from '../../assets/icons/arrow-right.svg';
import CircleImg from '../../assets/icons/circle.svg';
import Fire from '../../assets/icons/fire.svg';
import BannerItem from '../../components/BannerItem';
import CardCourse from '../../components/CardCourse';
import CardCourseSkeleton from '../../components/CardCourseSkeleton';
import CardMasters from '../../components/CardMasters';
import CarouselResponsiveHomeLogged from '../../components/CarouselResponsiveHomeLogged';
import HorizontalScroll from '../../components/HorizontalScroll';
import Spinner from '../../components/Spinner';
import { useAuth } from '../../hooks/auth';
import useCardCourse from '../../hooks/cardCourse';
import * as AscendedMasterService from '../../services/ascendedMastersService';
import { getContentNextCourses } from '../../services/Content';
import { ContentsService } from '../../services/contentsService';
import { getUserPayments } from '../../services/plans';
import { UserService } from '../../services/userService';
import { goToTop } from '../../utils/helpers';
import { parseJwt } from '../../utils/parseJwt';

import { Container, SpinnerWrapper } from './styles';

const HomeLogged = () => {
  const [highlightedContents, setHighlightedContents] = useState([]);
  const [inProgressContents, setInProgressContents] = useState([]);
  const [loadingInProgressContents, setLoadingInProgressContents] =
    useState(true);
  const [mostWatchedContents, setMostWatchedContents] = useState([]);
  const [recommendedContents, setRecommendedContents] = useState([]);

  const [ascendedMasters, setAscendedMasters] = useState([]);

  const [loadingNextCourses, setLoadingNextCourses] = useState(false);
  const [nextCourses, setNextCourses] = useState([]);

  const [loadingAscendedMasters, setLoadingAscendedMasters] = useState(false);
  const { getCardCourseData } = useCardCourse();
  const cardCourseHook = useCardCourse();
  const history = useHistory();

  const { token } = useAuth();

  const recoverStatusLastTransactions = useCallback(async () => {
    const { data } = await getUserPayments();

    const lastTransaction = data.find(transaction => transaction.content_id);

    if (
      lastTransaction &&
      lastTransaction?.status === 'refused' &&
      differenceInDays(new Date(), new Date(lastTransaction?.start_at)) <= 1
    ) {
      toast.error(
        '⚠️ Sua última compra foi recusada, por favor verifique seu email para tentar novamente, ou entre em contato com a administradora do cartão',
        {
          autoClose: false,
          position: 'bottom-right',
        },
      );
    }

    if (
      lastTransaction &&
      lastTransaction?.status === 'processing' &&
      differenceInDays(new Date(), new Date(lastTransaction?.start_at)) <= 1
    ) {
      toast.info('⚠️ Sua última compra esta sendo processada.', {
        autoClose: false,
        position: 'bottom-right',
      });
    }
  }, []);

  useEffect(() => {
    let userCoursesIds = [];

    if (!token) return;

    const jwtData = parseJwt(token);
    const expirationDate = new Date(jwtData.exp * 1000);

    if (!localStorage.getItem('@HaluGamashi:user') || isPast(expirationDate)) {
      toast.warning('Sua sessão expirou, faça login novamente.');
      history.push('/logar');
      return;
    }

    (async () => {
      const userService = new UserService();

      await userService.getContentsPurchaseIds().then(res => {
        userCoursesIds = res.data;
      });
    })();

    (async () => {
      try {
        const contentsService = new ContentsService();
        const response = await contentsService.searchHighlightedContents();
        setHighlightedContents(
          response.data.map(item =>
            cardCourseHook.getCardCourseData(item, userCoursesIds),
          ),
        );
      } catch {
        toast.error(
          'Ocorreu um erro ao buscar os conteúdos em destaque, recarregue a página.',
        );
      }
    })();

    (async () => {
      try {
        const contentsService = new ContentsService();
        const response = await contentsService.searchMostWatchedContents();

        setMostWatchedContents(
          response.data.map(item =>
            cardCourseHook.getCardCourseData(item, userCoursesIds),
          ),
        );
      } catch (err) {
        toast.error(
          'Ocorreu um erro ao buscar os conteúdos em mais assistidos, recarregue a página.',
        );
      }
    })();

    (async () => {
      try {
        const contentsService = new ContentsService();
        const response = await contentsService.searchRecommendedContents();
        setRecommendedContents(
          response.data.map(item =>
            cardCourseHook.getCardCourseData(item, userCoursesIds),
          ),
        );
      } catch {
        toast.error(
          'Ocorreu um erro ao buscar os conteúdos recomendados, recarregue a página.',
        );
      }
    })();

    (async () => {
      try {
        setLoadingInProgressContents(true);
        const contentsService = new ContentsService();
        const response = await contentsService.searchContents({
          in_progress: true,
          limit: 15,
          ...{
            flag: ['course', 'retreat'],
            type: ['curso', 'on-demand', 'retiro'],
            in_progress: true,
            relations: [
              'reviews',
              'content_user',
              // 'progress',
              'authors',
              'tags',
              'categories',
            ],
          },
        });
        setInProgressContents(
          response.data.map(item =>
            cardCourseHook.getCardCourseData(item, userCoursesIds),
          ),
        );
      } catch {
        toast.error(
          'Ocorreu um erro ao buscar os conteúdos em progresso, recarregue a página.',
        );
      } finally {
        setLoadingInProgressContents(false);
      }
    })();

    (async () => {
      try {
        setLoadingAscendedMasters(true);
        await AscendedMasterService.getAll().then(response => {
          setAscendedMasters(response.data);
        });
      } catch {
        // teste
        toast.error(
          'Ocorreu um erro ao buscar os mestres ascensionados, recarregue a página.',
        );
      } finally {
        setLoadingAscendedMasters(false);
      }
    })();

    getContents(userCoursesIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    // recoverStatusLastTransactions()
  }, []);

  const getContents = useCallback(
    async userCoursesIds => {
      setLoadingNextCourses(true);
      const {
        data: { data: launchContents },
      } = await getContentNextCourses();
      setNextCourses(
        (launchContents || []).map(c => getCardCourseData(c, userCoursesIds)),
      );
      setLoadingNextCourses(false);
    },
    [getCardCourseData],
  );

  goToTop();

  return (
    <Container>
      <Carousel
        showStatus={false}
        autoPlay
        infiniteLoop
        interval={6000}
        renderIndicator={false}
        renderArrowPrev={clickHandler => {
          return (
            <button
              type="button"
              aria-label="previous slide / item"
              className="control-arrow"
              onClick={clickHandler}
            >
              <ChevronArrowLeft />
            </button>
          );
        }}
        renderArrowNext={clickHandler => {
          return (
            <button
              type="button"
              aria-label="next slide / item"
              className="control-arrow button_next"
              onClick={clickHandler}
            >
              <ChevronArrowRight />
            </button>
          );
        }}
      >
        {highlightedContents.length ? (
          highlightedContents.map(content => <BannerItem content={content} />)
        ) : (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        )}
      </Carousel>

      {!loadingInProgressContents && !inProgressContents.length ? null : (
        <HorizontalScroll
          className="hidden-mobile"
          icon={CircleImg}
          title="Em andamento"
          subtitle="Continue a assistir os conteúdos em andamento"
          link="/meus-cursos"
          linkText="VER MAIS"
          totalItems={inProgressContents.length}
        >
          {loadingInProgressContents
            ? [...Array(6).keys()].map(item => (
                <CardCourseSkeleton key={item} />
              ))
            : inProgressContents.map(item => (
                <div style={{ marginLeft: 24, marginRight: 24 }}>
                  <CardCourse data={item} />
                </div>
              ))}
        </HorizontalScroll>
      )}

      {!loadingInProgressContents && !inProgressContents.length ? null : (
        <CarouselResponsiveHomeLogged
          className="hidden-desktop"
          icon={CircleImg}
          title="Em andamento"
          subtitle="Veja os conteúdos mais assistidos"
          link="/meus-cursos"
          linkText="VER MAIS"
        >
          {loadingInProgressContents
            ? [...Array(6).keys()].map(item => (
                <CardCourseSkeleton key={item} />
              ))
            : inProgressContents.map(item => (
                <div style={{ marginLeft: 24, marginRight: 24 }}>
                  <CardCourse data={item} />
                </div>
              ))}
        </CarouselResponsiveHomeLogged>
      )}
      <HorizontalScroll
        className="hidden-mobile"
        icon={CircleImg}
        title="Mais assistidos"
        subtitle="Veja os conteúdos mais assistidos"
        totalItems={mostWatchedContents.length}
      >
        {mostWatchedContents.length
          ? mostWatchedContents.map(item => (
              <div style={{ marginLeft: 24, marginRight: 24 }}>
                <CardCourse data={item} />
              </div>
            ))
          : [...Array(6).keys()].map(item => <CardCourseSkeleton key={item} />)}
      </HorizontalScroll>

      <CarouselResponsiveHomeLogged
        className="hidden-desktop"
        title="Mais assistidos"
        subtitle="Veja os conteúdos mais assistidos"
        link="/meus-cursos"
        linkText="VER MAIS"
        indicators
      >
        {mostWatchedContents.length
          ? mostWatchedContents.map(item => (
              <div style={{ marginLeft: 24, marginRight: 24 }}>
                <CardCourse data={item} />
              </div>
            ))
          : [...Array(6).keys()].map(item => <CardCourseSkeleton key={item} />)}
      </CarouselResponsiveHomeLogged>

      <HorizontalScroll
        className="hidden-mobile"
        icon={CircleImg}
        title="Recomendados"
        subtitle="Veja os conteúdos recomendados"
        totalItems={recommendedContents.length}
      >
        {recommendedContents.length
          ? recommendedContents.map(item => (
              <div style={{ marginLeft: 24, marginRight: 24 }}>
                <CardCourse data={item} />
              </div>
            ))
          : [...Array(6).keys()].map(item => <CardCourseSkeleton key={item} />)}
      </HorizontalScroll>

      <CarouselResponsiveHomeLogged
        className="hidden-desktop"
        icon={CircleImg}
        title="Recomendados"
        subtitle="Veja os conteúdos recomendados"
        link="/meus-cursos"
        linkText="VER MAIS"
      >
        {recommendedContents.length
          ? recommendedContents.map(item => (
              <div style={{ marginLeft: 24, marginRight: 24 }}>
                <CardCourse data={item} />
              </div>
            ))
          : [...Array(6).keys()].map(item => <CardCourseSkeleton key={item} />)}
      </CarouselResponsiveHomeLogged>

      <HorizontalScroll
        className="hidden-mobile"
        icon={CircleImg}
        title="Lançamentos"
        subtitle="Veja os conteúdos que serão lançados"
        totalItems={nextCourses.length}
        textFallback="No momento não temos lançamentos"
      >
        {nextCourses.length
          ? nextCourses.map(item => (
              <div style={{ marginLeft: 24, marginRight: 24 }}>
                <CardCourse data={item} />
              </div>
            ))
          : null}

        {loadingNextCourses &&
          [...Array(6).keys()].map(item => <CardCourseSkeleton key={item} />)}
      </HorizontalScroll>

      <CarouselResponsiveHomeLogged
        className="hidden-desktop"
        icon={CircleImg}
        title="Lançamentos"
        subtitle="Veja os conteúdos que serão lançados"
        textFallback="No momento não temos lançamentos"
      >
        {nextCourses.length
          ? nextCourses.map(item => (
              <div style={{ marginLeft: 24, marginRight: 24 }}>
                <CardCourse data={item} />
              </div>
            ))
          : null}

        {loadingNextCourses &&
          [...Array(6).keys()].map(item => <CardCourseSkeleton key={item} />)}
      </CarouselResponsiveHomeLogged>

      <CarouselResponsiveHomeLogged
        className="hidden-desktop"
        title="Mestre Ascencionados"
        icon={Fire}
        subtitle="Mestres da Sabedoria que inspiram e Orientam o IMA"
      >
        {!loadingAscendedMasters
          ? ascendedMasters.map(item => (
              <div>
                <CardMasters
                  key={item.ascended_master_id}
                  image={
                    item.images?.url
                      ? item.images?.url
                      : item.images?.thumbnail_url
                  }
                  name={item.name}
                  description={item.description}
                />
              </div>
            ))
          : [...Array(6).keys()].map(item => <CardCourseSkeleton key={item} />)}
      </CarouselResponsiveHomeLogged>

      <HorizontalScroll
        className="hidden-mobile"
        title="Mestre Ascencionados"
        icon={Fire}
        subtitle="Mestres da Sabedoria que inspiram e Orientam o IMA"
      >
        {!loadingAscendedMasters
          ? ascendedMasters.map(item => (
              <div style={{ marginLeft: 24, marginRight: 24 }}>
                <CardMasters
                  key={item.ascended_master_id}
                  image={
                    item.images?.url
                      ? item.images?.url
                      : item.images?.thumbnail_url
                  }
                  name={item.name}
                  description={item.description}
                />
              </div>
            ))
          : [...Array(6).keys()].map(item => <CardCourseSkeleton key={item} />)}
      </HorizontalScroll>
    </Container>
  );
};

export default HomeLogged;
