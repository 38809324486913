import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import iconNavPlay from '../../../assets/icons/icon-nav-play.svg';
import playIcon from '../../../assets/icons/play.svg';
import { useExecutionCourse } from '../../../hooks/executionCourse';

import {
  MenuTitle,
  MenuItems,
  MenuItem,
  MenuItemTitle,
  MenuItemTime,
  Menu,
} from './styles';

function Modules({ parentUuid, setOpenSidebar, modules }) {
  const { uuid } = useParams();
  const history = useHistory();

  const verifyInitialModule = () => {
    const currentModule = modules?.find(module => {
      return module?.children?.find(lesson => {
        return lesson?.content_id === uuid;
      });
    });
    const index = modules?.findIndex(
      x => x?.content_id === currentModule?.content_id,
    );
    return index;
  };

  const [indexOpened, setIndexOpened] = useState(verifyInitialModule() + 1);

  const { setData, data, setUpdateModules } = useExecutionCourse();
  function toggleItem(module, position) {
    if (module?.isLocked) {
      setIndexOpened(0);
      return;
    }
    if (position === indexOpened) {
      setIndexOpened(0);
    } else setIndexOpened(position);
  }

  function goToLesson(contentId, id) {
    if (contentId === uuid) {
      window.location.reload();
      return;
    }
    if (data.courseStep === 'finished') {
      history.push(`/curso/${contentId}/${id}`);
      window.location.reload();
    }
    setData(state => ({
      ...state,
      courseStep: 'inProgress',
      isLoading: true,
    }));
    setOpenSidebar(false);
    setUpdateModules(false);
    document.body.style.overflow = 'auto';
    history.push(`/curso/${contentId}/${id}`);
    window.location.reload();
  }

  function convertDuration(seconds) {
    const minutes = Math.floor(seconds / 60);
    const hours = (seconds / 3600).toFixed(2);

    if (minutes === 0) {
      return `${seconds} seg`;
    }

    return minutes < 60 ? `${minutes} min` : `${hours} h`;
  }

  return modules
    ?.sort((a, b) => (a.position > b.position ? 1 : -1))
    ?.map((module, index) => {
      return (
        <Menu tabIndex={-1}>
          <MenuTitle
            id={`${module?.content_id}`}
            className={indexOpened === index + 1 ? 'active' : 'inactive'}
            onClick={() => toggleItem(module, index + 1)}
          >
            <h2>{module?.name}</h2>
            <span
              className={
                (module?.isLocked ? 'locked' : 'inactive',
                indexOpened === index + 1 ? 'active' : 'inactive')
              }
            />
          </MenuTitle>
          <MenuItems active={indexOpened === index + 1}>
            {module?.children
              ?.sort((a, b) => (a.position > b.position ? 1 : -1))
              .map(lesson => {
                return (
                  <>
                    <MenuItem
                      active={lesson.content_id === uuid}
                      className="hidden-mobile"
                      onClick={() => goToLesson(lesson.content_id, parentUuid)}
                    >
                      <MenuItemTitle>
                        <div aria-hidden="true">
                          <img
                            src={
                              lesson?.content_user &&
                              lesson?.content_user?.finish_at
                                ? playIcon
                                : iconNavPlay
                            }
                            alt="Play"
                          />
                          <p className="lesson-name">{lesson?.name}</p>
                        </div>
                      </MenuItemTitle>
                      <MenuItemTime>
                        {convertDuration(lesson?.duration)}
                      </MenuItemTime>
                    </MenuItem>

                    <MenuItem
                      className="hidden-desktop"
                      onClick={() => goToLesson(lesson.content_id, parentUuid)}
                      aria-hidden="true"
                    >
                      <MenuItemTitle>
                        <div>
                          <img
                            src={
                              lesson.content_user &&
                              lesson.content_user.finish_at
                                ? playIcon
                                : iconNavPlay
                            }
                            alt="Play"
                          />
                        </div>
                        <MenuItemTime>
                          <p>{lesson.name}</p>
                          <span>{convertDuration(lesson.duration)}</span>
                        </MenuItemTime>
                      </MenuItemTitle>
                    </MenuItem>
                  </>
                );
              })}
          </MenuItems>
        </Menu>
      );
    });
}

export default Modules;
