import styled from 'styled-components';

import { theme } from '../../../../styles/theme';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 26px 16px;

  button {
    min-width: fit-content;

    font-weight: bold;
    color: ${theme.colors.light};
    text-transform: uppercase;
    font-size: 12px;
  }
`;

export const Box = styled.div`
  img {
    cursor: pointer;
    max-width: 120px;
    height: 40px;
  }
`;
