import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 80px;

  h5 {
    margin: auto;
    font-size: 18px;
    font-style: italic;
    color: ${({ theme }) => theme.colors.textSecondary};
    text-align: center;
    transform: translate3d(-30px, 0px, 0px);
  }

  ul.react-multi-carousel-track {
    padding-bottom: 45px;
    width: 100%;
    transform: translate3d(0px, 0px, 0px);
  }

  .react-multiple-carousel__arrow {
    bottom: 15px;
    width: 40px;
    height: 40px;
    background: var(--default-blue);
    border-radius: 50%;

    &::before {
      font-size: 18px;
    }

    &:hover {
      background: var(--default-blue);
    }
  }

  .react-multi-carousel-dot-list {
    width: 100%;
    padding: 0 20px;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background: #909599;
      margin: 0 4px;
      max-width: 32px;
      width: 100%;

      button {
        max-width: 32px;
        width: 100%;
        height: 6px;
        width: 100%;
        opacity: 0;
        border: none;
        margin: 0;
      }

      &.react-multi-carousel-dot--active {
        background: var(--default-blue);
      }
    }
  }
`;

export const CardInfoMobile = styled.div`
  @media (max-width: 1100px) {
    display: flex;
    margin-bottom: 25px;
    padding: 0 20px;

    h2 {
      font-weight: 600;
      font-size: 24px;
      flex: 1;
      color: #25282b;
    }

    a {
      font-weight: 600;
      font-size: 12px;
      align-self: center;
    }
  }
`;
