import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  AiOutlineUser,
  AiFillCreditCard,
  AiFillIdcard,
  AiOutlineClose,
} from 'react-icons/ai';
import { BsFillCalendarFill, BsPaypal } from 'react-icons/bs';
import { FaRegAddressCard } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { ReactComponent as CheckCircle } from '../../../../assets/icons/check.svg';
import iconGenerateBillet from '../../../../assets/icons/icon-generate-billet.svg';
import iconTicket from '../../../../assets/icons/icon-ticket.png';
import paypalImage from '../../../../assets/icons/paypal-2700934-2239070.png';
import PixIcon from '../../../../assets/icons/pix.png';
import { ReactComponent as SwapIcon } from '../../../../assets/icons/swap.svg';
import { ReactComponent as WalletIcon } from '../../../../assets/icons/wallet.svg';
import AddressContainerIcon from '../../../../components/AddressIcon';
import { Button } from '../../../../components/Button';
import {
  TicketNumberCamp,
  ViewBillet,
  ViewBilletCamp,
  ViewBilletPaypal,
} from '../../../../components/CardCourseInfo/components/PaymentModal/styles';
import ValueContainerCardIcon from '../../../../components/CardIcon';
import { Input } from '../../../../components/Input';
import Spinner from '../../../../components/Spinner';
import brStates from '../../../../data/brStatesISO.json';
import { useAuth } from '../../../../hooks/auth';
import { usePaymentModal } from '../../../../hooks/paymentModal';
import {
  changePaymentWithPaypalPlan,
  checkPaymentStatus,
  createPaymentPlanWitPix,
  createPaymentWithBankSlipPlan,
} from '../../../../services/paymentsService';
import { UserService } from '../../../../services/userService';
import { theme } from '../../../../styles/theme';
import { mappedErrors } from '../../../../utils/paymentsMappedErrors';
import {
  paymentYupValidation,
  paymentYupValidationBankSlip,
  paymentYupValidationGeneric,
} from '../../../../utils/paymentYupValidation';
import { mapPeriodInDaysToString } from '../../../../utils/planConverter';

import {
  Overlay,
  Container,
  PurchaseDetails,
  ProductInfo,
  PaymentForm,
  FormInputs,
  RowInputs,
  FormButtons,
  SavedCardsList,
  SavedCard,
  CardInfo,
  CloseButtonMobile,
  SelectCustomStyle,
  ErrorMessage,
  SelectContainer,
  SelectFormOfPayment,
  SelectContainerInstallments,
  SelectFormOfPaymentOption,
  PixContainer,
  PixCopyAndPasteContainer,
  PixCopyAndPaste,
  ContainerDebitCreditCard,
  LabelDebitCreditCard,
} from './styles';

const PaymentModal = ({ modalOpen, plan, onClose, currentPlan }) => {
  const [rememberCard, setRememberCard] = useState(false);
  const [newCardInputCheck, setNewCardInputCheck] = useState(true);
  const [savedCardSelected, setSavedCardSelected] = useState(false);

  const { modalData, handlePurchasePlan, changeStatusLoading } =
    usePaymentModal();
  const { token } = useAuth();
  const { isLoading, storedCard } = modalData;
  const [payment, setPayment] = useState('credit_card');
  const [paymentBankSlipSuccess, setPaymentBankSlipSuccess] = useState(false);
  const [paymentBankSlip, setPaymentBankSlip] = useState(false);
  const [installments, setInstallments] = useState('1');
  const history = useHistory();
  const [paymentPaypal, setPaymentPaypal] = useState(false);
  const [paymentPaypalSuccess, setPaymentPaypalSuccess] = useState(false);
  const [showUserAddressForm, setShowUserAddressForm] = useState(false);

  const [pixInfo, setPixInfo] = useState(null);
  const [intervalId, setIntervalId] = useState(0);
  const pixInstructionsQrCodeRef = useRef();

  function getScheme() {
    if (payment === 'credit_card') {
      return paymentYupValidation;
    }
    if (payment === 'bank_slip') {
      return paymentYupValidationBankSlip;
    }
    if (payment === 'paypal') {
      return paymentYupValidationGeneric;
    }
    if (payment === 'pix') {
      return paymentYupValidationGeneric;
    }
    return null;
  }

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setValue,
    reset,
    register,
  } = useForm({
    resolver: yupResolver(getScheme()),
  });

  const handleSubmitPayment = async data => {
    const formattedData = {
      ...data,
    };

    delete formattedData.installments;
    const userService = new UserService();
    try {
      const userAddress = await userService
        .getUserCustomerAddress()
        .then(res => res.data)
        .catch(() => {
          changeStatusLoading(true);
          toast.error(
            'Erro ao tentar recuperar dados do usuário, tente novamente.',
          );
          return null;
        });

      delete userAddress.additional_details;

      const allFieldsOk = Object.values(userAddress).every(
        value => value !== null,
      );

      if (!allFieldsOk) {
        changeStatusLoading(false);
        setShowUserAddressForm(true);
        toast.info('Preencha os dados do endereço para continuar.');
        return;
      }

      const response = await handlePurchasePlan(
        rememberCard,
        formattedData,
        plan.plan_id,
        token,
        installments,
      );

      if (!response) {
        throw new Error('Ocorreu um erro ao obter resposta');
      }

      switch (response?.status) {
        case 'paid':
          toast.info('Novo plano contratado com sucesso!');

          break;

        case 'processing':
          toast.warning('Estamos liberando seu acesso ao novo plano...');
          break;

        default:
          break;
      }

      const user = await userService.getUserData();
      localStorage.setItem('@HaluGamashi:user', JSON.stringify(user.data));
      setTimeout(() => {
        history.push('/perfil#plan');
      }, 2000);
      document.querySelector('body').style.overflow = 'auto';
    } catch (err) {
      const parsedErrorMessage = mappedErrors.find(
        option => option.error === err?.response?.data?.message,
      );

      if (parsedErrorMessage) {
        toast.warning(parsedErrorMessage.message, {
          closeOnClick: false,
        });
        return;
      }

      toast.error(
        'Não foi possível fazer uma nova assinatura, tente novamente',
      );
    }
  };

  const handleSubmitPaymentSavedCard = async () => {
    try {
      const response = await handlePurchasePlan(
        null,
        null,
        plan.plan_id,
        null,
        installments,
      );
      if (!response) {
        throw new Error('Ocorreu um erro ao obter resposta');
      }

      switch (response?.status) {
        case 'paid':
          toast.info('Novo plano contratado com sucesso!');

          break;

        case 'processing':
          toast.warning('Estamos liberando seu acesso ao novo plano...');
          break;

        default:
          break;
      }

      const userService = new UserService();
      const user = await userService.getUserData();
      localStorage.setItem('@HaluGamashi:user', JSON.stringify(user.data));

      document.querySelector('body').style.overflow = 'auto';
      setTimeout(() => {
        history.push('/perfil#plan');
      }, 2000);
    } catch (err) {
      const parsedErrorMessage = mappedErrors.find(
        option => option.error === err?.response?.data?.message,
      );

      if (parsedErrorMessage) {
        toast.warning(parsedErrorMessage.message, {
          closeOnClick: false,
        });
        return;
      }

      toast.error(
        'Não foi possível fazer uma nova assinatura, tente novamente',
      );
    }
  };

  const handleToggleNewCardInput = () => {
    setNewCardInputCheck(state => !state);
    setSavedCardSelected(() => false);
  };

  const handleSelectSavedCard = async () => {
    setSavedCardSelected(state => !state);
    setNewCardInputCheck(() => false);

    const userService = new UserService();

    const userAddress = await userService
      .getUserCustomerAddress()
      .then(res => res.data)
      .catch(() => {
        changeStatusLoading(true);
        toast.error(
          'Erro ao tentar recuperar dados do usuário, tente novamente.',
        );
        return null;
      });

    delete userAddress.additional_details;

    const allFieldsOk = Object.values(userAddress).every(
      value => value !== null,
    );

    if (!allFieldsOk) {
      changeStatusLoading(false);
      setShowUserAddressForm(true);
      toast.info('Preencha os dados do endereço para continuar.');
    }
  };

  const handleSubmitPaymentBankSlip = async data => {
    changeStatusLoading(true);

    const formattedData = {
      ...data,
      cpf: data.cpf.replaceAll('.', '').replaceAll('-', ''),
      zipcode: data.zipcode.replaceAll('-', '').replaceAll(' ', ''),
    };

    delete formattedData.name;
    delete formattedData.document;
    delete formattedData.expirationDate;
    delete formattedData.cvv;
    delete formattedData.cardNumber;
    delete formattedData.installments;

    await createPaymentWithBankSlipPlan(plan.plan_id, formattedData)
      .then(res => {
        toast.info(
          '📨️ Este boleto foi enviado para seu e-mail cadastrado na plataforma',
          { autoClose: false },
        );
        setPaymentBankSlip(res.data);
        setPaymentBankSlipSuccess(true);
      })
      .catch(err => {
        const parsedErrorMessage = mappedErrors.find(
          option => option.error === err?.response?.data?.message,
        );

        if (parsedErrorMessage) {
          toast.warning(parsedErrorMessage.message);
          return;
        }

        toast.error(
          'Erro ao registrar boleto, verifique os dados e tente novamente.',
        );
      })
      .finally(() => {
        changeStatusLoading(false);
      });
  };

  const handleSubmitPaymentPaypal = async () => {
    changeStatusLoading(true);

    await changePaymentWithPaypalPlan(plan.plan_id)
      .then(res => {
        setPaymentPaypal(res.data);
        setPaymentPaypalSuccess(true);
      })
      .catch(() => {
        toast.error('Erro ao enviar compra com o PayPal, tente novamente.');
      })
      .finally(() => {
        changeStatusLoading(false);
      });
  };

  function paymentName(paymentType) {
    switch (paymentType) {
      case 'credit_card':
        return 'Dados do Cartão';
      case 'bank_slip':
        return 'Dados do Boleto';
      case 'paypal':
        return null;
      default:
        return '';
    }
  }

  const handleCopyBarCode = barCode => {
    navigator.clipboard.writeText(barCode);
    document.querySelector('body').style.overflow = 'auto';
    toast.info('Texto copiado!');
    history.goBack();
  };

  const handleClose = () => {
    reset({
      name: '',
      document: '',
      cardNumber: '',
      expirationDate: '',
      cvv: '',
    });
    onClose();
  };

  const pollingCheckPixPaymentStatus = async userPaymentId => {
    try {
      const userPayment = await checkPaymentStatus(userPaymentId);

      if (userPayment?.data.status === 'paid') {
        setPixInfo(state => ({ ...state, status: 'paid' }));
        setTimeout(() => {
          history.push('/perfil#plan');
        }, 3000);
        return;
      }
    } catch {
      toast.error(
        'Ocorreu um error ao verificar o status do pagamento, vamos tentar novamente aguarde...',
        { toastId: 'pix-polling-error' },
      );
    }
  };

  const handleSubmitPaymentPix = async formData => {
    const objectFormatted = formData;

    delete objectFormatted.expirationDate;
    delete objectFormatted.installments;
    delete objectFormatted.cardNumber;
    delete objectFormatted.cvv;
    delete objectFormatted.name;
    delete objectFormatted.document;

    objectFormatted.cpf = objectFormatted.cpf.replace(/[^\d]/g, '');
    objectFormatted.zipcode = objectFormatted.zipcode.replace(/[^\d]/g, '');

    const userPaymentPix = await createPaymentPlanWitPix(
      plan.plan_id,
      objectFormatted,
    )
      .then(response => {
        const { data } = response;

        const userPayment = {
          qrCode: data.qrcode,
          paymentUrl: data.payment_url,
          pixKey: data.pix_key,
          status: data.status,
          userPaymentId: data.user_payment_id,
        };

        setPixInfo(userPayment);
        return userPayment;
      })
      .catch(err => {
        const parsedErrorMessage = mappedErrors.find(
          option => option.error === err?.response?.data?.message,
        );

        if (parsedErrorMessage) {
          toast.warning(parsedErrorMessage.message, { autoClose: false });
          return null;
        }

        toast.error('Ocorreu um erro ao gerar o Pix, tente novamente.');
        return null;
      })
      .finally(() => {
        if (pixInstructionsQrCodeRef?.current) {
          pixInstructionsQrCodeRef?.current?.scrollIntoView();
        }
      });

    if (!userPaymentPix) return;

    const id = setInterval(async () => {
      await pollingCheckPixPaymentStatus(userPaymentPix.userPaymentId);
    }, 2000);

    setIntervalId(id);
  };

  const handleCopyPixKey = pixKey => {
    navigator.clipboard.writeText(pixKey);
    toast.info('Chave copiada!');
  };

  const installmentOptions = (() => {
    const options = [];

    if (!plan.info.max_installments) return [];

    for (let index = 0; index < plan.info.max_installments; index += 1) {
      const numInstallment = index + 1;
      const installmentValue = (parseFloat(plan?.price, 2) / numInstallment)
        .toFixed(2)
        .replace('.', ',');

      const getInstallment = () => {
        if (numInstallment === 1) {
          return `${numInstallment}x parcela de R$ ${installmentValue}`;
        }
        if (numInstallment !== 1) {
          return `${numInstallment}x parcelas de R$ ${installmentValue}`;
        }
        return null;
      };
      options.push({
        label: `${getInstallment()}`,
        value: numInstallment,
      });
    }

    return options;
  })();

  const updateCustomerProfile = async (data, isCreditCard) => {
    changeStatusLoading(true);
    const objectFormatted = {
      ...data,
      country: 'BR',
    };

    delete objectFormatted.expirationDate;
    delete objectFormatted.installments;
    delete objectFormatted.cardNumber;
    delete objectFormatted.cvv;
    delete objectFormatted.name;
    delete objectFormatted.document;
    delete objectFormatted.coupon;

    objectFormatted.cpf = objectFormatted.cpf
      .replaceAll('.', '')
      .replace('-', '');

    objectFormatted.zipcode = objectFormatted.zipcode.replaceAll(' ', '');

    const userService = new UserService();

    try {
      await userService.updateUserCustomerAddress(objectFormatted);
      if (isCreditCard) {
        toast.info(
          'Dados do cartão atualizado com sucesso! Realize a confirmação do pagamento novamente.',
        );
        setShowUserAddressForm(false);
        return;
      }
      toast.info(
        'Endereço atualizado com sucesso! Realize a confirmação do Pix novamente.',
      );
      setShowUserAddressForm(false);
    } catch {
      toast.error('Erro ao atualizar endereço, tente novamente.');
    } finally {
      changeStatusLoading(false);
    }
  };

  useEffect(() => {
    setValue('installments', installmentOptions[0]?.value);
  }, [installmentOptions, setValue]);

  useEffect(() => {
    if (!modalOpen && intervalId) {
      clearInterval(intervalId);
    }
  }, [modalOpen, intervalId]);

  return (
    <Overlay>
      <Container>
        <WalletIcon />

        <CloseButtonMobile onClick={handleClose}>
          <AiOutlineClose
            style={{
              color: theme.colors.tertiary,
              width: '20px',
              height: '20px',
              cursor: 'pointer',
            }}
          />
        </CloseButtonMobile>

        <h1>Faça seu pagamento</h1>

        <PurchaseDetails>
          <ProductInfo>
            <p>
              PLANO ATUAL{' '}
              {mapPeriodInDaysToString[
                currentPlan?.plan?.period_in_days
              ]?.toUpperCase()}
            </p>
            <h3>{currentPlan?.plan?.title}</h3>
            <h4>R$ {currentPlan?.price?.toFixed(2).replace('.', ',')}</h4>
          </ProductInfo>

          <SwapIcon />

          <ProductInfo>
            <p>
              PLANO{' '}
              {mapPeriodInDaysToString[plan?.period_in_days]?.toUpperCase()}
            </p>
            <h5>{plan?.title}</h5>
            <h4>R$ {plan?.price?.toFixed(2).replace('.', ',')}</h4>
          </ProductInfo>
        </PurchaseDetails>

        {!paymentBankSlipSuccess && (
          <SelectFormOfPayment>
            <SelectFormOfPaymentOption>
              <input
                type="radio"
                checked={payment === 'credit_card'}
                onChange={() => setPayment('credit_card')}
              />
              <label>Cartão de crédito</label>
            </SelectFormOfPaymentOption>

            <SelectFormOfPaymentOption>
              <input
                type="radio"
                checked={payment === 'bank_slip'}
                onChange={() => setPayment('bank_slip')}
              />
              <label>Boleto</label>
            </SelectFormOfPaymentOption>

            <SelectFormOfPaymentOption>
              <input
                type="radio"
                checked={payment === 'paypal'}
                onChange={() => setPayment('paypal')}
              />
              <label>PayPal</label>
            </SelectFormOfPaymentOption>

            <SelectFormOfPaymentOption>
              <input
                type="radio"
                checked={payment === 'pix'}
                onChange={() => setPayment('pix')}
              />
              <label>Pix</label>
            </SelectFormOfPaymentOption>
          </SelectFormOfPayment>
        )}
        {payment === 'credit_card' && (
          <ContainerDebitCreditCard>
            <LabelDebitCreditCard>
              O pagamento via cartão de crédito é feito via débito automático.
            </LabelDebitCreditCard>
          </ContainerDebitCreditCard>
        )}

        {payment === 'credit_card' && (
          <>
            {storedCard && payment !== 'bank_slip' ? (
              <SavedCardsList>
                <SavedCard
                  aria-hidden="true"
                  onKeyDown={handleSelectSavedCard}
                  onClick={handleSelectSavedCard}
                >
                  <label htmlFor="savedCard">
                    <input
                      id="savedCard"
                      type="radio"
                      checked={savedCardSelected}
                    />
                  </label>

                  <CardInfo>
                    <span>CARTÃO SALVO</span>
                    <p>
                      {storedCard?.payment_company?.name}
                      <span>{` •••• ${storedCard.card_number_last_four}`}</span>
                    </p>
                  </CardInfo>
                </SavedCard>

                <SavedCard
                  aria-hidden="true"
                  onKeyDown={handleToggleNewCardInput}
                  onClick={handleToggleNewCardInput}
                >
                  <label htmlFor="newCard">
                    <input
                      id="newCard"
                      type="radio"
                      checked={newCardInputCheck}
                    />
                  </label>

                  <CardInfo>
                    <p>Adicionar novo cartão</p>
                  </CardInfo>
                </SavedCard>
              </SavedCardsList>
            ) : null}
          </>
        )}

        {(newCardInputCheck || !storedCard) &&
        payment !== 'pix' &&
        payment !== 'paypal' ? (
          <PaymentForm>
            <h5
              storedCard={storedCard}
              aria-hidden="true"
              onKeyDown={handleToggleNewCardInput}
              onClick={handleToggleNewCardInput}
            >
              <CheckCircle />
              {paymentName(payment)}
            </h5>

            <form>
              <FormInputs>
                {payment === 'credit_card' && (
                  <>
                    <Input
                      control={control}
                      containerStyle={{
                        minWidth: 90,
                      }}
                      placeholder={
                        payment === 'credit_card'
                          ? 'Nome do titular do cartão'
                          : 'Nome completo'
                      }
                      icon={<AiOutlineUser color="#909599" />}
                      isInvalid={errors.name?.message}
                      errorMessage={errors.name?.message}
                      name="name"
                    />

                    <Input
                      control={control}
                      containerStyle={{
                        minWidth: 90,
                      }}
                      placeholder="CPF ou CNPJ"
                      icon={<AiFillIdcard color="#909599" />}
                      isInvalid={errors.document?.message}
                      errorMessage={errors.document?.message}
                      mask="999.999.999-99"
                      name="document"
                    />

                    <Input
                      control={control}
                      containerStyle={{
                        minWidth: 90,
                      }}
                      placeholder="Número do cartão"
                      icon={<AiFillCreditCard color="#909599" />}
                      isInvalid={errors.cardNumber?.message}
                      errorMessage={errors.cardNumber?.message}
                      mask="9999 9999 9999 9999"
                      name="cardNumber"
                    />

                    <RowInputs>
                      <Input
                        control={control}
                        containerStyle={{
                          minWidth: 90,
                        }}
                        placeholder="Validade"
                        icon={<BsFillCalendarFill color="#909599" />}
                        isInvalid={errors.expirationDate?.message}
                        errorMessage={errors.expirationDate?.message}
                        mask="99/9999"
                        name="expirationDate"
                      />

                      <Input
                        control={control}
                        containerStyle={{
                          minWidth: 90,
                        }}
                        type="number"
                        placeholder="CVV"
                        icon={<AiFillCreditCard color="#909599" />}
                        isInvalid={errors.cvv?.message}
                        errorMessage={errors.cvv?.message}
                        name="cvv"
                      />
                    </RowInputs>
                    <label htmlFor="rememberCard">
                      <input
                        id="rememberCard"
                        type="checkbox"
                        onChange={() => setRememberCard(state => !state)}
                      />
                      Quero salvar os dados do cartão para futuras compras.
                    </label>
                  </>
                )}
              </FormInputs>
            </form>
          </PaymentForm>
        ) : null}

        {payment === 'credit_card' && plan?.price ? (
          <>
            <h6>Parcelamento</h6>

            <Controller
              control={control}
              name="installments"
              render={({ field: { onChange, ref } }) => (
                <SelectContainerInstallments
                  error={errors.installments?.message}
                >
                  <Select
                    ref={ref}
                    options={installmentOptions}
                    components={{
                      ValueContainer: ValueContainerCardIcon,
                    }}
                    placeholder={<span>Escolha um plano de parcelamento</span>}
                    styles={SelectCustomStyle}
                    noOptionsMessage={() => 'Sem opções disponíveis'}
                    defaultValue={installmentOptions[0]}
                    onChange={option => {
                      setInstallments(option.value);
                      onChange(option.value);
                    }}
                  />
                  {errors.installments?.message ? (
                    <ErrorMessage>{errors.installments?.message}</ErrorMessage>
                  ) : null}
                </SelectContainerInstallments>
              )}
            />
          </>
        ) : null}

        {(!paymentBankSlipSuccess && payment === 'bank_slip') ||
        showUserAddressForm ? (
          <PaymentForm>
            <FormInputs>
              <Input
                control={control}
                containerStyle={{
                  minWidth: 90,
                }}
                placeholder="CPF ou CNPJ"
                icon={<AiFillIdcard color="#909599" />}
                isInvalid={errors.cpf?.message}
                errorMessage={errors.cpf?.message}
                mask="999.999.999-99"
                name="cpf"
              />

              <Input
                control={control}
                placeholder="Rua"
                icon={<FaRegAddressCard />}
                isInvalid={errors.street?.message}
                errorMessage={errors.street?.message}
                name="street"
              />

              <Input
                control={control}
                placeholder="Número"
                icon={<FaRegAddressCard />}
                isInvalid={errors.number?.message}
                errorMessage={errors.number?.message}
                name="number"
              />

              <Input
                control={control}
                placeholder="Bairro"
                icon={<FaRegAddressCard />}
                isInvalid={errors.neighborhood?.message}
                errorMessage={errors.neighborhood?.message}
                name="neighborhood"
              />

              <Controller
                control={control}
                name="additional_details"
                render={({ field: { onChange, ref } }) => (
                  <SelectContainer error={errors.additional_details?.message}>
                    <Select
                      ref={ref}
                      options={[
                        { label: 'Casa', value: 'Casa' },
                        {
                          label: 'Apartamento',
                          value: 'Apartamento',
                        },
                      ]}
                      components={{
                        ValueContainer: AddressContainerIcon,
                      }}
                      placeholder={<span>Complemento</span>}
                      styles={SelectCustomStyle}
                      noOptionsMessage={() => 'Sem opções disponíveis'}
                      onChange={option => {
                        onChange(option.value);
                      }}
                    />
                    {errors.additional_details?.message ? (
                      <ErrorMessage>
                        {errors.additional_details?.message}
                      </ErrorMessage>
                    ) : null}
                  </SelectContainer>
                )}
              />

              <Input
                control={control}
                placeholder="Código Postal"
                icon={<FaRegAddressCard />}
                mask="99999 999"
                isInvalid={errors.zipcode?.message}
                errorMessage={errors.zipcode?.message}
                name="zipcode"
              />

              <Input
                control={control}
                placeholder="Cidade"
                icon={<FaRegAddressCard />}
                isInvalid={errors.city?.message}
                errorMessage={errors.city?.message}
                name="city"
              />

              <Controller
                control={control}
                name="state"
                render={({ field: { onChange, ref } }) => (
                  <SelectContainer
                    style={{ marginBottom: 32 }}
                    error={errors.state?.message}
                  >
                    <Select
                      ref={ref}
                      options={brStates}
                      components={{
                        ValueContainer: AddressContainerIcon,
                      }}
                      placeholder={<span>Estado</span>}
                      styles={SelectCustomStyle}
                      noOptionsMessage={() => 'Sem opções disponíveis'}
                      onChange={option => {
                        onChange(option.value);
                      }}
                    />
                    {errors.state?.message ? (
                      <ErrorMessage>{errors.state?.message}</ErrorMessage>
                    ) : null}
                  </SelectContainer>
                )}
              />
            </FormInputs>
          </PaymentForm>
        ) : null}

        {payment === 'bank_slip' && paymentBankSlipSuccess ? (
          <ViewBilletCamp>
            <h5>Boleto gerado!</h5>
            <p>Você poderá pagar o boleto abaixo impresso ou por aplicativo.</p>
            <ViewBillet>
              <img src={iconTicket} alt="Visualizar boleto" />
              <a
                href={paymentBankSlip?.bank_slip_url}
                download="boleto.pdf"
                rel="noreferrer"
              >
                VISUALIZAR BOLETO
              </a>
            </ViewBillet>
            <h4>Código do boleto</h4>
            <TicketNumberCamp>
              <span>{paymentBankSlip?.barcode}</span>
              <button
                onClick={() => handleCopyBarCode(paymentBankSlip?.barcode)}
              >
                <img src={iconGenerateBillet} alt="Gerar boleto" />
              </button>
            </TicketNumberCamp>
          </ViewBilletCamp>
        ) : null}

        {payment === 'paypal' && (
          <ViewBilletCamp>
            <h5>PayPal</h5>
            <BsPaypal size={60} />
          </ViewBilletCamp>
        )}

        {payment === 'paypal' && paymentPaypalSuccess && (
          <ViewBilletCamp>
            <h5>Pagamento com o Paypal gerado com sucesso!</h5>

            <ViewBilletPaypal>
              <img src={paypalImage} alt="Pagamento com PayPal" />
              <a
                href={paymentPaypal?.payment_url}
                target="_blank"
                rel="noreferrer"
                onClick={onClose}
              >
                ACESSAR PAYPAL
              </a>
            </ViewBilletPaypal>
          </ViewBilletCamp>
        )}

        {payment === 'pix' && (
          <PixContainer>
            <h5>Pix</h5>

            {pixInfo?.qrCode && pixInfo?.status !== 'paid' ? (
              <>
                <p ref={pixInstructionsQrCodeRef}>
                  Leia o QrCode abaixo para realizar o pagamento
                </p>

                <iframe src={pixInfo?.qrCode} title="QrCode Pix" />
              </>
            ) : (
              <img src={PixIcon} alt="Pagamento com Pix" />
            )}

            {pixInfo && pixInfo?.status !== 'paid' && (
              <PixCopyAndPasteContainer>
                <p>Aguardando seu pagamento...</p>
                <p>
                  Esta operação poderá levar até
                  <br />
                  30 minutos para ser reconhecida após o pagamento
                </p>
                <div className="spinner">
                  <Spinner />
                </div>

                <PixCopyAndPaste>
                  <p>Pix Copia e Cola</p>

                  <textarea readOnly>{pixInfo?.pixKey}</textarea>

                  <button
                    type="button"
                    onClick={() => handleCopyPixKey(pixInfo?.pixKey)}
                  >
                    COPIAR
                  </button>
                </PixCopyAndPaste>
                <a
                  href={pixInfo?.paymentUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ou clique aqui...
                </a>
              </PixCopyAndPasteContainer>
            )}

            {pixInfo && pixInfo?.status === 'paid' && (
              <PixCopyAndPasteContainer>
                <h1>Pagamento realizado com sucesso!</h1>
              </PixCopyAndPasteContainer>
            )}
          </PixContainer>
        )}

        {payment === 'pix' && !pixInfo && (
          <PaymentForm>
            <FormInputs>
              <Input
                containerStyle={{
                  minWidth: 90,
                }}
                placeholder="CPF ou CNPJ"
                icon={<AiFillIdcard color="#909599" />}
                isInvalid={errors.cpf?.message}
                errorMessage={errors.cpf?.message}
                mask="999.999.999-99"
                {...register('cpf')}
              />

              <Input
                placeholder="Rua"
                icon={<FaRegAddressCard />}
                isInvalid={errors.street?.message}
                errorMessage={errors.street?.message}
                {...register('street')}
              />

              <Input
                placeholder="Número"
                icon={<FaRegAddressCard />}
                isInvalid={errors.number?.message}
                errorMessage={errors.number?.message}
                {...register('number')}
              />

              <Input
                placeholder="Bairro"
                icon={<FaRegAddressCard />}
                isInvalid={errors.neighborhood?.message}
                errorMessage={errors.neighborhood?.message}
                {...register('neighborhood')}
              />

              <Controller
                control={control}
                name="additional_details"
                render={({ field: { onChange, ref } }) => (
                  <SelectContainer error={errors.additional_details?.message}>
                    <Select
                      ref={ref}
                      options={[
                        { label: 'Casa', value: 'Casa' },
                        { label: 'Apartamento', value: 'Apartamento' },
                      ]}
                      components={{
                        ValueContainer: AddressContainerIcon,
                      }}
                      placeholder={<span>Complemento</span>}
                      styles={SelectCustomStyle}
                      noOptionsMessage={() => 'Sem opções disponíveis'}
                      onChange={option => {
                        onChange(option.value);
                      }}
                    />
                    {errors.additional_details?.message ? (
                      <ErrorMessage>
                        {errors.additional_details?.message}
                      </ErrorMessage>
                    ) : null}
                  </SelectContainer>
                )}
              />

              <Input
                control={control}
                placeholder="Código Postal"
                icon={<FaRegAddressCard />}
                mask="99999 999"
                isInvalid={errors.zipcode?.message}
                errorMessage={errors.zipcode?.message}
                name="zipcode"
              />

              <Input
                placeholder="Cidade"
                icon={<FaRegAddressCard />}
                isInvalid={errors.city?.message}
                errorMessage={errors.city?.message}
                {...register('city')}
              />

              <Controller
                control={control}
                name="state"
                render={({ field: { onChange, ref } }) => (
                  <SelectContainer
                    style={{ marginBottom: 32 }}
                    error={errors.state?.message}
                  >
                    <Select
                      ref={ref}
                      options={brStates}
                      components={{
                        ValueContainer: AddressContainerIcon,
                      }}
                      placeholder={<span>Estado</span>}
                      styles={SelectCustomStyle}
                      noOptionsMessage={() => 'Sem opções disponíveis'}
                      onChange={option => {
                        onChange(option.value);
                      }}
                    />
                    {errors.state?.message ? (
                      <ErrorMessage>{errors.state?.message}</ErrorMessage>
                    ) : null}
                  </SelectContainer>
                )}
              />
            </FormInputs>
          </PaymentForm>
        )}

        <FormButtons>
          {isLoading || isSubmitting ? (
            <Spinner />
          ) : (
            <>
              <Button
                title="VOLTAR"
                onClick={handleClose}
                type="button"
                containerStyle={{
                  justifyContent: 'center',
                  height: 30,
                  padding: 20,
                  background: 'transparent',
                  maxWidth: 100,
                  minWidth: 300,
                }}
                buttonStyle={{
                  color: '#240F85',
                }}
              />
              {payment === 'credit_card' && !paymentBankSlipSuccess && (
                <Button
                  title="CONFIRMAR COMPRA"
                  type="button"
                  onClick={
                    // eslint-disable-next-line no-nested-ternary
                    showUserAddressForm
                      ? handleSubmit(updateCustomerProfile, true)
                      : savedCardSelected
                      ? handleSubmitPaymentSavedCard
                      : handleSubmit(handleSubmitPayment)
                  }
                  containerStyle={{
                    justifyContent: 'center',
                    height: 30,
                    maxWidth: 100,
                    minWidth: 300,
                    padding: 20,
                  }}
                />
              )}

              {payment === 'bank_slip' && !paymentBankSlipSuccess && (
                <Button
                  title="GERAR BOLETO"
                  type="button"
                  onClick={() => handleSubmit(handleSubmitPaymentBankSlip)()}
                  containerStyle={{
                    justifyContent: 'center',
                    height: 30,
                    maxWidth: 100,
                    minWidth: 300,
                    padding: 20,
                  }}
                />
              )}
              {payment === 'paypal' &&
                !paymentBankSlipSuccess &&
                !paymentPaypalSuccess && (
                  <Button
                    title="PAGAR COM PAYPAL"
                    type="button"
                    onClick={() => handleSubmit(handleSubmitPaymentPaypal)()}
                    containerStyle={{
                      justifyContent: 'center',
                      height: 30,
                      maxWidth: 100,
                      minWidth: 300,
                      padding: 20,
                    }}
                  />
                )}
              {payment === 'pix' && !pixInfo && (
                <Button
                  title="CONFIRMAR PIX"
                  type="button"
                  onClick={() => handleSubmit(handleSubmitPaymentPix)()}
                  containerStyle={{
                    justifyContent: 'center',
                    height: 30,
                    maxWidth: 100,
                    minWidth: 300,
                    padding: 20,
                  }}
                />
              )}
            </>
          )}
        </FormButtons>
      </Container>
    </Overlay>
  );
};

export default PaymentModal;
