import styled, { keyframes, css } from 'styled-components';

import CheckboxChecked from '../../assets/icons/checkbox-checked.svg';
import CheckboxUnChecked from '../../assets/icons/checkbox-unchecked.svg';
import inProgressBanner from '../../assets/images/in-progress-banner.png';
import { theme } from '../../styles/theme';

const fadeInDown = keyframes`{
0% {
  opacity: 0;
  transform: translateY(-1.25em);
}

100% {
  opacity: 1;
  transform: translateY(0);
}

}`;

const fadeOutUp = keyframes`{
0% {
  opacity: 1;
  transform: translateY(0);
}

100% {
  opacity: 0;
  transform: translateY(1.25em);
}`;

export const Container = styled.div`
  @media (max-width: 480px) {
    width: 100%;
    min-height: 100%;
  }
`;

export const Header = styled.section`
  background: url(${inProgressBanner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  object-fit: contain;
  @media (max-width: 480px) {
    margin-bottom: 30px;
  }

  h1 {
    padding-left: 63px;
    font-size: 7rem;
    color: ${theme.colors.textLight};
    z-index: 1;
    position: relative;
    width: 90%;
    span {
      color: ${theme.colors.text};
      position: absolute;
      font-size: 8rem;
      opacity: 0.2;
      bottom: 10px;
      left: 24px;
      z-index: -1;
    }
  }

  @media (max-width: 1100px) {
    h1 {
      font-size: 2rem;
      z-index: 0;

      span {
        font-size: 5rem;
      }
    }
  }

  @media (max-width: 700px) {
    h1 {
      font-size: 2rem;

      span {
        font-size: 3rem;
      }
    }
  }

  @media (max-width: 444px) {
    h1 {
      font-size: 1.5rem;

      span {
        font-size: 2rem;
      }
    }
  }

  @media (max-width: 370px) {
    h1 {
      font-size: 2rem !important;

      span {
        font-size: 1.6rem;
      }
    }
  }
`;

export const ImageFilter = styled.small`
  background: linear-gradient(
    90deg,
    rgba(89, 74, 173, 0.83) -5.53%,
    rgba(159, 151, 201, 0.3818) 60.04%,
    rgba(47, 24, 156, 0.83) 131.32%
  );
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
  width: 100%;
  height: 100%;
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  padding: 192px 24px 24px 24px;
  position: relative;

  @media (max-width: 560px) {
    height: 216px;

    padding: 0;

    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;

    span {
      position: absolute;
      top: 112px;
      left: 24px;
    }

    h1 {
      padding: 0;
      font-size: 40px;
      font-weight: bold;
      letter-spacing: 0.05em;
      white-space: nowrap;

      position: absolute;
      top: 160px;
      left: 24px;

      span {
        position: absolute;
        top: -24px;
        left: 8px;
      }
    }
  }
`;

export const Content = styled.section`
  padding: 72px 40px 90px;
  display: flex;

  section {
    flex: 1;
    margin: 0 24px;
  }

  .unfinished-label {
    text-align: center;
    margin: 0 auto;
  }

  @media (max-width: 1400px) {
    padding-right: 20px;
  }

  @media (max-width: 1100px) {
    padding: 0;
    padding: 64px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 320px - 244px);
  }

  @media (max-width: 700px) {
    min-height: calc(100vh - 320px - 289px);
  }

  @media (max-width: 400px) {
    padding: 0;
    section {
      margin: 0;
    }
  }

  > h3 {
    margin-left: 40px;
    @media (max-width: 400px) {
      font-size: 14px;
      width: 240px;
      margin-left: 0;
      text-align: center;
    }
  }
`;

export const Filters = styled.aside`
  max-width: 240px;

  h2 {
    font-size: 2rem;
    font-weight: 700;
    color: ${theme.colors.secondary};
    padding-bottom: 40px;
  }

  @media (max-width: 1400px) {
    h2 {
      font-size: 1rem;
    }
  }

  @media (max-width: 1100px) {
    display: none;
  }
`;

export const Filter = styled.div`
  border-top: 1px solid ${theme.colors.shapeSecondary};
  padding-top: 24px;
  padding-bottom: 40px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  h3 {
    font-size: 1.4rem;
    font-weight: 700;
    color: ${theme.colors.text};
  }

  svg {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 1400px) {
    margin-bottom: 10px;

    h3 {
      font-size: 1rem;
    }
  }

  @media (max-width: 1100px) {
    h3 {
      padding-left: 16px;
    }
  }
`;

export const FilterOptions = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  ${({ isOpen }) =>
    isOpen
      ? css`
          animation: ${fadeInDown} 0.5s;
        `
      : css`
          animation: ${fadeOutUp} 0.5s;
        `};

  input[type='checkbox']:checked + label {
    font-weight: 700;
  }

  &.disabledCheck {
    input[type='checkbox']::before {
      position: absolute;
      content: '';
      width: 13px;
      height: 13px;
      top: -1px;
      left: -2px;
      background: url(${CheckboxUnChecked});
      background-size: cover;
      background-repeat: no-repeat;
      background-color: #7e75b8;
      border: 2px solid #5646aa;
      border-radius: 6px;
    }
  }

  @media (max-width: 1100px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const FilterOption = styled.div`
  margin: 20px 0;

  label {
    color: ${theme.colors.tertiary};
    font-size: 1.1rem;
    font-weight: 600;
  }

  input {
    margin-right: 10px;
    position: relative;
    cursor: pointer;
  }

  input[type='checkbox']::before {
    content: '';
    width: inherit;
    height: inherit;
    position: absolute;
    top: -1px;
    left: -2px;
    border: 2px solid ${theme.colors.text};
    background-color: ${theme.colors.light};
    border-radius: 6px;
  }

  input:checked:after {
    position: absolute;
    content: '';
    width: 13px;
    height: 13px;
    top: -1px;
    left: -2px;
    background: url(${CheckboxChecked});
    background-color: ${theme.colors.secondary};
    background-size: cover;
    border: 2px solid ${theme.colors.secondary};
    border-radius: 6px;
  }

  @media (max-width: 1400px) {
    label {
      font-size: 0.9rem;
    }
  }
`;

export const CoursesGrid = styled.section`
  flex: 1;
  margin: 0 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  align-items: start;
  justify-items: center;
  column-gap: 24px;
  row-gap: 24px;
  position: relative;
  
  @media screen and (min-width: 1920px){
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  }

  @media (max-width: 1400px) {
    margin: 0 12px;
    column-gap: 12px;
    row-gap: 12px;
    padding: 0 20px;
  }

  @media (max-width: 1400px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }

  @media (max-width: 1100px) {
    margin-top: 20px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    column-gap: 12px;
    padding-bottom: 80px;
    >div{
      margin: 0;
      max-width: 340px;
      width: 100%;
    }
  }
  @media (max-width: 700px){
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Pagination = styled.section`
  position: absolute;
  bottom: -48px;
  width: 328px;

  .pagination {
    display: flex;
    list-style-type: none;
  }

  .page {
    display: flex;
    align-items: center;
    margin: 0 20px;
    cursor: pointer;
  }

  .activeLink {
    color: ${theme.colors.secondary};
    font-weight: 700;
  }

  .nextButton {
    margin-left: 72px;
  }

  .previousButton {
    margin-right: 72px;
  }

  .previousContainerButton,
  .nextContainerButton {
    display: flex;
  }

  .nextButton,
  .previousButton {
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      font-size: 2rem;
      fill: ${theme.colors.secondary};
    }
  }

  .disabledButtons {
    cursor: default;

    svg {
      fill: #cfd2d4;
    }
  }

  @media (max-width: 1100px) {
    position: absolute;
    bottom: 20px;
    width: auto;
  }
`;

export const FiltersMobileButton = styled.button`
  display: none;
  color: ${theme.colors.primary};
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
  border: 2px solid ${theme.colors.primary};
  width: 70%;
  border-radius: 20px;
  padding: 4px;

  @media screen and (max-width: 1100px) {
    width: 340px;
    padding: 8px;
    display: block;
  }

  > div:nth-last-child(2) {
    padding-bottom: 80px;
  }

  @media screen and (max-width: 560px) {
    width: calc(100% - 48px);
    padding: 6px;
  }
`;

export const BadgeFiltersMobile = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;

  span {
    padding: 4px 8px;
    font-size: 0.8rem;
    color: ${theme.colors.textLight};
    background: ${theme.colors.primary};
    margin: 4px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    width: fit-content;

    button {
      display: flex;
      color: ${theme.colors.light};
      margin-left: 4px;
    }
  }
`;

export const FiltersMobile = styled.aside`
  display: none;
  background: ${theme.colors.light};
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1000;
  box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px 20px 0 0;
  overflow-y: auto;
  height: 95vh;

  h2 {
    font-size: 1.7rem;
    text-align: center;
    font-weight: 700;
    color: ${theme.colors.secondary};
    padding-bottom: 40px;
  }

  @media (max-width: 1100px) {
    padding-bottom: 80px;
    h2 {
      padding-top: 16px;
    }
  }

  @media (max-width: 480px) {
    height: 64vh;
    overflow-y: scroll;
  }
`;

export const CloseButtonMobile = styled.div`
  position: absolute;
  top: 22px;
  right: 16px;
`;

export const FilterButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${theme.colors.light};
  padding: 16px;
  border-top: 1px solid ${theme.colors.shapeSecondary};
`;
