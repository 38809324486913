import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

import { theme } from '../../styles/theme';

export const Toast = styled(ToastContainer)`
  .Toastify__toast--error {
    background: ${theme.colors.error};
  }
  .Toastify__toast--warning {
    background: ${theme.colors.warning};
  }
  .Toastify__toast--success {
    background: ${theme.colors.success};
  }
`;
