import styled from 'styled-components';

const HomePlansContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  padding-top: 48px;
  text-align: center;
  color: var(--default-font-black);

  h2 {
    font-size: 1.8em;
  }

  .react-tabs {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .react-tabs__tab-list {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 10px;
      width: 100%;
      margin: 0 auto;
      border-radius: 16px;
      overflow: hidden;
      margin-bottom: 32px;
      max-width: 800px;

      .react-tabs__tab {
        border: none;
        outline: none;
        width: 200px;
        padding: 8px inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 1.2em;
        border-radius: 0;
        top: 0;
        background: #f5f8fa;

        border-right: 2px solid #e1e3e6;

        &:last-child {
          border-right: none;
        }

        &.react-tabs__tab--selected {
          background: var(--default-blue);
          color: white;
        }
      }
    }

    .react-tabs__tab-panel {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 16px;
      flex-wrap: wrap;
    }
  }

  @media (max-width: 835px) {
    .react-tabs {
      .react-tabs__tab-list {
        flex-direction: column;
        max-width: 300px;
        background: #f5f8fa;
        margin: 0 auto;

        .react-tabs__tab {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          border: none;
          outline: none;
          padding: 8px inherit;

          font-weight: 600;
          font-size: 1.2em;
        }
      }
    }
  }
`;

const NoPlansFound = styled.div`
  font-size: 2.5rem;
  color: #594aad;
  padding: 20px 0;
  text-align: center;
`;

export { HomePlansContainer, NoPlansFound };
