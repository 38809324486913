import styled from 'styled-components';

import { theme } from '../../styles/theme';

export const Container = styled.div`
  margin-top: auto;
  background: linear-gradient(
    180deg,
    ${theme.colors.primary} 0%,
    ${theme.colors.secondary} 100%
  );
  padding: 45px 40px;

  div {
    display: flex;
    flex-direction: column;
  }

  &,
  a {
    color: ${theme.colors.textLight};
    text-decoration: none;
  }

  a {
    text-align: end;
    margin: 16px 0;
  }

  section {
    display: flex;
    justify-content: space-between;

    img {
      max-width: 564px;
      max-height: 70px;
    }

    @media (max-width: 480px) {
      flex-direction: column;
      margin: 0 0 40px 0;

      div {
        margin: 0;
        padding: 0;
      }

      img {
        padding: 0 48px;
        margin: 24px 0 0 0;
        max-width: 100%;
        align-items: center;
      }

      span {
        width: 100%;
        margin-bottom: 20px;
      }

      a {
        padding: 0;
        margin: 0 0 20px 0;
        text-align: center;
        font-size: 16px;
      }
    }
  }

  hr {
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
    margin: 0 auto;
    align-self: center;
    margin-top: 45px;
  }

  p {
    text-align: center;
    margin-top: 24px;
  }

  @media (max-width: 480px) {
    padding: 0 0 8px 0;
    width: 100%;
    margin: 0;

    div {
      width: 100%;
      height: 50px;
    }

    hr {
      margin-top: 20px;
      width: 90%;
    }

    p {
      padding: 0;
      margin: 20px 0 8px 0;
      font-weight: 500;
      font-size: 12px;
    }
  }

  @media (max-width: 800px) {
    section {
      flex-direction: column;
      align-items: center;
    }

    img {
      max-width: 350px;
    }

    a {
      text-align: center;
    }
  }
`;
