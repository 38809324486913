import styled from 'styled-components';

export const EmbedContainer = styled.div`
  @media (max-width: 1100px) {
    * {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
    }
    .video {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
    }
    .video iframe {
      min-height: 0 !important;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;
