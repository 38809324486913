import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { GrClose } from 'react-icons/gr';
import ReactRating from 'react-rating';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { ReactComponent as HoverStarImg } from '../../../../assets/icons/hover-star.svg';
import ModalStarsImg from '../../../../assets/icons/modal-stars.svg';
import { ReactComponent as StarFilledImg } from '../../../../assets/icons/star.svg';
import { ReactComponent as UnFilledStarImg } from '../../../../assets/icons/un-filled-star.svg';
import { Button } from '../../../../components/Button';
import Spinner from '../../../../components/Spinner';
import { TextArea } from '../../../../components/TextArea';
import { ContentsService } from '../../../../services/contentsService';
import { theme } from '../../../../styles/theme';

import { Container, Step, SpinnerWrapper } from './styles';

const schema = yup.object().shape({
  description: yup.string().optional(),
});

const ModalRating = ({
  contentType,
  contentName,
  contentId,
  setShowModalRating,
}) => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  async function sendRating({ value, description }) {
    try {
      setLoading(true);
      const contentsService = new ContentsService();
      await contentsService.reviewContent(contentId, value, description);
      setStep(3);
      return;
    } catch {
      toast.error('Ocorreu um erro ao realizar a avaliação, tente novamente');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container step={step}>
      <Step>{step}/3</Step>
      <GrClose onClick={() => setShowModalRating(false)} />

      <img src={ModalStarsImg} alt="" />
      {step === 3 ? (
        <>
          <h1>Muito Obrigado</h1>
          <h2>Sua opinião nos ajudará a melhorar nosso conteúdo.</h2>
        </>
      ) : (
        <>
          <h1>Avaliação</h1>
          <h2>
            do {contentType} {contentName}
          </h2>
        </>
      )}

      {step === 1 ? (
        <>
          <hr />
          <h3>
            Gostou do {contentType}? Deixe sua <b>avaliação</b> sobre o
            {contentType}.
          </h3>
          <ReactRating
            placeholderRating={5}
            emptySymbol={<UnFilledStarImg width="36px" height="37px" />}
            fullSymbol={<StarFilledImg width="36px" height="37px" />}
            placeholderSymbol={<HoverStarImg width="36px" height="37px" />}
            onChange={value => sendRating({ value, description: '' })}
          />
          {loading ? (
            <SpinnerWrapper distance={103}>
              <Spinner />
            </SpinnerWrapper>
          ) : (
            <Button
              title="CONTINUAR"
              onClick={() => setStep(2)}
              containerStyle={{ maxWidth: 311, marginTop: 103 }}
            />
          )}

          <Button
            title="FAZER DEPOIS"
            onClick={() => setShowModalRating(false)}
            containerStyle={{
              maxWidth: 311,
              backgroundColor: 'transparent',
              marginTop: 36,
            }}
            buttonStyle={{ color: theme.colors.secondary }}
          />
        </>
      ) : null}

      {step === 2 ? (
        <>
          <hr />

          <h3>
            Deixe sua depoimento sobre o {contentType}. <b>(Opcional)</b>
          </h3>

          <TextArea
            isInvalid={errors.description?.message}
            errorMessage={errors.description?.message}
            {...register('description')}
          />

          {loading ? (
            <SpinnerWrapper distance={32}>
              <Spinner />
            </SpinnerWrapper>
          ) : (
            <Button
              title="PUBLICAR"
              onClick={handleSubmit(sendRating)}
              containerStyle={{ maxWidth: 311, marginTop: 32 }}
            />
          )}

          <Button
            title="VOLTAR"
            onClick={() => setStep(1)}
            containerStyle={{
              maxWidth: 311,
              backgroundColor: 'transparent',
              marginTop: 24,
            }}
            buttonStyle={{ color: theme.colors.secondary }}
          />
        </>
      ) : null}

      {step === 3 ? (
        <Button
          title="OK"
          onClick={() => setShowModalRating(false)}
          containerStyle={{
            marginTop: 374,
          }}
        />
      ) : null}
    </Container>
  );
};

export default ModalRating;
