import axiosApi from './api';

class FilesService {
  constructor() {
    this.api = axiosApi;
  }

  async uploadFile(formData) {
    return this.api.post('files', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async uploadAvatar(formData) {
    return this.api.post('files/avatar', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export { FilesService };
