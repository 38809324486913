/* eslint-disable no-param-reassign */
import React, { useEffect, useRef } from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import {
  Container,
  CourseListInfo,
  ScrollButtons,
  ScrollContainer,
} from './styles';

const HorizontalScroll = ({
  children,
  icon,
  title,
  subtitle,
  link,
  linkText,
  className,
  totalItems,
  textFallback = 'No momento não possuímos conteúdos para exibir aqui',
}) => {
  const containerScrollRef = useRef(null);
  const leftButtonRef = useRef(null);
  const rightButtonRef = useRef(null);

  function scrollRight() {
    containerScrollRef.current.scrollLeft += 440;
  }

  function scrollLeft() {
    containerScrollRef.current.scrollLeft -= 440;
  }

  function isInViewport(el) {
    if (!el) return false;

    const rect = el?.getBoundingClientRect();
    const condition =
      rect.left >= 0 &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth);

    return condition;
  }

  useEffect(() => {
    const firstChild = containerScrollRef.current.children[0];

    containerScrollRef.current.addEventListener('scroll', () => {
      const isVisible = isInViewport(firstChild);

      if (isVisible) {
        leftButtonRef.current.classList.add('disabled');
        rightButtonRef.current.classList.remove('disabled');
      } else {
        leftButtonRef.current.classList.remove('disabled');
        rightButtonRef.current.classList.add('disabled');
      }
    });
  });

  return (
    <Container className={className}>
      <CourseListInfo className="hidden-mobile">
        <h2>{title}</h2>
        {link ? <Link to={link}>{linkText}</Link> : null}
        <p>
          <img src={icon} alt="Ícone" />
          <span>{subtitle}</span>
        </p>

        <ScrollButtons>
          <button
            ref={leftButtonRef}
            type="button"
            onClick={() => scrollLeft(containerScrollRef)}
            className="disabled"
          >
            <BsChevronLeft />
          </button>

          <button
            ref={rightButtonRef}
            type="button"
            onClick={() => scrollRight(containerScrollRef)}
          >
            <BsChevronRight />
          </button>
        </ScrollButtons>
      </CourseListInfo>

      <CourseListInfo className="hidden-desktop">
        <span>
          <h2>{title}</h2>
          {link ? <Link to={link}>{linkText}</Link> : null}
        </span>
      </CourseListInfo>

      <ScrollContainer total={totalItems} ref={containerScrollRef}>
        {children[0] ? children : <h5>{textFallback}</h5>}
      </ScrollContainer>
    </Container>
  );
};

export default HorizontalScroll;
