export const theme = {
  colors: {
    primary: '#594AAD',
    secondary: '#2F189C',
    tertiary: '#1E1246',

    error: '#D70926 ',
    warning: '#F7B228',
    success: '#23D735',

    shape: '#F3F9FB',
    shapeSecondary: '#E1E3E6',

    text: '#25282B',
    textSecondary: '#657787',
    textLight: '#FFF',
    textDisabled: '#CFD2D4',

    light: '#FFF',
    gold: '#C0A200',
  },
  fonts: {},
};
