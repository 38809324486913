import React from 'react';
import { AiFillShopping, AiOutlineBars } from 'react-icons/ai';
import { BsEnvelope } from 'react-icons/bs';
import { FaQuestionCircle } from 'react-icons/fa';
import { SiFacebook, SiInstagram, SiYoutube, SiWhatsapp } from 'react-icons/si';
import { Link } from 'react-router-dom';

import logoFooter from '../../../../assets/images/logo-footer.png';

import {
  UnloggedFooterContainer,
  UnloggedFooterUpperContent,
  UnloggedFooterSocialMedias,
  UnloggedFooterBannerContainer,
  UnloggedFooterContacts,
  UnloggedFooterLowerContent,
  UnloggedFooterCopyright,
  UnloggedFooterDivider,
} from './style';

const UnloggedFooter = () => {
  return (
    <UnloggedFooterContainer>
      <UnloggedFooterUpperContent className="max-content">
        <UnloggedFooterSocialMedias>
          <a
            href="https://www.facebook.com/HaluGamashi.HG"
            target="_blank"
            rel="noreferrer"
          >
            <span>
              <SiFacebook />
            </span>
            <span>Facebook</span>
          </a>
          <a
            href="https://www.instagram.com/halu.gamashi/"
            target="_blank"
            rel="noreferrer"
          >
            <span>
              <SiInstagram />
            </span>
            <span>Instagram</span>
          </a>
          <a href="https://www.youtube.com/c/HaluGamashi">
            <span>
              <SiYoutube />
            </span>
            <span>Youtube</span>
          </a>
          <div>
            <span>
              <SiWhatsapp />
            </span>
            <span>
              WhatsApp:
              <br />
              +55 11 95057-8895
            </span>
          </div>
        </UnloggedFooterSocialMedias>

        <UnloggedFooterBannerContainer>
          <img src={logoFooter} alt="Logo Rodapé" />
        </UnloggedFooterBannerContainer>

        <UnloggedFooterContacts>
          <a href="https://halugamashi.com.br" target="_blank" rel="noreferrer">
            <span>
              <AiOutlineBars />
            </span>
            <span>
              Site Halu Gamashi:
              <br />
              https://halugamashi.com.br
            </span>
          </a>
          <a href="https://maberu.com.br" target="_blank" rel="noreferrer">
            <span>
              <AiFillShopping />
            </span>
            <span>
              Nossa Loja:
              <br />
              https://maberu.com.br
            </span>
          </a>
          <Link to="/faq">
            <span>
              <FaQuestionCircle />
            </span>
            <span>Fale Conosco</span>
          </Link>
        </UnloggedFooterContacts>
      </UnloggedFooterUpperContent>

      <UnloggedFooterDivider className="max-content" />

      <UnloggedFooterLowerContent className="max-content">
        <UnloggedFooterCopyright>
          &copy; Copyright 2022 -Todos direitos reservados - HG CURSOS LTDA
        </UnloggedFooterCopyright>
      </UnloggedFooterLowerContent>
    </UnloggedFooterContainer>
  );
};

export default UnloggedFooter;
