import {
  CarouselProvider,
  Slide,
  Slider,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

import CardCourse from '../../../../components/CardCourse';
import useCardCourse from '../../../../hooks/cardCourse';
import useWindowSize from '../../../../hooks/useWindowSize';
import { getContentNextCourses } from '../../../../services/Content';

import { HomeNextLaunchsContainer } from './style';

const HomeNextLaunchs = () => {
  const [contents, setContents] = useState([]);
  const { windowSize } = useWindowSize();
  const { getCardCourseData } = useCardCourse();

  const getContents = useCallback(async () => {
    const {
      data: { data: launchContents },
    } = await getContentNextCourses();
    setContents((launchContents || []).map(c => getCardCourseData(c)));
  }, [getCardCourseData]);

  useEffect(() => {
    getContents();
  }, [getContents]);

  const visibleSlides = useMemo(() => {
    let slides = 0;

    if (windowSize > 1400) {
      slides = 4;
    } else if (windowSize > 1120) {
      slides = 3.4;
    } else if (windowSize > 1000) {
      slides = 2.8;
    } else if (windowSize > 810) {
      slides = 2.4;
    } else if (windowSize > 620) {
      slides = 1.8;
    } else {
      slides = 1;
    }

    return slides;
  }, [windowSize]);

  return (
    <HomeNextLaunchsContainer>
      <h2>Lançamentos</h2>

      {contents.length ? (
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={100}
          totalSlides={contents.length}
          visibleSlides={visibleSlides}
        >
          <Slider className="max-content">
            {contents.map((c, index) => (
              <Slide key={c.id} index={index}>
                <CardCourse showButtons={false} key={c.id} data={c} />
              </Slide>
            ))}
          </Slider>

          <ButtonBack>
            <AiOutlineLeft size={32} />
          </ButtonBack>
          <ButtonNext>
            <AiOutlineRight size={32} />
          </ButtonNext>

          <DotGroup />
        </CarouselProvider>
      ) : (
        <h3>Sem conteúdos programados no momento!</h3>
      )}
    </HomeNextLaunchsContainer>
  );
};

export default HomeNextLaunchs;
