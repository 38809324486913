import React from 'react';

import {
  MasterCardBottomContent,
  MasterCardContainer,
  MasterCardTopContent,
} from './style';

const MasterCard = ({ master }) => {
  return (
    <MasterCardContainer>
      <MasterCardTopContent background={master?.images?.url}>
        <p>{master?.name}</p>
      </MasterCardTopContent>
      <MasterCardBottomContent>
        <p>{master?.description}</p>
      </MasterCardBottomContent>
    </MasterCardContainer>
  );
};

export default MasterCard;
