import React, { useEffect, useState } from 'react';
import { CgProfile } from 'react-icons/cg';

import { useAuth } from '../../../../hooks/auth';
import { getLevelById, getLevels } from '../../../../services/gamification';

import ImageCropper from './ImageCropper';
import { Container, Avatar, Info, Badge } from './styles';

const PersonalData = () => {
  const [userLevel, setUserLevel] = useState('');
  const [isEditing, setEditing] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    const fetchLevel = async () => {
      if (user?.level) return;
      if (undefined === user?.level_id || user?.level_id === null) {
        const response = await getLevels();
        const lowestLevel = response.data.reduce((prev, curr) => {
          return prev?.range?.start < curr?.range?.start ? prev : curr;
        });
        setUserLevel(lowestLevel.name);
      } else {
        const response = await getLevelById(user.level_id);
        setUserLevel(response.data.name);
      }
    };

    fetchLevel();
  }, [user]);

  return (
    <Container>
      <Avatar>
        {!isEditing && user?.images?.avatar && (
          <img src={user.images.avatar} alt="Sua foto de perfil" />
        )}
        {!isEditing && !user?.images?.avatar && (
          <CgProfile alt="Sem foto de perfil" />
        )}
        <ImageCropper setEditing={setEditing} isEditing={isEditing} />
      </Avatar>

      <Info>
        <Badge>{userLevel}</Badge>
        <h1>{user?.name}</h1>
        <h2>{user?.email}</h2>
      </Info>
    </Container>
  );
};

export default PersonalData;
