import styled from 'styled-components';

import { theme } from '../../../../../styles/theme';

export const Container = styled.li`
  display: flex;
  flex-direction: column;
  list-style: none;
  background-color: transparent;
  padding: 24px 32px;
  position: relative;
  border-bottom: 1px solid #e1e3e6;
  padding: 32px;

  &:hover {
    background-color: ${theme.colors.light};
  }

  > img {
    max-width: 700px;
    max-height: 400px;
    margin-bottom: 24px;
  }

  @media (max-width: 500px) {
    padding: 16px;
  }

  @media (max-width: 500px) {
    > img {
      max-width: 200px;
      max-height: 200px;
    }
  }

  @media (max-width: 900px) {
    > img {
      max-width: 350px;
      max-height: 350px;
    }
  }
`;

export const Answer = styled.p`
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 28px;

  @media (max-width: 650px) {
    font-size: 0.9rem;
  }

  @media (max-width: 500px) {
    text-align: start;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 42px;

  img {
    margin-right: 9px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  @media (max-width: 650px) {
    margin-top: 21px;
  }

  @media (max-width: 500px) {
    width: fit-content;
    flex-wrap: wrap;
  }
`;

export const GamificationBadge = styled.span`
  margin: 0 16px;
  padding: 4px 12px;

  font-size: 0.8rem;
  font-weight: 600;
  color: ${theme.colors.light};

  background: ${theme.colors.primary};
  border-radius: 30px;
`;

export const UserName = styled.span`
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Published = styled.span`
  font-size: 0.8rem;
  color: ${theme.colors.textSecondary};

  @media (max-width: 500px) {
    margin-top: 8px;
    width: 100%;
    margin-left: 16px;
    color: ${theme.colors.text};
  }
`;

export const ActionIcons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  padding-bottom: 10px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    color: ${theme.colors.secondary};
    span {
      color: ${theme.colors.textSecondary};
    }

    &:not(:first-of-type) {
      margin: 0 21px;
    }
  }

  svg {
    fill: ${theme.colors.secondary};
    margin-right: 8px;
    font-size: 18px;

    &.invert-orientation {
      transform: scaleX(-1);
    }
  }
`;

export const AnswerContainer = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 32px 40px 0;
  position: relative;

  div.input {
    flex: 1;
  }

  .spinner {
    margin-left: 19px;
  }

  img:first-of-type {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 19px;
  }

  input[type='file'] {
    visibility: hidden;
    width: 0;
  }

  > svg {
    width: 24px;
    height: 24px;

    position: absolute;
    right: 230px;

    color: ${theme.colors.primary};
    cursor: pointer;
  }

  @media (max-width: 500px) {
    flex-direction: column;

    > svg {
      right: 60px;
      bottom: 92px;
    }

    .spinner {
      margin: unset !important;
    }

    div {
      margin-left: 0 !important;
    }

    img {
      display: none;
    }
  }
`;

export const MoreAnswers = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
  margin: 32px 0 auto;
  color: ${theme.colors.secondary};

  span {
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const CommentsList = styled.ul`
  max-height: 400px;
  overflow-y: auto;

  li:not(:last-child) {
    border-bottom: 1px solid #e1e3e6;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #e1e3e6;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #2f189c;
    border-radius: 10px;
  }
`;

export const TrashIcon = styled.span`
  display: flex;
  align-items: center;

  color: #ff0000;
  font-size: 0.9rem;
  z-index: 1;

  > svg {
    fill: #ff0000;
    margin-right: 10px;
  }

  @media (max-width: 650px) {
    position: static;
    margin-top: 12px;
  }

  @media (max-width: 500px) {
    justify-content: center;
  }
`;
