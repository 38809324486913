import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';
import { ReactComponent as Identification } from '../../assets/icons/identification.svg';
import { ReactComponent as TelephoneIcon } from '../../assets/icons/telephone.svg';
import { ReactComponent as UnlockedIcon } from '../../assets/icons/unlocked.svg';
import { Button } from '../../components/Button';
import HeaderLoginAndRegister from '../../components/HeaderLoginAndRegister';
import { Input } from '../../components/Input';
import Spinner from '../../components/Spinner';
import { RegisterLayoutRoutes } from '../../routes/RegisterLayoutRoutes';
import { AuthService } from '../../services/authService';
import { goToTop } from '../../utils/helpers';
import {
  regexPasswordPhrase,
  regexPasswordValidation,
} from '../../utils/regexPasswordValidation';

import { Container, FormContainer, SpinnerWrapper } from './styles';

goToTop();

const schema = yup.object().shape({
  document: yup.string().required('Documento é obrigatório'),
  telephone: yup
    .string()
    .required('Telefone é obrigatório')
    .min(8, 'Digite um telefone válido'),
  email: yup
    .string()
    .email('Digite um email válido')
    .required('Email é obrigatório'),
  password: yup
    .string()
    .required('Senha é obrigatória')
    .matches(regexPasswordValidation, regexPasswordPhrase),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Senhas não conferem'),
});

const CompleteRegistration = () => {
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  async function handleRegister(data) {
    const authService = new AuthService();
    const { name, email, phoneNumber, password, document } = data;

    setLoading(true);
    try {
      await authService.firstLogin({
        name,
        email,
        phoneNumber,
        password,
        document,
      });

      setLoading(false);
      toast.info('Cadastro completo! Confirme sua conta antes de continuar');
      history.push('/registro/confirmar-conta', { redirectEmail: email });
    } catch {
      setLoading(false);
      toast.error(
        'Ocorreu um erro ao finalizar seu cadastro, por favor tente novamente',
      );
    }
  }

  return (
    <RegisterLayoutRoutes>
      <Container>
        <HeaderLoginAndRegister
          className="hidden-mobile"
          buttonText="JÁ SOU ASSINANTE"
        />
        <HeaderLoginAndRegister
          className="hidden-desktop"
          buttonText="JÁ SOU"
          buttonSubText="ASSINANTE"
        />

        <h1>Complete seu cadastro</h1>

        <FormContainer>
          <form>
            <Input
              placeholder="Documento de Identificação"
              icon={<Identification />}
              isInvalid={errors.document?.message}
              errorMessage={errors.document?.message}
              {...register('document')}
            />
            <Input
              placeholder="Email"
              type="email"
              icon={<EmailIcon />}
              isInvalid={errors.email?.message}
              errorMessage={errors.email?.message}
              {...register('email')}
            />
            <Input
              placeholder="Telefone"
              type="telephone"
              icon={<TelephoneIcon />}
              isInvalid={errors.telephone?.message}
              errorMessage={errors.telephone?.message}
              {...register('telephone')}
            />

            <Input
              placeholder="Senha"
              type="password"
              icon={<UnlockedIcon />}
              isInvalid={errors.password?.message}
              errorMessage={errors.password?.message}
              {...register('password')}
            />
            <Input
              placeholder="Confirme a senha"
              type="password"
              icon={<UnlockedIcon />}
              isInvalid={errors.passwordConfirmation?.message}
              errorMessage={errors.passwordConfirmation?.message}
              {...register('passwordConfirmation')}
            />

            {loading ? (
              <SpinnerWrapper>
                <Spinner />
              </SpinnerWrapper>
            ) : (
              <Button
                onClick={handleSubmit(handleRegister)}
                type="submit"
                title="CADASTRAR"
                containerStyle={{ marginTop: 32 }}
              />
            )}
          </form>
        </FormContainer>
      </Container>
    </RegisterLayoutRoutes>
  );
};

export default CompleteRegistration;
