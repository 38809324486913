import React from 'react';

import { EmbedContainer } from './styles';

function SVLivePlayer({ liveUrl }) {
  return (
    <EmbedContainer>
      <div className="video">
        <iframe
          id="playerId"
          title="live-player"
          allowFullScreen
          frameBorder="0"
          width="100%"
          height="100%"
          src={liveUrl}
        />
      </div>
    </EmbedContainer>
  );
}

export default SVLivePlayer;
