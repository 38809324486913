import styled from 'styled-components';

import { theme } from '../../styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: url(${({ bg }) => bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 48px;
  min-height: 564px;
  max-height: 564px;

  @media (max-width: 480px) {
    flex-direction: row;
    width: 100%;
    min-width: 100%;
    padding: 0;
  }
`;

export const Overlay = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 160px;
  padding-left: 172px;
  padding-right: 148px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.664) 0%,
    rgba(0, 0, 0, 0) 100%
  );

  min-height: 564px;
  max-height: 564px;

  @media (max-width: 1100px) {
    padding: 160px 32px 0 24px;

    .hidden-desktop.button {
      min-width: fit-content !important;

      align-self: flex-start !important;

      margin-bottom: 32px !important;
    }
  }

  @media (max-width: 480px) {
    padding: 120px 0 0 0;
    margin: 0;
    justify-content: space-between;
    min-height: 100%;

    .hidden-desktop.button {
      margin-left: 20px !important;
    }
  }

  .button {
    width: fit-content;
    height: fit-content;

    > button {
      width: fit-content;
      height: fit-content;

      font-size: 16px;
      letter-spacing: 2px;
      font-weight: 700;
      color: white;
    }
  }
`;

export const BannerHeader = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${theme.colors.textLight};
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    min-width: 100%;
    width: 100%;
  }

  svg {
    color: ${theme.colors.textLight};
    width: 24px;
    height: 24px;
  }
`;

export const Tag = styled.span`
  width: fit-content;
  height: fit-content;

  display: flex;
  justify-content: center;

  padding: 6px 20px;
  margin-right: 35px;
  background-color: ${theme.colors.primary};
  border-radius: 20px;
  text-align: center;
  color: ${theme.colors.textLight};
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 4px;
  font-weight: 600;

  @media (max-width: 480px) {
    margin: 0 0 20px 16px;
    justify-content: flex-start;
  }
`;

export const MobileDateAndDuration = styled.div`
  flex-direction: row;
  display: flex;
`;

export const Date = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 480px) {
    padding: 0 33px 16px 16px;
    flex: 1;
  }

  span {
    margin-left: 8px;
    white-space: nowrap;
  }

  img {
    width: 24px;
    height: 24px;
  }
`;

export const Duration = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  margin-left: 32px;
  @media (max-width: 480px) {
    padding: 0 0 16px 16px;
    margin: 0;
  }

  span {
    margin-left: 8px;
  }

  svg {
    color: ${theme.colors.textLight};
    width: 24px;
    height: 24px;
  }
`;

export const BannerContent = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  @media (max-width: 480px) {
    padding: 0 16px;
    flex: 1;
  }

  h1 {
    margin: 16px 0 24px;

    font-size: 50px;
    line-height: 64px;
    font-weight: 800;
    text-align: start;
    color: ${theme.colors.textLight};

    @media (max-width: 480px) {
      font-weight: 800;
      font-size: 30px;
      line-height: 42px;
    }
  }

  span,
  cite {
    color: ${theme.colors.textLight};
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    text-align: start;
    font-style: normal;
    @media (max-width: 480px) {
      font-weight: 600;
      font-size: 16px;
    }
  }
`;
