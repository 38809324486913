import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link, useHistory } from 'react-router-dom';
import FooterUnlogged from '../../components/FooterUnlogged';
import iconBack from '../../assets/icons/icon-back.svg';
import bannerAboutHaluGamashi from '../../assets/images/banner-about-halu-gamashi.png';
import Header from '../../components/Header';
import { getTermsContent } from '../../services/HomeInfo';
import { goToTop } from '../../utils/helpers';

import { ContentHeader, Content } from './styles';

const PrivacyPolicy = () => {
  const [content, setContent] = useState(null);
  const history = useHistory();
  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    (async () => {
      const response = await getTermsContent();
      setContent(response.data[0]);
    })();
  }, []);

  goToTop();

  return (
    <>
      <Header
        className="hidden-mobile"
        backgroundImage={bannerAboutHaluGamashi}
      >
        <ContentHeader>
          <div>
            <img src={iconBack} alt="Icone Voltar" />
            <span
              onKeyPress={handleGoBack}
              role="button"
              tabIndex="0"
              onClick={handleGoBack}
            >
              Voltar
            </span>
          </div>

          <h1>
            Política de Privacidade
            <span>Política de Privacidade</span>
          </h1>
        </ContentHeader>
      </Header>

      <Content>
        {content ? (
          <div
            dangerouslySetInnerHTML={{ __html: content.info.privacyPolicy }}
          />
        ) : (
          <>
            {[...Array(2).keys()].map(() => (
              <>
                <Skeleton
                  count={1}
                  style={{ marginTop: 10 }}
                  width="100%"
                  height={15}
                />
                <Skeleton count={3} width="100%" height={15} />
                <Skeleton count={3} width="80%" height={15} />
                <Skeleton count={2} width="70%" height={15} />

                <Skeleton
                  count={1}
                  style={{ marginTop: 10 }}
                  width="100%"
                  height={15}
                />
                <Skeleton count={3} width="100%" height={15} />
                <Skeleton count={3} width="80%" height={15} />
                <Skeleton count={2} width="70%" height={15} />
              </>
            ))}
          </>
        )}
      </Content>
      <FooterUnlogged />
    </>
  );
};

export default PrivacyPolicy;
