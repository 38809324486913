import React from 'react';
import { useHistory } from 'react-router-dom';

import ResponsivePlayer from '../../../../components/ResponsivePlayer';
import { VideoWrapper } from '../../../../components/VideoWrapper';
import { controlsToEnable } from '../../../../configs/SvPlayer';
import useWindowSize from '../../../../hooks/useWindowSize';

import {
  HomeBannerContainer,
  ImageFilter,
  HomeBannerContent,
  HomeBannerLeftContent,
  HomeBannerRightContent,
} from './style';

const HomeBanner = ({ bannerUrl, mediaUrl }) => {
  const history = useHistory();
  const { windowSize } = useWindowSize();

  const handleGoToLogin = () => {
    history.push('/logar');
  };

  return (
    <HomeBannerContainer background={bannerUrl}>
      <ImageFilter />
      <HomeBannerContent className="max-content">
        <HomeBannerLeftContent>
          <h2>
            INSTITUTO DOS
            <br /> MISTÉRIOS ASCENSIONAIS
          </h2>

          {windowSize <= 768 && (
            <VideoWrapper>
              <ResponsivePlayer
                contentUrl={mediaUrl}
                controlsToEnable={controlsToEnable}
                id="playerDesktopLadingPageIMA"
              />
            </VideoWrapper>
          )}

          <p>
            Um Místico é todo aquele que busca a revelação dos Mistérios.
            Começando pelos Mistérios Menores, preparatórios para o despertar. E
            os Mistérios Maiores, preparatórios para a Ascensão...
          </p>
          <button type="button" onClick={handleGoToLogin}>
            COMEÇAR MINHA JORNADA
          </button>
        </HomeBannerLeftContent>
        {windowSize > 768 && (
          <HomeBannerRightContent>
            <VideoWrapper>
              <ResponsivePlayer
                contentUrl={mediaUrl}
                controlsToEnable={controlsToEnable}
                id="playerDesktopLadingPageIMA"
              />
            </VideoWrapper>
          </HomeBannerRightContent>
        )}
      </HomeBannerContent>
    </HomeBannerContainer>
  );
};

export default HomeBanner;
